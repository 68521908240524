export var planTypes = [
    { id: "10 Year Term", name: "10 Year Term" },
    { id: "15 Year Term", name: "15 Year Term" },
    { id: "20 Year Term", name: "20 Year Term" },
    { id: "25 Year Term", name: "25 Year Term" },
    { id: "30 Year Term", name: "30 Year Term" },
    { id: "GUL to 85", name: "GUL to 85" },
    { id: "GUL to 90", name: "GUL to 90" },
    { id: "GUL to 95", name: "GUL to 95" },
    { id: "GUL to 100", name: "GUL to 100" },
    { id: "GUL to 105", name: "GUL to 105" },
    { id: "GUL to 110", name: "GUL to 110" },
    { id: "GUL to 121", name: "GUL to 121" },
    { id: "iUL", name: "iUL" },
    { id: "UL", name: "UL" },
    { id: "WholeLife", name: "WholeLife" },
    { id: "Conversion", name: "Conversion" },
];
export var modalities = [
    { id: "Monthly", name: "Monthly" },
    { id: "Quarterly", name: "Quarterly" },
    { id: "Semi-Annually", name: "Semi-Annually" },
    { id: "Annually", name: "Annually" },
];
