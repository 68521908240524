import React from "react";
import { Box, ListItemButton, ListItemText, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import FileUploadForm from "./FileUploadForm";
var ManageDocuments = function (_a) {
    var _b, _c;
    var resource = _a.resource, resourceType = _a.resourceType;
    return (React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Box, { mx: 1, mt: 3 },
                React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Attachments"))),
        React.createElement(Grid, { xs: 12 },
            React.createElement(Box, { mx: 1 },
                React.createElement(FileUploadForm, { memberId: resource === null || resource === void 0 ? void 0 : resource.member_id, userId: (_b = resource === null || resource === void 0 ? void 0 : resource.transfer_user_id) !== null && _b !== void 0 ? _b : resource === null || resource === void 0 ? void 0 : resource.created_by, name: resourceType, id: resource.id }))),
        React.createElement(Grid, { xs: 12, style: { marginBottom: "14px" } },
            React.createElement(Box, { sx: {
                    width: "100%",
                    height: 220,
                    maxWidth: "100%",
                    bgcolor: "background.paper",
                    overflow: "auto",
                } }, (_c = resource === null || resource === void 0 ? void 0 : resource.documents) === null || _c === void 0 ? void 0 : _c.map(function (el) {
                try {
                    return (React.createElement(ListItemButton, { sx: { py: 0 }, key: el.id, component: "a", href: "/file/show?path=".concat(btoa(el.file_name)), target: "_blank" },
                        React.createElement(ListItemText, null, el.file_name)));
                }
                catch (e) {
                    return (React.createElement(ListItemButton, { key: el.id }, el.file_name));
                }
            })))));
};
export default ManageDocuments;
