import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { Typography, Modal, Dialog, DialogActions, DialogContent, } from "@mui/material";
import { useForm } from "react-hook-form";
import { router } from '@inertiajs/react';
import WarningIcon from '@mui/icons-material/Warning';
var GenericDeleteButton = function (_a) {
    var deleteRoute = _a.deleteRoute, label = _a.label, variant = _a.variant, id = _a.id, appType = _a.appType;
    var _b = useForm(), handleSubmit = _b.handleSubmit, formErrors = _b.formState.errors;
    var _c = useState(false), openDelete = _c[0], setOpenDelete = _c[1];
    var variantType = variant == "outlined" ? "outlined" : "contained";
    // delete
    var submitDelete = function () {
        setOpenDelete(false);
        if (appType) { // exception for app's polymorphism
            router.post(deleteRoute, {
                appable_id: id,
                appable_type: appType,
                route: window.location.href
            });
        }
        else {
            router.post(deleteRoute, {
                id: id,
                route: window.location.href
            });
        }
    };
    var handleCloseDelete = function () {
        setOpenDelete(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: variantType, color: "secondary", fullWidth: true, size: "small", onClick: function () {
                setOpenDelete(true);
            } },
            "Delete ",
            label),
        React.createElement(Modal, { open: openDelete, onClose: handleCloseDelete },
            React.createElement(Dialog, { open: openDelete, onClose: handleCloseDelete },
                React.createElement(DialogContent, { style: { minWidth: "500px" } },
                    React.createElement(Grid, { container: true, spacing: 2 },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Typography, { variant: "h5", component: "h5", className: "section-title" }, "Are you sure?"),
                            React.createElement("div", null,
                                React.createElement("br", null),
                                React.createElement(WarningIcon, { color: "secondary", fontSize: "large" }),
                                "\u00A0 This ",
                                label,
                                " will be deleted.")))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleCloseDelete, color: "primary", size: "small" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitDelete), color: "primary", size: "small" }, "Delete"))))));
};
export default GenericDeleteButton;
