import React from "react";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import AmacAccountProfileLink from "./AmacAccountProfileLink";
var AmacAccountSubmitButtons = function (_a) {
    var onSubmit = _a.onSubmit, amac_account = _a.amac_account, success = _a.success, handleSubmit = _a.handleSubmit, buttonLabel = _a.buttonLabel, isSubmitting = _a.isSubmitting;
    return (React.createElement(Grid, { container: true, alignItems: "center", justifyContent: "center", spacing: 2, xs: 12 }, success ? (React.createElement(AmacAccountProfileLink, { amac_account: amac_account })) : (React.createElement(Grid, null,
        React.createElement(Button, { type: "submit", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true, disabled: isSubmitting }, buttonLabel)))));
};
export default AmacAccountSubmitButtons;
