import React from "react";
import isEmpty from "lodash/isEmpty";
import DefaultBtn from "../../../components/DefaultBtn";
import Grid from "@mui/material/Unstable_Grid2";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
var LinkFormFields = function (_a) {
    var link = _a.link, index = _a.index, control = _a.control, handleDeleteLink = _a.handleDeleteLink;
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 2, alignItems: "center" },
        React.createElement(Grid, { xs: 12 },
            React.createElement(GenericControllerTextInput, { className: "login-input", variant: "outlined", label: "Link Name", type: "text", control: control, name: "links[link-" + link.id + "][name]", defaultValue: !isEmpty(link) ? link.name : "" })),
        React.createElement(Grid, { xs: 12 },
            React.createElement(GenericControllerTextInput, { className: "login-input", variant: "outlined", label: "Link URL", type: "text", control: control, name: "links[link-" + link.id + "][url]", defaultValue: !isEmpty(link) ? link.url : "" })),
        React.createElement(Grid, { xs: 12 },
            React.createElement(DefaultBtn, { type: "button", "data-link_id": link.id, "data-index": index, onClick: handleDeleteLink, variant: "contained", color: "primary" }, "Delete Link"))));
};
export default LinkFormFields;
