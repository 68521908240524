import React, { useState } from "react";
import Layout from "../Layout";
import SubordinatesTable from "./components/SubordinateTable";
import { Grid } from "@mui/material";
import HomeMenu from "./HomeMenu";
export default function Agents(_a) {
    var _b;
    var response_data = _a.response_data, auth = _a.auth;
    var user = response_data.user, totals = response_data.totals;
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    return (React.createElement(Layout, { title: "Home", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(HomeMenu, { auth: auth, user: user, title: "Team", data: {}, totals: totals }),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(SubordinatesTable, { subordinates: (_b = user === null || user === void 0 ? void 0 : user.subordinates) !== null && _b !== void 0 ? _b : [] })))));
}
