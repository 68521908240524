import React from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import { Box, Typography } from "@mui/material";
export var BrokerQuestionsCard = function (_a) {
    var broker = _a.broker;
    var rows = [
        ["Diversifying my current business", "diversifying"],
        ["Growing my revenue", "growing_revenue"],
        ["All that AMAC stands for", "amac_values"],
        ["Leads", "want_leads"],
        ["Sales Support", "want_support"],
        ["Sales Literature", "want_literature"],
        ["'A' Rated Carriers", "want_training"],
        ["Training", "want_carriers"],
        ["Medicare Advantage (Medicare Part C)", "offer_med_c"],
        ["Medicare Supplement Plans (MediGap)", "offer_medigap"],
        ["Medicare Prescription Drug Plans (Medicare Part D)", "offer_med_d"],
        ["Guaranteed Acceptance Life", "offer_gal"],
        ["Individual Life Insurance", "offer_end_life"],
        ["Group Life Insurance", "offer_group_life"],
        ["Group Health Insurance", "offer_group_health"],
        ["Long Term Care Insurance", "offer_ltc"],
        ["Dental Insurance", "offer_dental"],
        ["Vision Insurance", "offer_vision"],
    ];
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { xs: 12, style: {
                backgroundColor: "#F1F1F1",
                padding: "6px",
                marginBottom: "14px",
            } },
            React.createElement(Typography, { variant: "h6" }, "Broker Questions")),
        rows.map(function (row) {
            return (React.createElement(Grid, { container: true, xs: 12, key: "broker_".concat(row[1]) },
                React.createElement(Grid, { xs: 6 },
                    React.createElement(Box, null,
                        React.createElement(Typography, { variant: "body2" },
                            row[0],
                            ":"))),
                React.createElement(Grid, { xs: 6 },
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, broker["".concat(row[1])] ? "Yes" : "No"))));
        })));
};
