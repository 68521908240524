import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import _ from "lodash";
import NumberFormat from "react-number-format";
var ContactDonationsOverview = function (_a) {
    var contact = _a.contact, showDefault = _a.showDefault;
    var _b = useState(showDefault), show = _b[0], setShow = _b[1];
    var last_donation_date = new Date(Math.max.apply(null, contact === null || contact === void 0 ? void 0 : contact.donations.slice().map(function (e) {
        return new Date(e.donation_date);
    })));
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "10px #E21D37 solid",
            borderRight: "2px #E21D37 solid",
            borderBottom: "2px #E21D37 solid",
            borderTop: "2px #E21D37 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Box, { m: 1 },
                React.createElement(Typography, { variant: "body1" }, "Donations Overview"))),
        React.createElement(Grid, { xs: 3 },
            React.createElement(Box, { mx: 1, mb: 1 },
                React.createElement(Typography, { variant: "body2" },
                    "Average Donation:",
                    " ",
                    React.createElement("strong", null,
                        " ",
                        React.createElement(NumberFormat, { value: (contact === null || contact === void 0 ? void 0 : contact.donations.length) > 0
                                ? _.meanBy(contact === null || contact === void 0 ? void 0 : contact.donations, function (d) { return parseInt(d.amount); })
                                : 0, displayType: "text", decimalScale: 2, fixedDecimalScale: true, thousandSeparator: true, prefix: "$" }))),
                React.createElement(Typography, { variant: "body2" },
                    "Total Donated:",
                    " ",
                    React.createElement("strong", null,
                        " ",
                        React.createElement(NumberFormat, { value: _.sumBy(contact === null || contact === void 0 ? void 0 : contact.donations, function (d) {
                                return parseInt(d.amount);
                            }), displayType: "text", decimalScale: 2, fixedDecimalScale: true, thousandSeparator: true, prefix: "$" }))),
                React.createElement(Typography, { variant: "body2" },
                    "Last Donation Date:",
                    React.createElement("strong", null,
                        " ",
                        (contact === null || contact === void 0 ? void 0 : contact.donations.length) > 0
                            ? last_donation_date === null || last_donation_date === void 0 ? void 0 : last_donation_date.toLocaleDateString("en-US", {
                                timeZone: "UTC",
                            }).replace(/\//g, "-")
                            : "N/A")))),
        React.createElement(Grid, { xs: 3 },
            React.createElement(Box, { mx: 1, mb: 1 },
                React.createElement(Typography, { variant: "body2" },
                    "Congressional District:",
                    React.createElement("strong", null, contact === null || contact === void 0 ? void 0 : contact.congressional_district)))),
        React.createElement(Grid, { xs: 2 })));
};
export default ContactDonationsOverview;
