var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { router } from "@inertiajs/react";
import route from "ziggy-js";
import GenericControllerTextInput from "./GenericControllerTextInput";
var PolicySearch = function () {
    var _a = useForm(), handleSubmit = _a.handleSubmit, control = _a.control;
    var onSubmit = function (data) {
        if (!data.policy_number) {
            return;
        }
        if (!data.policy_number) {
            delete data.policy_number;
        }
        router.visit(route("search_by_policy_number"), {
            method: "post",
            data: data,
            replace: false,
        });
    };
    return (React.createElement(SearchForm, { onSubmit: handleSubmit(onSubmit) },
        React.createElement(GenericControllerTextInput, { className: "amac-input search-input", variant: "outlined", label: "Policy Number", type: "text", control: control, name: "policy_number", size: "small", inputProps: {
                maxLength: 200,
            }, placeholder: "Policy Number", defaultValue: "" })));
};
var SearchForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n\n    .search-input {\n        width: 300px;\n    }\n"], ["\n    position: relative;\n\n    .search-input {\n        width: 300px;\n    }\n"])));
export default PolicySearch;
var templateObject_1;
