import React from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import { BrokerInfoCard } from "./BrokerInfoCard";
import { BrokerContactInfoCard } from "./BrokerContactInfoCard";
import { BrokerAddressCard } from "./BrokerAddressCard";
import { BrokerNoteCard } from "./BrokerNoteCard";
import { BrokerDatesCard } from "./BrokerDatesCard";
import { BrokerQuestionsCard } from "./BrokerQuestionsCard";
var BrokerDetail = function (_a) {
    var broker = _a.broker, user = _a.user;
    return (React.createElement(Grid, { container: true, spacing: 0.5 },
        React.createElement(Grid, { xs: 12, md: 4 },
            React.createElement(BrokerInfoCard, { broker: broker, user: user })),
        React.createElement(Grid, { xs: 12, md: 4 },
            React.createElement(BrokerContactInfoCard, { broker: broker, user: user })),
        React.createElement(Grid, { xs: 12, md: 4 },
            React.createElement(BrokerAddressCard, { broker: broker })),
        React.createElement(Grid, { xs: 12, md: 4 },
            React.createElement(BrokerDatesCard, { broker: broker })),
        React.createElement(Grid, { xs: 12, md: 4 },
            React.createElement(BrokerQuestionsCard, { broker: broker })),
        React.createElement(Grid, { xs: 12, md: 4 },
            React.createElement(BrokerNoteCard, { broker: broker, user: user }))));
};
export default BrokerDetail;
