import React from "react";
import { Typography } from "@mui/material";
import { Link } from "@inertiajs/react";
import route from "ziggy-js";
var ContactLink = function (_a) {
    var contact_id = _a.contact_id, contact_name = _a.contact_name, contact_spouse_name = _a.contact_spouse_name, typography_variant = _a.typography_variant;
    return (React.createElement(Link, { href: route("show_contact", {
            id: contact_id,
        }), style: {
            color: "#000",
        } },
        React.createElement(Typography, { variant: typography_variant !== null && typography_variant !== void 0 ? typography_variant : "body2", style: { fontWeight: "bold" } }, contact_name
            ? contact_name + " (Contact)"
            : contact_spouse_name
                ? contact_spouse_name + " (Spouse)"
                : contact_id)));
};
export default ContactLink;
