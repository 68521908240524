import React, { useState } from "react";
import startCase from "lodash/startCase";
import Layout from "../Layout";
import { useForm } from "react-hook-form";
import { router, usePage } from "@inertiajs/react";
import route from "ziggy-js";
import { Button, Container, Divider, FormControl, InputAdornment, InputLabel, ListItemText, MenuItem, Select, Typography, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { throttle } from "lodash";
import GenericControllerSelect from "../../components/GenericFormFields/Select";
import GenericControllerTextInput from "../../components/GenericFormFields/TextInput";
import { hasPermission } from "../../utils/utils";
export default function OrderAccountEditForm(_a) {
    var response_data = _a.response_data;
    var order = response_data.order;
    var auth = usePage().props.auth;
    order.formType = "account";
    var _b = useForm(), handleSubmit = _b.handleSubmit, errors = _b.formState.errors, watch = _b.watch, control = _b.control;
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    var _d = useState(order.product_id), productId = _d[0], setProductId = _d[1];
    var _e = useState(order.renewal), renewal = _e[0], setRenewal = _e[1];
    var _f = useState(order.created_by), orderUserId = _f[0], setOrderUserId = _f[1];
    var _g = useState(order.status), orderStatus = _g[0], setOrderStatus = _g[1];
    var handleOrderStatusChange = function (e) {
        setOrderStatus(e.target.value);
    };
    var handleOrderAgentIdChange = function (e) {
        setOrderUserId(parseInt(e.target.value));
    };
    var handleProductIdChange = function (e) {
        setProductId(parseInt(e.target.value));
    };
    var handleRenewalChange = function (e) {
        setRenewal(parseInt(e.target.value));
    };
    var renewalOptions = [
        { name: "Yes", id: 1 },
        { name: "No", id: 0 },
    ];
    var onSubmit = function (data) {
        data.product_id = productId;
        data.renewal = renewal;
        data.created_by = orderUserId;
        data.id = order.id;
        data.status = orderStatus;
        router.post("/order/edit", data);
    };
    var handleCancel = function () {
        router.get(route("show_account", [order.account_id]));
    };
    return (React.createElement(Layout, { title: "Edit Order", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Typography, { variant: "h4" }, "Edit Order Form"))),
                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerSelect, { label: "Product", name: "product-select", defaultValue: productId, handleChange: handleProductIdChange, isDisabled: true, control: control, customOptions: response_data.products.map(function (product) {
                                return (React.createElement(MenuItem, { key: product.id, value: product.id, classes: {} }, startCase(product.name)));
                            }) })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerSelect, { label: "Renewal", name: "renewal-select", defaultValue: renewal || "", handleChange: handleRenewalChange, control: control, 
                            // options={renewalOptions}
                            customOptions: renewalOptions.map(function (option) {
                                return (React.createElement(MenuItem, { key: "".concat(option.name, "_").concat(option.id), value: option.id },
                                    React.createElement(ListItemText, { primary: option.name })));
                            }) })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { className: "login-input", variant: "outlined", label: "Transaction Id", type: "text", control: control, name: "usaepay_id", defaultValue: order.usaepay_id, fullWidth: true })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { className: "login-input", variant: "outlined", label: "Order Amount", type: "text", control: control, rules: {
                                required: true,
                            }, name: "order_amount", defaultValue: order.order_amount, helperText: errors.order_amount
                                ? "This is a required field"
                                : null, InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" }, "$")),
                            }, fullWidth: true }))),
                React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                    React.createElement(Divider, { light: true })),
                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                    hasPermission(auth, "edit order agent") && (React.createElement(Grid, { xs: 12, md: 6, lg: 4 },
                        React.createElement(GenericControllerSelect, { label: "Order Agent", name: "order-agent-select", defaultValue: orderUserId, control: control, handleChange: handleOrderAgentIdChange, customOptions: response_data.agents.map(function (agent) {
                                return (React.createElement(MenuItem, { key: "agent" + agent.id, value: agent.id }, startCase(agent === null || agent === void 0 ? void 0 : agent.name)));
                            }) }))),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 4 },
                        React.createElement(GenericControllerTextInput, { className: "login-input", variant: "outlined", label: "Payment Type", type: "text", control: control, name: "payment_type", defaultValue: order.payment_type, fullWidth: true })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 4 },
                        React.createElement(FormControl, { variant: "outlined", fullWidth: true },
                            React.createElement(InputLabel, { id: "order-status-label" }, "Order Status"),
                            React.createElement(Select, { label: "Order Status", labelId: "order-status-label", id: "order-status-select", defaultValue: orderStatus, onChange: handleOrderStatusChange }, response_data.order_statuses.map(function (orderStatusEnum) {
                                return (React.createElement(MenuItem, { key: orderStatusEnum, value: orderStatusEnum }, startCase(orderStatusEnum)));
                            }))))),
                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                    React.createElement(Divider, { light: true })),
                React.createElement(Grid, { xs: 12, container: true, alignItems: "center", justifyContent: "center", spacing: 2 },
                    React.createElement(Grid, { xs: 12, md: 4, lg: 3 },
                        React.createElement(Button, { type: "submit", variant: "outlined", color: "secondary", fullWidth: true, onClick: handleCancel }, "Cancel")),
                    React.createElement(Grid, { xs: 12, md: 4, lg: 3 },
                        React.createElement(Button, { type: "submit", variant: "contained", color: "primary", onClick: throttle(handleSubmit(onSubmit), 2000), fullWidth: true }, "Save Order")))))));
}
