import React from "react";
import { TableCell, Paper, Table, TableBody, TableContainer, TableHead, TableRow, } from "@mui/material";
import OrderRow from "./OrderRow";
var OrderTable = function (_a) {
    var data = _a.data;
    return (React.createElement(TableContainer, { component: Paper },
        React.createElement(Table, { className: "member-lead-table" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "ID"),
                    React.createElement(TableCell, null, "Payment"),
                    React.createElement(TableCell, null, "Product/Status"),
                    React.createElement(TableCell, null, "Referrals"),
                    React.createElement(TableCell, null, "Created/Updated By"),
                    React.createElement(TableCell, null))),
            React.createElement(TableBody, { className: "member-lead-body" }, data.map(function (order, index) { return (React.createElement(OrderRow, { key: index, order: order })); })))));
};
export default OrderTable;
