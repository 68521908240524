import { Link } from "@inertiajs/react";
import { Box, Button, Grid } from "@mui/material";
import React from "react";
var defaultProps = {
    hasCustomStyles: false
};
export var BackToAccount = function (_a) {
    var account = _a.account, hasCustomStyles = _a.hasCustomStyles;
    return (hasCustomStyles ?
        React.createElement(Link, { href: "/account/show/".concat(account.id), style: { textDecoration: "none" } },
            React.createElement(Button, { variant: "contained", size: "small" }, "Back to Account"))
        :
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center" },
                React.createElement(Box, null,
                    React.createElement(Link, { href: "/account/show/".concat(account.id), style: { textDecoration: "none" } },
                        React.createElement(Button, { variant: "contained", size: "small" }, "Back to Account")))));
};
