import { Box, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import Layout from "../../../Layout";
import { ContactNavWrapper } from "../Navigation/ContactNavWrapper";
import ContactDocument from "./ContactDocument";
import ContactFileUploadForm from "../../../../components/ContactFileUploadForm";
import ContactHeader from "../ContactHeader";
var ContactDocuments = function (_a) {
    var response_data = _a.response_data, auth = _a.auth;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var contact = response_data.contact, all_documents = response_data.all_documents;
    return (React.createElement(Layout, { title: "Contact Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(ContactNavWrapper, { contact: contact, all_documents: all_documents, activity_log: response_data === null || response_data === void 0 ? void 0 : response_data.activity_log }))),
        React.createElement(Paper, { style: { minHeight: "80vh" } },
            React.createElement(Grid, { container: true },
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement(ContactHeader, { contact: contact })),
                React.createElement(Grid, { item: true, xs: 9 }),
                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                    React.createElement(Box, { mx: 1, mt: 3 },
                        React.createElement(ContactFileUploadForm, { contactId: contact.id, userId: auth.user.id, name: "contacts", id: contact.id }))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { m: 2 },
                        React.createElement(ContactDocument, { data: all_documents })))))));
};
export default ContactDocuments;
