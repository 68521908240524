import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import React, { useState } from "react";
import NotesForm from "../../components/NotesForm";
import MemberNotes from "../Member/components/MemberNotes";
import { getNoteParentID, getNoteRouteFromModel } from "../../utils/taskUtils";
var TaskNoteCard = function (_a) {
    var task = _a.task;
    var _b = useState(false), addNotesFlag = _b[0], setAddNotesFlag = _b[1];
    var handleAddNotesFlag = function () {
        setAddNotesFlag(!addNotesFlag);
    };
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { xs: 12, style: { marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Grid, { container: true },
                    React.createElement(Grid, { xs: 6 },
                        React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Notes:")),
                    React.createElement(Grid, { container: true, xs: 6, justifyContent: "flex-end", alignContent: "center" }, !addNotesFlag && (React.createElement(Button, { type: "button", onClick: handleAddNotesFlag, variant: "outlined", color: "primary", size: "small", style: {
                            fontSize: "10px",
                            fontWeight: "bold",
                            backgroundColor: "#1871BF",
                            color: "#FFF",
                        } }, "+ Note")))))),
        addNotesFlag ? (React.createElement(NotesForm, { parentRoute: getNoteRouteFromModel(task.taskable_type), parentId: getNoteParentID(task), noteOwner: task, noteType: "task", handleAddNotesFlag: handleAddNotesFlag })) : (React.createElement(Grid, { xs: 12, mx: 1 },
            React.createElement(MemberNotes, { notes: task === null || task === void 0 ? void 0 : task.task_notes })))));
};
export default TaskNoteCard;
