import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import NumberFormat from "react-number-format";
import { getStateById } from "../utils/utils";
import { helperFunctions } from "../Helpers";
import AmacAccountLink from "./AmacAccountLink";
import AmacAccountStatusIcon from "./AmacAccountStatusIcon";
import AmacAccountNav from "../Pages/AmacAccount/components/Navigation/AmacAccountNav";
var FormAmacAccountInfo = function (_a) {
    var _b, _c;
    var amac_account = _a.amac_account, spouseInfo = _a.spouseInfo, _d = _a.useAmacAccountNav, useAmacAccountNav = _d === void 0 ? false : _d, states = _a.states;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2, component: Paper, sx: {
            borderLeft: "10px #035399 solid",
            borderRight: "2px #035399 solid",
            borderBottom: "2px #035399 solid",
            borderTop: "2px #035399 solid",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 12, md: 0.5, container: true, alignItems: "center", justifyContent: "right" },
            React.createElement(AmacAccountStatusIcon, { status: amac_account.status })),
        React.createElement(Grid, { container: true, xs: 12, md: 11.5 },
            React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(AmacAccountLink, { amac_account_id: amac_account === null || amac_account === void 0 ? void 0 : amac_account.id, amac_account_name: spouseInfo ? undefined : amac_account.full_name, amac_account_spouse_name: spouseInfo ? amac_account.spouse_full_name : undefined, typography_variant: "h6" }),
                React.createElement(Typography, { variant: "body2" }, "# ".concat(amac_account.id)),
                React.createElement(Typography, { variant: "body2" },
                    "DOB:",
                    " ",
                    React.createElement("strong", null, spouseInfo
                        ? helperFunctions.formatDate(amac_account === null || amac_account === void 0 ? void 0 : amac_account.spouse_birth_date)
                        : helperFunctions.formatDate(amac_account === null || amac_account === void 0 ? void 0 : amac_account.birth_date))),
                React.createElement(Typography, { variant: "body2" },
                    "Age:",
                    " ",
                    React.createElement("strong", null, spouseInfo
                        ? helperFunctions.getAge(amac_account === null || amac_account === void 0 ? void 0 : amac_account.spouse_birth_date)
                        : helperFunctions.getAge(amac_account === null || amac_account === void 0 ? void 0 : amac_account.birth_date))),
                React.createElement(Typography, { variant: "body2" },
                    "Email:",
                    " ",
                    React.createElement("strong", null, spouseInfo ? amac_account.spouse_email : amac_account.email)),
                React.createElement(Typography, { variant: "body2" },
                    "Phone:",
                    " ",
                    React.createElement(NumberFormat, { value: spouseInfo ? amac_account.spouse_phone : amac_account.phone, displayType: "text", format: "(###) ###-####", renderText: function (value) { return React.createElement("strong", null, value); } }))),
            React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(Box, { mb: 2 },
                    React.createElement(Typography, { variant: "h6" }, "Mailing Address")),
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("span", null, amac_account.ship_address_1),
                    React.createElement("br", null),
                    React.createElement("span", null, amac_account.ship_address_2 ? amac_account.ship_address_2 : ""),
                    amac_account.ship_address_2 ? React.createElement("br", null) : "",
                    React.createElement("span", null,
                        (amac_account === null || amac_account === void 0 ? void 0 : amac_account.ship_city) ? amac_account.res_city + "," : "",
                        React.createElement("strong", null, " ".concat((_b = getStateById(amac_account.ship_state_id, states)) === null || _b === void 0 ? void 0 : _b.name, " ")),
                        amac_account.ship_zip))),
            React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(Box, { mb: 2 },
                    React.createElement(Typography, { variant: "h6" }, "Residential Address")),
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("span", null, amac_account.res_address_1),
                    React.createElement("br", null),
                    React.createElement("span", null, amac_account.res_address_2 ? amac_account.res_address_2 : ""),
                    amac_account.res_address_2 ? React.createElement("br", null) : "",
                    React.createElement("span", null,
                        (amac_account === null || amac_account === void 0 ? void 0 : amac_account.res_city) ? amac_account.res_city + "," : "",
                        React.createElement("strong", null, " ".concat((_c = getStateById(amac_account.res_state_id, states)) === null || _c === void 0 ? void 0 : _c.name, " ")),
                        amac_account.res_zip)))),
        useAmacAccountNav && (React.createElement(AmacAccountNav, { amac_account: amac_account, order_count: amac_account === null || amac_account === void 0 ? void 0 : amac_account.orders_count, lead_count: amac_account === null || amac_account === void 0 ? void 0 : amac_account.leads_count, app_count: amac_account === null || amac_account === void 0 ? void 0 : amac_account.apps_count, policy_count: amac_account === null || amac_account === void 0 ? void 0 : amac_account.policies_count, document_count: amac_account === null || amac_account === void 0 ? void 0 : amac_account.documents_count, donation_count: amac_account === null || amac_account === void 0 ? void 0 : amac_account.donations_count, activity_log_count: amac_account === null || amac_account === void 0 ? void 0 : amac_account.activities_count }))));
};
export default FormAmacAccountInfo;
