import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../css/app.css";
// import GenericContactNameForm from "./GenericFormFields/GenericContactNameForm";
import GenericPhoneInput from "./GenericFormFields/GenericPhoneInput";
import GenericControllerTextInput from "./GenericFormFields/TextInput";
var AmacAccountInfoForm = function (props) {
    var _a, _b, _c, _d;
    var amac_account = props.amac_account, errors = props.errors, control = props.control, produceAmacAccounts = props.produceAmacAccounts, //don't produce amac_accounts if function not provided
    _e = props.data, //don't produce amac_accounts if function not provided
    data = _e === void 0 ? {} : _e;
    return (React.createElement(Grid, { xs: 12, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "AmacAccount Information")),
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { onblur: produceAmacAccounts, control: control, defaultValue: (_a = amac_account === null || amac_account === void 0 ? void 0 : amac_account.email) !== null && _a !== void 0 ? _a : "", type: "email", label: "Email", name: "email", rules: { required: false }, error: "email" in errors })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { onblur: produceAmacAccounts, control: control, type: "date", label: "Birth Date", name: "birth_date", rules: { required: false }, defaultValue: (_b = amac_account === null || amac_account === void 0 ? void 0 : amac_account.birth_date) !== null && _b !== void 0 ? _b : "", error: "birth_date" in errors, shrinkLabel: true }))),
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericPhoneInput, { name: "phone", label: "Phone", defaultValue: (_c = amac_account === null || amac_account === void 0 ? void 0 : amac_account.phone) !== null && _c !== void 0 ? _c : "", control: control, helperText: "Please provide full phone (###) ###-####.", required: true, onblur: produceAmacAccounts })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericPhoneInput, { label: "Alt. Phone 1", defaultValue: (_d = amac_account === null || amac_account === void 0 ? void 0 : amac_account.phone_alt_1) !== null && _d !== void 0 ? _d : "", name: "phone_alt_1", control: control, required: false, helperText: "Please provide full phone (###) ###-####.", onblur: produceAmacAccounts })))));
};
export default AmacAccountInfoForm;
