import React, { useState } from "react";
import { Button, Box, Modal, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { router, Link, usePage } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import { now } from "lodash";
import GenericControllerTextInput from "../../../../components/GenericFormFields/TextInput";
import { hasPermission, agentHasRole, agentNumRoles, } from "../../../../utils/utils";
import route from "ziggy-js";
import { Alert } from "@mui/material";
import GenericRouteButton from "../../../../components/GenericRouteButton";
import ModalAccountRecurring from "../../../../components/Modals/ModalAccountRecurring";
var AccountAction = function (_a) {
    var _b, _c, _d, _e, _f;
    var account = _a.account, 
    //accountStatuses,
    leaveReasons = _a.leaveReasons;
    // const [accountStatus, setAccountStatus] = useState(account?.status);
    // const [frontEndStatus, setFrontEndStatus] = useState(account?.status);
    // const [openStatusUpdate, setOpenStatusUpdate] = useState(false);
    var _g = useState(false), openExpiration = _g[0], setOpenExpiration = _g[1];
    var _h = useState(false), openAccountSince = _h[0], setOpenAccountSince = _h[1];
    var _j = useState(false), clearLeaveReason = _j[0], setClearLeaveReason = _j[1];
    var _k = useState((_b = account === null || account === void 0 ? void 0 : account.account_leave_reason_id) !== null && _b !== void 0 ? _b : ""), accountLeaveReasonId = _k[0], setAccountLeaveReasonId = _k[1];
    var _l = useState(false), open = _l[0], setOpen = _l[1];
    var auth = usePage().props.auth;
    // const frontEndStatuses = [...accountStatuses, "cancelled"];
    var _m = useForm({
        shouldUnregister: true,
    }), handleSubmit = _m.handleSubmit, formErrors = _m.formState.errors, control = _m.control, watch = _m.watch;
    var watchPassword = watch("password", "");
    var watchPasswordConfirm = watch("password_confirm", "");
    /* const handleStatusUpdate = () => {
        setOpenStatusUpdate(true);
    }; */
    var handleResetPassword = function () {
        setOpen(true);
    };
    var handleExpirationChange = function () {
        setOpenExpiration(true);
    };
    var handleAccountSinceChange = function () {
        setOpenAccountSince(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    /* const handleCloseStatusUpdate = () => {
        setOpenStatusUpdate(false);
    }; */
    var handleCloseExpiration = function () {
        setOpenExpiration(false);
    };
    var handleCloseAccountSince = function () {
        setOpenAccountSince(false);
    };
    var handleAccountLeaveReasonChange = function (e) {
        setAccountLeaveReasonId(e.target.value);
    };
    /* const handleAccountStatusChange: EventChange = (e) => {
        if (e.target.value === "cancelled") {
            setClearLeaveReason(false);
            setAccountStatus("deleted");
            setFrontEndStatus(e.target.value);
            return;
        }
        setClearLeaveReason(true);
        setAccountStatus(e.target.value);
        setFrontEndStatus(e.target.value);
    }; */
    var handleSendCard = function () {
        router.post("/account/send-card", { id: account.id });
    };
    var handleSendText = function () {
        router.post("/account/send-text", { id: account.id });
    };
    var handleSendEmail = function () {
        router.post("/account/send-email", { id: account.id });
    };
    var handleSendSelfEmail = function () {
        router.post("/account/send-self-email", {
            id: account.id,
            email: auth.user.email,
        });
    };
    var handleSendLtcCrossSell = function () {
        router.post("/account/send-ltc-cross-sell", {
            id: account.id,
        });
    };
    var handleSendSelfEnroll = function () {
        router.post("/account/send-self-enroll", {
            id: account.id,
        });
    };
    var handleResetEmail = function () {
        router.post("/account/send-reset-email", {
            email: account.email,
            id: account.id,
        });
    };
    /* const submitStatusUpdate = () => {
        setOpenStatusUpdate(false);

        router.post("/account/status-update", {
            id: account.id,
            status: accountStatus,
            account_leave_reason_id: !clearLeaveReason
                ? accountLeaveReasonId
                : "",
        });
    };*/
    var submitResetPassword = function (data) {
        setOpen(false);
        router.post("/account/reset-password", {
            email: account.email,
            id: account.id,
            password: data.password,
            password_confirmation: data.password_confirm,
        });
    };
    var submitExpirationUpdate = function (data) {
        setOpenExpiration(false);
        router.post("/account/set-expiration-date", {
            id: account.id,
            account_expiration_date: data.account_expiration_date,
        });
    };
    var submitAccountSince = function (data) {
        setOpenAccountSince(false);
        router.post("/account/set-account-since", {
            id: account.id,
            account_since: data.account_since,
        });
    };
    return (React.createElement(Grid, { container: true, spacing: 1, xs: 12, justifyContent: "flex-end", alignItems: "flex-end" },
        React.createElement(Grid, { container: true, xs: 12, justifyContent: "flex-end", alignItems: "flex-end" },
            React.createElement(Box, { mr: 1 }, hasPermission(auth, "edit accounts") && (React.createElement(GenericRouteButton, { variant: "text", route_name: "edit_account", data: account.id, buttonText: "Edit Account" }))),
            React.createElement(Box, { mr: 1 }, hasPermission(auth, "add orders") && (React.createElement(GenericRouteButton, { route_name: "create_order", data: account.id, buttonText: "Add Order", backgroundColor: "#f2a22f" }))),
            React.createElement(Box, { mr: 1 }, hasPermission(auth, "add donations") && (React.createElement(GenericRouteButton, { route_name: "create_donation", data: account.id, buttonText: "Add Donation", backgroundColor: "#14943F" }))),
            (!agentHasRole(auth, "action_organizer") ||
                agentNumRoles(auth) > 1) && (React.createElement(React.Fragment, null,
                hasPermission(auth, "run account send text") && (React.createElement(Box, { mr: 1 },
                    React.createElement(Button, { variant: "text", onClick: handleSendText }, "Send Text"))),
                React.createElement(Box, { mr: 1 },
                    React.createElement(Button, { variant: "text", 
                        /* style={{
                            backgroundColor: "#F2A22F",
                            color: "#FFF",
                            fontSize: "11px",
                            fontWeight: "400",
                        }} */
                        onClick: handleSendCard }, "Mail Cards")),
                React.createElement(Box, { mr: 1 },
                    React.createElement(Button, { variant: "text", 
                        /* style={{
                            backgroundColor: "#1871BF",
                            color: "#FFF",
                            fontSize: "11px",
                            fontWeight: "400",
                        }} */
                        onClick: handleSendEmail }, "Email Cards")),
                React.createElement(Box, { mr: 1 },
                    React.createElement(Button, { variant: "text", 
                        /* style={{
                            backgroundColor: "#3EBF3E",
                            color: "#FFF",
                            fontSize: "11px",
                            fontWeight: "400",
                        }} */
                        onClick: handleSendSelfEmail }, "Email Myself")))),
            (agentHasRole(auth, "msr") || agentHasRole(auth, "admin")) && (React.createElement(React.Fragment, null,
                React.createElement(Box, { mr: 1 },
                    React.createElement(ModalAccountRecurring, { current_setting: account.recurring, account_id: account.id, size: "small", style: {
                            backgroundColor: "#392869",
                            color: "#FFF",
                            fontSize: "11px",
                            fontWeight: "400",
                        } }))))),
        React.createElement(Grid, { container: true, xs: 12, justifyContent: "flex-end", alignContent: "flex-end", mt: 1 },
            React.createElement(Box, { mr: 1 }, hasPermission(auth, "view recordings") && (React.createElement(Link, { as: "span", href: route("show_recordings", { id: account.id }), method: "post", data: { id: account.id }, style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { variant: "text" }, "Recordings")))),
            (!agentHasRole(auth, "action_organizer") ||
                agentNumRoles(auth) > 1) && (React.createElement(Box, { mr: 1 },
                React.createElement(Button, { variant: "text", 
                    /*style={{
                        backgroundColor: "#F2A22F",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }}*/
                    onClick: handleResetPassword }, "Reset Password"),
                React.createElement(Modal, { open: open, onClose: handleClose, "aria-labelledby": "simple-modal-title", "aria-describedby": "simple-modal-description" },
                    React.createElement(Dialog, { open: open, onClose: handleClose, "aria-labelledby": "form-dialog-title" },
                        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Change Password"),
                        React.createElement(DialogContent, null,
                            React.createElement(DialogContentText, null, "Please provide a password that contains: number, uppercase, lowercase and is at least 8 characters long."),
                            "password" in formErrors && (React.createElement(Alert, { severity: "error" }, (_d = (_c = formErrors.password) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.toString())),
                            React.createElement(GenericControllerTextInput, { autoFocus: true, margin: "dense", name: "password", rules: {
                                    required: true,
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&]?)[A-Za-z\d$@$!%*?&]{8,}/,
                                        message: "Don't match pattern above",
                                    },
                                    validate: {
                                        match: function (value) {
                                            return value ===
                                                watchPasswordConfirm ||
                                                "Password Mismatch";
                                        },
                                    },
                                }, defaultValue: "", id: "password", label: "New Password", type: "password", error: "password" in formErrors, control: control, fullWidth: true }),
                            React.createElement(GenericControllerTextInput, { margin: "dense", name: "password_confirm", rules: {
                                    required: true,
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&]?)[A-Za-z\d$@$!%*?&]{8,}/,
                                        message: "Don't match pattern above",
                                    },
                                    validate: {
                                        match: function (value) {
                                            return value === watchPassword ||
                                                "Password Mismatch";
                                        },
                                    },
                                }, defaultValue: "", control: control, id: "password_confirm", label: "Confirm Password", type: "password", error: "password_confirm" in formErrors, fullWidth: true })),
                        React.createElement(DialogActions, null,
                            React.createElement(Button, { onClick: handleClose, color: "primary" }, "Cancel"),
                            React.createElement(Button, { onClick: handleSubmit(submitResetPassword), color: "primary" }, "Change")))))),
            hasPermission(auth, "edit account-expiration") && (React.createElement(Box, { mr: 1 },
                React.createElement(Button, { variant: "text", 
                    /* style={{
                        backgroundColor: "#1871BF",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }} */
                    onClick: handleExpirationChange }, "Change Expiration"),
                React.createElement(Modal, { open: openExpiration, onClose: handleCloseExpiration },
                    React.createElement(Dialog, { open: openExpiration, onClose: handleCloseExpiration },
                        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Set Expiration Date"),
                        React.createElement(DialogContent, null,
                            React.createElement(GenericControllerTextInput, { label: "Expiration Date", type: "date", name: "account_expiration_date", control: control, defaultValue: (_e = account === null || account === void 0 ? void 0 : account.account_expiration_date) !== null && _e !== void 0 ? _e : now(), error: "account_expiration_date" in
                                    formErrors, shrinkLabel: true, fullWidth: true })),
                        React.createElement(DialogActions, null,
                            React.createElement(Button, { onClick: handleCloseExpiration, color: "primary" }, "Cancel"),
                            React.createElement(Button, { onClick: handleSubmit(submitExpirationUpdate), color: "primary" }, "Change")))))),
            hasPermission(auth, "edit account-since") && (React.createElement(Box, { mr: 1 },
                React.createElement(Button, { variant: "text", 
                    /* style={{
                        backgroundColor: "#3EBF3E",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }} */
                    onClick: handleAccountSinceChange }, "Change Account Since"),
                React.createElement(Modal, { open: openAccountSince, onClose: handleCloseAccountSince },
                    React.createElement(Dialog, { open: openAccountSince, onClose: handleCloseAccountSince },
                        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Set Account Since"),
                        React.createElement(DialogContent, null,
                            React.createElement(GenericControllerTextInput, { label: "Account Since", type: "date", control: control, name: "account_since", defaultValue: (_f = account === null || account === void 0 ? void 0 : account.account_since) !== null && _f !== void 0 ? _f : now(), error: "account_since" in formErrors, shrinkLabel: true, fullWidth: true })),
                        React.createElement(DialogActions, null,
                            React.createElement(Button, { onClick: handleCloseAccountSince, color: "primary" }, "Cancel"),
                            React.createElement(Button, { onClick: handleSubmit(submitAccountSince), color: "primary" }, "Change")))))),
            hasPermission(auth, "send ltc-cross-sell") && (React.createElement(Box, { mr: 1 },
                React.createElement(Button, { variant: "text", 
                    /* style={{
                        backgroundColor: "#F1221C",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }} */
                    onClick: handleSendLtcCrossSell }, "Send LTC Cross Sell"))),
            hasPermission(auth, "send self-enroll") && (React.createElement(Box, { mr: 1 },
                React.createElement(Button, { variant: "text", 
                    /* style={{
                        backgroundColor: "#880000",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }} */
                    onClick: handleSendSelfEnroll }, "Send SE Welcome"))))));
};
export default AccountAction;
