import { Link } from "@inertiajs/react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { Pagination } from '@mui/material';
import { chunk } from "lodash";
import React, { useEffect, useState } from "react";
import route from "ziggy-js";
import PageTitle from "../../components/PageTitle";
import { hasPermission } from "../../utils/utils";
import Layout from "../Layout";
import { AgentSearchCard } from "./components/AgentSearchCard";
export default function AllAgents(_a) {
    var _b;
    var response_data = _a.response_data, auth = _a.auth;
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    var _d = useState(response_data.agents), agents = _d[0], setAgents = _d[1];
    var _e = React.useState(1), page = _e[0], setPage = _e[1];
    var handleChange = function (event, value) {
        setPage(value);
    };
    var _f = useState(""), filter = _f[0], setFilter = _f[1];
    useEffect(function () {
        setPage(1);
        setAgents(response_data.agents.filter(function (agent) { var _a, _b; return (_b = (_a = agent === null || agent === void 0 ? void 0 : agent.user) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(filter.toLowerCase()); }));
    }, [filter]);
    var currentPages = chunk(agents, 15);
    return (React.createElement(Layout, { title: "All Agents", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(PageTitle, { title: "All Agents" }),
        React.createElement(Grid, { container: true, spacing: 2, justifyContent: "flex-end" },
            hasPermission(auth, "create agent") && (React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(Link, { href: route("create_agent_form") },
                    React.createElement(Button, { type: "button", color: "primary", variant: "contained" }, "Create Agent")))),
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(TextField, { variant: "outlined", size: "small", label: "Filter By Name", type: "text", name: "filterByName", defaultValue: filter, fullWidth: true, onChange: function (e) { return setFilter(e.target.value); } })),
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center" },
                React.createElement(Pagination, { count: currentPages.length, page: page, onChange: handleChange })),
            React.createElement(Grid, { item: true, xs: 12 }, (_b = currentPages[page - 1]) === null || _b === void 0 ? void 0 : _b.map(function (agent) { return (React.createElement(Box, { mb: 2, key: agent.id },
                React.createElement(AgentSearchCard, { agent: agent }))); })))));
}
