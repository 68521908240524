import React, { useState } from "react";
import Layout from "../Layout";
import LeadTable from "../../components/LeadTable";
import AppTable from "../../components/AppTable";
import HomeMenu from "./HomeMenu";
import { maxBy } from "lodash";
export default function Recent(_a) {
    var response_data = _a.response_data, auth = _a.auth;
    var recent_health_apps = response_data.recent_health_apps, recent_financial_apps = response_data.recent_financial_apps, recent_life_apps = response_data.recent_life_apps, recent_leads = response_data.recent_leads, lead_types = response_data.lead_types, insurance_carriers = response_data.insurance_carriers, agents = response_data.agents, user = response_data.user, totals = response_data.totals, states = response_data.states, dispositions = response_data.dispositions, app_dispositions = response_data.app_dispositions;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var dataWithMaxTotal = maxBy([
        recent_financial_apps,
        recent_leads,
        recent_health_apps,
        recent_life_apps,
    ], "total");
    return (React.createElement(Layout, { title: "Home", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(React.Fragment, null,
            React.createElement(HomeMenu, { auth: auth, user: user, title: "Recent", data: dataWithMaxTotal, totals: totals }),
            React.createElement(LeadTable, { data: recent_leads.data, title: "Recent Leads", lead_types: lead_types, agents: agents, states: states, dispositions: dispositions }),
            recent_health_apps.data.length > 0 && (React.createElement(AppTable, { noteType: "health", title: "Recent Health Apps", data: recent_health_apps.data, leadTypes: lead_types, insuranceCarriers: insurance_carriers, numberOfTableColumns: 7, fileUploadDirectory: "health_insurance_applications", agents: agents, states: states, appType: "App\\Models\\HealthInsuranceApplication", dispositions: app_dispositions })),
            recent_financial_apps.data.length > 0 && (React.createElement(AppTable, { noteType: "financial", title: "Recent Financial Apps", data: recent_financial_apps.data, leadTypes: lead_types, insuranceCarriers: insurance_carriers, numberOfTableColumns: 7, fileUploadDirectory: "financial_applications", agents: agents, states: states, appType: "App\\Models\\FinancialApplication", dispositions: app_dispositions })),
            recent_life_apps.data.length > 0 && (React.createElement(AppTable, { noteType: "life", title: "Recent Life Apps", data: recent_life_apps.data, leadTypes: lead_types, insuranceCarriers: insurance_carriers, numberOfTableColumns: 7, fileUploadDirectory: "life_insurance_applications", agents: agents, states: states, appType: "App\\Models\\LifeInsuranceApplication", dispositions: app_dispositions })))));
}
