import React from "react";
import { ListItemText, MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
import { UtmAutoComplete } from "../../../components/UtmAutoComplete";
import GenericControllerSelect from "../../../components/GenericControllerSelect";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import DonationAmountTextInput from "./DonationAmountField";
export default function DonationAddForm(props) {
    var donation_utmSources = props.donation_utmSources, donation_utmCampaigns = props.donation_utmCampaigns, donation_utmMediums = props.donation_utmMediums, donation_referrals = props.donation_referrals, member = props.member, errors = props.errors, watch = props.watch, control = props.control, getValues = props.getValues;
    var watchDonationAmount = watch("donation[amount]", "");
    var watchDonationUtmSource = watch("donation_utm_source", "");
    var watchDonationInternalSource = watch("donation_internal_source", "");
    var paymentOptions = [
        {
            id: "check",
            name: "Check",
        },
        {
            id: "cash",
            name: "Cash",
        },
        {
            id: "money-order",
            name: "Money Order",
        },
    ];
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "AMAC Action Donation"))),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(Controller, { control: control, defaultValue: "", name: "donation[amount]", rules: {
                        required: member.id
                            ? false
                            : getValues("product_id") != ""
                                ? false
                                : true,
                        min: member.id
                            ? 0
                            : getValues("product_id") != ""
                                ? 0
                                : 1,
                    }, render: function (_a) {
                        var _b = _a.field, value = _b.value, name = _b.name, ref = _b.ref, onChange = _b.onChange;
                        return (React.createElement(NumberFormat, { value: value, onChange: onChange, allowNegative: false, displayType: "input", decimalScale: 2, fixedDecimalScale: true, customInput: DonationAmountTextInput }));
                    } }))),
        watchDonationAmount != "" && (React.createElement(React.Fragment, null,
            React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerTextInput, { control: control, name: "donation[transaction_id]", defaultValue: "", label: "Transaction Id", error: errors.donation &&
                            "transaction_id" in errors.donation })),
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerSelect, { name: "donation[payment_type]", control: control, label: "Payment Type", customOptions: paymentOptions.map(function (option, idx) { return (React.createElement(MenuItem, { key: "".concat(name, "_").concat(idx), value: option.id },
                            React.createElement(ListItemText, { primary: option.name }))); }), rules: {
                            required: true,
                        }, error: errors.donation &&
                            "payment_type" in errors.donation, defaultValue: "" }))),
            !watchDonationInternalSource && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(UtmAutoComplete, { limitTags: 1, name: "donation_utm_source", control: control, label: "Referral Source", options: donation_utmSources, rules: {
                        required: false,
                    }, error: "donation_utm_source" in errors, value: null, multiple: false }))),
            !watchDonationUtmSource && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(UtmAutoComplete, { limitTags: 1, name: "donation_internal_source", control: control, label: "Internal Source", options: donation_referrals, rules: {
                        required: false,
                    }, error: "donation_internal_source" in errors, value: null, multiple: false }))),
            donation_utmMediums
                .map(function (el) { return el.member_utm_source_id; })
                .includes(watchDonationUtmSource === null || watchDonationUtmSource === void 0 ? void 0 : watchDonationUtmSource.id) ? (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(UtmAutoComplete, { limitTags: 1, name: "donation_utm_medium", control: control, utmSourceID: watchDonationUtmSource === null || watchDonationUtmSource === void 0 ? void 0 : watchDonationUtmSource.id, label: "Referral Campaign", options: donation_utmMediums.filter(function (el) {
                        return el.member_utm_source_id ==
                            (watchDonationUtmSource === null || watchDonationUtmSource === void 0 ? void 0 : watchDonationUtmSource.id);
                    }), rules: {
                        required: false,
                    }, error: "donation_utm_medium" in errors, value: null, multiple: false }))) : (watchDonationUtmSource && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(UtmAutoComplete, { limitTags: 1, name: "donation_utm_campaign", control: control, utmSourceID: watchDonationUtmSource === null || watchDonationUtmSource === void 0 ? void 0 : watchDonationUtmSource.id, label: "Referral Campaign", options: donation_utmCampaigns.filter(function (el) {
                        return el.member_utm_source_id ==
                            (watchDonationUtmSource === null || watchDonationUtmSource === void 0 ? void 0 : watchDonationUtmSource.id);
                    }), rules: {
                        required: false,
                    }, error: "donation_utm_campaign" in errors, value: null, multiple: false }))))))));
}
