import React, { Fragment } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Paper, Typography } from "@mui/material";
import Task from "./Task";
var TaskGroup = function (_a) {
    var tasks = _a.tasks, groupName = _a.groupName, agentsArray = _a.agentsArray, setAgentsArray = _a.setAgentsArray;
    return (React.createElement(Grid, { xs: 12, container: true },
        React.createElement(Grid, { xs: 12, mb: 1, justifyContent: "flex-start" },
            React.createElement(Typography, { variant: "h5", component: "h2" },
                groupName,
                " Tasks")),
        React.createElement(Grid, { xs: 12 }, tasks &&
            tasks.map(function (task) {
                return (React.createElement(Fragment, { key: "task_fragment_" + task.id },
                    React.createElement(Box, { mb: 1, key: task.id, id: "task_".concat(task.id) },
                        React.createElement(Paper, null,
                            React.createElement(Task, { task: task, key: "task_" + task.id, agentsArray: agentsArray, setAgentsArray: setAgentsArray })))));
            }))));
};
export default TaskGroup;
