import { Link } from "@inertiajs/react";
import { Box, Button, Grid } from "@mui/material";
import React from "react";
var defaultProps = {
    hasCustomStyles: false
};
export var BackToContact = function (_a) {
    var contact = _a.contact, hasCustomStyles = _a.hasCustomStyles;
    return (hasCustomStyles ?
        React.createElement(Link, { href: "/contact/show/".concat(contact.id), style: { textDecoration: "none" } },
            React.createElement(Button, { variant: "contained", size: "small" }, "Back to Contact"))
        :
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center" },
                React.createElement(Box, null,
                    React.createElement(Link, { href: "/contact/show/".concat(contact.id), style: { textDecoration: "none" } },
                        React.createElement(Button, { variant: "contained", size: "small" }, "Back to Contact")))));
};
