import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, Title, Filler, } from "chart.js";
import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, Title, Filler);
var AmacPieChartJs = function (_a) {
    var data = _a.data, title = _a.title;
    return (React.createElement(Pie, { data: data, options: {
            plugins: {
                legend: {
                    position: "top",
                },
                title: {
                    display: false,
                    text: title,
                },
            },
        } }));
};
export default AmacPieChartJs;
