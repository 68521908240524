import React from "react";
import { Button, ButtonGroup } from "@mui/material/";
var OfficeLocations = function (_a) {
    var currentLocation = _a.currentLocation, setCurrentLocation = _a.setCurrentLocation, department = _a.department;
    // according to Carl, eventually we will show pb for all locations/rosemark+ full coverage, so this will have to come in from a request
    var locations = department === "Rosemark"
        ? ["all", "Rosemark"]
        : ["all", "New York", "Florida", "New Mexico", "Las Vegas"];
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonGroup, { variant: "outlined", color: "primary", "aria-label": "outlined primary button group" }, locations === null || locations === void 0 ? void 0 : locations.map(function (location) {
            return (React.createElement(Button, { variant: currentLocation === location
                    ? "contained"
                    : "outlined", key: location, onClick: function () { return setCurrentLocation(location); } }, location));
        }))));
};
export default OfficeLocations;
