import React, { useState } from "react";
import Layout from "../Layout";
import { useForm } from "react-hook-form";
import { router } from "@inertiajs/react";
import route from "ziggy-js";
import { Button, InputAdornment, ListItemText, MenuItem, Paper, Typography, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { throttle } from "lodash";
import GenericControllerSelect from "../../components/GenericControllerSelect";
import GenericControllerTextInput from "../../components/GenericControllerTextInput";
export default function DonationEditForm(_a) {
    var _b, _c, _d, _e, _f;
    var response_data = _a.response_data;
    var donation = response_data.donation, statuses = response_data.statuses, payment_types = response_data.payment_types;
    var _g = useForm(), handleSubmit = _g.handleSubmit, errors = _g.formState.errors, control = _g.control;
    var _h = useState(true), menuOpen = _h[0], toggleMenuOpen = _h[1];
    var onSubmit = function (data) {
        data.id = donation.id;
        router.post("/donation/update", data);
    };
    var handleCancel = function () {
        router.get(route("show_donations", [donation.member_id]));
    };
    return (React.createElement(Layout, { title: "Edit Donation", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h4" },
                    "Edit ", (_b = donation.member) === null || _b === void 0 ? void 0 :
                    _b.full_name,
                    "'s Donation"))),
        React.createElement(Grid, { component: Paper, container: true, xs: 12, spacing: 2 },
            React.createElement(Grid, { xs: 12, spacing: 2, m: 1 },
                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                    React.createElement(Grid, { xs: 12, md: 6, lg: 4 },
                        React.createElement(GenericControllerTextInput, { variant: "outlined", label: "Transaction Id", type: "text", control: control, name: "values.transaction_id", defaultValue: (_c = donation === null || donation === void 0 ? void 0 : donation.transaction_id) !== null && _c !== void 0 ? _c : "", fullWidth: true })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 4 },
                        React.createElement(GenericControllerTextInput, { variant: "outlined", label: "Donation Amount", type: "text", control: control, rules: {
                                required: {
                                    value: true,
                                    message: "This is a required field",
                                },
                                min: {
                                    value: 0,
                                    message: "Please enter a valid amount",
                                },
                            }, name: "values.amount", defaultValue: donation.amount, error: errors.values
                                ? "amount" in errors.values
                                : false, InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" }, "$")),
                            }, fullWidth: true })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 4 },
                        React.createElement(GenericControllerTextInput, { control: control, type: "date", label: "Donation Date", name: "values.donation_date", rules: { required: true }, defaultValue: (_d = donation === null || donation === void 0 ? void 0 : donation.donation_date) !== null && _d !== void 0 ? _d : "", error: errors.values
                                ? "donation_date" in errors.values
                                : false, shrinkLabel: true }))),
                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                    React.createElement(Grid, { xs: 12, md: 6, lg: 4 },
                        React.createElement(GenericControllerSelect, { label: "Payment Type", name: "values.payment_type", defaultValue: (_e = donation === null || donation === void 0 ? void 0 : donation.payment_type) !== null && _e !== void 0 ? _e : "N/A", control: control, customOptions: payment_types.map(function (option) {
                                return (React.createElement(MenuItem, { key: "".concat(option), value: option },
                                    React.createElement(ListItemText, { primary: option })));
                            }) })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 4 },
                        React.createElement(GenericControllerSelect, { label: "Status", name: "values.status", defaultValue: (_f = donation === null || donation === void 0 ? void 0 : donation.status) !== null && _f !== void 0 ? _f : "", control: control, customOptions: statuses.map(function (option) {
                                return (React.createElement(MenuItem, { key: "".concat(option), value: option },
                                    React.createElement(ListItemText, { primary: option })));
                            }) }))),
                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { variant: "outlined", label: "UTM Campaign", type: "text", control: control, name: "values.utm_campaign", defaultValue: donation.utm_campaign, fullWidth: true })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { variant: "outlined", label: "UTM Source", type: "text", control: control, name: "values.utm_source", defaultValue: donation.utm_source, fullWidth: true })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { variant: "outlined", label: "UTM Medium", type: "text", control: control, name: "values.utm_medium", defaultValue: donation.utm_medium, fullWidth: true })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { variant: "outlined", label: "UTM Content", type: "text", control: control, name: "values.utm_content", defaultValue: donation.utm_content, fullWidth: true })),
                    React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                        React.createElement(GenericControllerTextInput, { variant: "outlined", label: "UTM Objective", type: "text", control: control, name: "values.utm_objective", defaultValue: donation.utm_objective, fullWidth: true }))))),
        React.createElement(Grid, { xs: 12, m: 2 },
            React.createElement(Grid, { xs: 12, container: true, alignItems: "center", justifyContent: "center", spacing: 2 },
                React.createElement(Grid, { xs: 12, md: 4, lg: 3 },
                    React.createElement(Button, { type: "submit", variant: "outlined", color: "secondary", fullWidth: true, onClick: handleCancel }, "Cancel")),
                React.createElement(Grid, { xs: 12, md: 4, lg: 3 },
                    React.createElement(Button, { type: "submit", variant: "contained", color: "primary", onClick: throttle(handleSubmit(onSubmit), 2000), fullWidth: true }, "Save Donation"))))));
}
