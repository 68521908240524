import { MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import React, { useState } from "react";
import GenericControllerDropdownInput from "../../../components/GenericControllerDropdownInput";
import GenericNumberInput from "../../../components/GenericNumberInput";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
var BrokerInfo = function (_a) {
    var _b, _c, _d, _e;
    var broker = _a.broker, errors = _a.errors, states = _a.states, control = _a.control, onboarding_statuses = _a.onboarding_statuses;
    var _f = useState((_b = broker === null || broker === void 0 ? void 0 : broker.status) !== null && _b !== void 0 ? _b : "new"), status = _f[0], setStatus = _f[1];
    var _g = useState((_c = broker === null || broker === void 0 ? void 0 : broker.dom_state_id) !== null && _c !== void 0 ? _c : ""), state = _g[0], setState = _g[1];
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Broker / Account Info")),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerDropdownInput, { label: "Status", name: "broker[status]", id: "status", defaultValue: status, handleChange: function (e) { return setStatus(e.target.value); }, rules: {
                        required: true,
                    }, control: control, error: errors.error.errors.broker &&
                        "status" in errors.error.errors.broker, options: [
                        { value: "new", name: "New" },
                        { value: "pending", name: "Pending" },
                        { value: "active", name: "Active" },
                        { value: "inactive", name: "Inactive" },
                    ].map(function (option) {
                        return (React.createElement(MenuItem, { key: option.value, value: option.value }, option.name));
                    }) })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerDropdownInput, { name: "broker[onboarding_status]", label: "Onboarding Status", defaultValue: (_d = broker.onboarding_status) !== null && _d !== void 0 ? _d : "", control: control, options: onboarding_statuses.map(function (status) {
                        return (React.createElement(MenuItem, { value: status, key: "onboarding_status_" +
                                onboarding_statuses.indexOf(status) }, status));
                    }) })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericNumberInput, { name: "broker[npn]", label: "NPN Number", type: "text", error: errors.error.errors.broker &&
                        "npn" in errors.error.errors.broker, rules: { required: true }, defaultValue: (_e = broker === null || broker === void 0 ? void 0 : broker.npn) !== null && _e !== void 0 ? _e : "", control: control }))),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            (broker === null || broker === void 0 ? void 0 : broker.id) ? null : (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { name: "user[password]", type: "password", label: "Password", error: errors.error.errors.user &&
                        "password" in errors.error.errors.user, rules: {
                        required: true,
                    }, defaultValue: "", control: control }))),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerDropdownInput, { label: "Domicile State", name: "broker[dom_state_id]", id: "domicile_state", defaultValue: state, handleChange: function (e) { return setState(e.target.value); }, rules: {
                        required: true,
                    }, control: control, error: errors.error.errors.broker &&
                        "dom_state_id" in errors.error.errors.broker, options: !!(states === null || states === void 0 ? void 0 : states.length) &&
                        states.map(function (state) { return (React.createElement(MenuItem, { key: state.id, value: state.id }, state.name)); }) })))));
};
export default BrokerInfo;
