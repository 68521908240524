var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Box, Card, CardActions, CardContent, CardHeader, Collapse, Typography, } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AmacAccountStatusIcon from "./AmacAccountStatusIcon";
import AmacAccountLink from "./AmacAccountLink";
var ExpandMore = styled(function (props) {
    var expand = props.expand, other = __rest(props, ["expand"]);
    return React.createElement(IconButton, __assign({}, other));
})(function (_a) {
    var theme = _a.theme, expand = _a.expand;
    return ({
        transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    });
});
var AmacAccountCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var amac_account = _a.amac_account;
    var _p = React.useState(false), expanded = _p[0], setExpanded = _p[1];
    var handleExpandClick = function () {
        setExpanded(!expanded);
    };
    return (React.createElement(Card, { sx: {
            width: "100%",
            height: "fit-content",
            borderLeft: "10px #035399 solid",
            borderRight: "2px #035399 solid",
            borderBottom: "2px #035399 solid",
            borderTop: "2px #035399 solid",
            borderRadius: "3px",
            marginBottom: "10px",
        } },
        React.createElement(CardContent, null,
            React.createElement(Box, null,
                React.createElement(CardHeader, { avatar: React.createElement(AmacAccountStatusIcon, { status: amac_account.status }), action: React.createElement(CardActions, { disableSpacing: true },
                        React.createElement(ExpandMore, { expand: expanded, onClick: handleExpandClick, "aria-expanded": expanded, "aria-label": "show more" },
                            React.createElement(ExpandMoreIcon, null))), title: React.createElement(AmacAccountLink, { amac_account_id: amac_account === null || amac_account === void 0 ? void 0 : amac_account.id, amac_account_name: amac_account.full_name }), subheader: React.createElement(React.Fragment, null,
                        React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (amac_account === null || amac_account === void 0 ? void 0 : amac_account.spouse_full_name)
                            ? "".concat(amac_account === null || amac_account === void 0 ? void 0 : amac_account.spouse_full_name, " (Spouse)")
                            : ""),
                        React.createElement(Typography, { variant: "subtitle1", style: { color: "#9e9e9e" } },
                            "#", amac_account === null || amac_account === void 0 ? void 0 :
                            amac_account.id)) }))),
        React.createElement(Collapse, { in: expanded, timeout: "auto", unmountOnExit: true },
            React.createElement(CardContent, null,
                React.createElement(Box, { my: 2 },
                    React.createElement(Typography, { variant: "body2" },
                        "Email: ",
                        React.createElement("strong", null, amac_account === null || amac_account === void 0 ? void 0 : amac_account.email)),
                    React.createElement(Typography, { variant: "body2" },
                        "Spouse Email:",
                        " ",
                        React.createElement("strong", null, amac_account === null || amac_account === void 0 ? void 0 : amac_account.spouse_email)),
                    React.createElement(Typography, { variant: "body2" },
                        "Status: ",
                        React.createElement("strong", null, amac_account === null || amac_account === void 0 ? void 0 : amac_account.status)),
                    React.createElement(Typography, { variant: "body2" },
                        "AmacAccount Since:",
                        " ",
                        React.createElement("strong", null, amac_account === null || amac_account === void 0 ? void 0 : amac_account.amac_account_since)),
                    React.createElement(Typography, { variant: "body2" },
                        "AmacAccount Expiration:",
                        " ",
                        React.createElement("strong", null, amac_account === null || amac_account === void 0 ? void 0 : amac_account.amac_account_expiration_date))),
                React.createElement(Box, { my: 2 },
                    React.createElement(Typography, { variant: "body2" },
                        "Phone: ",
                        React.createElement("strong", null, amac_account === null || amac_account === void 0 ? void 0 : amac_account.phone)),
                    React.createElement(Typography, { variant: "body2" },
                        "Phone Alt.: ",
                        React.createElement("strong", null, amac_account === null || amac_account === void 0 ? void 0 : amac_account.phone_alt_1)),
                    React.createElement(Typography, { variant: "body2" },
                        "Spouse Phone:",
                        " ",
                        React.createElement("strong", null, amac_account === null || amac_account === void 0 ? void 0 : amac_account.spouse_phone)),
                    React.createElement(Typography, { variant: "body2" },
                        "Spouse Phone alt:",
                        " ",
                        React.createElement("strong", null, amac_account === null || amac_account === void 0 ? void 0 : amac_account.spouse_phone_alt))),
                React.createElement(Box, { my: 2 },
                    React.createElement(Typography, { variant: "body2" },
                        "Ship Address 1:",
                        " ",
                        React.createElement("strong", null, (_b = amac_account === null || amac_account === void 0 ? void 0 : amac_account.ship_address_1) !== null && _b !== void 0 ? _b : "")),
                    React.createElement(Typography, { variant: "body2" },
                        "Ship Address 2:",
                        " ",
                        React.createElement("strong", null, (_c = amac_account === null || amac_account === void 0 ? void 0 : amac_account.ship_address_2) !== null && _c !== void 0 ? _c : "")),
                    React.createElement(Typography, { variant: "body2" },
                        "Ship City:",
                        " ",
                        React.createElement("strong", null, (_d = amac_account === null || amac_account === void 0 ? void 0 : amac_account.ship_city) !== null && _d !== void 0 ? _d : "")),
                    React.createElement(Typography, { variant: "body2" },
                        "Ship State:",
                        " ",
                        React.createElement("strong", null, (_f = (_e = amac_account === null || amac_account === void 0 ? void 0 : amac_account.mailing_state) === null || _e === void 0 ? void 0 : _e.abbreviation) !== null && _f !== void 0 ? _f : "")),
                    React.createElement(Typography, { variant: "body2" },
                        "Ship Zip: ",
                        React.createElement("strong", null, (_g = amac_account === null || amac_account === void 0 ? void 0 : amac_account.ship_zip) !== null && _g !== void 0 ? _g : ""))),
                React.createElement(Box, { my: 2 },
                    React.createElement(Typography, { variant: "body2" },
                        "Res Address 1:",
                        " ",
                        React.createElement("strong", null, (_h = amac_account === null || amac_account === void 0 ? void 0 : amac_account.res_address_1) !== null && _h !== void 0 ? _h : "")),
                    React.createElement(Typography, { variant: "body2" },
                        "Res Address 2:",
                        " ",
                        React.createElement("strong", null, (_j = amac_account === null || amac_account === void 0 ? void 0 : amac_account.res_address_2) !== null && _j !== void 0 ? _j : "")),
                    React.createElement(Typography, { variant: "body2" },
                        "Res City: ",
                        React.createElement("strong", null, (_k = amac_account === null || amac_account === void 0 ? void 0 : amac_account.res_city) !== null && _k !== void 0 ? _k : "")),
                    React.createElement(Typography, { variant: "body2" },
                        "Res State:",
                        " ",
                        React.createElement("strong", null, (_m = (_l = amac_account === null || amac_account === void 0 ? void 0 : amac_account.state) === null || _l === void 0 ? void 0 : _l.abbreviation) !== null && _m !== void 0 ? _m : "")),
                    React.createElement(Typography, { variant: "body2" },
                        "Res Zip: ",
                        React.createElement("strong", null, (_o = amac_account === null || amac_account === void 0 ? void 0 : amac_account.res_zip) !== null && _o !== void 0 ? _o : "")))),
            " ")));
};
export default AmacAccountCard;
