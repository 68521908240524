var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
var GenericNumberInput = function (_a) {
    var name = _a.name, label = _a.label, defaultValue = _a.defaultValue, error = _a.error, control = _a.control, rules = _a.rules, customOnChange = _a.customOnChange, _b = _a.helperText, helperText = _b === void 0 ? "This field is required." : _b, other = __rest(_a, ["name", "label", "defaultValue", "error", "control", "rules", "customOnChange", "helperText"]);
    return (React.createElement(Controller, { control: control, name: name, rules: rules, defaultValue: defaultValue, render: function (_a) {
            var _b = _a.field, value = _b.value, name = _b.name, ref = _b.ref, onChange = _b.onChange;
            return (React.createElement(NumberFormat, __assign({}, other, { name: name, label: label, error: error, inputRef: ref, type: "text", fullWidth: true, helperText: error ? helperText : "", defaultValue: value, onValueChange: customOnChange
                    ? function (values) {
                        customOnChange(values.value);
                        onChange(values.value);
                    }
                    : function (values) { return onChange(values.value); }, customInput: TextField, variant: "outlined" })));
        } }));
};
export default GenericNumberInput;
