import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { usePage } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import { Typography, Paper, Modal, Dialog, DialogContent } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { agentHasRole, constructAmacAccountFormUtms } from "../../utils/utils";
import DisplayErrors from "../../components/DisplayErrors";
import axios from "axios";
import AmacAccountCard from "../../components/AmacAccountCard";
import AmacAccountSubmitButtons from "../AmacAccount/AmacAccountSubmitButtons";
import AmacAccountFormFields from "./AmacAccountFormFields";
var AddAmacAccount = function (_a) {
    var amac_account = _a.amac_account, states = _a.states, utm_sources = _a.utm_sources, utm_campaigns = _a.utm_campaigns, utm_mediums = _a.utm_mediums, referrals = _a.referrals, serverErrors = _a.errors, setCreatedAmacAccount = _a.setCreatedAmacAccount;
    var _b = useState([]), producedContacts = _b[0], setProducedContacts = _b[1];
    var _c = useState(serverErrors), axiosErrors = _c[0], setAxiosErrors = _c[1];
    amac_account.formType = "amac_account";
    var auth = usePage().props.auth;
    var _d = useForm({
        shouldUnregister: true,
    }), handleSubmit = _d.handleSubmit, errors = _d.formState.errors, control = _d.control, watch = _d.watch, getValues = _d.getValues;
    var watchAddress = watch("ship_address_1", "");
    var watchCity = watch("ship_city", "");
    var _e = useState(false), isSubmitting = _e[0], setIsSubmitting = _e[1];
    useEffect(function () {
        for (var key in axiosErrors) {
            // if form is stuck because of server error, check if the key needs to be bypassed
            // look to old amac_account form to find out how.
            key = key.replace("amac_account.", "");
            errors[key] = axiosErrors[key];
        }
    }, [axiosErrors]);
    var handleCloseModal = function () {
        setIsSubmitting(false);
    };
    useEffect(function () {
        if (!isEmpty(serverErrors)) {
            setIsSubmitting(false);
        }
    }, [serverErrors]);
    var produceContacts = function () {
        axios
            .post("/amac_account/find", {
            amac_account: watch(),
            produce_conditions_method: "get_amac_account_form_conditions",
        })
            .then(function (res) {
            setProducedContacts(res.data.amac_accounts);
        })
            .catch(function () {
            console.error("Error finding amac_accounts.");
        });
    };
    var onSubmit = function (data, e) {
        var constructedData = constructAmacAccountFormUtms(data, false, false);
        if (!isSubmitting) {
            setIsSubmitting(true);
            constructedData.status = agentHasRole(auth, "action_organizer")
                ? "unmarketable"
                : "prospect";
            axios
                .post("/amac_account/store", {
                amac_account: constructedData,
            })
                .then(function (res) {
                setCreatedAmacAccount(res.data.amac_account);
                setIsSubmitting(false);
            })
                .catch(function (error) {
                setAxiosErrors(error.response.data.errors);
                console.error("Error storing amac_account.");
                setIsSubmitting(false);
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, xs: 12, direction: "row", spacing: 2 },
            React.createElement(Grid, { container: true, xs: 9, component: Paper, direction: "row" },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h4", component: "h3" }, "Add New AmacAccount")),
                !isEmpty(axiosErrors) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(DisplayErrors, { errors: axiosErrors }))),
                React.createElement(AmacAccountFormFields, { amac_account: amac_account, states: states, utm_sources: utm_sources, utm_campaigns: utm_campaigns, utm_mediums: utm_mediums, referrals: referrals, errors: errors, control: control, watch: watch, watchAddress: watchAddress, watchCity: watchCity, setCreatedAmacAccount: setCreatedAmacAccount, produceContacts: produceContacts, formType: "add" }),
                React.createElement(AmacAccountSubmitButtons, { onSubmit: onSubmit, handleSubmit: handleSubmit, success: (amac_account === null || amac_account === void 0 ? void 0 : amac_account.id) ? true : false, amac_account: amac_account, buttonLabel: "Add Amac Account", isSubmitting: isSubmitting })),
            React.createElement(Grid, { container: true, xs: true, component: Paper, direction: "column", ml: 2 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Similar AmacAccounts"),
                producedContacts.length > 0 ? (producedContacts.map(function (amac_account) {
                    return (React.createElement(AmacAccountCard, { amac_account: amac_account, key: amac_account === null || amac_account === void 0 ? void 0 : amac_account.id }));
                })) : (React.createElement(Grid, { xs: true },
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "No amac_accounts found"))))),
        React.createElement(Modal, { open: isSubmitting, onClose: handleCloseModal },
            React.createElement(Dialog, { open: isSubmitting, onClose: handleCloseModal },
                React.createElement(DialogContent, { style: { minWidth: "500px", textAlign: "center" } }, "This form is being submitted")))));
};
export default AddAmacAccount;
