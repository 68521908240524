var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import DefaultBtn from "../../../components/DefaultBtn";
import PageTitle from "../../../components/PageTitle";
import styled from 'styled-components';
var columns = [
    {
        field: 'name',
        headerName: 'Recording',
        flex: .2,
        editable: false,
    },
    {
        field: 'date',
        headerName: 'Date',
        flex: .2,
        editable: false,
    },
    {
        field: 'time',
        headerName: 'Time',
        type: 'string',
        flex: .2,
        editable: false,
        sortable: false,
    },
    {
        field: 'size',
        headerName: 'File Size (KB)',
        flex: .2,
    },
    {
        field: 'download',
        headerName: "Download",
        flex: .2,
        sortable: false,
        renderCell: function (rowValues) {
            return React.createElement("a", { href: "/recording/download/".concat(rowValues.row.url) },
                " ",
                React.createElement(DefaultBtn, { variant: "outlined", color: "primary", fullWidth: true, height: "30px" }, "Download"));
        }
    }
];
var MemberRecordings = function (_a) {
    var recordings = _a.recordings, member = _a.member;
    var rows = [];
    var id = 0;
    recordings.map(function (recording) {
        recording.id = id;
        rows.push(recording);
        id++;
    });
    return (React.createElement("div", { style: { width: 1010 } },
        React.createElement(PageTitle, { title: member.full_name + " Recordings" }),
        React.createElement("br", null),
        React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: 20, rowsPerPageOptions: [20], density: "compact", autoHeight: true })));
};
var MemberRecordingsHeader = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\nmargin-bottom:20px;\ncolor:#04539a;\nfont-style:italic;\nfont-weight:bold;\n\n"], ["\n\nmargin-bottom:20px;\ncolor:#04539a;\nfont-style:italic;\nfont-weight:bold;\n\n"])));
export default MemberRecordings;
var templateObject_1;
