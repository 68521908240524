import { Box, Grid, Paper, Snackbar, Typography, Link, } from "@mui/material";
import orderBy from "lodash/orderBy";
import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import ContactAction from "./components/Contact/ContactAction";
import ContactInfoCard from "./components/Contact/ContactInfoCard";
import ContactActivityCard from "./components/ActivityLog/ContactActivityCard";
import ContactContactCard from "./components/Contact/ContactContactCard";
import ContactShipAddressCard from "./components/Contact/ContactShipAddressCard";
import ContactResAddressCard from "./components/Contact/ContactResAddressCard";
import ContactNoteCard from "./components/Contact/ContactNoteCard";
import { Alert } from "@mui/material";
import { agentHasRole } from "../../utils/utils";
import { usePage } from "@inertiajs/react";
import { ErrorOutline } from "@mui/icons-material";
import { ContactNavWrapper } from "./components/Navigation/ContactNavWrapper";
import ContactHeader from "./components/ContactHeader";
var ContactMain = function (_a) {
    var response_data = _a.response_data, flash = _a.flash, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState(false), openMessage = _c[0], setOpenMessage = _c[1];
    var auth = usePage().props.auth;
    useEffect(function () {
        if (flash.message) {
            setOpenMessage(true);
        }
    }, [flash]);
    var contact = response_data.contact, contact_statuses = response_data.contact_statuses, contact_leave_reasons = response_data.contact_leave_reasons, states = response_data.states, all_documents = response_data.all_documents, activity_log = response_data.activity_log;
    contact.orders = orderBy(contact === null || contact === void 0 ? void 0 : contact.orders, ["created_at"], "desc");
    return (React.createElement(Layout, { title: "Contact Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(ContactNavWrapper, { contact: contact, all_documents: all_documents, activity_log: activity_log }),
                openMessage && (React.createElement(Box, { mb: 1 },
                    React.createElement(Snackbar, { open: openMessage, anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        }, autoHideDuration: 3000, onClose: function () { return setOpenMessage(false); } },
                        React.createElement(Alert, { onClose: function () { return setOpenMessage(false); }, severity: "success" }, flash === null || flash === void 0 ? void 0 : flash.message)))))),
        !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { my: 2 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))))),
        React.createElement(Paper, { style: { minHeight: "80vh" } },
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { container: true, item: true, xs: 12 },
                    React.createElement(Grid, { item: true, xs: 12, md: 3 },
                        React.createElement(ContactHeader, { contact: contact })),
                    !agentHasRole(auth, "broker") && (React.createElement(Grid, { item: true, xs: 12, md: 9 },
                        React.createElement(Box, { mx: 2, my: 2 },
                            React.createElement(ContactAction, { contact: contact, contactLeaveReasons: contact_leave_reasons, contactStatuses: contact_statuses }))))),
                contact.merged_to_id && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(Alert, { variant: "filled", severity: "error", icon: React.createElement(ErrorOutline, { fontSize: "large" }) },
                            React.createElement(Typography, { variant: "h6" },
                                "This contact was transferred to\u00A0",
                                React.createElement(Link, { href: "/contact/show/" +
                                        contact.merged_to_id, color: "inherit" },
                                    "#",
                                    contact.merged_to_id)))))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(ContactInfoCard, { contact: contact }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(ContactActivityCard, { contact: contact }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(ContactNoteCard, { contact: contact }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(ContactContactCard, { contact: contact }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(ContactShipAddressCard, { contact: contact, states: states }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(ContactResAddressCard, { contact: contact, states: states })))))));
};
export default ContactMain;
