import React from "react";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";
import { FormControl, InputLabel } from "@mui/material";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        formControl: {
            minWidth: 270,
            maxWidth: 300,
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
        indeterminateColor: {
            color: "#f50057",
        },
        selectAllText: {
            fontWeight: 500,
        },
        selectedAll: {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
        },
    });
});
var GenericControllerSelectCheckbox = function (_a) {
    var name = _a.name, label = _a.label, options = _a.options, control = _a.control, value = _a.value, rules = _a.rules, error = _a.error;
    var classes = useStyles();
    return (React.createElement(Controller, { name: name, control: control, defaultValue: value, rules: rules, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value;
            return (React.createElement(FormControl, { fullWidth: true, variant: "outlined" },
                React.createElement(InputLabel, { id: "select-".concat(name, "-id") }, label),
                React.createElement(Select, { labelId: "select-".concat(name, "-id"), label: label, multiple: true, variant: "outlined", value: value, error: error, onChange: function (e) {
                        if (e.target.value[e.target.value.length - 1] ===
                            "all") {
                            onChange(value.length === options.length
                                ? []
                                : options.map(function (el) { return el.id; }));
                            return;
                        }
                        onChange(e);
                    }, renderValue: function () {
                        return value
                            .map(function (idx) {
                            return options
                                .filter(function (el) { return el.id == idx; })
                                .map(function (el) { return el.name; });
                        })
                            .join(", ");
                    }, fullWidth: true },
                    React.createElement(MenuItem, { value: "all", classes: {
                            root: value.length === options.length
                                ? classes.selectedAll
                                : "",
                        } },
                        React.createElement(ListItemIcon, null,
                            React.createElement(Checkbox, { checked: value.length === options.length, classes: {
                                    indeterminate: classes.indeterminateColor,
                                }, indeterminate: value.length > 0 &&
                                    value.length < options.length })),
                        React.createElement(ListItemText, { classes: { primary: classes.selectAllText }, primary: "Select All" })),
                    Object.values(options).map(function (option, idx) { return (React.createElement(MenuItem, { key: "".concat(name, "_").concat(idx), value: option.id },
                        React.createElement(ListItemIcon, null,
                            React.createElement(Checkbox, { checked: value.includes(option.id) })),
                        React.createElement(ListItemText, { primary: option.name }))); }))));
        } }));
};
export default GenericControllerSelectCheckbox;
