import { Box, Grid, Typography } from "@mui/material";
import { helperFunctions } from "../../../Helpers";
import React from "react";
var MemberActivityCard = function (_a) {
    var _b, _c, _d, _e;
    var member = _a.member;
    return (React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
        React.createElement(Grid, { item: true, xs: 12, style: { backgroundColor: "#F1F1F1", marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "Activity"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Created By: "))),
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_c = (_b = member === null || member === void 0 ? void 0 : member.user_created) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Date Created:"))),
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.getDateTime(member === null || member === void 0 ? void 0 : member.created_at)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Modified By: "))),
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_e = (_d = member === null || member === void 0 ? void 0 : member.user_updated) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Last Modified:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.getDateTime(member === null || member === void 0 ? void 0 : member.updated_at)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Mailing List:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (member === null || member === void 0 ? void 0 : member.post_mail) ? "YES" : "NO"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Magazine: "))),
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (member === null || member === void 0 ? void 0 : member.magazine) ? "YES" : "NO"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Email List:"))),
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (member === null || member === void 0 ? void 0 : member.emailing_list) ? "YES" : "NO"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "SBS Newsletter:"))),
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (member === null || member === void 0 ? void 0 : member.sbs_newsletter) ? "YES" : "NO")))));
};
export default MemberActivityCard;
