import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import ProductionBoardsNav from "./ProductionBoardsNav";
import ProductionBoards from "./Boards/ProductionBoards";
import Carriers from "./Boards/Carriers";
import Conversions from "./Boards/Conversions";
import Office from "./Boards/Office";
import Business from "./Boards/Business";
import MonthlyTotals from "./Boards/MonthlyTotals";
import Policy from "./Boards/Policy";
import PolicyReferrals from "./Boards/PolicyReferrals";
import Applications from "./Boards/Applications";
export default function MedicareLoader(_a) {
    var board = _a.board, department = _a.department, location = _a.location, start_date = _a.start_date, end_date = _a.end_date, selected_month = _a.selected_month, selected_year = _a.selected_year, carriers = _a.carriers, use_inactive = _a.use_inactive, selected_carriers = _a.selected_carriers, business_by_agents = _a.business_by_agents, policies_by_agent = _a.policies_by_agent, policies_by_carrier = _a.policies_by_carrier, policies_by_conversion = _a.policies_by_conversion, policies_by_month = _a.policies_by_month, policies_by_office = _a.policies_by_office, policies_by_type = _a.policies_by_type, policy_referrals_data = _a.policy_referrals_data, applications_by_month = _a.applications_by_month, csrf = _a.csrf;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState(board), currentBoard = _c[0], setCurrentBoard = _c[1];
    var formData = {
        board: board,
        department: department,
        location: location !== null && location !== void 0 ? location : "",
        start_date: start_date !== null && start_date !== void 0 ? start_date : "",
        end_date: end_date !== null && end_date !== void 0 ? end_date : "",
        selected_month: selected_month !== null && selected_month !== void 0 ? selected_month : "",
        selected_year: selected_year !== null && selected_year !== void 0 ? selected_year : "",
        carriers: carriers !== null && carriers !== void 0 ? carriers : "",
        selected_carriers: selected_carriers !== null && selected_carriers !== void 0 ? selected_carriers : "",
        use_inactive: use_inactive !== null && use_inactive !== void 0 ? use_inactive : "",
        business_by_agents: business_by_agents !== null && business_by_agents !== void 0 ? business_by_agents : "",
        policies_by_agent: policies_by_agent !== null && policies_by_agent !== void 0 ? policies_by_agent : "",
        policies_by_carrier: policies_by_carrier !== null && policies_by_carrier !== void 0 ? policies_by_carrier : "",
        policies_by_conversion: policies_by_conversion !== null && policies_by_conversion !== void 0 ? policies_by_conversion : "",
        policies_by_month: policies_by_month !== null && policies_by_month !== void 0 ? policies_by_month : "",
        policies_by_office: policies_by_office !== null && policies_by_office !== void 0 ? policies_by_office : "",
        policies_by_type: policies_by_type !== null && policies_by_type !== void 0 ? policies_by_type : "",
        applications_by_month: applications_by_month !== null && applications_by_month !== void 0 ? applications_by_month : "",
    };
    useEffect(function () {
        if (currentBoard !== board) {
            setCurrentBoard(board);
        }
    }, [board]);
    return (React.createElement(Layout, { title: "".concat(department, " Production Boards"), menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(ProductionBoardsNav, { board: currentBoard, department: department }),
        department === "Policy-Referrals" && (React.createElement(PolicyReferrals, { response_data: policy_referrals_data, csrf: csrf })),
        currentBoard === "production-boards" && (React.createElement(ProductionBoards, { start_date: start_date, end_date: end_date, location: location, use_inactive: use_inactive, policies_by_agent: policies_by_agent, department: department, formData: formData, csrf: csrf })),
        currentBoard === "carriers" && (React.createElement(Carriers, { carriers: carriers, selected_carriers: selected_carriers, start_date: start_date, end_date: end_date, policies_by_carrier: policies_by_carrier, formData: formData, department: department, csrf: csrf })),
        (currentBoard === "conversion" ||
            currentBoard === "life-conversion" ||
            currentBoard === "financial-conversion") && (React.createElement(Conversions, { board: currentBoard, selected_year: selected_year, policies_by_conversion: policies_by_conversion, use_inactive: use_inactive, formData: formData, department: department })),
        currentBoard === "office" && (React.createElement(Office, { start_date: start_date, end_date: end_date, policies_by_office: policies_by_office, formData: formData, csrf: csrf, department: department })),
        currentBoard === "policy" && (React.createElement(Policy, { policies_by_type: policies_by_type, start_date: start_date, end_date: end_date, formData: formData, csrf: csrf, department: department })),
        currentBoard === "business" && (React.createElement(Business, { selected_month: selected_month, selected_year: selected_year, business_by_agents: business_by_agents, formData: formData, csrf: csrf, department: department })),
        currentBoard === "monthly-totals" && (React.createElement(MonthlyTotals, { policies_by_month: policies_by_month, selected_year: selected_year, formData: formData, csrf: csrf, department: department })),
        department == "Rosemark" && currentBoard === "applications" && (React.createElement(Applications, { applications_by_month: applications_by_month, selected_year: selected_year, formData: formData, csrf: csrf, department: department }))));
}
