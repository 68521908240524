import { Box, Grid, Typography, Divider } from "@mui/material";
import React from "react";
import { Link } from "@inertiajs/react";
import AccountContactCard from "./AccountContactCard";
var AccountContactsCard = function (_a) {
    var account = _a.account;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 6, sx: { borderBottom: 1, marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "Contacts"))),
        React.createElement(Grid, { item: true, xs: 6, sx: { borderBottom: 1, marginBottom: "14px" } },
            React.createElement(Box, { mx: 1, mt: 1, style: { textAlign: "right" } },
                React.createElement(Link, { href: "#", style: {
                        textDecoration: "underline",
                        fontSize: '9pt',
                        color: '#3f51b5'
                    } }, "Edit Contacts"))),
        account.contacts_ordered.map(function (contact) { return (React.createElement(Grid, { container: true, item: true, xs: 12, key: contact.id },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(AccountContactCard, { contact: contact, is_primary: account.primary_contact_id == (contact === null || contact === void 0 ? void 0 : contact.id) })),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Divider, { sx: { marginTop: 2, marginBottom: 2 } }))))); })));
};
export default AccountContactsCard;
