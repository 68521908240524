import React from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import IllnessLead from "../Pages/Lead/components/IllnessLead";
import DentalLead from "../Pages/Lead/components/DentalLead";
import FinancialLead from "../Pages/Lead/components/FinancialLead";
import ImmLead from "../Pages/Lead/components/ImmLead";
import LifeLead from "../Pages/Lead/components/LifeLead";
import LongTermCareLead from "../Pages/Lead/components/LongTermCareLead";
import MedicareLead from "../Pages/Lead/components/MedicareLead";
import MembershipActionLead from "../Pages/Lead/components/MembershipActionLead";
import QuickLead from "../Pages/Lead/components/QuickLead";
import DonorLead from "../Pages/Lead/components/DonorLead";
import { Paper } from "@mui/material";
export default function LeadSection(props) {
    var responseData = props.responseData, errors = props.errors, control = props.control, lead = props.lead, leadType = props.leadType, insuranceTypes = props.insuranceTypes, medicarePlans = props.medicarePlans, medicareChangeReasons = props.medicareChangeReasons;
    return (React.createElement(React.Fragment, null,
        parseInt(leadType) === 2 && ( //done
        React.createElement(IllnessLead, null)),
        parseInt(leadType) === 3 && ( //done
        React.createElement(DentalLead, null)),
        parseInt(leadType) === 5 && ( //done
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(FinancialLead, { errors: errors, control: control, lead: lead })))))),
        parseInt(leadType) === 10 && ( //done
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(ImmLead, { control: control, errors: errors, lead: lead, insuranceTypes: insuranceTypes, medicarePlans: medicarePlans, medicareChangeReasons: medicareChangeReasons, leadType: leadType })))))),
        parseInt(leadType) === 11 && (React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(LifeLead, { errors: errors, control: control, lead: lead })))))),
        parseInt(leadType) === 12 && ( //done
        React.createElement(LongTermCareLead, null)),
        parseInt(leadType) === 13 && ( //done
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(MedicareLead, { errors: errors, control: control, lead: lead, insuranceTypes: insuranceTypes, medicarePlans: medicarePlans, medicareChangeReasons: medicareChangeReasons, leadType: leadType })))))),
        parseInt(leadType) === 14 && ( //done
        React.createElement(MembershipActionLead, null)),
        parseInt(leadType) === 15 && ( //done
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(QuickLead, { control: control, errors: errors, lead: lead, leadTypes: responseData.lead_types })))))),
        parseInt(leadType) === 16 && ( //done
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(DonorLead, { control: control, errors: errors, lead: lead, leadTypes: responseData.lead_types }))))))));
}
