import Grid from "@mui/material/Unstable_Grid2/";
import React, { useState } from "react";
import DisplayErrors from "../../components/DisplayErrors";
import Layout from "../Layout";
import MemberUtmCard from "./MemberUtmCard";
export default function MemberUtmMain(_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var utmSources = response_data.utmSources;
    return (React.createElement(Layout, { title: "Member UTM", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { xs: 12, container: true },
            serverErrors && React.createElement(DisplayErrors, { errors: serverErrors }),
            React.createElement(MemberUtmCard, { resources: utmSources, resourceType: "sources", childType: "campaigns", resourceColor: "#bcbcbf", childColor: "#8989f8" }))));
}
