import React, { useState } from "react";
import { router } from "@inertiajs/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Modal, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import axios from "axios";
import GenericControllerDropdownInput from "./GenericControllerDropdownInput";
var ModalOnboardingStatus = function (_a) {
    var broker_id = _a.broker_id, current_onboarding_status = _a.current_onboarding_status, onboardingStatuses = _a.onboardingStatuses, setOnboardingStatuses = _a.setOnboardingStatuses;
    var _b = useState(false), openOnboardingStatusModal = _b[0], setOpenOnboardingStatusModal = _b[1];
    var _c = useForm(), control = _c.control, formErrors = _c.formState.errors, handleSubmit = _c.handleSubmit;
    var getOnboardingStatusArray = function () {
        onboardingStatuses.length == 0 && setOnboardingStatuses
            ? axios
                .get("/user/abs/get-onboarding-statuses")
                .then(function (res) {
                setOnboardingStatuses(res.data.onboarding_statuses);
            })
                .then(function () { return setOpenOnboardingStatusModal(true); })
                .catch(function (err) {
                console.log("err", err);
            })
            : setOpenOnboardingStatusModal(true);
    };
    var submitOnboardingStatus = function (formData) {
        setOpenOnboardingStatusModal(false);
        router.post("/user/abs/update-onboarding-status", {
            onboarding_status: formData.onboarding_status,
            broker_id: broker_id,
        });
    };
    var handleCloseOnboardingStatus = function () {
        setOpenOnboardingStatusModal(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: getOnboardingStatusArray, variant: "contained", size: "small", style: {
                backgroundColor: "#1871BF",
                color: "#FFF",
            } }, "Update Onboarding Status"),
        React.createElement(Modal, { open: openOnboardingStatusModal, onClose: handleCloseOnboardingStatus },
            React.createElement(Dialog, { open: openOnboardingStatusModal, onClose: handleCloseOnboardingStatus },
                React.createElement(DialogTitle, null, "Update Onboarding Status"),
                React.createElement(DialogContent, { style: { minWidth: "500px" } },
                    React.createElement(Grid, { xs: 12, container: true, margin: "dense" },
                        React.createElement(GenericControllerDropdownInput, { name: "onboarding_status", label: "Onboarding Status", defaultValue: current_onboarding_status, control: control, options: onboardingStatuses.map(function (status) {
                                return (React.createElement(MenuItem, { value: status, key: "onboarding_status_" +
                                        onboardingStatuses.indexOf(status) }, status));
                            }) }))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleCloseOnboardingStatus, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitOnboardingStatus), color: "primary" }, "Change"))))));
};
export default ModalOnboardingStatus;
