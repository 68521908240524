var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Box, Typography } from "@mui/material";
import React from "react";
import styled from 'styled-components';
var Note = function (_a) {
    var _b, _c;
    var note = _a.note;
    return (React.createElement(StyledNote, null,
        React.createElement(Typography, { component: "span", className: "note-name", variant: "body1" },
            React.createElement("strong", null, (_b = note.user) === null || _b === void 0 ? void 0 :
                _b.first_name,
                " ", (_c = note.user) === null || _c === void 0 ? void 0 :
                _c.last_name,
                " ",
                " "),
            React.createElement(NoteDate, null, "".concat(new Date(note.created_at).toDateString(), " "),
                new Date(note.created_at).toLocaleTimeString())),
        React.createElement(Box, { className: "note-text" }, note.text)));
};
var StyledNote = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display:flex;\n    min-height:55px;\n    padding:15px;\n    .note-name{\n        margin-bottom:5px;\n        width:15%;\n    }\n    .note-text{\n        width:70%;\n        padding-right:50px;\n    }\n\n    \n"], ["\n    display:flex;\n    min-height:55px;\n    padding:15px;\n    .note-name{\n        margin-bottom:5px;\n        width:15%;\n    }\n    .note-text{\n        width:70%;\n        padding-right:50px;\n    }\n\n    \n"])));
var NoteDate = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([" \n    font-size:12px;\n"], [" \n    font-size:12px;\n"])));
export default Note;
var templateObject_1, templateObject_2;
