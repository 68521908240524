import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
// import AmacAccountSpouseInfo from "../../components/AmacAccountSpouseInfo";
import AmacAccountInfoForm from "../../components/AmacAccountInfoForm";
import ContactAddress from "../../components/ContactAddress";
import { mailingAddressOptions, residentialAddressOptions, } from "../../utils/utils";
import AmacAccountOptions from "../AmacAccount/AmacAccountOptions";
import UtmSection from "../AmacAccount/UtmSection";
var AmacAccountFormFields = function (_a) {
    var amac_account = _a.amac_account, states = _a.states, utm_sources = _a.utm_sources, utm_campaigns = _a.utm_campaigns, utm_mediums = _a.utm_mediums, referrals = _a.referrals, produceContacts = _a.produceContacts, errors = _a.errors, watch = _a.watch, watchAddress = _a.watchAddress, watchCity = _a.watchCity, control = _a.control, formType = _a.formType;
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(AmacAccountInfoForm, { amac_account: amac_account, control: control, errors: errors, data: amac_account, produceAmacAccounts: produceContacts }))),
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(ContactAddress, { data: amac_account, control: control, errors: errors, states: states, options: mailingAddressOptions, produceContacts: produceContacts }),
                React.createElement(ContactAddress, { data: amac_account, control: control, errors: errors, states: states, options: residentialAddressOptions, showResidentialSame: watchAddress != "" && watchCity != "", produceContacts: produceContacts }))),
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 })),
        formType != "edit" && (React.createElement(Grid, { xs: 12 },
            React.createElement(UtmSection, { utm_sources: utm_sources, utm_campaigns: utm_campaigns, utm_mediums: utm_mediums, referrals: referrals, errors: errors, watch: watch, control: control, formType: formType }))),
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(AmacAccountOptions, { amac_account: amac_account, control: control })))));
};
export default AmacAccountFormFields;
