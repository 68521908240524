import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { helperFunctions } from "../../../Helpers";
import route from "ziggy-js";
import { Link } from "@inertiajs/react";
import { hasPermission } from "../../../utils/utils";
import DefaultBtn from "../../../components/DefaultBtn";
export default function AgentRow(_a) {
    var _b;
    var agent = _a.agent, auth = _a.auth;
    return (React.createElement(TableRow, null,
        React.createElement(TableCell, null,
            agent.user.first_name,
            " ",
            agent.user.last_name),
        React.createElement(TableCell, null, agent.id),
        React.createElement(TableCell, null, agent.status),
        React.createElement(TableCell, null, (_b = agent.location) === null || _b === void 0 ? void 0 : _b.name),
        React.createElement(TableCell, null, agent.user.email),
        React.createElement(TableCell, null, agent.phone),
        React.createElement(TableCell, null, agent.extension),
        React.createElement(TableCell, null, "last login"),
        React.createElement(TableCell, null, helperFunctions.getDateTime(agent.created_at)),
        React.createElement(TableCell, null, hasPermission(auth, "edit agent") && (React.createElement(Link, { href: route("edit_agent_form", agent.id) },
            React.createElement(DefaultBtn, { type: "button", color: "primary", variant: "contained" }, "Edit Agent")))),
        React.createElement(TableCell, null, hasPermission(auth, "edit permissions") && (React.createElement(Link, { href: route("agent_permissions", agent.id) },
            React.createElement(DefaultBtn, { type: "button", color: "primary", variant: "contained" }, "Edit Permissions"))))));
}
