import React, { useEffect, useState } from "react";
import { ListItemText, MenuItem, Typography, Paper, Modal, Dialog, DialogContent, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerTextInput from "../../components/GenericFormFields/TextInput";
import { UtmAutoComplete } from "../../components/UtmAutoComplete";
import GenericControllerSelect from "../../components/GenericFormFields/Select";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import DonationAmountInput from "../../components/DonationAmountInput";
import axios from "axios";
import SubmitButtons from "../Membership/SubmitButtons";
import GenericControllerCheckboxInput from "../../components/GenericFormFields/CheckboxInput";
import { constructMembershipFormUtms } from "../../utils/utils";
import { router } from "@inertiajs/react";
import route from "ziggy-js";
import DisplayErrors from "../../components/DisplayErrors";
import isEmpty from "lodash/isEmpty";
export default function ContactDonationAddForm(props) {
    var utm_sources = props.utm_sources, utm_campaigns = props.utm_campaigns, utm_mediums = props.utm_mediums, member = props.member, referrals = props.referrals, payment_options = props.payment_options, onMembershipForm = props.onMembershipForm, serverErrors = props.serverErrors;
    var _a = useState(serverErrors), axiosErrors = _a[0], setAxiosErrors = _a[1];
    var _b = useForm({
        shouldUnregister: true,
    }), handleSubmit = _b.handleSubmit, errors = _b.formState.errors, control = _b.control, watch = _b.watch, getValues = _b.getValues;
    var _c = useState(false), donationSucceeded = _c[0], setDonationSucceeded = _c[1];
    var _d = useState(false), isSubmitting = _d[0], setIsSubmitting = _d[1];
    useEffect(function () {
        for (var key in axiosErrors) {
            // if form is stuck because of server error, check if the key needs to be bypassed
            // look to old member form to find out how.
            key = key.replace("member.", "");
            errors[key] = axiosErrors[key];
        }
    }, [axiosErrors]);
    var handleCloseModal = function () {
        setIsSubmitting(false);
    };
    var watchDonationUtmSource = watch("donation[utm_source]", "");
    var watchDonationInternalSource = watch("donation[internal_source]", "");
    var useMemberUTMs = watch("use_member_utms", "");
    var onSubmit = function (data, e) {
        if (!isSubmitting) {
            setIsSubmitting(true);
            var utmData = constructMembershipFormUtms(data.donation, member, useMemberUTMs);
            var constructedData = Object.assign(data.donation, utmData);
            constructedData.status = "successful";
            constructedData.donation_origin = "Dashboard";
            axios
                .post("/donation/store", {
                member_id: member.id,
                donation: constructedData,
            })
                .then(function (res) {
                var _a, _b, _c, _d, _e, _f;
                setDonationSucceeded(((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.donation) === null || _b === void 0 ? void 0 : _b.id) ? true : false);
                ((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.donation) === null || _d === void 0 ? void 0 : _d.id) && !onMembershipForm
                    ? router.get(route("show_donations", [
                        (_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.donation) === null || _f === void 0 ? void 0 : _f.member_id,
                    ]))
                    : setIsSubmitting(false);
            })
                .catch(function (error) {
                setAxiosErrors(error.response.data.errors);
                console.error("Error storing donation.");
                setIsSubmitting(false);
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { mt: 1, container: true, component: Paper, xs: 12, spacing: 2 },
            React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h4", component: "h3" }, "Add AMAC Action Donation"))),
            !isEmpty(axiosErrors) && (React.createElement(Grid, { xs: 12 },
                React.createElement(DisplayErrors, { errors: axiosErrors }))),
            React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(Controller, { control: control, defaultValue: "", name: "donation[amount]", rules: {
                            required: true,
                            min: 1,
                        }, render: function (_a) {
                            var _b = _a.field, value = _b.value, name = _b.name, ref = _b.ref, onChange = _b.onChange;
                            return (React.createElement(NumberFormat, { value: value, onChange: onChange, allowNegative: false, displayType: "input", decimalScale: 2, fixedDecimalScale: true, customInput: DonationAmountInput }));
                        } }))),
            React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerTextInput, { control: control, name: "donation[transaction_id]", defaultValue: "", label: "Transaction Id", error: errors.donation &&
                            "transaction_id" in errors.donation })),
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerSelect, { name: "donation[payment_type]", control: control, label: "Payment Type", customOptions: payment_options.map(function (option, idx) { return (React.createElement(MenuItem, { key: "".concat(name, "_").concat(idx), value: option.id },
                            React.createElement(ListItemText, { primary: option.name }))); }), rules: {
                            required: true,
                        }, error: errors.donation && "type" in errors.donation, defaultValue: "" }))),
            onMembershipForm && (React.createElement(Grid, { xs: 12 },
                React.createElement(GenericControllerCheckboxInput, { name: "use_member_utms", label: "Use Member UTMs", control: control }))),
            useMemberUTMs == false && (React.createElement(React.Fragment, null,
                !watchDonationInternalSource && (React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(UtmAutoComplete, { limitTags: 1, name: "donation[utm_source]", control: control, label: "Referral Source", options: utm_sources, rules: {
                            required: false,
                        }, error: "donation[utm_source]" in errors, value: null, multiple: false }))),
                !watchDonationUtmSource && (React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(UtmAutoComplete, { limitTags: 1, name: "donation[internal_source]", control: control, label: "Internal Source", options: referrals, rules: {
                            required: false,
                        }, error: "donation[internal_source]" in errors, value: null, multiple: false }))),
                utm_mediums
                    .map(function (el) { return el.member_utm_source_id; })
                    .includes(watchDonationUtmSource === null || watchDonationUtmSource === void 0 ? void 0 : watchDonationUtmSource.id) ? (React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(UtmAutoComplete, { limitTags: 1, name: "donation[utm_medium]", control: control, utmSourceID: watchDonationUtmSource === null || watchDonationUtmSource === void 0 ? void 0 : watchDonationUtmSource.id, label: "Referral Campaign", options: utm_mediums.filter(function (el) {
                            return el.member_utm_source_id ==
                                (watchDonationUtmSource === null || watchDonationUtmSource === void 0 ? void 0 : watchDonationUtmSource.id);
                        }), rules: {
                            required: false,
                        }, error: "donation[utm_medium]" in errors, value: null, multiple: false }))) : (watchDonationUtmSource && (React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(UtmAutoComplete, { limitTags: 1, name: "donation[utm_campaign]", control: control, utmSourceID: watchDonationUtmSource === null || watchDonationUtmSource === void 0 ? void 0 : watchDonationUtmSource.id, label: "Referral Campaign", options: utm_campaigns.filter(function (el) {
                            return el.member_utm_source_id ==
                                (watchDonationUtmSource === null || watchDonationUtmSource === void 0 ? void 0 : watchDonationUtmSource.id);
                        }), rules: {
                            required: false,
                        }, error: "donation[utm_campaign]" in errors, value: null, multiple: false })))))),
            React.createElement(SubmitButtons, { onSubmit: onSubmit, handleSubmit: handleSubmit, success: donationSucceeded, member: member, buttonLabel: "Add Donation", isSubmitting: isSubmitting })),
        React.createElement(Modal, { open: isSubmitting, onClose: handleCloseModal },
            React.createElement(Dialog, { open: isSubmitting, onClose: handleCloseModal },
                React.createElement(DialogContent, { style: { minWidth: "500px", textAlign: "center" } }, "This form is being submitted")))));
}
