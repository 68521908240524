import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import Layout from "../Layout";
import BrokerNav from "./components/BrokerNav";
import AssignedRelation from "./components/AssignedRelation";
var AbsCarriersStates = function (_a) {
    var response_data = _a.response_data;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var user = response_data.user, insurance_carriers = response_data.insurance_carriers, states = response_data.states;
    return (React.createElement(Layout, { title: "Broker Carriers and States", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
            React.createElement(Grid, { container: true, xs: 12, mb: 2, alignItems: "center", justifyContent: "center" },
                React.createElement(BrokerNav, { user: user })),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(AssignedRelation, { list: insurance_carriers, listType: "carriers", user: user }),
                React.createElement(AssignedRelation, { list: states, listType: "states", user: user })))));
};
export default AbsCarriersStates;
