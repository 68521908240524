import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import AmacPieChartJs from "../../Pages/Reports/components/AmacPieChartJs";
export default function PieChartDaily(_a) {
    var data = _a.data, title = _a.title;
    return (React.createElement(Grid, { container: true, justifyContent: "center" },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { pt: 2 },
                React.createElement(Typography, { variant: "h5", align: "center" }, title))),
        React.createElement(Grid, { item: true, xs: 8, md: 6 },
            React.createElement(Box, { p: 2 },
                React.createElement(AmacPieChartJs, { data: data, title: title })))));
}
