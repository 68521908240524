import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import AmacLineChartJs from "../../Pages/Reports/components/AmacLineChartJs";
export default function TodayMembers(_a) {
    var data = _a.data, title = _a.title;
    return (React.createElement(Grid, { container: true, justifyContent: "center" },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { p: 1 },
                React.createElement(Box, { p: 1 },
                    React.createElement(Typography, { variant: "h5", align: "center" }, title)),
                React.createElement(Box, { p: 1 },
                    React.createElement(AmacLineChartJs, { data: data }))))));
}
