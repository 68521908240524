import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Layout from "../Layout";
import DisplayErrors from "../../components/DisplayErrors";
import AddAmacAccount from "../AmacAccount/AddAmacAccount";
import { hasPermission } from "../../utils/utils";
import { usePage } from "@inertiajs/react";
import OrderContactAddForm from "../Order/OrderContactAddForm";
import DonationAddForm from "../Donation/DonationAddForm";
import GenericRouteButton from "../../components/GenericRouteButton";
import FormAmacAccountInfo from "../../components/FormAmacAccountInfo";
var AmacAccountForm = function (_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState(), createdAmacAccount = _c[0], setCreatedAmacAccount = _c[1];
    var amac_account = response_data.amac_account, states = response_data.states, products = response_data.products, utm_sources = response_data.utm_sources, utm_campaigns = response_data.utm_campaigns, utm_mediums = response_data.utm_mediums, referrals = response_data.referrals, payment_options = response_data.payment_options;
    var auth = usePage().props.auth;
    return (React.createElement(Layout, { title: "Add Amac Account", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { container: true, direction: "column", xs: 12, spacing: 2 },
                React.createElement(Grid, { xs: 12 }, !createdAmacAccount && (React.createElement(Typography, { variant: "h4", component: "h3", align: "center" }, "Add New Amac Account"))),
                !isEmpty(serverErrors) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))),
                React.createElement(Grid, { xs: 12 }, createdAmacAccount ? (React.createElement(FormAmacAccountInfo, { amac_account: createdAmacAccount, spouseInfo: false, states: states })) : (React.createElement(AddAmacAccount, { errors: serverErrors, 
                    // member={createdAmacAccount ?? member}
                    amac_account: createdAmacAccount !== null && createdAmacAccount !== void 0 ? createdAmacAccount : amac_account, states: states, utm_sources: utm_sources, utm_campaigns: utm_campaigns, utm_mediums: utm_mediums, referrals: referrals, setCreatedAmacAccount: setCreatedAmacAccount }))),
                hasPermission(auth, "add orders") && (createdAmacAccount === null || createdAmacAccount === void 0 ? void 0 : createdAmacAccount.id) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(OrderContactAddForm, { errors: serverErrors, 
                        // contact={createdContact ?? contact}
                        contact: amac_account.contact_id1, 
                        // amac_account={createdAmacAccount ?? amac_account}
                        products: products, utm_sources: utm_sources, utm_campaigns: utm_campaigns, utm_mediums: utm_mediums, referrals: referrals, setCreatedAmacAccount: setCreatedAmacAccount, onAmacAccountForm: true }))),
                hasPermission(auth, "add donations") &&
                    (createdAmacAccount === null || createdAmacAccount === void 0 ? void 0 : createdAmacAccount.id) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(DonationAddForm, { setCreatedAmacAccount: setCreatedAmacAccount, 
                        // member={createdAmacAccount ?? member}
                        contact: amac_account.contact_id1, utm_sources: utm_sources, utm_campaigns: utm_campaigns, utm_mediums: utm_mediums, errors: serverErrors, referrals: referrals, payment_options: payment_options, onAmacAccountForm: true }))),
                (createdAmacAccount === null || createdAmacAccount === void 0 ? void 0 : createdAmacAccount.id) && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { container: true, alignItems: "center", justifyContent: "center", spacing: 2, xs: 12, mt: 2 }, hasPermission(auth, "edit leads") &&
                        hasPermission(auth, "view leads") && (React.createElement(GenericRouteButton, { route_name: "lead_form_with_source", data: {
                            member_id: createdAmacAccount === null || createdAmacAccount === void 0 ? void 0 : createdAmacAccount.id,
                            source: "member_form",
                        }, buttonText: "Add Lead", backgroundColor: "#33B496", variant: "contained", buttonSize: "medium", fontSize: "14px" }))),
                    React.createElement(Grid, { container: true, alignItems: "center", justifyContent: "center", spacing: 2, xs: 12, mt: 2 },
                        React.createElement(GenericRouteButton, { route_name: "show_member", data: createdAmacAccount === null || createdAmacAccount === void 0 ? void 0 : createdAmacAccount.id, buttonText: "Go To Amac Account Profile", backgroundColor: "#1E5496", variant: "contained", buttonSize: "medium", fontSize: "14px" }))))))));
};
export default AmacAccountForm;
