import { Box, Paper, TableContainer, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import PageTitle from "../../../components/PageTitle";
import { helperFunctions } from "../../../Helpers";
var MemberDocument = function (_a) {
    var data = _a.data, customTitle = _a.customTitle, customPagination = _a.customPagination;
    if (data.length < 1) {
        return null;
    }
    var rows = [];
    var count = 1;
    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
        var item = data_1[_i];
        var values = {
            count: count,
            id: item.id,
            name: item.file_name,
            created_at: item.created_at,
        };
        rows.push(values);
        count += 1;
    }
    var columns = [
        {
            field: "count",
            headerName: "#",
            editable: false,
            flex: 0.4,
        },
        {
            field: "id",
            headerName: "ID",
            editable: false,
            flex: 0.4,
        },
        {
            field: "name",
            headerName: "Document",
            editable: false,
            flex: 2,
            renderCell: function (rowValues) {
                var _a, _b, _c, _d;
                try {
                    return (React.createElement(Tooltip, { title: (_a = rowValues === null || rowValues === void 0 ? void 0 : rowValues.row) === null || _a === void 0 ? void 0 : _a.name },
                        React.createElement("a", { href: "/file/show?path=".concat(btoa((_b = rowValues === null || rowValues === void 0 ? void 0 : rowValues.row) === null || _b === void 0 ? void 0 : _b.name)), target: "_blank" }, (_c = rowValues === null || rowValues === void 0 ? void 0 : rowValues.row) === null || _c === void 0 ? void 0 : _c.name)));
                }
                catch (e) {
                    return (_d = rowValues === null || rowValues === void 0 ? void 0 : rowValues.row) === null || _d === void 0 ? void 0 : _d.name;
                }
            },
        },
        {
            field: "created_at",
            headerName: "Uploaded",
            editable: false,
            flex: 1.2,
            renderCell: function (rowValues) {
                return helperFunctions.getDateTime(rowValues.row.created_at);
            },
        },
    ];
    return (React.createElement(Box, { mt: 2 },
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: customTitle ? customTitle : "Documents" })),
        React.createElement(TableContainer, { component: Paper, elevation: 3 },
            React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: customPagination ? customPagination : 20, autoHeight: true, rowsPerPageOptions: [
                    customPagination ? customPagination : 20,
                ] }))));
};
export default MemberDocument;
