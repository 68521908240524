import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { usePage } from "@inertiajs/react";
import { Button, Typography } from "@mui/material";
import TaskFilters from "./TaskFilters";
var TasksHeader = function (_a) {
    var useCompleted = _a.useCompleted, setUseCompleted = _a.setUseCompleted, filter = _a.filter, setFilter = _a.setFilter, sort = _a.sort, setSort = _a.setSort;
    var auth = usePage().props.auth;
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, xs: 12, mb: 1 },
            React.createElement(Grid, { container: true, xs: 6, justifyContent: "flex-start" },
                React.createElement(Typography, { variant: "h4", component: "h2" }, "".concat(auth.user.name, "'s Tasks"))),
            React.createElement(Grid, { container: true, xs: 6, justifyContent: "flex-end" },
                React.createElement(Button, { onClick: function () { return setUseCompleted(!useCompleted); }, style: {
                        backgroundColor: useCompleted
                            ? "#E21D37"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                    } }, useCompleted
                    ? "Hide Completed Tasks"
                    : "Show Completed Tasks"))),
        React.createElement(Grid, { xs: 12 },
            React.createElement(TaskFilters, { setFilter: setFilter, filter: filter, sort: sort, setSort: setSort }))));
};
export default TasksHeader;
