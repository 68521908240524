var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";
var IconBox = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    width: 230px;\n    height: 210px;\n    border: solid 2px grey;\n    justify-content: space-evenly;\n    align-items: center;\n    margin: 15px 15px 15px 15px;\n    cursor: pointer;\n    color: grey;\n    text-align: center;\n    padding: 10px;\n    h6 {\n        font-size: 16px;\n        padding-top: 20px;\n    }\n    &:hover {\n        cursor: pointer;\n        text-decoration: none;\n        color: grey;\n        background-color: #2c3036;\n        transition: 0.2s;\n    }\n    &:hover svg path {\n        fill: white;\n    }\n    &:hover h6 {\n        color: white;\n    }\n    @media (max-width: 1800px) {\n        width: 220px;\n        height: 200px;\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    width: 230px;\n    height: 210px;\n    border: solid 2px grey;\n    justify-content: space-evenly;\n    align-items: center;\n    margin: 15px 15px 15px 15px;\n    cursor: pointer;\n    color: grey;\n    text-align: center;\n    padding: 10px;\n    h6 {\n        font-size: 16px;\n        padding-top: 20px;\n    }\n    &:hover {\n        cursor: pointer;\n        text-decoration: none;\n        color: grey;\n        background-color: #2c3036;\n        transition: 0.2s;\n    }\n    &:hover svg path {\n        fill: white;\n    }\n    &:hover h6 {\n        color: white;\n    }\n    @media (max-width: 1800px) {\n        width: 220px;\n        height: 200px;\n    }\n"])));
var GenericIcon = function (_a) {
    var id = _a.id, handleClick = _a.handleClick, title = _a.title, icon = _a.icon;
    return (React.createElement(Box, { display: "flex", justifyContent: "center" },
        React.createElement(IconBox, { onClick: handleClick, key: id, id: id },
            icon,
            React.createElement("h6", null, title.toUpperCase()))));
};
export default GenericIcon;
var templateObject_1;
