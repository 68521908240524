var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import GenericIcon from "../../components/GenericIcon";
import { QuestionBubbleIcon, WarningIcon, WashingtonIcon, PhoneCircleIcon, PlusCircleIcon, PhoneRingIcon, OutboundIcon, HandshakeIcon, PhoneRingHandIcon, VoicemailIcon, BriefcaseHealthIcon, } from "../../components/IconLibrary";
var ScriptsPage = function () {
    var scriptItems = [
        {
            name: "FAQ's",
            icon: React.createElement(QuestionBubbleIcon, { color: "#2c3036" }),
            url: "/pdf/faq.pdf",
            target: "_blank"
        },
        {
            name: "Stance on Issues",
            icon: React.createElement(WarningIcon, { color: "#2c3036" }),
            url: "https://amac.us/about-us/our-stance-on-the-issues/",
            target: "_blank"
        },
        {
            name: "HSP Washington",
            icon: React.createElement(WashingtonIcon, { color: "#2c3036" }),
            url: "/pdf/hsp-washington.pdf",
            target: "_blank"
        },
        {
            name: "Call Center Phone Etiquette",
            icon: React.createElement(PhoneCircleIcon, { color: "#2c3036" }),
            url: "/pdf/call-center-phone-etiquette.pdf",
            target: "_blank"
        },
        {
            name: "New / Renewal Script",
            icon: React.createElement(PlusCircleIcon, { color: "#2c3036" }),
            url: "/pdf/new_renewal_script.pdf",
            target: "_blank"
        },
        {
            name: "Common Phone List",
            icon: React.createElement(PhoneRingIcon, { color: "#2c3036" }),
            url: "/pdf/common-phone-list.pdf",
            target: "_blank"
        },
        {
            name: "AMAC - Why Join",
            icon: React.createElement(OutboundIcon, { color: "#2c3036" }),
            url: "/pdf/AMAC - Why Join_.pdf",
            target: "_blank"
        },
        {
            name: "Complaint Process-Specifics",
            icon: React.createElement(HandshakeIcon, { color: "#2c3036" }),
            url: "/pdf/Complaint Process-Specifics.pdf",
            target: "_blank"
        },
        {
            name: "HSP vs Mailing House",
            icon: React.createElement(PhoneRingHandIcon, { color: "#2c3036" }),
            url: "/pdf/HSP vs Mailing House.pdf",
            target: "_blank"
        },
        {
            name: "Renewal Call Voicemail Message",
            icon: React.createElement(VoicemailIcon, { color: "#2c3036" }),
            url: "/pdf/renewal-call-voicemail-message-002.pdf",
            target: "_blank"
        },
        {
            name: "2018 Medicare AEP Script",
            icon: React.createElement(BriefcaseHealthIcon, { color: "#2c3036" }),
            url: "/pdf/2018-medicare-aep-script.pdf",
            target: "_blank"
        },
        {
            name: "AEP 2021 MSR Scripting And Instructions",
            icon: React.createElement(BriefcaseHealthIcon, { color: "#2c3036" }),
            url: "/pdf/AEP 2021 MSR Scripting And Instructions.pdf",
            target: "_blank"
        },
        {
            name: "Quick Access Benefit Phone Numbers",
            icon: React.createElement(BriefcaseHealthIcon, { color: "#2c3036" }),
            url: "/pdf/Quick Access Benefit Phone Numbers.pdf",
            target: "_blank"
        },
        {
            name: "Renewal Call script - Manual Dial Updated",
            icon: React.createElement(BriefcaseHealthIcon, { color: "#2c3036" }),
            url: "/pdf/Renewal Call script - Manual Dial Updated.pdf",
            target: "_blank"
        },
    ];
    return (React.createElement(ScriptPage, null, scriptItems.map(function (el, index) { return (React.createElement(GenericIcon, { key: 'script' + index, id: '' + index, title: el.name, icon: el.icon, handleClick: function () { return window.open(el.url, el.target); } })); })));
};
var ScriptPage = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display:flex;\n    flex-wrap:wrap;\n"], ["\n    display:flex;\n    flex-wrap:wrap;\n"])));
export default ScriptsPage;
var templateObject_1;
