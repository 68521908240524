import React, { useState } from "react";
import Layout from "../Layout";
import { usePage } from "@inertiajs/react";
import FormAmacAccountInfo from "../../components/FormAmacAccountInfo";
import { Box } from "@mui/material";
import PageTitle from "../../components/PageTitle";
var IndexAmacAccount = function (_a) {
    var data = _a.data;
    var auth = usePage().props.auth;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    return (React.createElement(Layout, { title: "Product Index", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: "Latest AmacAccounts" })),
        data.amac_accounts.map(function (amac_account) {
            return (React.createElement(Box, { key: amac_account.id, my: 2 },
                React.createElement(FormAmacAccountInfo, { amac_account: amac_account, spouseInfo: false, states: data.states, useAmacAccountNav: true })));
        })));
};
export default IndexAmacAccount;
