import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import MemberLead from "../Pages/Member/components/MemberLead";
import PageTitle from "./PageTitle";
var LeadTable = function (_a) {
    var data = _a.data, title = _a.title, states = _a.states, lead_types = _a.lead_types, agents = _a.agents, dispositions = _a.dispositions;
    if (!data.length) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: title })),
        React.createElement(Box, { mb: 2 },
            React.createElement(Typography, { variant: "subtitle2" }, "*Disclaimer: In order to promote a lead to an app, the disposition \"Application Submitted to Carrier\" must be used.")), data === null || data === void 0 ? void 0 :
        data.map(function (lead) {
            if (!lead.member && !lead.member_with_unmarketable) {
                return null;
            }
            return (React.createElement(Box, { mb: 1, key: lead.id },
                React.createElement(Paper, null,
                    React.createElement(MemberLead, { member: lead.member
                            ? lead.member
                            : lead.member_with_unmarketable, lead: lead, leadTypes: lead_types, agents: agents, states: states, dispositions: dispositions }))));
        })));
};
export default LeadTable;
