import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "@inertiajs/react";
import { Button, Grid, Box, Typography } from "@mui/material";
import route from "ziggy-js";
import { helperFunctions } from "../../Helpers";
var columns = [
    {
        field: "id",
        headerName: "Member ID",
        editable: false,
        hideSortIcons: false,
        disableColumnMenu: true,
        flex: 1,
    },
    {
        field: "full_name",
        headerName: "Name",
        editable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        flex: 1,
        renderCell: function (rowValues) {
            var _a, _b, _c;
            return (React.createElement("div", { style: { lineHeight: "1.2rem" } },
                React.createElement("div", null,
                    "(M): ", (_a = rowValues.row) === null || _a === void 0 ? void 0 :
                    _a.full_name),
                ((_b = rowValues.row) === null || _b === void 0 ? void 0 : _b.spouse_full_name) && (React.createElement("div", null,
                    "(S): ", (_c = rowValues.row) === null || _c === void 0 ? void 0 :
                    _c.spouse_full_name))));
        },
    },
    {
        field: "email",
        headerName: "Email",
        editable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        flex: 1,
        renderCell: function (rowValues) {
            var _a, _b, _c;
            return (React.createElement("div", { style: { lineHeight: "1.2rem" } },
                React.createElement("div", null,
                    "(M): ", (_a = rowValues.row) === null || _a === void 0 ? void 0 :
                    _a.email),
                ((_b = rowValues.row) === null || _b === void 0 ? void 0 : _b.spouse_email) && (React.createElement("div", null,
                    "(S): ", (_c = rowValues.row) === null || _c === void 0 ? void 0 :
                    _c.spouse_email))));
        },
    },
    {
        field: "state.abbreviation",
        headerName: "State/Zip",
        editable: false,
        flex: 1,
        hideSortIcons: true,
        disableColumnMenu: true,
        headerAlign: "left",
        align: "left",
        renderCell: function (rowValues) {
            var _a, _b, _c, _d;
            return (React.createElement(Grid, { container: true, style: { lineHeight: "1.2rem" } },
                React.createElement(Grid, { item: true, xs: 12 }, "(R): ",
                    React.createElement(Typography, { variant: "subtitle2", component: "span", style: { fontWeight: "bold" } }, "".concat((_b = (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.abbreviation)), " ".concat(rowValues.row.res_zip)),
                React.createElement(Grid, { item: true, xs: 12 }, "(S): ",
                    React.createElement(Typography, { variant: "subtitle2", component: "span", style: { fontWeight: "bold" } }, "".concat((_d = (_c = rowValues.row) === null || _c === void 0 ? void 0 : _c.mailing_state) === null || _d === void 0 ? void 0 : _d.abbreviation)), " ".concat(rowValues.row.ship_zip))));
        },
    },
    {
        field: "birth_date",
        headerName: "DOB",
        editable: false,
        flex: 1,
        hideSortIcons: true,
        disableColumnMenu: true,
        headerAlign: "left",
        align: "left",
        renderCell: function (rowValues) { return (React.createElement(Grid, { container: true, style: { lineHeight: "1.2rem" } },
            React.createElement(Grid, { item: true, xs: 12 },
                "(M): ",
                helperFunctions.formatDate(rowValues === null || rowValues === void 0 ? void 0 : rowValues.row.birth_date)),
            rowValues.row.spouse_birth_date && (React.createElement(Grid, { item: true, xs: 12 },
                "(S):",
                " ",
                helperFunctions.formatDate(rowValues.row.spouse_birth_date))))); },
    },
    {
        field: "phone",
        headerName: "Phone",
        editable: false,
        flex: 1,
        hideSortIcons: true,
        disableColumnMenu: true,
        renderCell: function (rowValues) { return (React.createElement("div", { style: { lineHeight: "1.2rem" } },
            React.createElement("div", null,
                "(M): ", rowValues === null || rowValues === void 0 ? void 0 :
                rowValues.row.phone),
            rowValues.row.spouse_phone && (React.createElement("div", null,
                "(S): ",
                rowValues.row.spouse_phone)))); },
    },
    {
        field: "view",
        headerName: "View",
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        align: "center",
        headerAlign: "center",
        flex: 1,
        renderCell: function (rowValues) {
            return (React.createElement(Link, { href: route("show_member", { id: rowValues.id }), as: "span" },
                React.createElement(Button, { variant: "outlined", color: "primary", fullWidth: true }, "View")));
        },
    },
];
var getCurrentRange = function (count, page, itemsPerPage) {
    if (page == 1) {
        return count;
    }
    var pageBase = (page - 1) * itemsPerPage;
    return "".concat(pageBase, " - ").concat(pageBase + count);
};
var SearchResults = function (_a) {
    var response_data = _a.response_data;
    var pagesize = 10;
    var _b = useState(0), page = _b[0], setPage = _b[1];
    var members = response_data["members"];
    var links = response_data["links"];
    var query = response_data["query"];
    var rows = [];
    members.map(function (member) {
        rows.push(member);
    });
    useEffect(function () {
        setPage(0);
    }, [response_data]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, direction: "column", alignItems: "center", justifyContent: "center" },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { m: 1 },
                    React.createElement(Typography, { variant: "subtitle1", style: { fontWeight: "bold" } },
                        "Showing",
                        " ",
                        getCurrentRange(links.count, links.currentPage, links.itemsPerPage),
                        " ",
                        "Results"))),
            (links.prev || links.next) && (React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mb: 1 },
                    React.createElement(Box, { component: "span", m: 2 }, links.prev && (React.createElement(Link, { href: links.prev, as: "span", method: "post", data: query },
                        React.createElement(Button, { variant: "outlined", color: "secondary" },
                            "Prev ",
                            links.itemsPerPage)))),
                    React.createElement(Box, { component: "span", m: 2 }, links.next && (React.createElement(Link, { href: links.next, as: "span", method: "post", data: query },
                        React.createElement(Button, { variant: "outlined", color: "primary" },
                            "Next ",
                            links.itemsPerPage)))))))),
        React.createElement(DataGrid, { rows: rows, columns: columns, disableSelectionOnClick: true, pageSize: pagesize, rowsPerPageOptions: [pagesize], autoHeight: true, rowHeight: 70, hideFooter: false, page: page, onPageChange: function (newPage) { return setPage(newPage); } })));
};
export default SearchResults;
