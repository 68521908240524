var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import Layout from "../Layout";
import { useForm } from "react-hook-form";
import route from "ziggy-js";
import GenericControllerCheckboxInput from "../../components/GenericControllerCheckboxInput";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { kebabCase, startCase } from "lodash";
import styled from "styled-components";
import { agentHasPermission, agentHasRole } from "../../utils/utils";
import { router, Link } from "@inertiajs/react";
export default function AbsPermissions(_a) {
    var response_data = _a.response_data;
    var user = response_data.user;
    var _b = useForm(), control = _b.control, handleSubmit = _b.handleSubmit, watch = _b.watch;
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    var allPermissions = response_data.all_permissions;
    var allRolesWithPermissions = response_data.all_roles_with_permissions;
    var allRoles = response_data.all_roles;
    var currentRoles = user.roles.reduce(function (result, el) {
        var _a;
        return (__assign((_a = {}, _a[el.name] = true, _a), result));
    }, {});
    var watchRoles = watch("roles", currentRoles);
    var selectedRoles = Object.entries(watchRoles)
        .filter(function (el) { return el[1]; })
        .map(function (el) { return el[0]; });
    var selectedRolesPermissions = new Set([]);
    var _loop_1 = function (roleName) {
        var role = allRolesWithPermissions.filter(function (role) { return role.name === roleName; })[0];
        for (var _d = 0, _e = role.permissions; _d < _e.length; _d++) {
            var permission = _e[_d];
            selectedRolesPermissions.add(permission.name);
        }
    };
    for (var _i = 0, selectedRoles_1 = selectedRoles; _i < selectedRoles_1.length; _i++) {
        var roleName = selectedRoles_1[_i];
        _loop_1(roleName);
    }
    var onSubmit = function (data) {
        router.post(route("edit_abs_permissions", user.id), { data: data });
    };
    return (React.createElement(Layout, { title: "Broker Permissions", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
            React.createElement("h3", null,
                user.first_name,
                " ",
                user.last_name,
                "'s Roles & Permissions"),
            React.createElement("br", null),
            React.createElement("h5", null, "Roles:"),
            React.createElement(PermissionsList, null, allRoles.map(function (role) {
                if (["admin"].includes(role.name)) {
                    return null;
                }
                return (React.createElement("div", { key: role.id },
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: agentHasRole(user, role.name), control: control, name: "roles[".concat(role.name, "]"), color: "primary", label: role.name === "msr"
                            ? "MSR"
                            : startCase(role.name) })));
            })),
            selectedRolesPermissions.size > 0 && (React.createElement(React.Fragment, null,
                React.createElement("br", null),
                React.createElement("h5", null, "Permissions by Role:"),
                React.createElement(PermissionsList, null, Array.from(selectedRolesPermissions)
                    .sort(function (a, b) {
                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                })
                    .map(function (permission) {
                    return (React.createElement("div", { key: kebabCase(permission) },
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: true, control: control, name: "permissions[".concat(permission, "]"), color: "primary", label: startCase(permission), isDisabled: true })));
                })))),
            selectedRolesPermissions.size !== allPermissions.length && (React.createElement(React.Fragment, null,
                React.createElement("br", null),
                React.createElement("h5", null, "Additional Permissions:"),
                React.createElement(PermissionsList, null, allPermissions
                    .sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                })
                    .map(function (permission) {
                    if (Array.from(selectedRolesPermissions).includes(permission.name)) {
                        return null;
                    }
                    return (React.createElement("div", { key: permission.id },
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: agentHasPermission(user, permission.name), control: control, name: "permissions[".concat(permission.name, "]"), color: "primary", label: startCase(permission.name) })));
                })))),
            React.createElement(Grid, { container: true, spacing: 4, alignItems: "center", justifyContent: "center", xs: 12 },
                React.createElement(Grid, { xs: 3 },
                    React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: route("abs_brokers").toString() }, "Cancel")),
                React.createElement(Grid, { xs: 3 },
                    React.createElement(Button, { type: "submit", variant: "contained", color: "primary" }, "Update Roles & Permissions"))))));
}
var PermissionsList = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    list-style: none;\n    columns: 3;\n\n    .MuiFormControlLabel-label.Mui-disabled {\n        color: black;\n    }\n\n    .MuiFormControlLabel-root.Mui-disabled {\n        cursor: not-allowed;\n    }\n    .MuiCheckbox-colorPrimary.Mui-disabled {\n        color: rgb(44 143 98);\n    }\n"], ["\n    list-style: none;\n    columns: 3;\n\n    .MuiFormControlLabel-label.Mui-disabled {\n        color: black;\n    }\n\n    .MuiFormControlLabel-root.Mui-disabled {\n        cursor: not-allowed;\n    }\n    .MuiCheckbox-colorPrimary.Mui-disabled {\n        color: rgb(44 143 98);\n    }\n"])));
var templateObject_1;
