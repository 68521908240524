import React from "react";
import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
import GenericAutoComplete from "../../../components/GenericAutoComplete";
var HealthInsuranceInfo = function (_a) {
    var control = _a.control, errors = _a.errors, insuranceCarriers = _a.insuranceCarriers, watchInsuranceCarrier = _a.watchInsuranceCarrier, app = _a.app;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
        React.createElement(Grid, { xs: 12, component: Paper, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Health Insurance Information")),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericAutoComplete, { limitTags: 1, label: "Insurance Carrier", name: "insurance_carrier", control: control, options: insuranceCarriers, rules: {
                            required: true,
                        }, error: "insurance_carrier" in errors, value: watchInsuranceCarrier, multiple: false })),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericControllerTextInput, { label: "Effective Date", type: "date", control: control, name: "effective_date", defaultValue: app === null || app === void 0 ? void 0 : app.effective_date, shrinkLabel: true, error: false }))))));
};
export default HealthInsuranceInfo;
