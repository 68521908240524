import React, { useState } from "react";
import Layout from "../Layout";
import LeadTable from "../../components/LeadTable";
import ExecutiveChart from "./components/ExecutiveCharts";
import ExecutiveForm from "./components/ExecutiveForm";
import { agentHasRole, hasPermission } from "../../utils/utils";
import ScriptsPage from "../Static/ScriptsPage";
import Grid from "@mui/material/Unstable_Grid2/";
import AppTable from "../../components/AppTable";
import HomeMenu from "./HomeMenu";
import { maxBy } from "lodash";
import DonationChart from "./components/DonationCharts";
export default function Agents(_a) {
    var response_data = _a.response_data, auth = _a.auth, serverErrors = _a.errors;
    var todays_health_apps = response_data.todays_health_apps, todays_financial_apps = response_data.todays_financial_apps, todays_life_apps = response_data.todays_life_apps, todays_financial_leads = response_data.todays_financial_leads, todays_health_leads = response_data.todays_health_leads, todays_ltc_leads = response_data.todays_ltc_leads, todays_donation_leads = response_data.todays_donation_leads, lead_types = response_data.lead_types, insurance_carriers = response_data.insurance_carriers, summary = response_data.summary, agents = response_data.agents, user = response_data.user, totals = response_data.totals, states = response_data.states, dispositions = response_data.dispositions, app_dispositions = response_data.app_dispositions;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var todaysData = [
        todays_financial_apps,
        todays_health_leads,
        todays_ltc_leads,
        todays_financial_leads,
        todays_health_apps,
        todays_life_apps,
    ];
    var dataWithMaxTotal = maxBy(todaysData, "total");
    todaysData.forEach(function (data) {
        data.data.sort(function (x, y) {
            return x.disposition_id == 20 ? -1 : y.disposition_id == 20 ? 1 : 0;
        });
    });
    return (React.createElement(Layout, { title: "Home", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        hasPermission(auth, "view executive-dashboard") && (React.createElement(Grid, { container: true, m: 1 },
            React.createElement(Grid, { xs: 12, mb: 1 },
                React.createElement(ExecutiveForm, { data: response_data, serverErrors: serverErrors })),
            React.createElement(ExecutiveChart, { data: response_data }))),
        (agentHasRole(auth, "action_organizer") ||
            hasPermission(auth, "view executive-dashboard")) && (React.createElement(Grid, { container: true, m: 1 },
            React.createElement(DonationChart, { data: response_data }))),
        (agentHasRole(auth, "agent") ||
            agentHasRole(auth, "supervisor") ||
            agentHasRole(auth, "broker") ||
            agentHasRole(auth, "action_organizer")) && (React.createElement(Grid, { container: true },
            React.createElement(Grid, { xs: 12 },
                React.createElement(HomeMenu, { auth: auth, user: user, title: "Today's", data: dataWithMaxTotal, totals: totals })),
            React.createElement(Grid, { xs: 12 },
                React.createElement(LeadTable, { data: todays_financial_leads.data, title: "Today's Financial Leads", lead_types: lead_types, agents: agents, states: states, dispositions: dispositions }),
                React.createElement(LeadTable, { data: todays_ltc_leads.data, title: "Today's LTC Leads", lead_types: lead_types, agents: agents, states: states, dispositions: dispositions }),
                React.createElement(LeadTable, { data: todays_health_leads.data, title: "Today's Health Leads", lead_types: lead_types, agents: agents, states: states, dispositions: dispositions }),
                React.createElement(LeadTable, { data: todays_donation_leads.data, title: "Today's Donation Leads", lead_types: lead_types, agents: agents, states: states, dispositions: dispositions }),
                todays_health_apps.data.length > 0 && (React.createElement(AppTable, { noteType: "health", title: "Today's Health Apps", data: todays_health_apps.data, leadTypes: lead_types, insuranceCarriers: insurance_carriers, numberOfTableColumns: 7, fileUploadDirectory: "health_insurance_applications", agents: agents, states: states, appType: "App\\Models\\HealthInsuranceApplication", dispositions: app_dispositions })),
                todays_financial_apps.data.length > 0 && (React.createElement(AppTable, { noteType: "financial", title: "Today's Financial Apps", data: todays_financial_apps.data, leadTypes: lead_types, insuranceCarriers: insurance_carriers, numberOfTableColumns: 7, fileUploadDirectory: "financial_applications", agents: agents, states: states, appType: "App\\Models\\FinancialApplication", dispositions: app_dispositions })),
                todays_life_apps.data.length > 0 && (React.createElement(AppTable, { noteType: "life", title: "Today's Life Apps", data: todays_life_apps.data, leadTypes: lead_types, insuranceCarriers: insurance_carriers, numberOfTableColumns: 7, fileUploadDirectory: "life_insurance_applications", agents: agents, states: states, appType: "App\\Models\\LifeInsuranceApplication", dispositions: app_dispositions }))))),
        (agentHasRole(auth, "msr") || agentHasRole(auth, "analyst")) && (React.createElement(Grid, { justifyContent: "center", xs: 12, spacing: 2 },
            React.createElement(ScriptsPage, null)))));
}
