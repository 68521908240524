import React, { useState } from 'react';
import Layout from '../Layout';
import { Link } from "@inertiajs/react";
import route from 'ziggy-js';
var AllUsers = function (_a) {
    var users = _a.users;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    if (!users)
        return React.createElement(React.Fragment, null);
    return (React.createElement(Layout, { title: "All Users", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement("h2", null, "All Users"),
        users.map(function (user, index) {
            return (React.createElement("li", { key: index },
                React.createElement(Link, { href: route('user_details', [user.id]) }, user.first_name)));
        })));
};
export default AllUsers;
