import React from "react";
import { Link } from "@inertiajs/react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
function LinkActive(props) {
    return (React.createElement(Link, { href: props.url, as: "span", key: props.label, method: "post", data: { id: props.user.id } },
        React.createElement(Button, { variant: props.active ? "contained" : "outlined", color: props.active ? "secondary" : "primary", size: "small" }, props.label
            .replace("&laquo; Previous", "Prev")
            .replace("Next &raquo;", "Next"))));
}
function LinkInactive(props) {
    if (props.label == "&laquo; Previous") {
        return null;
    }
    if (props.label == "Next &raquo;") {
        return null;
    }
    return (React.createElement(Button, { variant: "outlined", color: "primary", disabled: true, size: "small" }, props.label
        .replace("&laquo; Previous", "Prev")
        .replace("Next &raquo;", "Next")));
}
function PageLink(props) {
    var hasUrl = props.url;
    if (hasUrl) {
        return (React.createElement(LinkActive, { label: props.label, url: props.url, active: props.active, user: props.user }));
    }
    return React.createElement(LinkInactive, { label: props.label });
}
export default function HomePagination(_a) {
    var _b;
    var data = _a.data, user = _a.user;
    return (React.createElement(Grid, { xs: 12, container: true, direction: "column", alignItems: "center", justifyContent: "center" },
        React.createElement(Grid, null,
            React.createElement(Box, { m: 3 }, (_b = data === null || data === void 0 ? void 0 : data.links) === null || _b === void 0 ? void 0 : _b.map(function (link, index) {
                return (React.createElement(PageLink, { label: link.label, url: link.url, key: "search_link_" + index, active: link.active, user: user }));
            })))));
}
