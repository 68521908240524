import React, { useState } from "react";
import { Button, Modal, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { router } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import GenericControllerDropdownInput from "./GenericControllerDropdownInput";
import { startCase } from "lodash";
var ModalStatus = function (_a) {
    var current_status = _a.current_status, model = _a.model, model_id = _a.model_id, _b = _a.size, size = _b === void 0 ? "small" : _b;
    var _c = useForm(), handleSubmit = _c.handleSubmit, control = _c.control, formErrors = _c.formState.errors;
    var _d = useState(false), open = _d[0], setOpen = _d[1];
    // possibly needs to be dynamic after conversion w/ ori + mike - easy to pass as prop
    // also need to consider member status composite with leave reason
    var statusOptions = ["active", "completed"];
    var handleChange = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var submitUpdate = function (data) {
        setOpen(false);
        data.status = data.status;
        data.model = model;
        data.model_id = model_id;
        router.post("/status/update", data);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "contained", style: {
                backgroundColor: "#392869",
                color: "#FFF",
            }, onClick: handleChange, fullWidth: true, size: size }, "Change Status"),
        React.createElement(Modal, { open: open, onClose: handleClose },
            React.createElement(Dialog, { open: open, onClose: handleClose },
                React.createElement(DialogTitle, null, "Member Status"),
                React.createElement(DialogContent, { style: { minWidth: "500px" } },
                    React.createElement(Grid, { container: true, xs: 12, spacing: 1 },
                        React.createElement(GenericControllerDropdownInput, { formControlSX: { my: 1 }, name: "status", fullWidth: true, control: control, label: "Member Status", id: "member-status", defaultValue: current_status, handleChange: handleChange, rules: { required: true }, options: statusOptions.map(function (status) {
                                return (React.createElement(MenuItem, { key: status, value: status }, startCase(status)));
                            }) }))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary", size: "small" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitUpdate), color: "primary" }, "Change"))))));
};
export default ModalStatus;
