import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from "@mui/material/";
import YearPicker from "../components/YearPicker";
import monthsList from "../../../constants/months";
var borders = {
    border: "1px solid #707070",
};
var header = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
};
var headerLeft = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderLeft: "1px solid #707070",
};
var headerRight = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderRight: "1px solid #707070",
};
function ApplicationsTable(_a) {
    var applications_by_month = _a.applications_by_month, year = _a.year, setYear = _a.setYear, department = _a.department;
    var columns = department === "Rosemark"
        ? [
            { id: "month", label: "month" },
            {
                id: "withdrawn",
                label: "Withdrawn",
            },
            {
                id: "approved",
                label: "Approved",
            },
            {
                id: "submitted",
                label: "Submitted",
            },
            {
                id: "declined",
                label: "Declined",
            },
            {
                id: "issued",
                label: "Issued",
            },
        ]
        : [];
    var colHeaders = department === "Rosemark" ? (React.createElement(React.Fragment, null,
        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Month"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Withdrawn")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Declined")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Submitted")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Approved - Waiting")),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 },
            React.createElement("strong", null, "Issued")))) : (React.createElement(React.Fragment, null));
    return (React.createElement(Paper, null, applications_by_month && (React.createElement(TableContainer, null,
        React.createElement(Table, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }),
                    React.createElement(TableCell, { align: "left", style: header, colSpan: 1 }),
                    React.createElement(TableCell, { align: "center", style: header, colSpan: columns.length - 3 },
                        React.createElement(YearPicker, { year: year, setYear: setYear })),
                    React.createElement(TableCell, { align: "left", style: headerRight, colSpan: 1 })),
                React.createElement(TableRow, null, colHeaders)),
            React.createElement(TableBody, null, applications_by_month &&
                applications_by_month.map(function (row) {
                    return (React.createElement(TableRow, { role: "checkbox", tabIndex: -1, key: row.month + "_row" }, columns.map(function (column) {
                        var value = row[column.id];
                        if (column.id == "month") {
                            var monthName = monthsList[value - 1];
                            value = monthName;
                        }
                        return (React.createElement(TableCell, { key: row.month +
                                column.id, style: borders, align: !column.id.includes("month")
                                ? "center"
                                : "left" }, value));
                    })));
                })))))));
}
export default ApplicationsTable;
