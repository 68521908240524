import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { spouseFormOptions } from "../utils/utils";
import GenericContactNameForm from "./GenericFormFields/GenericContactNameForm";
import GenericPhoneInput from "./GenericFormFields/GenericPhoneInput";
import GenericControllerTextInput from "./GenericFormFields/TextInput";
var ContactSpouseInfo = function (_a) {
    var _b, _c, _d, _e;
    var contact = _a.contact, control = _a.control, errors = _a.errors, _f = _a.data, data = _f === void 0 ? {} : _f, produceContacts = _a.produceContacts;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Spouse/Family Contact Information")),
        React.createElement(GenericContactNameForm, { control: control, contact: contact, errors: errors, data: data, options: spouseFormOptions, onblur: produceContacts }),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { control: control, type: "email", defaultValue: (_b = contact === null || contact === void 0 ? void 0 : contact.spouse_email) !== null && _b !== void 0 ? _b : "", name: "spouse_email", label: "Spouse/Family Contact Email", error: "spouse_email" in errors, onblur: produceContacts })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { control: control, type: "date", defaultValue: (_c = contact === null || contact === void 0 ? void 0 : contact.spouse_birth_date) !== null && _c !== void 0 ? _c : "", name: spouseFormOptions.birth.name, label: spouseFormOptions.birth.label, shrinkLabel: true, error: false }))),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericPhoneInput, { defaultValue: (_d = contact === null || contact === void 0 ? void 0 : contact.spouse_phone) !== null && _d !== void 0 ? _d : "", label: "Spouse/Family Contact Phone", name: "spouse_phone", control: control, required: false, helperText: "Please provide full phone (###) ###-####.", onblur: produceContacts })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericPhoneInput, { defaultValue: (_e = contact === null || contact === void 0 ? void 0 : contact.spouse_phone_alt) !== null && _e !== void 0 ? _e : "", label: "Spouse/Family Contact Alt. Phone Number", name: "spouse_phone_alt", control: control, required: false, helperText: "Please provide full phone (###) ###-####.", onblur: produceContacts })))));
};
export default ContactSpouseInfo;
