import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { Link } from "@inertiajs/react";
import { Button } from "@mui/material";
var AmacAccountProfileLink = function (props) {
    var amac_account = props.amac_account;
    return (React.createElement(Grid, { container: true, alignItems: "center", justifyContent: "center", xs: true, direction: "row", spacing: 2 },
        React.createElement(Grid, null,
            React.createElement(Button, { type: "submit", variant: "contained", color: "primary", fullWidth: true, disabled: true }, "Success!")),
        React.createElement(Grid, null,
            React.createElement(Link, { href: "/amac_account/show/".concat(amac_account === null || amac_account === void 0 ? void 0 : amac_account.id) },
                React.createElement(Button, { type: "button", color: "primary", variant: "contained", fullWidth: true }, "Go To AmacAccount Profile")))));
};
export default AmacAccountProfileLink;
