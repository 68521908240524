import React from "react";
import { filterDeletedAppables, filterDeletedDonations, filterDeletedLeads, filterDeletedPolicies, getShowDeleted, } from "../../../../utils/utils";
import ContactNav from "./ContactNav";
export var ContactNavWrapper = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var contact = _a.contact, all_documents = _a.all_documents, donation_count = _a.donation_count, activity_log = _a.activity_log, _l = _a.showDeleted, showDeleted = _l === void 0 ? getShowDeleted() : _l;
    return (React.createElement(ContactNav, { contact: contact, order_count: (_b = contact === null || contact === void 0 ? void 0 : contact.orders) === null || _b === void 0 ? void 0 : _b.length, lead_count: (_d = (_c = contact === null || contact === void 0 ? void 0 : contact.leads) === null || _c === void 0 ? void 0 : _c.filter(function (el) {
            return filterDeletedLeads(el, showDeleted);
        })) === null || _d === void 0 ? void 0 : _d.length, app_count: (_f = (_e = contact === null || contact === void 0 ? void 0 : contact.apps) === null || _e === void 0 ? void 0 : _e.filter(function (el) {
            return filterDeletedAppables(el, showDeleted);
        })) === null || _f === void 0 ? void 0 : _f.length, policy_count: (_h = (_g = contact === null || contact === void 0 ? void 0 : contact.policies) === null || _g === void 0 ? void 0 : _g.filter(function (el) {
            return filterDeletedPolicies(el, showDeleted);
        })) === null || _h === void 0 ? void 0 : _h.length, document_count: all_documents.length, 
        // donation_count={contact?.donations?.length}
        donation_count: (_k = (_j = contact === null || contact === void 0 ? void 0 : contact.donations) === null || _j === void 0 ? void 0 : _j.filter(function (el) {
            return filterDeletedDonations(el, showDeleted);
        })) === null || _k === void 0 ? void 0 : _k.length, activity_log_count: activity_log === null || activity_log === void 0 ? void 0 : activity_log.length }));
};
