import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { LifetimeBadge } from "../../../components/IconLibrary";
import { ActionDonorBadge, FoundationDonorBadge, } from "../../../components/IconLibrary";
import { fullNameWithPrefixSuffix } from "../../../utils/utils";
var ContactHeader = function (_a) {
    var _b;
    var contact = _a.contact;
    return (React.createElement(Box, { mx: 2, my: 2 },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "h6" }, (_b = fullNameWithPrefixSuffix(contact.full_name, contact.prefix, contact.suffix)) !== null && _b !== void 0 ? _b : ""),
                React.createElement(Typography, { variant: "subtitle1", style: { color: "#000" } },
                    "#",
                    contact.id,
                    " ")),
            React.createElement(Grid, { container: true, item: true, xs: 2, alignItems: "flex-start", justifyContent: "flex-start" }, (contact === null || contact === void 0 ? void 0 : contact.lifetime) == 1 && React.createElement(LifetimeBadge, null)),
            React.createElement(Grid, { container: true, item: true, xs: 2, alignItems: "flex-start", justifyContent: "flex-start" }, (contact === null || contact === void 0 ? void 0 : contact.donations.length) > 0 && React.createElement(ActionDonorBadge, null)),
            React.createElement(Grid, { container: true, item: true, xs: 2, alignItems: "flex-start", justifyContent: "flex-start" }, (contact === null || contact === void 0 ? void 0 : contact.orders.filter(function (order) { return order.product_id === 7; }).length) > 0 && React.createElement(FoundationDonorBadge, null)))));
};
export default ContactHeader;
