import React from "react";
import { agentHasRole, hasPermission } from "../../utils/utils";
import { Box, Button, ButtonGroup, Grid } from "@mui/material";
import HomePageTitle from "./components/HomePageTitle";
import PolicySearch from "../../components/PolicySearch";
import HomePagination from "./components/HomePagination";
import { router, usePage } from "@inertiajs/react";
var handleClick = function (e, user, path) {
    e.preventDefault();
    router.post(path, { id: user.id });
};
var handleTask = function (e, path) {
    e.preventDefault();
    router.get(path);
};
export default function HomeMenu(_a) {
    var auth = _a.auth, user = _a.user, title = _a.title, data = _a.data, totals = _a.totals;
    var url = usePage().url;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center" },
            React.createElement(ButtonGroup, null,
                React.createElement(ButtonGroup, null,
                    React.createElement(Button, { onClick: function (e) { return handleClick(e, user, "/home"); }, style: {
                            backgroundColor: [
                                "/home",
                                "/dashboard",
                                "/",
                            ].includes(url)
                                ? "#E21D37"
                                : "#1E5496",
                            color: "#FFF",
                            textDecoration: "none",
                        } },
                        "Today's ", "(".concat(totals.todays, ")")),
                    React.createElement(Button, { onClick: function (e) { return handleClick(e, user, "/urgent"); }, style: {
                            backgroundColor: url.includes("/urgent")
                                ? "#E21D37"
                                : "#1E5496",
                            color: "#FFF",
                            textDecoration: "none",
                        } },
                        "Urgent ", "(".concat(totals.urgent, ")")),
                    React.createElement(Button, { onClick: function (e) { return handleClick(e, user, "/upcoming"); }, style: {
                            backgroundColor: url.includes("/upcoming")
                                ? "#E21D37"
                                : "#1E5496",
                            color: "#FFF",
                            textDecoration: "none",
                        } },
                        "Upcoming ", "(".concat(totals.upcoming, ")")),
                    React.createElement(Button, { onClick: function (e) { return handleClick(e, user, "/recent"); }, style: {
                            backgroundColor: ["/recent"].includes(url)
                                ? "#E21D37"
                                : "#1E5496",
                            color: "#FFF",
                            textDecoration: "none",
                        } },
                        "Recent ", "(".concat(totals.recent, ")")),
                    React.createElement(Button, { onClick: function (e) {
                            return handleClick(e, user, "/recently-transferred");
                        }, style: {
                            backgroundColor: url.includes("/recently-transferred")
                                ? "#E21D37"
                                : "#1E5496",
                            color: "#FFF",
                            textDecoration: "none",
                        } },
                        "Recently Transferred",
                        " ", "(".concat(totals.recently_transferred, ")")),
                    hasPermission(auth, "view tasks") && (React.createElement(Button, { onClick: function (e) { return handleTask(e, "/task"); }, style: {
                            backgroundColor: url.includes("/task")
                                ? "#E21D37"
                                : "#1E5496",
                            color: "#FFF",
                            textDecoration: "none",
                        } },
                        "Tasks ", "(".concat(totals.tasks, ")"))),
                    agentHasRole(auth, "supervisor") && (React.createElement(Button, { onClick: function (e) { return handleClick(e, auth, "/team"); }, style: {
                            backgroundColor: url.includes("/team")
                                ? "#E21D37"
                                : "#1E5496",
                            color: "#FFF",
                            textDecoration: "none",
                        } }, "Subordinates"))))),
        React.createElement(Grid, { container: true, item: true, xs: 12, lg: 3 },
            React.createElement(HomePageTitle, { user: user, title: title })),
        React.createElement(Grid, { container: true, item: true, xs: 12, lg: 6 },
            React.createElement(HomePagination, { data: data, user: user })),
        hasPermission(auth, "search policies") && (React.createElement(Grid, { container: true, item: true, xs: 12, lg: 3, justifyContent: "flex-end" },
            React.createElement(Box, { m: 2 },
                React.createElement(PolicySearch, null))))));
}
