import React, { useState } from "react";
import Layout from "../Layout";
import ScriptsPage from "./ScriptsPage";
import PageTitle from "../../components/PageTitle";
var Scripts = function () {
    var _a = useState(true), menuOpen = _a[0], toggleMenuOpen = _a[1];
    return (React.createElement(Layout, { title: "Scripts", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(PageTitle, { title: "Scripts" }),
        React.createElement(ScriptsPage, null)));
};
export default Scripts;
