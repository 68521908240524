import React from "react";
import { Link, usePage } from "@inertiajs/react";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import route from "ziggy-js";
import { hasPermission, capitalizeFirstLetter } from "../../../utils/utils";
var AssignedRelation = function (_a) {
    var list = _a.list, listType = _a.listType, user = _a.user;
    var auth = usePage().props.auth;
    return (React.createElement(Grid, { m: 1, xs: true, style: {
            borderLeft: "10px #035399 solid",
            borderRight: "2px #035399 solid",
            borderBottom: "2px #035399 solid",
            borderTop: "2px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 12, container: true },
            React.createElement(Grid, { xs: 3 },
                React.createElement(Typography, { variant: "h5", component: "h2" }, capitalizeFirstLetter(listType))),
            React.createElement(Grid, { xs: 6 }),
            hasPermission(auth, "assign broker-".concat(listType)) && (React.createElement(Grid, { xs: 3 },
                React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, href: route("abs_assign_".concat(listType), [
                        user === null || user === void 0 ? void 0 : user.id,
                    ]).toString() }, "Assign ".concat(capitalizeFirstLetter(listType)))))),
        React.createElement(Grid, { xs: 12 }, list
            .sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        })
            .map(function (list) { return (React.createElement(Grid, { key: list.id, xs: 3 },
            React.createElement(Typography, { variant: "body1" }, list.name))); }))));
};
export default AssignedRelation;
