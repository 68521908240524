import React from "react";
import { Menu, MenuItem, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import route from "ziggy-js";
import { getProductionBoardsArray, hasPermission } from "../../utils/utils";
import { Link, usePage } from "@inertiajs/react";
var departmentOptions = [
    { name: "Medicare", access: "run medicare production" },
    { name: "IMM", access: "run imm production" },
    { name: "Rosemark", access: "run rosemark production" },
    { name: "Policy-Referrals", access: "run policy-referrals production" },
];
var ProductionBoardsNav = function (_a) {
    var _b;
    var board = _a.board, department = _a.department;
    var auth = usePage().props.auth;
    var _c = React.useState(null), boardAnchorEl = _c[0], setBoardAnchorEl = _c[1];
    var _d = React.useState(null), departmentAnchorEl = _d[0], setDepartmentAnchorEl = _d[1];
    var handleClick = function (event, handler) {
        handler(event.currentTarget);
    };
    var handleClose = function (handler) {
        handler(null);
    };
    var showDepartments = function () {
        return departmentOptions.filter(function (option) {
            return hasPermission(auth, option.access);
        }).length > 1
            ? true
            : false;
    };
    var boardOptions = getProductionBoardsArray(department);
    return (React.createElement(React.Fragment, null, department && (React.createElement(Grid, { spacing: 3, xs: 12, container: true },
        React.createElement(Grid, { xs: 6 },
            React.createElement(Typography, { variant: "h4", component: "h4" }, department !== "Policy-Referrals"
                ? department +
                    " - " +
                    ((_b = boardOptions[boardOptions.findIndex(function (option) {
                        return option
                            .toLowerCase()
                            .replace(" ", "-") === board;
                    })]) !== null && _b !== void 0 ? _b : "Choose a board to display")
                : "Policy - Referrals")),
        React.createElement(Grid, { xs: 3, container: true, spacing: 1, justifyContent: "end" }, showDepartments() && (React.createElement(Button, { "aria-controls": "departments-menu", "aria-haspopup": "true", variant: "outlined", onClick: function (e) {
                return handleClick(e, setDepartmentAnchorEl);
            } },
            department !== "Policy-Referrals"
                ? department
                : "Policy - Referrals",
            React.createElement(ArrowDropDownIcon, null)))),
        React.createElement(Menu, { id: "department-menu", anchorEl: departmentAnchorEl, keepMounted: true, open: Boolean(departmentAnchorEl), onClose: function () { return handleClose(setDepartmentAnchorEl); } }, departmentOptions.map(function (option) {
            if (!hasPermission(auth, option.access)) {
                return null;
            }
            return (React.createElement(Link, { method: "get", href: route("".concat(department, "-production-board"), {
                    department: option.name,
                    board: board,
                }), style: {
                    textDecoration: "none",
                }, key: option.name + "department-menu-link" },
                React.createElement(MenuItem, { onClick: function () {
                        return handleClose(setDepartmentAnchorEl);
                    }, key: option.name + "department-menu-item" }, option.name !== "Policy-Referrals"
                    ? option.name
                    : "Policy - Referrals")));
        })),
        department != "Policy-Referrals" && (React.createElement(Grid, { xs: 3, container: true, spacing: 1 },
            React.createElement(Button, { "aria-controls": "production-board-menu", "aria-haspopup": "true", variant: "outlined", onClick: function (e) {
                    return handleClick(e, setBoardAnchorEl);
                } },
                boardOptions[boardOptions.findIndex(function (option) {
                    return option
                        .toLowerCase()
                        .replace(" ", "-") === board;
                })],
                React.createElement(ArrowDropDownIcon, null)))),
        department != "Policy-Referrals" && (React.createElement(Menu, { id: "production-board-menu", anchorEl: boardAnchorEl, keepMounted: true, open: Boolean(boardAnchorEl), onClose: function () { return handleClose(setBoardAnchorEl); } }, boardOptions.map(function (option) {
            return (React.createElement(Link, { method: "get", href: route("".concat(department, "-production-board"), {
                    department: department,
                    board: option
                        .toLowerCase()
                        .replace(" ", "-"),
                }), style: {
                    textDecoration: "none",
                }, key: option
                    .toLowerCase()
                    .replace(" ", "-") +
                    "-menu-link" },
                " ",
                React.createElement(MenuItem, { onClick: function () {
                        return handleClose(setBoardAnchorEl);
                    }, key: option
                        .toLowerCase()
                        .replace(" ", "-") +
                        "-menu-item" }, option)));
        })))))));
};
export default ProductionBoardsNav;
