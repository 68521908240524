import React from "react";
import { Button, Grid } from "@mui/material";
import { Link } from "@inertiajs/react";
var LeadSelection = function (_a) {
    var member = _a.member, leadId = _a.leadId, leadIds = _a.leadIds, leadTypes = _a.leadTypes, setLeadId = _a.setLeadId, leads = _a.leads, showDeleted = _a.showDeleted, setShowDeleted = _a.setShowDeleted;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { container: true, item: true, justifyContent: "center", alignItems: "center", spacing: 1 },
            React.createElement(Grid, { item: true },
                React.createElement(Button, { variant: "contained", style: {
                        backgroundColor: "#392869",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }, size: "small", onClick: function () { return setShowDeleted(!showDeleted); } }, showDeleted ? "Hide Deleted" : "Show Deleted")),
            React.createElement(Grid, { item: true },
                React.createElement(Link, { href: "/lead/form/".concat(member.id) },
                    React.createElement(Button, { variant: "contained", style: {
                            backgroundColor: "#286941",
                            color: "#FFF",
                            fontSize: "11px",
                            fontWeight: "400",
                        }, size: "small" }, "Add Lead"))),
            leadIds.map(function (el) {
                var _a, _b;
                return (React.createElement(Grid, { item: true, key: el },
                    React.createElement(Button, { variant: "contained", color: leadId === el ? "secondary" : "primary", style: {
                            color: "#fff",
                            fontSize: "12px",
                        }, size: "small", onClick: function () { return setLeadId(el); } }, "".concat((_a = leadTypes.find(function (leadType) { return leadType.id == el; })) === null || _a === void 0 ? void 0 : _a.name),
                        React.createElement("span", { style: {
                                marginLeft: "3px",
                                backgroundColor: "#FFF",
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                            } },
                            React.createElement("span", { style: {
                                    color: "#000",
                                } }, (_b = leads[el]) === null || _b === void 0 ? void 0 : _b.length)))));
            }))));
};
export default LeadSelection;
