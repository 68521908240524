import React, { useState } from "react";
import Layout from "../Layout";
import LeadTable from "../../components/LeadTable";
import SubordinatesTable from "./components/SubordinateTable";
import { agentHasRole } from "../../utils/utils";
import { Tab, Tabs } from "@mui/material";
export default function AbsBrokers(_a) {
    var _b, _c;
    var response_data = _a.response_data, auth = _a.auth, serverErrors = _a.errors;
    var user = response_data.user, recent_leads = response_data.recent_leads, urgent_leads = response_data.urgent_leads, todays_leads = response_data.todays_leads, upcoming_leads = response_data.upcoming_leads, lead_types = response_data.lead_types, summary = response_data.summary, agents = response_data.agents, states = response_data.states, dispositions = response_data.dispositions;
    var _d = useState(true), menuOpen = _d[0], toggleMenuOpen = _d[1];
    var _e = useState(0), tab = _e[0], setTab = _e[1];
    var handleTabChange = function (_, value) {
        setTab(value);
    };
    return (React.createElement(Layout, { title: "Home", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Tabs, { value: tab, onChange: handleTabChange, indicatorColor: "primary", textColor: "primary" },
            React.createElement(Tab, { label: "Today's Leads (".concat(todays_leads === null || todays_leads === void 0 ? void 0 : todays_leads.data.length, ")") }),
            React.createElement(Tab, { label: "Urgent Leads (".concat(urgent_leads === null || urgent_leads === void 0 ? void 0 : urgent_leads.data.length, ")") }),
            React.createElement(Tab, { label: "Recent Leads (".concat(recent_leads === null || recent_leads === void 0 ? void 0 : recent_leads.data.length, ")") }),
            React.createElement(Tab, { label: "Upcoming Leads (".concat(upcoming_leads === null || upcoming_leads === void 0 ? void 0 : upcoming_leads.data.length, ")") }),
            agentHasRole(auth, "supervisor")
                ? ((_b = user === null || user === void 0 ? void 0 : user.subordinates) === null || _b === void 0 ? void 0 : _b.length) && (React.createElement(Tab, { label: "Subordinates (\n                                            ".concat(user === null || user === void 0 ? void 0 : user.subordinates.length, ")") }))
                : null),
        tab === 0 && (React.createElement(LeadTable, { data: todays_leads === null || todays_leads === void 0 ? void 0 : todays_leads.data, title: "Today's Leads", lead_types: lead_types, agents: agents, states: states, dispositions: dispositions })),
        tab === 1 && (React.createElement(LeadTable, { data: urgent_leads === null || urgent_leads === void 0 ? void 0 : urgent_leads.data, title: "Urgent Leads", lead_types: lead_types, agents: agents, states: states, dispositions: dispositions })),
        tab === 2 && (React.createElement(LeadTable, { data: recent_leads === null || recent_leads === void 0 ? void 0 : recent_leads.data, title: "Recent Leads", lead_types: lead_types, agents: agents, states: states, dispositions: dispositions })),
        tab === 3 && (React.createElement(LeadTable, { data: upcoming_leads === null || upcoming_leads === void 0 ? void 0 : upcoming_leads.data, title: "Upcoming Leads", lead_types: lead_types, agents: agents, states: states, dispositions: dispositions })),
        tab === 4 && (React.createElement(SubordinatesTable, { subordinates: (_c = user === null || user === void 0 ? void 0 : user.subordinates) !== null && _c !== void 0 ? _c : [] }))));
}
