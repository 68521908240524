import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, ButtonGroup } from "@mui/material";
var TaskFilters = function (_a) {
    var setFilter = _a.setFilter, filter = _a.filter, setSort = _a.setSort, sort = _a.sort;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { container: true, xs: 10, justifyContent: "center", alignContent: "center" },
            React.createElement(ButtonGroup, null,
                React.createElement(Button, { onClick: function () { return setFilter("today"); }, style: {
                        backgroundColor: filter === "today" ? "#E21D37" : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                    } }, "Today's"),
                React.createElement(Button, { onClick: function () { return setFilter(""); }, style: {
                        backgroundColor: filter === "" ? "#E21D37" : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                    } }, "All"))),
        React.createElement(Grid, { container: true, xs: 2, justifyContent: "right", alignContent: "center" },
            React.createElement(ButtonGroup, null,
                React.createElement(Button, { onClick: function () {
                        return setSort([
                            "follow_up_date",
                            (function (val) {
                                return val == "asc" ? "desc" : "asc";
                            })(sort[1]),
                        ]);
                    }, style: {
                        backgroundColor: sort[0] === "follow_up_date"
                            ? sort[1] === "desc"
                                ? "#1ecc49"
                                : sort[1] === "asc"
                                    ? "#E21D37"
                                    : "#1E5496"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                    } }, "Follow Up"),
                React.createElement(Button, { onClick: function () {
                        return setSort([
                            "created_at",
                            (function (val) {
                                return val == "asc" ? "desc" : "asc";
                            })(sort[1]),
                        ]);
                    }, style: {
                        backgroundColor: sort[0] === "created_at"
                            ? sort[1] === "desc"
                                ? "#1ecc49"
                                : sort[1] === "asc"
                                    ? "#E21D37"
                                    : "#1E5496"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                    } }, "Created At")))));
};
export default TaskFilters;
