import React, { useState } from "react";
import { router } from "@inertiajs/react";
import { Button, Dialog, DialogActions, DialogTitle, Modal, } from "@mui/material";
import { useForm } from "react-hook-form";
var ModalStatusProspect = function (_a) {
    var member = _a.member, _b = _a.button_color, button_color = _b === void 0 ? "#22D172" : _b, _c = _a.button_font, button_font = _c === void 0 ? "#FFF" : _c, _d = _a.size, size = _d === void 0 ? "medium" : _d, _e = _a.fullWidth, fullWidth = _e === void 0 ? false : _e, _f = _a.variant, variant = _f === void 0 ? "contained" : _f;
    var _g = useState(false), openModal = _g[0], setOpenModal = _g[1];
    var _h = useForm(), control = _h.control, formErrors = _h.formState.errors, handleSubmit = _h.handleSubmit;
    var handleOpenNotification = function () {
        setOpenModal(true);
    };
    var submitModal = function () {
        setOpenModal(false);
        router.post("/member/update_prospect/" + member.id);
    };
    var handleCloseModal = function () {
        setOpenModal(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: handleOpenNotification, variant: variant, fullWidth: fullWidth, size: "small", style: {
                backgroundColor: button_color,
                color: button_font,
            } }, "Change Member to Prospect"),
        React.createElement(Modal, { open: openModal, onClose: handleCloseModal },
            React.createElement(Dialog, { open: openModal, onClose: handleCloseModal },
                React.createElement(DialogTitle, null, "This button will change the member's status to prospect and create a corresponding prospect order for this member."),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleCloseModal, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitModal), color: "primary" }, "Submit"))))));
};
export default ModalStatusProspect;
