import { Box, Grid, Typography } from "@mui/material";
import { helperFunctions } from "../../../Helpers";
import React from "react";
var LeadMemberInfoCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var member = _a.member, lead = _a.lead, states = _a.states;
    var email = (lead === null || lead === void 0 ? void 0 : lead.member_spouse) ? member === null || member === void 0 ? void 0 : member.spouse_email : member === null || member === void 0 ? void 0 : member.email;
    // const phone = lead?.member_spouse ? member?.spouse_phone : member?.phone;
    var dob = (lead === null || lead === void 0 ? void 0 : lead.member_spouse)
        ? member === null || member === void 0 ? void 0 : member.spouse_birth_date
        : member === null || member === void 0 ? void 0 : member.birth_date;
    return (React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
        React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Member Info:"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Email: "))),
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, email !== null && email !== void 0 ? email : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Phone Number:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, lead.member_spouse && member.spouse_phone ? member.spouse_phone : (_b = member.phone) !== null && _b !== void 0 ? _b : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Date of Birth:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, dob !== null && dob !== void 0 ? dob : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Age: "))),
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_c = helperFunctions.getAge(dob)) !== null && _c !== void 0 ? _c : ""))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { mx: 1, mt: 2 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "Residential Address"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_d = member.res_address_1) !== null && _d !== void 0 ? _d : "")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_e = member.res_address_2) !== null && _e !== void 0 ? _e : "")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "".concat((member === null || member === void 0 ? void 0 : member.res_city) ? member.res_city + "," : "", "\n                        ").concat((_g = (_f = states === null || states === void 0 ? void 0 : states.find(function (el) {
                        return el.id == Number(member.res_state_id);
                    })) === null || _f === void 0 ? void 0 : _f.abbreviation) !== null && _g !== void 0 ? _g : "", "\n                        ").concat((_h = member.res_zip) !== null && _h !== void 0 ? _h : ""))))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_j = member.res_country) !== null && _j !== void 0 ? _j : "None")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "County: ", (_k = member.res_county) !== null && _k !== void 0 ? _k : "None"))))));
};
export default LeadMemberInfoCard;
