import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from "@mui/material/";
import YearPicker from "../components/YearPicker";
import { calculate_health_conversion, calculate_rosemark_conversion, } from "../../../utils/utils";
var borders = {
    border: "1px solid #707070",
};
var header = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
};
var headerLeft = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderLeft: "1px solid #707070",
};
var headerRight = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderRight: "1px solid #707070",
};
function ConversionTable(_a) {
    var policies_by_conversion = _a.policies_by_conversion, year = _a.year, setYear = _a.setYear, department = _a.department;
    var columns = department === "Medicare"
        ? [
            { id: "agent", label: "agent" },
            {
                id: "ob_leads_count",
                label: "ob_Leads",
            },
            {
                id: "ob_policies_count",
                label: "ob_Policies",
            },
            {
                id: "ob_conversion_rate",
                label: "ob_Conversion",
            },
            {
                id: "leads_count",
                label: "Leads",
            },
            {
                id: "policies_count",
                label: "Policies",
            },
            {
                id: "conversion_rate",
                label: "Conversion",
            },
        ]
        : department === "Rosemark"
            ? [
                { id: "agent", label: "agent" },
                {
                    id: "leads_count",
                    label: "Leads",
                },
                {
                    id: "apps_count",
                    label: "Apps Submitted",
                },
                {
                    id: "apps_conversion_rate",
                    label: "Leads to App",
                },
                {
                    id: "policies_count",
                    label: "Policies",
                },
                {
                    id: "policies_conversion_rate",
                    label: "App to Policies",
                },
                {
                    id: "conversion_rate",
                    label: "Leads to Policies",
                },
            ]
            : [
                { id: "agent", label: "agent" },
                {
                    id: "leads_count",
                    label: "Leads",
                },
                {
                    id: "policies_count",
                    label: "Policies",
                },
                {
                    id: "conversion_rate",
                    label: "Conversion",
                },
            ];
    var colHeaders = department === "Medicare" ? (React.createElement(TableRow, null,
        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Agents"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "OB Leads"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "OB Policies Written"),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "OB Conversion Rate"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Leads"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Policies Written"),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "Conversion Rate"))) : department === "Rosemark" ? (React.createElement(TableRow, null,
        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Agents"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Leads"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Apps"),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "Lead To App"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Policies"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "App to Policies"),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "Lead to Policies"))) : (React.createElement(TableRow, null,
        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Agents"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Total Leads"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Total Policies Written"),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "Total Conversion Rate")));
    var calculate_conversion = function (row, ob, conversion_type) {
        return department === "Medicare" || department === "IMM"
            ? calculate_health_conversion(row, ob)
            : calculate_rosemark_conversion(row, conversion_type);
    };
    return (React.createElement(Paper, null, policies_by_conversion && (React.createElement(TableContainer, null,
        React.createElement(Table, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }),
                    React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 6 },
                        React.createElement(YearPicker, { year: year, setYear: setYear }))),
                colHeaders),
            React.createElement(TableBody, null, policies_by_conversion &&
                policies_by_conversion.map(function (row) {
                    return (React.createElement(TableRow, { role: "checkbox", tabIndex: -1, key: row.agent + "_row" }, columns.map(function (column) {
                        var value = row[column.id];
                        return (React.createElement(TableCell, { key: row.agent +
                                column.id, style: borders, align: !column.id.includes("agent")
                                ? "center"
                                : "left" }, column.id.includes("conversion")
                            ? calculate_conversion(row, column.id.includes("ob"), column.id.substring(0, column.id.indexOf("conversion")))
                            : value));
                    })));
                })))))));
}
export default ConversionTable;
