import React from "react";
import { Button, Grid } from "@mui/material";
var AppSelection = function (_a) {
    var appType = _a.appType, setAppType = _a.setAppType, appTypes = _a.appTypes, apps = _a.apps, appOptions = _a.appOptions, showDeleted = _a.showDeleted, setShowDeleted = _a.setShowDeleted;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { container: true, item: true, justifyContent: "center", alignItems: "center", spacing: 1 },
            React.createElement(Grid, { item: true },
                React.createElement(Button, { variant: "outlined", style: {
                        backgroundColor: "#392869",
                        color: "#FFF",
                        fontSize: "11px",
                        fontWeight: "400",
                    }, size: "small", onClick: function () { return setShowDeleted(!showDeleted); } }, showDeleted ? "Hide Deleted" : "Show Deleted")),
            appTypes.map(function (el) {
                var _a, _b, _c;
                return (React.createElement(Grid, { item: true, key: el },
                    React.createElement(Button, { variant: "contained", color: appType === el ? "secondary" : "primary", style: {
                            color: "#fff",
                            fontSize: "12px",
                        }, size: "small", onClick: function () { return setAppType(el); } }, (_a = appOptions === null || appOptions === void 0 ? void 0 : appOptions[el]) === null || _a === void 0 ? void 0 :
                        _a.title,
                        React.createElement("span", { style: {
                                marginLeft: "3px",
                                backgroundColor: "#FFF",
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                            } },
                            React.createElement("span", { style: {
                                    color: "#000",
                                } }, (_c = apps[(_b = appOptions === null || appOptions === void 0 ? void 0 : appOptions[el]) === null || _b === void 0 ? void 0 : _b.appType]) === null || _c === void 0 ? void 0 : _c.length)))));
            }))));
};
export default AppSelection;
