import { Box, Grid, Typography } from "@mui/material";
import React from "react";
var LeadUtmCard = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var lead = _a.lead;
    return (React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Referral:"))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_b = lead === null || lead === void 0 ? void 0 : lead.referral_name) !== null && _b !== void 0 ? _b : "")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 }, "UTMs:")),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_c = lead === null || lead === void 0 ? void 0 : lead.utm_source) !== null && _c !== void 0 ? _c : "")),
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_d = lead === null || lead === void 0 ? void 0 : lead.utm_campaign) !== null && _d !== void 0 ? _d : "")),
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_e = lead === null || lead === void 0 ? void 0 : lead.utm_medium) !== null && _e !== void 0 ? _e : "")),
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_f = lead === null || lead === void 0 ? void 0 : lead.utm_term) !== null && _f !== void 0 ? _f : "")),
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_g = lead === null || lead === void 0 ? void 0 : lead.utm_content) !== null && _g !== void 0 ? _g : ""))))));
};
export default LeadUtmCard;
