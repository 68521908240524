var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect } from "react";
import styled from "styled-components";
import SidebarMenu from "../components/SidebarMenu";
import ContactSearch from "../components/ContactSearch";
import { Box, Typography } from "@mui/material";
import Hidden from "@mui/material/Hidden";
import { Link, usePage } from "@inertiajs/react";
import { agentHasRole, hasPermission } from "../utils/utils";
import ModalSearchTokenizedPan from "../components/Modals/ModalSearchTokenizedPan";
import ModalSearchBroker from "../components/Modals/ModalSearchBroker";
var Layout = function (_a) {
    var title = _a.title, menuOpen = _a.menuOpen, toggleMenuOpen = _a.toggleMenuOpen, children = _a.children;
    useEffect(function () {
        document.title = "Dashboard | " + title;
    }, [title]);
    var auth = usePage().props.auth;
    return (React.createElement(React.Fragment, null,
        React.createElement(SidebarMenu, { menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen }),
        React.createElement(Content, { menuOpen: menuOpen },
            React.createElement(Header, { elevation: 0 },
                React.createElement(Box, null,
                    React.createElement(Hidden, { smDown: true },
                        React.createElement(Typography, { variant: "h5" },
                            React.createElement(AmacHeader, null,
                                "Welcome to",
                                " ",
                                React.createElement(AmacDashboardHeader, null, "AMAC Dashboard"),
                                ", ",
                                auth.user.first_name,
                                " ",
                                auth.user.last_name,
                                " ")))),
                hasPermission(auth, "search members") && (React.createElement(Box, { justifyContent: "flex-end", display: "flex", flexDirection: "column" },
                    React.createElement(Box, { justifyContent: "flex-end", display: "flex", mb: 1, sx: { gap: 1 } },
                        agentHasRole(auth, "msr") && (React.createElement(ModalSearchBroker, null)),
                        hasPermission(auth, "access tokenizedPAN") && (React.createElement(ModalSearchTokenizedPan, null))),
                    React.createElement(Box, null,
                        React.createElement(ContactSearch, null)),
                    React.createElement(AdvanceSearch, null,
                        React.createElement(Link, { href: "/member/advance-search", size: 10 }, "Advanced Search"))))),
            React.createElement(PageContent, null, children),
            React.createElement(Footer, null,
                React.createElement("span", null,
                    "\u00A9 ",
                    new Date().getFullYear(),
                    " ",
                    React.createElement("a", { target: "_blank", href: "https://amac.us/" }, "AMAC, Inc."))))));
};
var Content = styled.main(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-left: ", ";\n    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n"], ["\n    margin-left: ", ";\n    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n"])), function (_a) {
    var menuOpen = _a.menuOpen;
    return menuOpen ? "174px" : "150px";
});
var Header = styled.header(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 25px;\n    margin-bottom: 20px;\n    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.1);\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n"], ["\n    padding: 25px;\n    margin-bottom: 20px;\n    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.1);\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n"])));
var AdvanceSearch = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    justify-content: flex-end;\n    align-items: flex-end;\n"], ["\n    display: flex;\n    justify-content: flex-end;\n    align-items: flex-end;\n"])));
var Footer = styled.footer(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 15px;\n"], ["\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 15px;\n"])));
var PageContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    padding: 0 20px;\n    flex-grow: 1;\n"], ["\n    padding: 0 20px;\n    flex-grow: 1;\n"])));
var AmacHeader = styled.strong(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin-left: 0px;\n"], ["\n    margin-left: 0px;\n"])));
var AmacDashboardHeader = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    font-style: italic;\n    color: #e21d37;\n"], ["\n    font-style: italic;\n    color: #e21d37;\n"])));
export default Layout;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
