import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { helperFunctions } from "../../../Helpers";
import { orderBy } from "lodash";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            position: "relative",
            overflow: "auto",
            maxHeight: 300,
            minWidth: 300,
        },
        listSection: {
            backgroundColor: "inherit",
        },
        ul: {
            backgroundColor: "inherit",
            padding: 0,
        },
        round: {
            borderRadius: "3px",
        },
        outline: {
            border: "1px solid #f1f1f1",
            marginBottom: "2px",
        },
    });
});
var DonationNotes = function (_a) {
    var notes = _a.notes;
    var classes = useStyles();
    var sortedMemberNotes = orderBy(notes, ["created_at"], "desc");
    return (React.createElement("div", { className: classes.root }, sortedMemberNotes.map(function (note) {
        var _a;
        return (React.createElement(Grid, { container: true, xs: 12, justifyContent: "flex-end", key: note.id, className: classes.outline, spacing: 0 },
            React.createElement(Grid, { className: classes.round, container: true, xs: 12, justifyContent: "flex-end", alignContent: "flex-end" },
                React.createElement(Box, { style: {
                        backgroundColor: "#F1F1F1",
                        padding: "2px",
                    } },
                    React.createElement(Typography, { variant: "body2", style: { fontSize: "12px" } }, "".concat((_a = note === null || note === void 0 ? void 0 : note.user) === null || _a === void 0 ? void 0 : _a.name, " - ").concat(helperFunctions.getDateTime(note === null || note === void 0 ? void 0 : note.created_at))))),
            React.createElement(Grid, { xs: 12, style: { padding: "2px" } },
                React.createElement(Box, { m: 1, style: { fontSize: "14px" } }, "".concat(note === null || note === void 0 ? void 0 : note.text)))));
    })));
};
export default DonationNotes;
