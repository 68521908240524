import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";
import { Controller } from "react-hook-form";
var GenericRadioInput = function (_a) {
    var name = _a.name, legend = _a.legend, defaultValue = _a.defaultValue, _b = _a.handleChange, handleChange = _b === void 0 ? function () { } : _b, control = _a.control, error = _a.error, _c = _a.yesLabel, yesLabel = _c === void 0 ? "Yes" : _c, _d = _a.yesValue, yesValue = _d === void 0 ? "1" : _d, _e = _a.noLabel, noLabel = _e === void 0 ? "No" : _e, _f = _a.noValue, noValue = _f === void 0 ? "0" : _f, _g = _a.rules, rules = _g === void 0 ? false : _g;
    return (React.createElement(FormControl, { error: error },
        React.createElement(FormLabel, { component: "legend" }, legend),
        React.createElement(Controller, { name: name, control: control, defaultValue: String(defaultValue), rules: rules, render: function (_a) {
                var _b = _a.field, onChange = _b.onChange, name = _b.name, value = _b.value;
                return (React.createElement(RadioGroup, { name: name, value: value, onChange: function (value) {
                        onChange(value);
                        handleChange(value);
                    }, row: true },
                    React.createElement(FormControlLabel, { value: yesValue, control: React.createElement(Radio, { color: "primary" }), label: yesLabel, labelPlacement: "end" }),
                    React.createElement(FormControlLabel, { value: noValue, control: React.createElement(Radio, { color: "primary" }), label: noLabel, labelPlacement: "end" })));
            } }),
        React.createElement(FormHelperText, null, error ? "This field is required." : "")));
};
export default GenericRadioInput;
