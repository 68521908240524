import { Link, usePage } from "@inertiajs/react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { startCase } from "lodash";
import React from "react";
import NumberFormat from "react-number-format";
import route from "ziggy-js";
import { helperFunctions } from "../../../Helpers";
import { hasPermission } from "../../../utils/utils";
export var AgentSearchCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var agent = _a.agent;
    var auth = usePage().props.auth;
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "10px #035399 solid",
            borderRight: "2px #035399 solid",
            borderBottom: "2px #035399 solid",
            borderTop: "2px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { container: true, item: true, xs: 3 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Name:",
                        " "),
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" }, component: "span" }, (_c = (_b = agent === null || agent === void 0 ? void 0 : agent.user) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Agent ID:",
                        " "),
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" }, component: "span" }, (_d = agent === null || agent === void 0 ? void 0 : agent.id) !== null && _d !== void 0 ? _d : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Email:",
                        " "),
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" }, component: "span" }, (_f = (_e = agent === null || agent === void 0 ? void 0 : agent.user) === null || _e === void 0 ? void 0 : _e.email) !== null && _f !== void 0 ? _f : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Phone:",
                        " "),
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" }, component: "span" },
                        React.createElement(NumberFormat, { value: agent === null || agent === void 0 ? void 0 : agent.phone, displayType: "text", format: "(###) ###-####" }),
                        " ", "EXT. ".concat((_g = agent === null || agent === void 0 ? void 0 : agent.extension) !== null && _g !== void 0 ? _g : ""))))),
        React.createElement(Grid, { container: true, item: true, xs: 3 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Status:",
                        " "),
                    React.createElement(Typography, { variant: "body2", component: "span", style: {
                            fontWeight: "bold",
                            color: agent.status === "active"
                                ? "#3EBF3E"
                                : agent.status === "pending"
                                    ? "#1871BF"
                                    : agent.status === "deleted"
                                        ? "#F1221C"
                                        : "#222",
                        } }, agent.status === "deleted"
                        ? "Inactive"
                        : (agent === null || agent === void 0 ? void 0 : agent.status) != undefined
                            ? startCase(agent.status)
                            : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Location:",
                        " "),
                    React.createElement(Typography, { variant: "body2", component: "span", style: { fontWeight: "bold" } }, (_j = (_h = agent === null || agent === void 0 ? void 0 : agent.location) === null || _h === void 0 ? void 0 : _h.name) !== null && _j !== void 0 ? _j : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Show on TV Production Board:",
                        " "),
                    React.createElement(Typography, { variant: "body2", component: "span", style: {
                            fontWeight: "bold",
                            color: agent.show_tv_production_board === 1
                                ? "#3EBF3E"
                                : agent.show_tv_production_board === 0
                                    ? "#F1221C"
                                    : "#222",
                        } }, (agent === null || agent === void 0 ? void 0 : agent.show_tv_production_board) ? "true" : "false")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Create At:",
                        " "),
                    React.createElement(Typography, { variant: "body2", component: "span", style: { fontWeight: "bold" } }, helperFunctions.getDateTime(agent === null || agent === void 0 ? void 0 : agent.created_at))))),
        React.createElement(Grid, { container: true, item: true, xs: 4 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Production Boards Dept.",
                        " "),
                    (agent === null || agent === void 0 ? void 0 : agent.policy_departments.length) > 0 ? (agent === null || agent === void 0 ? void 0 : agent.policy_departments.map(function (dept, index) {
                        var policy_dept = index !=
                            (agent === null || agent === void 0 ? void 0 : agent.policy_departments.length) - 1
                            ? dept.policy_department[0].name +
                                ", "
                            : dept.policy_department[0].name;
                        return (React.createElement(Typography, { variant: "body2", component: "span", style: { fontWeight: "bold" }, key: dept.policy_department[0].name }, policy_dept));
                    })) : (React.createElement(Typography, { variant: "body2", component: "span", style: { fontWeight: "bold" } }, "None"))))),
        React.createElement(Grid, { container: true, item: true, xs: 2, justifyContent: "center", alignContent: "center", spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 }, hasPermission(auth, "edit agent") && (React.createElement(Link, { href: "/user/agent/edit/".concat(agent === null || agent === void 0 ? void 0 : agent.id) },
                React.createElement(Button, { type: "button", color: "secondary", variant: "outlined", size: "small", fullWidth: true }, "Edit")))),
            React.createElement(Grid, { item: true, xs: 12 }, hasPermission(auth, "edit permissions") &&
                agent.status == "active" && (React.createElement(Link, { href: route("agent_permissions", (_k = agent === null || agent === void 0 ? void 0 : agent.user) === null || _k === void 0 ? void 0 : _k.id) },
                React.createElement(Button, { type: "button", variant: "outlined", size: "small", fullWidth: true }, "Permissions")))),
            React.createElement(Grid, { item: true, xs: 12 }, hasPermission(auth, "access activity") && (React.createElement(Link, { href: "/user/agent/activity-log/".concat(agent === null || agent === void 0 ? void 0 : agent.user_id) },
                React.createElement(Button, { type: "button", variant: "outlined", size: "small", fullWidth: true }, "Activity Log")))))));
};
