import React from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import { Box, Typography } from "@mui/material";
import NumberFormat from "react-number-format";
import { helperFunctions } from "../../../Helpers";
export var BrokerContactInfoCard = function (_a) {
    var _b, _c, _d;
    var broker = _a.broker, user = _a.user;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { xs: 12, style: {
                backgroundColor: "#F1F1F1",
                padding: "6px",
                marginBottom: "14px",
            } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "Broker Contact Info"))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Phone: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } },
                    React.createElement(NumberFormat, { value: broker === null || broker === void 0 ? void 0 : broker.phone, displayType: "text", format: "(###) ###-####" }),
                    " "))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Phone Alt: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } },
                    React.createElement(NumberFormat, { value: broker === null || broker === void 0 ? void 0 : broker.phone_alt, displayType: "text", format: "(###) ###-####" }),
                    " "))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Email: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_b = user === null || user === void 0 ? void 0 : user.email) !== null && _b !== void 0 ? _b : ""))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "NPN Number: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_c = broker.npn) !== null && _c !== void 0 ? _c : ""))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Broker Since:",
                        " "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.getDateTime(broker.created_at)))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Domicile State:",
                        " "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_d = broker === null || broker === void 0 ? void 0 : broker.dom_state) === null || _d === void 0 ? void 0 : _d.name)))));
};
