import React, { useState } from "react";
import Layout from "../Layout";
import LeadTable from "../../components/LeadTable";
import HomeMenu from "./HomeMenu";
import PolicyTable from "../../components/PolicyTable";
import { maxBy } from "lodash";
export default function RecentlyTransferred(_a) {
    var response_data = _a.response_data, auth = _a.auth;
    var recently_transfer = response_data.recently_transfer, lead_types = response_data.lead_types, agents = response_data.agents, user = response_data.user, totals = response_data.totals, states = response_data.states, insurance_types = response_data.insurance_types, insurance_carriers = response_data.insurance_carriers, policies = response_data.policies, dispositions = response_data.dispositions;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var dataWithMaxTotal = maxBy([recently_transfer, policies], "total");
    return (React.createElement(Layout, { title: "Home", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(HomeMenu, { auth: auth, user: user, title: "Recently Transferred", data: dataWithMaxTotal, totals: totals }),
        React.createElement(LeadTable, { data: recently_transfer === null || recently_transfer === void 0 ? void 0 : recently_transfer.data, title: "Transfer Leads", lead_types: lead_types, agents: agents, states: states, dispositions: dispositions }),
        React.createElement(PolicyTable, { data: policies.data, title: "Policies In Process", lead_types: lead_types, states: states, insurance_types: insurance_types, insurance_carriers: insurance_carriers })));
}
