import { Box, Paper, TableContainer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { startCase } from "lodash";
import React from "react";
import NumberFormat from "react-number-format";
import PageTitle from "../../../components/PageTitle";
var LeadReportTable = function (_a) {
    var data = _a.data;
    if (data.length < 1) {
        return null;
    }
    var rows = [];
    var count = 1;
    data.map(function (item) {
        var values = {
            id: count,
            name: item.name,
            utm_campaign: item.utm_campaign,
            insurance_product: item.insurance_product,
            total: item.total,
        };
        rows.push(values);
        count += 1;
    });
    var columns = [
        {
            field: "id",
            headerName: "#",
            editable: false,
            flex: 1,
        },
        {
            field: "name",
            headerName: "Name",
            editable: false,
            flex: 2,
            renderCell: function (rowValues) { return (startCase(rowValues.row.name)); }
        },
        {
            field: "utm_campaign",
            headerName: "UTM Campaign",
            editable: false,
            flex: 2,
        },
        {
            field: "insurance_product",
            headerName: "Insurance Product",
            editable: false,
            flex: 2,
        },
        {
            field: "total",
            headerName: "Total",
            headerAlign: "right",
            align: "right",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) { return (React.createElement(NumberFormat, { value: rowValues.row.total, displayType: "text", thousandSeparator: true })); },
        },
    ];
    return (React.createElement(Box, { mt: 2 },
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: "Lead Report Summary" })),
        React.createElement(TableContainer, { component: Paper, elevation: 3 },
            React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: 10, autoHeight: true, rowsPerPageOptions: [10] }))));
};
export default LeadReportTable;
