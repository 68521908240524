import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { router } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import { Container, Button, Typography, Paper, Modal, Dialog, DialogContent, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import Layout from "../Layout";
import GenericControllerTextInput from "../../components/GenericFormFields/TextInput";
import DisplayErrors from "../../components/DisplayErrors";
export var amac_accountIdOptions = {
    id_from: {
        name: "From ID",
        required: true,
        valueAsNumber: true,
        pattern: {
            value: /^(0|[1-9]\d*)(\.\d+)?$/,
        },
    },
    id_to: {
        name: "To ID",
        required: true,
        valueAsNumber: true,
        pattern: {
            value: /^(0|[1-9]\d*)(\.\d+)?$/,
        },
    },
};
var AmacAccountMerge = function (_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useForm(), handleSubmit = _c.handleSubmit, errors = _c.formState.errors, control = _c.control, watch = _c.watch;
    var _d = useState(false), isSubmitting = _d[0], setIsSubmitting = _d[1];
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var handleCloseModal = function () {
        setIsSubmitting(false);
    };
    useEffect(function () {
        if (!isEmpty(serverErrors)) {
            setIsSubmitting(false);
        }
    }, [serverErrors]);
    var onSubmit = function (data) {
        if (!isSubmitting) {
            setIsSubmitting(true);
            var post_data = {
                id_from: data.id_from,
                id_to: data.id_to,
            };
            router.post("/amac_account/merge-process", post_data);
        }
    };
    return (React.createElement(Layout, { title: "Merge AmacAccount", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { container: true, xs: 12, direction: "column", spacing: 8 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h4", component: "h3", align: "center" },
                        "Merge AmacAccount!!!",
                        JSON.stringify(response_data, null, 2))),
                !isEmpty(serverErrors) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
                        React.createElement(Grid, { container: true, component: Paper, spacing: 2, xs: 3 },
                            React.createElement(GenericControllerTextInput, { defaultValue: "", control: control, name: "id_from", label: "From ID", variant: "outlined", error: amac_accountIdOptions.id_from.name in errors })),
                        React.createElement(Grid, { container: true, component: Paper, spacing: 2, xs: 3 },
                            React.createElement(GenericControllerTextInput, { defaultValue: "", control: control, name: "id_to", label: "To ID", variant: "outlined", error: amac_accountIdOptions.id_to.name in errors })),
                        React.createElement(Grid, { container: true, component: Paper, spacing: 2, xs: 3 },
                            React.createElement(Button, { type: "submit", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true }, "Submit"))))),
            React.createElement(Modal, { open: isSubmitting, onClose: handleCloseModal },
                React.createElement(Dialog, { open: isSubmitting, onClose: handleCloseModal },
                    React.createElement(DialogContent, { style: { minWidth: "500px", textAlign: "center" } }, "This form is being submitted"))))));
};
export default AmacAccountMerge;
