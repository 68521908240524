import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import { Typography, Paper, Modal, Dialog, DialogContent } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import DisplayErrors from "../../components/DisplayErrors";
import OrderAmacAccountAddFormFields from "./OrderAmacAccountAddFormFields";
import AmacAccountSubmitButtons from "../AmacAccount/AmacAccountSubmitButtons";
import { constructAmacAccountFormUtms } from "../../utils/utils";
import axios from "axios";
import { router } from "@inertiajs/react";
import route from "ziggy-js";
import ModalFail from "../../components/Modals/ModalFail";
var OrderAmacAccountAddForm = function (_a) {
    var account = _a.account, amac_account = _a.amac_account, contact = _a.contact, products = _a.products, utm_sources = _a.utm_sources, utm_campaigns = _a.utm_campaigns, utm_mediums = _a.utm_mediums, referrals = _a.referrals, _b = _a.onAmacAccountForm, onAmacAccountForm = _b === void 0 ? false : _b, serverErrors = _a.errors;
    var _c = useForm({
        shouldUnregister: true,
    }), handleSubmit = _c.handleSubmit, errors = _c.formState.errors, control = _c.control, watch = _c.watch, getValues = _c.getValues;
    var _d = useState(false), orderSucceeded = _d[0], setOrderSucceeded = _d[1];
    var _e = useState(false), orderFailed = _e[0], setOrderFailed = _e[1];
    var _f = useState(false), isSubmitting = _f[0], setIsSubmitting = _f[1];
    var _g = useState(serverErrors), axiosErrors = _g[0], setAxiosErrors = _g[1];
    var useAccountUTMs = watch("use_account_utms", "");
    useEffect(function () {
        for (var key in axiosErrors) {
            // if form is stuck because of server error, check if the key needs to be bypassed
            // look to old account form to find out how.
            key = key.replace("contact.", "");
            errors[key] = axiosErrors[key];
        }
    }, [axiosErrors]);
    var handleCloseModal = function () {
        setIsSubmitting(false);
    };
    useEffect(function () {
        if (!isEmpty(serverErrors)) {
            setIsSubmitting(false);
        }
    }, [serverErrors]);
    var onSubmit = function (data, e) {
        if (!isSubmitting) {
            setIsSubmitting(true);
            var utmData = constructAmacAccountFormUtms(data, amac_account, useAccountUTMs);
            axios
                .post("/order/store", {
                account_id: account.id,
                product_id: data.product_id,
                payment: data.payment,
                tracking: utmData,
            })
                .then(function (res) {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                setOrderSucceeded(((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.order) === null || _b === void 0 ? void 0 : _b.id) ? true : false);
                setOrderFailed(((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.order) === null || _d === void 0 ? void 0 : _d.id) ? false : true);
                ((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.order) === null || _f === void 0 ? void 0 : _f.id) && !onAmacAccountForm
                    ? router.get(route("show_account_orders", [
                        (_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.order) === null || _h === void 0 ? void 0 : _h.account_id,
                    ]))
                    : setIsSubmitting(false);
            })
                .catch(function (error) {
                setOrderFailed(true);
                setOrderSucceeded(false);
                setAxiosErrors(error.response.data.errors);
                console.error("Error storing order.");
                setIsSubmitting(false);
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { mt: 1, container: true, xs: 12, direction: "row", spacing: 2 },
            React.createElement(Grid, { container: true, xs: 12, component: Paper, direction: "row" },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h4", component: "h3" }, "Add Order")),
                !isEmpty(axiosErrors) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(DisplayErrors, { errors: axiosErrors }))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(ModalFail, { status: orderFailed && !orderSucceeded
                            ? "Fail"
                            : !orderFailed && orderSucceeded
                                ? "Success"
                                : "Pending", objectType: "Payment" })),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                        React.createElement(OrderAmacAccountAddFormFields, { account: account, products: products, utmSources: utm_sources, utmCampaigns: utm_campaigns, utmMediums: utm_mediums, referrals: referrals, getValues: getValues, errors: errors, control: control, watch: watch, onAmacAccountForm: onAmacAccountForm }))),
                React.createElement(AmacAccountSubmitButtons, { onSubmit: onSubmit, handleSubmit: handleSubmit, success: orderSucceeded, amac_account: amac_account, buttonLabel: "Add Order", isSubmitting: isSubmitting }))),
        React.createElement(Modal, { open: isSubmitting, onClose: handleCloseModal },
            React.createElement(Dialog, { open: isSubmitting, onClose: handleCloseModal },
                React.createElement(DialogContent, { style: { minWidth: "500px", textAlign: "center" } }, "This form is being submitted")))));
};
export default OrderAmacAccountAddForm;
