import React from "react";
import { Button } from "@mui/material";
import axios from "axios";
import { format_date } from "../../../utils/dateUtils";
function ExportButton(_a) {
    var formData = _a.formData, csrf = _a.csrf;
    var download = function () {
        axios
            .post("/production-boards/export/download", {
            csrf: csrf,
            board: formData.board,
            department: formData.department,
            formData: JSON.stringify(formData),
        }, { responseType: "blob" })
            .then(function (response) {
            var _a;
            var url = window.URL.createObjectURL(new Blob([response.data]));
            var link = document.createElement("a");
            link.href = url;
            var today = format_date(new Date());
            var fileName = formData.department +
                "_" +
                formData.board +
                "_" +
                today +
                ".csv";
            link.setAttribute("download", fileName);
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            (_a = link === null || link === void 0 ? void 0 : link.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(link);
        })
            .catch(function () {
            console.error("Error exporting.");
        });
    };
    return (React.createElement(React.Fragment, null, formData && (React.createElement(Button, { fullWidth: true, type: "submit", style: { borderRadius: 0 }, variant: "contained", color: "secondary", onClick: function () { return download(); } }, "Export"))));
}
export default ExportButton;
