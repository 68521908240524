import React from "react";
import { Typography } from "@mui/material";
import { Link } from "@inertiajs/react";
import route from "ziggy-js";
var AccountLink = function (_a) {
    var account_id = _a.account_id, account_name = _a.account_name, account_spouse_name = _a.account_spouse_name, typography_variant = _a.typography_variant;
    return (React.createElement(Link, { href: route("show_account", {
            id: account_id,
        }), style: {
            color: "#000",
        } },
        React.createElement(Typography, { variant: typography_variant !== null && typography_variant !== void 0 ? typography_variant : "body2", style: { fontWeight: "bold" } }, account_name
            ? account_name + " (Account)"
            : account_spouse_name
                ? account_spouse_name + " (Spouse)"
                : account_id)));
};
export default AccountLink;
