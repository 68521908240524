import React from "react";
import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
var DonorLead = function (_a) {
    var _b;
    var control = _a.control, errors = _a.errors, lead = _a.lead;
    return (React.createElement(Grid, { container: true, spacing: 4 },
        React.createElement(Grid, { container: true, xs: 12, component: Paper, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Donation Information")),
            React.createElement(Grid, { xs: 12, sm: 6 },
                React.createElement(GenericControllerTextInput, { label: "Projected Donation Amount", rules: { required: false }, name: "extra_data_json[projected_donation_amount]", defaultValue: (_b = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _b === void 0 ? void 0 : _b.projected_donation_amount, type: "number", control: control, error: errors.extra_data_json
                        ? "projected_donation_amount" in
                            errors.extra_data_json
                        : false })))));
};
export default DonorLead;
