import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import NotesForm from "../../../../components/NotesForm";
import ContactNotes from "../Contact/ContactNotes";
var AppNoteCard = function (_a) {
    var data = _a.data, noteType = _a.noteType;
    var _b = useState(false), addNotesFlag = _b[0], setAddNotesFlag = _b[1];
    var handleAddNotesFlag = function () {
        setAddNotesFlag(!addNotesFlag);
    };
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Notes:")),
                    React.createElement(Grid, { item: true, container: true, xs: 6, justifyContent: "flex-end", alignContent: "center" }, !addNotesFlag && (React.createElement(Button, { type: "button", onClick: handleAddNotesFlag, variant: "outlined", color: "primary", size: "small", style: {
                            fontSize: "10px",
                            fontWeight: "bold",
                            backgroundColor: "#1871BF",
                            color: "#FFF",
                        } }, "+ Note")))))),
        addNotesFlag ? (React.createElement(NotesForm, { noteOwner: data, noteType: noteType, handleAddNotesFlag: handleAddNotesFlag })) : (React.createElement(Grid, { item: true, container: true },
            React.createElement(ContactNotes, { notes: data === null || data === void 0 ? void 0 : data.notes })))));
};
export default AppNoteCard;
