import React, { useState } from "react";
import { MenuItem, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerCheckboxInput from "../../../components/GenericControllerCheckboxInput";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
import GenericControllerDropdownInput from "../../../components/GenericControllerDropdownInput";
import GenericNumberInput from "../../../components/GenericNumberInput";
import { snakeCase } from "lodash";
var ImmLead = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    var control = _a.control, errors = _a.errors, lead = _a.lead, medicarePlans = _a.medicarePlans, medicareChangeReasons = _a.medicareChangeReasons, insuranceTypes = _a.insuranceTypes, leadType = _a.leadType;
    var _v = useState((_c = (_b = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _b === void 0 ? void 0 : _b.current_plan) !== null && _c !== void 0 ? _c : ""), currentPlan = _v[0], setCurrentPlan = _v[1];
    var shortTerm = (_e = (_d = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _d === void 0 ? void 0 : _d.short_term_IMM) !== null && _e !== void 0 ? _e : false;
    var _w = useState((_g = (_f = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _f === void 0 ? void 0 : _f.medicare_change_reason) !== null && _g !== void 0 ? _g : ""), medicareChangeReason = _w[0], setMedicareChangeReason = _w[1];
    var _x = useState((_j = (_h = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _h === void 0 ? void 0 : _h.insurance_product) !== null && _j !== void 0 ? _j : ""), insuranceProduct = _x[0], setInsuranceProduct = _x[1];
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
        React.createElement(Grid, { container: true, component: Paper, xs: 12, spacing: 2 },
            Number(leadType) === 10 && (React.createElement(React.Fragment, null,
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h5", component: "h3" }, "IMM Insurance Info")),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: shortTerm, name: "extra_data_json[short_term_IMM]", control: control, label: "Short Term IMM", error: false })),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericControllerDropdownInput, { rules: {
                            required: true,
                        }, control: control, name: "extra_data_json[insurance_product]", id: "product_type_select", label: "Product Type", defaultValue: insuranceProduct, handleChange: function (e) {
                            setInsuranceProduct(e.target.value);
                        }, error: errors.extra_data_json
                            ? "insurance_product" in
                                errors.extra_data_json
                            : false, options: insuranceTypes
                            .sort(function (a, b) {
                            if (a.name < b.name) {
                                return -1;
                            }
                            if (a.name > b.name) {
                                return 1;
                            }
                            return 0;
                        })
                            .map(function (el) {
                            if (![
                                5, 6, 7, 8, 9, 10, 11, 12, 13,
                                16,
                            ].includes(el.id))
                                return null;
                            return (React.createElement(MenuItem, { value: el.name, key: "type".concat(snakeCase(el.name)) }, el.name));
                        }) })))),
            React.createElement(Grid, { xs: 12, sm: 6 },
                React.createElement(GenericControllerTextInput, { label: "Current Insurance Carrier", rules: { required: false }, name: "extra_data_json[current_insurance_carrier]", defaultValue: (_k = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _k === void 0 ? void 0 : _k.current_insurance_carrier, control: control, error: errors.extra_data_json
                        ? "current_insurance_carrier" in
                            errors.extra_data_json
                        : false })),
            React.createElement(Grid, { xs: 12, sm: 6 },
                React.createElement(GenericNumberInput, { label: "Monthly Premium", prefix: "$", thousandSeparator: true, rules: { required: false }, name: "extra_data_json[monthly_premium]", error: false, defaultValue: (_m = (_l = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _l === void 0 ? void 0 : _l.monthly_premium) !== null && _m !== void 0 ? _m : "", control: control })),
            React.createElement(Grid, { xs: 12, sm: 6 },
                React.createElement(GenericControllerDropdownInput, { control: control, name: "extra_data_json[current_plan]", id: "current_plan_select", label: "Current Plan", defaultValue: currentPlan, handleChange: function (e) {
                        return setCurrentPlan(e.target.value);
                    }, error: false, options: medicarePlans.map(function (medicarePlan) {
                        return (React.createElement(MenuItem, { key: medicarePlan.id, value: parseInt(medicarePlan.id) }, medicarePlan.name));
                    }) })),
            parseInt(currentPlan) === 10 && (React.createElement(React.Fragment, null,
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericControllerTextInput, { label: "Other Plan Type", control: control, name: "extra_data_json[other_plan_type]", defaultValue: (_p = (_o = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _o === void 0 ? void 0 : _o.other_plan_type) !== null && _p !== void 0 ? _p : "", error: false })))),
            React.createElement(Grid, { xs: 12, sm: 6 },
                React.createElement(GenericControllerDropdownInput, { control: control, name: "extra_data_json[medicare_change_reason]", id: "medicare_change_reason_select", label: "Change Reason", handleChange: setMedicareChangeReason, defaultValue: medicareChangeReason, error: false, placeHolder: "Select Current Plan", 
                    // shrinkLabel
                    options: medicareChangeReasons.map(function (changeReason) {
                        return (React.createElement(MenuItem, { key: changeReason.id, value: parseInt(changeReason.id) }, changeReason.reason));
                    }) })),
            parseInt(medicareChangeReason) === 12 && (React.createElement(React.Fragment, null,
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericControllerTextInput, { label: "Other Change Reason", control: control, name: "extra_data_json[other_medicare_change_reason]", defaultValue: (_r = (_q = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _q === void 0 ? void 0 : _q.other_medicare_change_reason) !== null && _r !== void 0 ? _r : "", error: false })))),
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3" }, "Effective coverage dates")),
            React.createElement(Grid, { xs: 12, sm: 6 },
                React.createElement(GenericControllerTextInput, { label: "Part A", type: "date", name: "extra_data_json[coverage_part_a]", defaultValue: (_t = (_s = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _s === void 0 ? void 0 : _s.coverage_part_a) !== null && _t !== void 0 ? _t : "", control: control, error: false, shrinkLabel: true })),
            React.createElement(Grid, { xs: 12, sm: 6 },
                React.createElement(GenericControllerTextInput, { label: "Part B", type: "date", name: "extra_data_json[coverage_part_b]", defaultValue: (_u = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _u === void 0 ? void 0 : _u.coverage_part_b, control: control, error: false, shrinkLabel: true })))));
};
export default ImmLead;
