import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Layout from "../Layout";
import DisplayErrors from "../../components/DisplayErrors";
import { usePage } from "@inertiajs/react";
// import AddContactOrder from "./OrderAmacAccountAddForm";
import AddAmacAccountOrder from "../Order/OrderAmacAccountAddForm";
import FormAccountInfo from "../../components/FormAccountInfo";
var OrderAddFormContainer = function (_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var contact = response_data.contact, account = response_data.account, states = response_data.states, products = response_data.products, utm_sources = response_data.utm_sources, utm_campaigns = response_data.utm_campaigns, utm_mediums = response_data.utm_mediums, referrals = response_data.referrals;
    var auth = usePage().props.auth;
    return (React.createElement(Layout, { title: "Add Order", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { container: true, direction: "column", xs: 12, spacing: 2 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h4", component: "h3", align: "center" }, "Add New Order")),
                !isEmpty(serverErrors) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(FormAccountInfo, { account: account, states: states, spouseInfo: false })),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(AddAmacAccountOrder, { errors: serverErrors, contact: contact, account: account, products: products, utm_sources: utm_sources, utm_campaigns: utm_campaigns, utm_mediums: utm_mediums, referrals: referrals }))))));
};
export default OrderAddFormContainer;
