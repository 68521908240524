import React, { useState } from "react";
import { router } from "@inertiajs/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, } from "@mui/material";
import { useForm } from "react-hook-form";
var ModalNotification = function (_a) {
    var notification_args = _a.notification_args, notification_method = _a.notification_method, notification_modal_text = _a.notification_modal_text, notification_button_text = _a.notification_button_text, _b = _a.button_color, button_color = _b === void 0 ? "#22D172" : _b, _c = _a.button_font, button_font = _c === void 0 ? "#FFF" : _c, _d = _a.size, size = _d === void 0 ? "small" : _d, _e = _a.fullWidth, fullWidth = _e === void 0 ? false : _e, _f = _a.variant, variant = _f === void 0 ? "contained" : _f;
    var _g = useState(false), openNotificationModal = _g[0], setOpenNotificationModal = _g[1];
    var _h = useForm(), control = _h.control, formErrors = _h.formState.errors, handleSubmit = _h.handleSubmit;
    var handleOpenNotification = function () {
        setOpenNotificationModal(true);
    };
    var submitNotification = function (formData) {
        setOpenNotificationModal(false);
        router.post("/notification/send", {
            notification_args: notification_args,
            notification_method: notification_method,
        });
    };
    var handleCloseNotification = function () {
        setOpenNotificationModal(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: handleOpenNotification, variant: variant, fullWidth: fullWidth, size: size, style: {
                backgroundColor: button_color,
                color: button_font,
            } }, notification_button_text),
        React.createElement(Modal, { open: openNotificationModal, onClose: handleCloseNotification },
            React.createElement(Dialog, { open: openNotificationModal, onClose: handleCloseNotification },
                React.createElement(DialogTitle, null, notification_modal_text),
                React.createElement(DialogContent, { style: { minWidth: "500px" } }),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleCloseNotification, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitNotification), color: "primary" }, "Send"))))));
};
export default ModalNotification;
