import { Box, Paper, TableContainer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { startCase } from "lodash";
import React from "react";
import NumberFormat from "react-number-format";
import PageTitle from "../../../components/PageTitle";
var OrderReportTable = function (_a) {
    var data = _a.data, groupByName = _a.groupByName, mainGroupByName = _a.mainGroupByName;
    if (data.length < 1) {
        return null;
    }
    var rows = [];
    var count = 1;
    data.map(function (order) {
        var _a;
        var values = (_a = {
                id: count
            },
            _a[mainGroupByName] = order[mainGroupByName],
            _a[groupByName] = order[groupByName],
            _a.total = order.total,
            _a);
        rows.push(values);
        count += 1;
    });
    var columns = [
        {
            field: "id",
            headerName: "#",
            editable: false,
            flex: 1,
        },
        {
            field: mainGroupByName,
            headerName: startCase(mainGroupByName),
            editable: false,
            flex: 2,
        },
        {
            field: groupByName,
            headerName: startCase(groupByName),
            editable: false,
            flex: 2,
            renderCell: function (rowValues) { return startCase(rowValues.row.status); },
        },
        {
            field: "total",
            headerName: "Total",
            headerAlign: "right",
            align: "right",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) { return (React.createElement(NumberFormat, { value: rowValues.row.total, displayType: "text", thousandSeparator: true })); },
        },
    ];
    return (React.createElement(Box, { mt: 2 },
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: "Order Report Summary" })),
        React.createElement(TableContainer, { component: Paper, elevation: 3 },
            React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: 10, autoHeight: true, rowsPerPageOptions: [10] }))));
};
export default OrderReportTable;
