import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Layout from "../Layout";
import { Link } from "@inertiajs/react";
import { Button, TableContainer, Paper } from "@mui/material";
import route from "ziggy-js";
import { helperFunctions } from "../../Helpers";
import { formatMemberStatus } from "../../utils/utils";
var columns = [
    {
        field: "id",
        headerName: "Member ID",
        editable: false,
        flex: 1,
    },
    {
        field: "full_name",
        headerName: "Name",
        editable: false,
        flex: 1.5,
    },
    {
        field: "email",
        headerName: "Email",
        editable: false,
        flex: 1.5,
    },
    {
        field: "spouse_full_name",
        headerName: "Spouse",
        editable: false,
        flex: 1.5,
    },
    {
        field: "abbreviation",
        headerName: "State",
        editable: false,
        flex: 0.7,
        headerAlign: "center",
        align: "center",
        renderCell: function (rowValues) { var _a, _b; return "".concat((_b = (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.abbreviation) !== null && _b !== void 0 ? _b : ""); },
    },
    {
        field: "member_since",
        headerName: "Member Since",
        editable: false,
        flex: 0.5,
        headerAlign: "center",
        align: "center",
        renderCell: function (rowValues) {
            return helperFunctions.formatDate(rowValues.row.member_since);
        },
    },
    {
        field: "member_expiration_date",
        headerName: "Expiration",
        editable: false,
        flex: 0.5,
        renderCell: function (rowValues) {
            return helperFunctions.formatDate(rowValues.row.member_expiration_date);
        },
    },
    {
        field: "status",
        headerName: "Status",
        editable: false,
        flex: 0.5,
        renderCell: function (rowValues) {
            var _a, _b, _c;
            return "".concat(formatMemberStatus((_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.status, (_b = rowValues.row) === null || _b === void 0 ? void 0 : _b.merged_to_id, !!((_c = rowValues.row) === null || _c === void 0 ? void 0 : _c.member_leave_reason_id)));
        },
    },
    {
        field: "view",
        headerName: "View",
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        align: "center",
        headerAlign: "center",
        flex: 1,
        renderCell: function (rowValues) {
            return (React.createElement(Link, { href: route("show_member", { id: rowValues.id }) },
                React.createElement(Button, { variant: "outlined", color: "primary", fullWidth: true }, "View")));
        },
    },
];
var MemberSearchResults = function (_a) {
    var response_data = _a.response_data;
    var max_rows = 1000;
    var members = response_data["members"];
    var num_rows = response_data["members"].length;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var rows = [];
    var _c = React.useState(15), pageSize = _c[0], setPageSize = _c[1];
    members.map(function (member) {
        rows.push(member);
    });
    return (React.createElement(Layout, { title: "Member Search Results", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        response_data.query || response_data.zip ? (React.createElement("h3", null,
            "Search for (Member):",
            " ",
            React.createElement("span", { className: "font-weight-bold" },
                (response_data === null || response_data === void 0 ? void 0 : response_data.query)
                    ? "Query: ".concat(response_data.query)
                    : "",
                (response_data === null || response_data === void 0 ? void 0 : response_data.zip) ? " Zip: ".concat(response_data.zip) : ""))) : (React.createElement("h3", null, "Showing all members")),
        num_rows == max_rows && (React.createElement("div", null,
            React.createElement("em", null,
                "Please note: A maximum of ",
                num_rows,
                " results are shown below, and some results may be missing. Please narrow your search."))),
        React.createElement(TableContainer, { component: Paper, elevation: 3 },
            React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: pageSize, rowsPerPageOptions: [5, 15, 50, 100], onPageSizeChange: function (newPageSize) { return setPageSize(newPageSize); }, autoHeight: true, hideFooter: false, pagination: true, sx: {
                    ".MuiTablePagination-displayedRows": {
                        marginTop: "14px",
                    },
                    ".MuiTablePagination-selectLabel": {
                        marginTop: "14px",
                    },
                } }))));
};
export default MemberSearchResults;
