import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
var years = getYears();
function getYears() {
    var endDate = new Date().getFullYear();
    var years = [];
    for (var i = 2007; i <= endDate; i++) {
        years.push(i);
    }
    return years;
}
var YearPicker = function (_a) {
    var year = _a.year, setYear = _a.setYear;
    return (React.createElement(React.Fragment, null, year && (React.createElement(FormControl, null,
        React.createElement(Select, { size: "small", id: "year-select", value: year, onChange: function (e) { return setYear(e.target.value); } }, years.reverse().map(function (y) {
            return (React.createElement(MenuItem, { key: y, value: y }, y));
        }))))));
};
export default YearPicker;
