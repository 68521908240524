import { Box, Grid, Paper, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import AppCard from "./components/AppCard";
import { Alert } from "@mui/material";
import { BackToMember } from "./components/BackToMember";
var MemberSingleApp = function (_a) {
    var _b, _c;
    var response_data = _a.response_data, serverErrors = _a.errors, flash = _a.flash;
    var member = response_data.member, lead_types = response_data.lead_types, insurance_carriers = response_data.insurance_carriers, agents = response_data.agents, states = response_data.states, app_id = response_data.app_id, appType = response_data.app_type, app_dispositions = response_data.app_dispositions;
    var _d = useState(true), menuOpen = _d[0], toggleMenuOpen = _d[1];
    var _e = useState(false), openMessage = _e[0], setOpenMessage = _e[1];
    var appOptions = {
        life_insurance_applications: {
            title: "Life Insurance",
            appType: "App\\Models\\LifeInsuranceApplication",
            noteType: "life",
            fileType: "life_insurance_applications",
        },
        health_insurance_applications: {
            title: "Health Insurance",
            appType: "App\\Models\\HealthInsuranceApplication",
            noteType: "health",
            fileType: "health_insurance_applications",
        },
        financial_applications: {
            title: "Financial Insurance",
            appType: "App\\Models\\FinancialApplication",
            noteType: "financial",
            fileType: "financial_applications",
        },
    };
    useEffect(function () {
        if (flash.message) {
            setOpenMessage(true);
        }
    }, [flash]);
    return (React.createElement(Layout, { title: "Member Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { my: 2 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))))),
        React.createElement(BackToMember, { member: member }),
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 12 }, openMessage && (React.createElement(Box, { mb: 1 },
                React.createElement(Snackbar, { open: openMessage, anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    }, autoHideDuration: 3000, onClose: function () { return setOpenMessage(false); } },
                    React.createElement(Alert, { onClose: function () { return setOpenMessage(false); }, severity: "success" }, flash === null || flash === void 0 ? void 0 : flash.message))))),
            React.createElement(Grid, { item: true, xs: 12 }, (_c = (_b = member[appType]) === null || _b === void 0 ? void 0 : _b.filter(function (el) { return el.id == app_id; })) === null || _c === void 0 ? void 0 : _c.map(function (app) {
                var _a, _b, _c;
                return (React.createElement(Box, { mb: 1, key: app.id, id: "app_".concat(app.id) },
                    React.createElement(Box, { mb: 2 },
                        React.createElement(Typography, { variant: "subtitle1" },
                            "App# ",
                            app_id),
                        React.createElement(Typography, { variant: "subtitle2" }, "*Disclaimer: In order to promote an app to a policy, the disposition \"Approved\" must be used.")),
                    React.createElement(Paper, null,
                        React.createElement(AppCard, { member: member, app: app, leadTypes: lead_types, agents: agents, states: states, appType: (_a = appOptions === null || appOptions === void 0 ? void 0 : appOptions[appType]) === null || _a === void 0 ? void 0 : _a.appType, noteType: (_b = appOptions === null || appOptions === void 0 ? void 0 : appOptions[appType]) === null || _b === void 0 ? void 0 : _b.noteType, fileType: (_c = appOptions === null || appOptions === void 0 ? void 0 : appOptions[appType]) === null || _c === void 0 ? void 0 : _c.fileType, insuranceCarriers: insurance_carriers, showDefault: true, dispositions: app_dispositions }))));
            })))));
};
export default MemberSingleApp;
