var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
var PageTitle = function (_a) {
    var title = _a.title;
    return (React.createElement(TestTest, null,
        React.createElement(Typography, { variant: "h5", component: "h3" },
            React.createElement(PageTitleStyle, null, title))));
};
var PageTitleStyle = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: #04539a;\n    font-style: italic;\n    font-weight: bold;\n    margin-top: 100px;\n"], ["\n    color: #04539a;\n    font-style: italic;\n    font-weight: bold;\n    margin-top: 100px;\n"])));
var TestTest = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-top: 20px;\n    margin-bottom: -10px;\n"], ["\n    margin-top: 20px;\n    margin-bottom: -10px;\n"])));
export default PageTitle;
var templateObject_1, templateObject_2;
