import { Box, Grid, Typography, Chip } from "@mui/material";
import React from "react";
var AccountAddressCard = function (_a) {
    var address = _a.address, is_residential = _a.is_residential, is_shipping = _a.is_shipping;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", style: { color: '#aaaaaa', fontSize: '9pt' } }, "Address ID:"))),
            React.createElement(Grid, { item: true, xs: 9 },
                React.createElement(Typography, { variant: "body2", style: { color: '#aaaaaa', fontSize: '9pt' } }, address.id))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, address.address_1)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, address.address_2)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        address.city,
                        ", ",
                        address.state,
                        " ",
                        address.zip)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, address.country)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, 'Congressional District: ' + address.congressional_district)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 3 }, is_residential &&
                React.createElement(Box, { mx: 1, mb: 1, sx: { display: 'inline' } },
                    React.createElement(Chip, { label: "Residential", size: "small" }))),
            React.createElement(Grid, { item: true, xs: 3 }, is_shipping &&
                React.createElement(Box, { mx: 1, mb: 1, sx: { display: 'inline' } },
                    React.createElement(Chip, { label: "Shipping", size: "small" }))))));
};
export default AccountAddressCard;
