import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import React from "react";
import GenericAutoComplete from "../../../components/GenericAutoComplete";
import GenericNumberInput from "../../../components/GenericNumberInput";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
import { planTypes } from "../../../constants/Rosemark";
var LifeInsuranceInfo = function (props) {
    var _a;
    var insuranceCarriers = props.insuranceCarriers, appState = props.appState, control = props.control, errors = props.errors, watchInsuranceCarrier = props.watchInsuranceCarrier, watchPlanTypes = props.watchPlanTypes;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Life Insurance Information")),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericAutoComplete, { limitTags: 1, label: "Insurance Carrier", name: "insurance_carrier", control: control, options: insuranceCarriers, rules: {
                            required: false,
                        }, error: "insurance_carrier" in errors, value: watchInsuranceCarrier, multiple: false })),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericControllerTextInput, { label: "Class Rating", control: control, name: "class_rating", defaultValue: appState === null || appState === void 0 ? void 0 : appState.class_rating, error: false })),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericAutoComplete, { limitTags: 1, label: "Type of Plan", name: "plan_type_select", control: control, options: planTypes, rules: {
                            required: false,
                        }, error: "plan_type_select" in errors, value: watchPlanTypes, multiple: false })),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericNumberInput, { label: "Face Amount", rules: { required: false }, name: "insurance_face_amount", defaultValue: (_a = appState === null || appState === void 0 ? void 0 : appState.insurance_face_amount) !== null && _a !== void 0 ? _a : null, error: false, prefix: "$", thousandSeparator: true, control: control }))))));
};
export default LifeInsuranceInfo;
