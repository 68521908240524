var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import NotesForm from "../../../../components/NotesForm";
import Note from "../../../../components/Note";
import orderBy from "lodash/orderBy";
import { helperFunctions } from "../../../../Helpers";
import { Box, Table, TableBody, TableCell, Paper, TableContainer, TableHead, TableRow, Button, } from "@mui/material";
import NumberFormat from "react-number-format";
import { getStateById } from "../../../../utils/utils";
import styled from "styled-components";
import { startCase } from "lodash";
var AccountInfo = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
    var account = _a.account, states = _a.states;
    var spouseAge;
    var _2 = useState(false), addNotesFlag = _2[0], setAddNotesFlag = _2[1];
    var handleAddNotesFlag = function () {
        setAddNotesFlag(!addNotesFlag);
    };
    var formattedResZip;
    if (((_b = account === null || account === void 0 ? void 0 : account.res_zip) === null || _b === void 0 ? void 0 : _b.includes("-")) || ((_c = account === null || account === void 0 ? void 0 : account.res_zip) === null || _c === void 0 ? void 0 : _c.length) === 5) {
        formattedResZip = account === null || account === void 0 ? void 0 : account.res_zip;
    }
    else {
        formattedResZip =
            ((_d = account === null || account === void 0 ? void 0 : account.res_zip) === null || _d === void 0 ? void 0 : _d.slice(0, 5)) + "-" + ((_e = account === null || account === void 0 ? void 0 : account.res_zip) === null || _e === void 0 ? void 0 : _e.slice(5, 9));
    }
    var formattedShipZip;
    if (((_f = account === null || account === void 0 ? void 0 : account.ship_zip) === null || _f === void 0 ? void 0 : _f.includes("-")) || ((_g = account === null || account === void 0 ? void 0 : account.ship_zip) === null || _g === void 0 ? void 0 : _g.length) === 5) {
        formattedShipZip = account === null || account === void 0 ? void 0 : account.ship_zip;
    }
    else {
        formattedShipZip =
            ((_h = account === null || account === void 0 ? void 0 : account.ship_zip) === null || _h === void 0 ? void 0 : _h.slice(0, 5)) + "-" + ((_j = account === null || account === void 0 ? void 0 : account.ship_zip) === null || _j === void 0 ? void 0 : _j.slice(5, 9));
    }
    var sortedAccountNotes = orderBy(account.account_notes, ["created_at"], "desc");
    //Calculate age from date string
    var accountAge = helperFunctions.getAge(account.birth_date);
    if (account.spouse_birth_date) {
        spouseAge = helperFunctions.getAge(account.spouse_birth_date);
    }
    return (React.createElement(AccountPage, null,
        React.createElement("div", null,
            React.createElement("h2", null, "Account Name"),
            React.createElement(TableContainer, { component: Paper, elevation: 3 },
                React.createElement(Table, null,
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Full Name"),
                            React.createElement(TableCell, null, "Email"),
                            React.createElement(TableCell, null, "Phone"),
                            React.createElement(TableCell, null, "Alt Phone 1"),
                            React.createElement(TableCell, null, "Birthdate"),
                            React.createElement(TableCell, null, "Age"))),
                    React.createElement(TableBody, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, account.full_name),
                            React.createElement(TableCell, null,
                                React.createElement("a", { href: "mailto:" + account.email }, account.email)),
                            React.createElement(TableCell, null,
                                React.createElement(NumberFormat, { value: account.phone, displayType: "text", format: "(###) ###-####" })),
                            React.createElement(TableCell, null,
                                React.createElement(NumberFormat, { value: account.phone_alt_1, displayType: "text", format: "(###) ###-####" })),
                            React.createElement(TableCell, null, helperFunctions.formatDate(account.birth_date)),
                            React.createElement(TableCell, null, accountAge)),
                        account.spouse_full_name ? (React.createElement(TableRow, null,
                            React.createElement(TableCell, null, account.spouse_full_name + " (Spouse)"),
                            React.createElement(TableCell, null,
                                React.createElement("a", { href: "mailto:" + account.spouse_email }, account.spouse_email)),
                            React.createElement(TableCell, null,
                                React.createElement(NumberFormat, { value: account.spouse_phone, displayType: "text", format: "(###) ###-####", renderText: function (value) { return React.createElement(React.Fragment, null, value); } })),
                            React.createElement(TableCell, null,
                                React.createElement(NumberFormat, { value: account.spouse_phone_alt, displayType: "text", format: "(###) ###-####", renderText: function (value) { return React.createElement(React.Fragment, null, value); } })),
                            React.createElement(TableCell, null, helperFunctions.formatDate(account.spouse_birth_date)),
                            React.createElement(TableCell, null, spouseAge))) : null)))),
        React.createElement(InfoSection, null,
            React.createElement("h2", null, "Account Info"),
            React.createElement(Paper, { elevation: 3 },
                React.createElement(InfoLayout, null,
                    React.createElement("div", null,
                        React.createElement("h5", null, "Account Id"),
                        React.createElement("p", null, account.id)),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Account Status"),
                        React.createElement("p", null, startCase(account.status))),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Account Since"),
                        React.createElement("p", null, helperFunctions.formatDate(account.account_since))),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Account Expiration"),
                        React.createElement("p", null, helperFunctions.formatDate(account.account_expiration_date))),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Mailing Address"),
                        React.createElement("p", null, (_k = account === null || account === void 0 ? void 0 : account.ship_address_1) !== null && _k !== void 0 ? _k : ""),
                        React.createElement("p", null, (_l = account === null || account === void 0 ? void 0 : account.ship_address_2) !== null && _l !== void 0 ? _l : ""),
                        React.createElement("p", null, "".concat((_m = account.ship_city) !== null && _m !== void 0 ? _m : "", ",\n                                    ").concat((_p = (_o = getStateById(account.ship_state_id, states)) === null || _o === void 0 ? void 0 : _o.name) !== null && _p !== void 0 ? _p : "", "\n                                    ").concat(formattedShipZip !== null && formattedShipZip !== void 0 ? formattedShipZip : "")))),
                React.createElement("hr", null),
                React.createElement(InfoLayout, null,
                    React.createElement("div", null,
                        React.createElement("h5", null, "Referral"),
                        React.createElement("p", null, (_r = (_q = account === null || account === void 0 ? void 0 : account.latest_account_order) === null || _q === void 0 ? void 0 : _q.referral) === null || _r === void 0 ? void 0 : _r.name)),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Recurring Billing"),
                        React.createElement("p", null, account.recurring ? "Yes" : "No")),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Renewal Call"),
                        React.createElement("p", null, account.renewal_call_date === null
                            ? "No"
                            : "Yes on " + (account === null || account === void 0 ? void 0 : account.renewal_call_date))),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Residential Address"),
                        React.createElement("p", null, (_s = account === null || account === void 0 ? void 0 : account.res_address_1) !== null && _s !== void 0 ? _s : ""),
                        React.createElement("p", null, (_t = account === null || account === void 0 ? void 0 : account.res_address_2) !== null && _t !== void 0 ? _t : ""),
                        React.createElement("p", null, "".concat((_u = account.res_city) !== null && _u !== void 0 ? _u : "", ",\n                                    ").concat((_w = (_v = getStateById(account.res_state_id, states)) === null || _v === void 0 ? void 0 : _v.name) !== null && _w !== void 0 ? _w : "", "\n                                    ").concat(formattedResZip !== null && formattedResZip !== void 0 ? formattedResZip : "")),
                        React.createElement("p", null, (_x = account === null || account === void 0 ? void 0 : account.res_county) !== null && _x !== void 0 ? _x : ""))))),
        React.createElement(InfoSection, null,
            React.createElement("h2", null, "Activity"),
            React.createElement(Paper, { elevation: 3 },
                React.createElement(InfoLayout, null,
                    React.createElement("div", null,
                        React.createElement("h5", null, "Created By"),
                        React.createElement("p", null, account.user_created
                            ? startCase(((_y = account.user_created) === null || _y === void 0 ? void 0 : _y.first_name) +
                                " " +
                                ((_z = account.user_created) === null || _z === void 0 ? void 0 : _z.last_name))
                            : "N/A")),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Created At"),
                        React.createElement("p", null, helperFunctions.getDateTime(account.created_at))),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Recent Order Date"),
                        React.createElement("p", null, !isEmpty(account.orders)
                            ? helperFunctions.getDateTime(account.orders[0].created_at)
                            : "No orders")),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Modified By"),
                        React.createElement("p", null, account.user_updated
                            ? ((_0 = account.user_updated) === null || _0 === void 0 ? void 0 : _0.first_name) +
                                " " +
                                ((_1 = account.user_updated) === null || _1 === void 0 ? void 0 : _1.last_name)
                            : "N/A")),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Modified At"),
                        React.createElement("p", null, helperFunctions.getDateTime(account.updated_at)))),
                React.createElement("hr", null),
                React.createElement(InfoLayout, null,
                    React.createElement("div", null,
                        React.createElement("h5", null, "Mailing List"),
                        React.createElement("p", null, (account === null || account === void 0 ? void 0 : account.post_mail) ? "Yes" : "No")),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Magazine"),
                        React.createElement("p", null, (account === null || account === void 0 ? void 0 : account.magazine) ? "Yes" : "No")),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Email List"),
                        React.createElement("p", null, (account === null || account === void 0 ? void 0 : account.emailing_list) ? "Yes" : "No")),
                    React.createElement("div", null,
                        React.createElement("h5", null, "SBS Newsletter"),
                        React.createElement("p", null, (account === null || account === void 0 ? void 0 : account.sbs_newsletter) ? "Yes" : "No")),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Recurring"),
                        React.createElement("p", null, (account === null || account === void 0 ? void 0 : account.recurring) ? "Yes" : "No"))),
                React.createElement("hr", null),
                React.createElement(InfoLayout, null,
                    React.createElement("div", null,
                        React.createElement("h5", null, "Text Notification"),
                        React.createElement("p", null, (account === null || account === void 0 ? void 0 : account.text_notification) ? "Yes" : "No")),
                    React.createElement("div", null,
                        React.createElement("h5", null, "Daily News"),
                        React.createElement("p", null, (account === null || account === void 0 ? void 0 : account.daily_news) ? "Yes" : "No"))))),
        React.createElement(InfoSection, null,
            React.createElement(AccountNotesHeader, null,
                React.createElement("h2", null, "Account Notes")),
            React.createElement(Paper, { elevation: 3 },
                React.createElement(NotesLayout, null,
                    !addNotesFlag && (React.createElement(Button, { type: "button", onClick: handleAddNotesFlag, variant: "outlined", color: "primary" }, "Add Note")),
                    addNotesFlag && (React.createElement(NotesForm, { noteOwner: account, noteType: "account", handleAddNotesFlag: handleAddNotesFlag })),
                    sortedAccountNotes.map(function (note) {
                        return (React.createElement(Box, { mt: 2, key: note.id },
                            React.createElement(Note, { note: note })));
                    }))))));
};
var AccountPage = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    h2 {\n        margin-bottom: 20px;\n    }\n"], ["\n    h2 {\n        margin-bottom: 20px;\n    }\n"])));
var InfoSection = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-top: 30px;\n    margin-bottom: 15px;\n    hr {\n        margin: 0 32px;\n    }\n"], ["\n    margin-top: 30px;\n    margin-bottom: 15px;\n    hr {\n        margin: 0 32px;\n    }\n"])));
var NotesLayout = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background-color: #ffffff;\n    padding: 15px;\n"], ["\n    background-color: #ffffff;\n    padding: 15px;\n"])));
var AccountNotesHeader = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    h2 {\n        margin-bottom: 20px;\n        margin-right: 20px;\n    }\n"], ["\n    display: flex;\n    h2 {\n        margin-bottom: 20px;\n        margin-right: 20px;\n    }\n"])));
var InfoLayout = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: flex;\n    flex-wrap: wrap;\n    padding: 20px;\n    div {\n        height: 140px;\n        width: 296px;\n        padding: 15px;\n        display: flex;\n        flex-direction: column;\n        border-right: 1px solid gainsboro;\n\n        h5 {\n            color: #bdbdbd;\n        }\n        p {\n            color: black;\n            font-size: 0.9rem;\n            margin-bottom: 0;\n        }\n    }\n    div:last-child {\n        border-right: none;\n    }\n    @media (max-width: 1875px) {\n        div {\n            width: 265px;\n        }\n    }\n    @media (max-width: 1720px) {\n        div {\n            width: 250px;\n        }\n    }\n    @media (max-width: 1635px) {\n        div {\n            width: 222px;\n            h5 {\n                font-size: 1rem;\n            }\n            p {\n                font-size: 0.8rem;\n            }\n        }\n    }\n"], ["\n    display: flex;\n    flex-wrap: wrap;\n    padding: 20px;\n    div {\n        height: 140px;\n        width: 296px;\n        padding: 15px;\n        display: flex;\n        flex-direction: column;\n        border-right: 1px solid gainsboro;\n\n        h5 {\n            color: #bdbdbd;\n        }\n        p {\n            color: black;\n            font-size: 0.9rem;\n            margin-bottom: 0;\n        }\n    }\n    div:last-child {\n        border-right: none;\n    }\n    @media (max-width: 1875px) {\n        div {\n            width: 265px;\n        }\n    }\n    @media (max-width: 1720px) {\n        div {\n            width: 250px;\n        }\n    }\n    @media (max-width: 1635px) {\n        div {\n            width: 222px;\n            h5 {\n                font-size: 1rem;\n            }\n            p {\n                font-size: 0.8rem;\n            }\n        }\n    }\n"])));
export default AccountInfo;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
