import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import PageTitle from "../../../components/PageTitle";
import { Box, Button, Paper, TableContainer } from "@mui/material";
import { Link } from "@inertiajs/react";
import route from "ziggy-js";
var columns = [
    {
        field: "name",
        headerName: "Name",
        editable: false,
        flex: 1,
        renderCell: function (rowValues) {
            return "".concat(rowValues.row.first_name, " ").concat(rowValues.row.last_name);
        },
    },
    {
        field: "id",
        headerName: "ID",
        editable: false,
        headerAlign: "center",
        align: "center",
        hideSortIcons: true,
        disableColumnMenu: true,
        flex: 1,
    },
    {
        field: "email",
        headerName: "Email",
        editable: false,
        flex: 2,
    },
    {
        field: "view",
        headerName: "View Agent",
        editable: false,
        flex: 1,
        renderCell: function (rowValues) {
            return (React.createElement(Link, { href: route("subordinates", { id: rowValues.row.id }), as: "span" },
                React.createElement(Button, { variant: "outlined", color: "primary", fullWidth: true }, "View")));
        },
    },
];
export default function SubordinatesTable(props) {
    var subordinates = props.subordinates;
    if (!subordinates.length) {
        return null;
    }
    var rows = [];
    subordinates.map(function (subordinate) {
        rows.push(subordinate);
    });
    return (React.createElement(Box, { mt: 2 },
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: "Subordinates" })),
        React.createElement(TableContainer, { component: Paper, elevation: 3 },
            React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: 10, rowsPerPageOptions: [10], autoHeight: true }))));
}
