import React from "react";
import { Link, usePage } from "@inertiajs/react";
import Grid from "@mui/material/Unstable_Grid2/";
import { Box, Button, Typography } from "@mui/material";
import NumberFormat from "react-number-format";
import { helperFunctions } from "../../../Helpers";
import { hasPermission } from "../../../utils/utils";
export var BrokerSearchCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var user = _a.user;
    var auth = usePage().props.auth;
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "10px #035399 solid",
            borderRight: "2px #035399 solid",
            borderBottom: "2px #035399 solid",
            borderTop: "2px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { container: true, xs: 3 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Name:",
                        " "),
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" }, component: "span" }, (_b = user === null || user === void 0 ? void 0 : user.name) !== null && _b !== void 0 ? _b : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Broker ID:",
                        " "),
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" }, component: "span" }, (_d = (_c = user === null || user === void 0 ? void 0 : user.userable) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Email:",
                        " "),
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" }, component: "span" }, (_e = user === null || user === void 0 ? void 0 : user.email) !== null && _e !== void 0 ? _e : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Phone:",
                        " "),
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" }, component: "span" },
                        React.createElement(NumberFormat, { value: (_f = user === null || user === void 0 ? void 0 : user.userable) === null || _f === void 0 ? void 0 : _f.phone, displayType: "text", format: "(###) ###-####" }),
                        " ")))),
        React.createElement(Grid, { container: true, xs: 3 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Status:",
                        " "),
                    React.createElement(Typography, { variant: "body2", component: "span", style: {
                            fontWeight: "bold",
                            color: (user === null || user === void 0 ? void 0 : user.userable.status) === "active"
                                ? "#3EBF3E"
                                : (user === null || user === void 0 ? void 0 : user.userable.status) === "pending"
                                    ? "#1871BF"
                                    : (user === null || user === void 0 ? void 0 : user.userable.status) === "inactive"
                                        ? "#F1221C"
                                        : "#222",
                        } }, (user === null || user === void 0 ? void 0 : user.userable.status) === "deleted"
                        ? "inactive"
                        : ((_g = user === null || user === void 0 ? void 0 : user.userable) === null || _g === void 0 ? void 0 : _g.status) != undefined
                            ? user === null || user === void 0 ? void 0 : user.userable.status
                            : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Business Name:",
                        " "),
                    React.createElement(Typography, { variant: "body2", component: "span", style: { fontWeight: "bold" } }, (_j = (_h = user === null || user === void 0 ? void 0 : user.userable) === null || _h === void 0 ? void 0 : _h.business_name) !== null && _j !== void 0 ? _j : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "State:",
                        " "),
                    React.createElement(Typography, { component: "span", variant: "body2", style: { fontWeight: "bold" } }, (_m = (_l = (_k = user === null || user === void 0 ? void 0 : user.userable) === null || _k === void 0 ? void 0 : _k.state) === null || _l === void 0 ? void 0 : _l.name) !== null && _m !== void 0 ? _m : "")),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Created At:",
                        " "),
                    React.createElement(Typography, { variant: "body2", component: "span", style: { fontWeight: "bold" } }, helperFunctions.getDateTime(user === null || user === void 0 ? void 0 : user.created_at))))),
        React.createElement(Grid, { container: true, xs: 4 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", component: "span" },
                        "Onboarding Status:",
                        " "),
                    React.createElement(Typography, { variant: "body2", component: "span", style: { fontWeight: "bold" } }, (_p = (_o = user === null || user === void 0 ? void 0 : user.userable) === null || _o === void 0 ? void 0 : _o.onboarding_status) !== null && _p !== void 0 ? _p : "-")))),
        React.createElement(Grid, { container: true, xs: 2, spacing: 1, justifyContent: "center", alignContent: "center" },
            React.createElement(Grid, { xs: 12 }, hasPermission(auth, "view broker") && (React.createElement(Link, { href: "/user/abs/show/".concat(user === null || user === void 0 ? void 0 : user.id) },
                React.createElement(Button, { type: "button", color: "primary", variant: "outlined", size: "small", fullWidth: true }, "View")))),
            React.createElement(Grid, { xs: 12 }, hasPermission(auth, "edit broker") && (React.createElement(Link, { href: "/user/abs/edit/".concat(user === null || user === void 0 ? void 0 : user.id) },
                React.createElement(Button, { type: "button", color: "secondary", variant: "outlined", size: "small", fullWidth: true }, "Edit")))),
            React.createElement(Grid, { xs: 12 }, hasPermission(auth, "edit permissions") && (React.createElement(Link, { href: "/user/abs/permissions/".concat(user === null || user === void 0 ? void 0 : user.id) },
                React.createElement(Button, { type: "button", variant: "outlined", size: "small", fullWidth: true }, "Permissions")))))));
};
