import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Box, Button, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { hasPermission } from "../../utils/utils";
import { Link, usePage } from "@inertiajs/react";
import route from "ziggy-js";
import { BrokerSearchCard } from "./components/BrokerSearchCard";
import { chunk } from "lodash";
import { Pagination } from "@mui/material";
import ModalAbsFilters from "../../components/ModalAbsFilters";
var AllAbsBrokers = function (_a) {
    var _b;
    var response_data = _a.response_data;
    var auth = usePage().props.auth;
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    var _d = useState(response_data.users), users = _d[0], setUsers = _d[1];
    var _e = React.useState(1), page = _e[0], setPage = _e[1];
    var handleChange = function (event, value) {
        setPage(value);
    };
    var _f = useState(""), filter = _f[0], setFilter = _f[1];
    var _g = useState([]), filters = _g[0], setFilters = _g[1];
    useEffect(function () {
        var _a;
        setPage(1);
        setUsers((_a = response_data === null || response_data === void 0 ? void 0 : response_data.users) === null || _a === void 0 ? void 0 : _a.filter(function (broker) {
            var _a, _b, _c, _d, _e, _f;
            return ((_a = broker === null || broker === void 0 ? void 0 : broker.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(filter.toLowerCase())) ||
                ((_c = (_b = broker === null || broker === void 0 ? void 0 : broker.userable) === null || _b === void 0 ? void 0 : _b.phone) === null || _c === void 0 ? void 0 : _c.includes(filter)) ||
                ((_e = (_d = broker === null || broker === void 0 ? void 0 : broker.userable) === null || _d === void 0 ? void 0 : _d.id) === null || _e === void 0 ? void 0 : _e.toString().includes(filter)) ||
                ((_f = broker === null || broker === void 0 ? void 0 : broker.email) === null || _f === void 0 ? void 0 : _f.toLowerCase().includes(filter.toLowerCase()));
        }));
    }, [filter, response_data]);
    var currentPages = chunk(users, 15);
    return (React.createElement(Layout, { title: "All Brokers", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true, spacing: 2, xs: 12, alignContent: "center", justifyContent: "center" },
            React.createElement(Grid, { xs: 3, container: true, alignContent: "center", justifyContent: "flex-start" },
                React.createElement(Typography, { variant: "h5", component: "h3" }, "AMAC Broker Services")),
            React.createElement(Grid, { xs: 6 }),
            React.createElement(Grid, { xs: 3, container: true, alignContent: "center", justifyContent: "flex-end" }, hasPermission(auth, "create broker") && (React.createElement(Link, { href: route("abs_brokers_create") },
                React.createElement(Button, { type: "button", color: "primary", variant: "contained" }, "Create Broker"))))),
        React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
            React.createElement(Grid, { container: true, xs: 12, my: 1, justifyContent: "center", alignContent: "center" },
                React.createElement(Grid, { xs: 3 },
                    React.createElement(ModalAbsFilters, { filters: filters, setFilters: setFilters })),
                React.createElement(Grid, { xs: 6, container: true, justifyContent: "center", alignContent: "center" },
                    React.createElement(Pagination, { count: currentPages.length, page: page, onChange: handleChange })),
                React.createElement(Grid, { xs: 3 },
                    React.createElement(TextField, { variant: "outlined", size: "small", label: "Filter By Name/Email/Phone/Broker ID", type: "text", name: "filterByName", defaultValue: filter, fullWidth: true, onChange: function (e) { return setFilter(e.target.value); } }))),
            React.createElement(Grid, { xs: 12 }, (_b = currentPages[page - 1]) === null || _b === void 0 ? void 0 : _b.map(function (user) { return (React.createElement(Box, { mb: 2, key: user.id },
                React.createElement(BrokerSearchCard, { user: user }))); })))));
};
export default AllAbsBrokers;
