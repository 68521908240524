import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from "@mui/material/";
var borders = {
    border: "1px solid #707070",
};
var header = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
};
var headerLeft = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderLeft: "1px solid #707070",
};
var headerRight = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderRight: "1px solid #707070",
};
var columns = [
    {
        id: "insurance_type",
        label: "Insurance Type",
    },
    {
        id: "written_custom",
        label: "# Written",
    },
    {
        id: "prem_custom",
        label: "Total Prem",
    },
    {
        id: "written_year_to_date",
        label: "# Written",
    },
    {
        id: "prem_year_to_date",
        label: "Total Prem",
    },
];
function PolicyTable(_a) {
    var policies_by_type = _a.policies_by_type;
    return (React.createElement(Paper, null, policies_by_type && (React.createElement(TableContainer, null,
        React.createElement(Table, { size: "small" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }),
                    React.createElement(TableCell, { align: "center", style: header, colSpan: 2 }, "Custom Date Range"),
                    React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 2 }, "Year To Date")),
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Policy Type"),
                    React.createElement(TableCell, { align: "center", style: headerLeft, colSpan: 1 }, "# Written"),
                    React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Total Premium"),
                    React.createElement(TableCell, { align: "center", style: headerLeft, colSpan: 1 }, "# Written"),
                    React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "Total Premium"))),
            React.createElement(TableBody, null, policies_by_type &&
                policies_by_type.map(function (row) {
                    return (React.createElement(TableRow, { role: "checkbox", tabIndex: -1, key: row.insurance_type + "_row" }, columns.map(function (column) {
                        var _a;
                        var value = row[column.id];
                        return (React.createElement(TableCell, { key: row.insurance_type +
                                "_" +
                                column.id, style: borders, align: !column.id.includes("insurance_type")
                                ? "center"
                                : "left" }, column.id.includes("prem")
                            ? "$" +
                                ((_a = parseInt(value).toLocaleString("en-US")) !== null && _a !== void 0 ? _a : 0)
                            : value));
                    })));
                })))))));
}
export default PolicyTable;
