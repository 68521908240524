var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { TextField } from "@mui/material";
var DonationAmountTextInput = function (_a) {
    var name = _a.name, label = _a.label, defaultValue = _a.defaultValue, inputRef = _a.inputRef, _b = _a.error, error = _b === void 0 ? false : _b, options = __rest(_a, ["name", "label", "defaultValue", "inputRef", "error"]);
    return (React.createElement(TextField, __assign({}, options, { variant: "outlined", label: "Donation Amount", type: "text", fullWidth: true })));
};
export default DonationAmountTextInput;
