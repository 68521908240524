import { Box, Grid, Paper, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../../../components/DisplayErrors";
import PolicyCard from "./PolicyCard";
import { Alert } from "@mui/material";
import { BackToContact } from "../BackToContact";
var ContactSinglePolicy = function (_a) {
    var _b;
    var response_data = _a.response_data, serverErrors = _a.errors, flash = _a.flash;
    var _c = useState(true), menuOpen = _c[0], toggleMenuOpen = _c[1];
    var _d = useState(false), openMessage = _d[0], setOpenMessage = _d[1];
    var contact = response_data.contact, lead_types = response_data.lead_types, insurance_carriers = response_data.insurance_carriers, insurance_types = response_data.insurance_types, states = response_data.states, policy_id = response_data.policy_id;
    useEffect(function () {
        if (flash.message) {
            setOpenMessage(true);
        }
    }, [flash]);
    return (React.createElement(Layout, { title: "Contact Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(BackToContact, { contact: contact }),
        !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { my: 2 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))))),
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 12 }, openMessage && (React.createElement(Box, { mb: 1 },
                React.createElement(Snackbar, { open: openMessage, anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    }, autoHideDuration: 3000, onClose: function () { return setOpenMessage(false); } },
                    React.createElement(Alert, { onClose: function () { return setOpenMessage(false); }, severity: "success" }, flash === null || flash === void 0 ? void 0 : flash.message)))))),
        React.createElement(Grid, { item: true, xs: 12 }, (_b = contact === null || contact === void 0 ? void 0 : contact.policies) === null || _b === void 0 ? void 0 : _b.filter(function (policy) { return policy.id == policy_id; }).map(function (policy) { return (React.createElement(Box, { mb: 1, key: policy.id },
            React.createElement(Box, null,
                React.createElement(Typography, { variant: "subtitle1" },
                    "Policy # ",
                    policy_id,
                    " ",
                    "   ")),
            React.createElement(Paper, null,
                React.createElement(PolicyCard, { contact: contact, policy: policy, leadTypes: lead_types, states: states, insuranceTypes: insurance_types, insuranceCarriers: insurance_carriers, showDefault: true })))); }))));
};
export default ContactSinglePolicy;
