import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import LeadMemberInfoCard from "./LeadMemberInfoCard";
import LeadExtraInfo from "./LeadExtraInfo";
import { hasAccessTo, hasPermission } from "../../../utils/utils";
import { router, Link, usePage } from "@inertiajs/react";
import route from "ziggy-js";
import AppInfoCard from "./AppInfoCard";
import AppNoteCard from "./AppNoteCard";
import ManageDocuments from "../../../components/ManageDocuments";
import { useForm } from "react-hook-form";
import GenericDeleteButton from "../../../components/GenericDeleteButton";
import ModalTransferUser from "../../../components/ModalTransferUser";
import AddTaskButton from "../../Task/AddTaskButton";
import SetDisposition from "./SetDisposition";
import ModalFollowUpDate from "../../../components/ModalFollowUpDate";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        appRow: {
            paddingTop: ".75em",
        },
    });
});
var AppDetails = function (_a) {
    var _b, _c, _d, _e;
    var member = _a.member, app = _a.app, agents = _a.agents, states = _a.states, appType = _a.appType, noteType = _a.noteType, fileType = _a.fileType, dispositions = _a.dispositions, insuranceCarriers = _a.insuranceCarriers;
    var auth = usePage().props.auth;
    var classes = useStyles();
    var _f = useForm(), handleSubmit = _f.handleSubmit, formErrors = _f.formState.errors;
    var _g = useState(false), openDelete = _g[0], setOpenDelete = _g[1];
    // delete
    var submitDelete = function () {
        setOpenDelete(false);
        router.post("/app/delete/" + app.id, {
            appable_type: appType,
            appable_id: app.id,
            route: window.location.href,
        });
    };
    var handleCloseDelete = function () {
        setOpenDelete(false);
    };
    var default_lead_type = 15;
    switch (fileType) {
        case "health_insurance_applications":
            default_lead_type = 15; //default quick lead
            break;
        case "financial_applications":
            default_lead_type = 5;
            break;
        case "life_insurance_applications":
            default_lead_type = 11;
            break;
    }
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "1px #035399 solid",
            borderRight: "1px #035399 solid",
            borderBottom: "1px #035399 solid",
            borderTop: "1px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 4, className: classes.appRow },
            React.createElement(LeadMemberInfoCard, { member: member, lead: app, states: states })),
        React.createElement(Grid, { xs: 4, className: classes.appRow },
            React.createElement(AppInfoCard, { data: app, insuranceCarriers: insuranceCarriers })),
        React.createElement(Grid, { xs: 4, className: classes.appRow },
            React.createElement(AppNoteCard, { data: app, noteType: noteType })),
        React.createElement(Grid, { xs: 4, className: classes.appRow },
            React.createElement(LeadExtraInfo, { extraInfo: (_b = app === null || app === void 0 ? void 0 : app.lead) === null || _b === void 0 ? void 0 : _b.extra_data_json, lead: app === null || app === void 0 ? void 0 : app.lead })),
        React.createElement(Grid, { xs: 4, className: classes.appRow },
            React.createElement(ManageDocuments, { resourceType: fileType, resource: app })),
        React.createElement(Grid, { xs: 4, className: classes.appRow },
            React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { mx: 1, mt: 3, mb: 1 },
                        React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Options"))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { mb: 1, ml: 1 },
                        React.createElement(ModalTransferUser, { agents: agents, current_transferred: app.user_transferred, created_by: app.user_created, lead_type_id: (_d = (_c = app === null || app === void 0 ? void 0 : app.lead) === null || _c === void 0 ? void 0 : _c.lead_type_id) !== null && _d !== void 0 ? _d : 0, model: appType.toString().split("\\")[2], model_id: app.id }))),
                appType && (React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { ml: 1, mb: 1 },
                        React.createElement(AddTaskButton, { variant: "outlined", color: "primary", fullWidth: true, size: "small", taskable_id: app.id, taskable_type: appType.substring(11) })))),
                React.createElement(Grid, { xs: 12 },
                    (app === null || app === void 0 ? void 0 : app.disposition_id) === 14 &&
                        hasAccessTo(auth, app) &&
                        hasPermission(auth, "edit policies") && (React.createElement(Box, { mb: 1, ml: 1 },
                        React.createElement(Link, { href: route("policy_form", [
                                default_lead_type,
                                app.id,
                            ]), as: "span" },
                            React.createElement(Button, { variant: "outlined", color: "primary", fullWidth: true, size: "small" }, "Create Policy")))),
                    (app === null || app === void 0 ? void 0 : app.disposition_id) !== 17 &&
                        hasAccessTo(auth, app) &&
                        hasPermission(auth, "edit apps") && (React.createElement(React.Fragment, null,
                        React.createElement(Box, { mb: 1, ml: 1 },
                            React.createElement(Link, { href: route("edit_app_form", [
                                    default_lead_type,
                                    app.id,
                                ]), as: "span" },
                                React.createElement(Button, { variant: "outlined", color: "primary", fullWidth: true, size: "small" }, "Edit App"))),
                        React.createElement(Grid, { xs: 12 },
                            React.createElement(Box, { ml: 1, mb: 1 },
                                React.createElement(SetDisposition, { data: app, options: dispositions, title: "Disposition", buttonName: "Disposition", label: "Dipositions", url: "/app/set-disposition", id: app.id, name: "disposition_id", appType: appType }))),
                        React.createElement(Grid, { xs: 12 },
                            React.createElement(Box, { ml: 1, mb: 1 },
                                React.createElement(ModalFollowUpDate, { current_follow_up_date: (_e = app.follow_up_date) !== null && _e !== void 0 ? _e : undefined, model: appType
                                        .toString()
                                        .split("\\")[2], model_id: app.id }))))),
                    (app === null || app === void 0 ? void 0 : app.disposition_id) !== 17 &&
                        hasPermission(auth, "delete apps") && (React.createElement(Box, { mb: 1, ml: 1 },
                        React.createElement(GenericDeleteButton, { deleteRoute: "/app/delete/" + app.id, label: "app", variant: "outlined", id: app.id, appType: appType }))))))));
};
export default AppDetails;
