import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { DataGrid } from "@mui/x-data-grid";
import { Button, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import PageTitle from "../../components/PageTitle";
import { hasPermission } from "../../utils/utils";
import { Link, usePage } from "@inertiajs/react";
import { helperFunctions } from "../../Helpers";
import { startCase } from "lodash";
var AbsMembers = function (_a) {
    var response_data = _a.response_data;
    var auth = usePage().props.auth;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState(response_data.members), members = _c[0], setMembers = _c[1];
    var rows = [];
    var _d = useState(""), filter = _d[0], setFilter = _d[1];
    useEffect(function () {
        setMembers(response_data.members.filter(function (agent) { var _a; return (_a = agent === null || agent === void 0 ? void 0 : agent.full_name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(filter.toLowerCase()); }));
    }, [filter]);
    members.map(function (member) {
        rows.push(member);
    });
    var columns = [
        {
            field: "full_name",
            headerName: "Name",
            flex: 2,
            editable: false,
            valueGetter: function (rowValues) { var _a; return (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.full_name; },
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            editable: false,
            valueGetter: function (rowValues) { var _a; return startCase((_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.status); },
        },
        {
            field: "email",
            headerName: "Email",
            flex: 2,
            editable: false,
            valueGetter: function (rowValues) { var _a; return (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.email; },
        },
        {
            field: "phone",
            headerName: "Phone",
            flex: 1,
            editable: false,
        },
        {
            field: "res_state_id",
            headerName: "State",
            flex: 1,
            editable: false,
            valueGetter: function (rowValues) { var _a, _b; return (_b = (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.abbreviation; },
        },
        {
            field: "created_at",
            headerName: "Created At",
            flex: 2,
            editable: false,
            valueGetter: function (rowValues) {
                return helperFunctions.getDateTime(rowValues.row.created_at);
            },
        },
        {
            field: "view",
            headerName: "Option",
            flex: 1,
            editable: false,
            renderCell: function (rowValues) {
                return hasPermission(auth, "view members") && (React.createElement(Link, { href: "/member/show/".concat(rowValues.row.id) },
                    React.createElement(Button, { type: "button", color: "primary", size: "small", variant: "contained" }, "View")));
            },
        },
    ];
    return (React.createElement(Layout, { title: "All Members For Broker", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(PageTitle, { title: "All Members For Broker" }),
        React.createElement(Grid, { container: true, spacing: 2, justifyContent: "flex-end" },
            React.createElement(Grid, { xs: 3 },
                React.createElement(TextField, { variant: "outlined", label: "Filter By Name", type: "text", name: "filterByName", defaultValue: filter, fullWidth: true, onChange: function (e) { return setFilter(e.target.value); } })),
            React.createElement(Grid, { xs: 12 },
                React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: 20, rowsPerPageOptions: [20], autoHeight: true })))));
};
export default AbsMembers;
