import React from "react";
import { Chart as ChartJS, Tooltip, Legend, PointElement, LineElement, Title, Filler } from "chart.js";
import { CategoryScale, LinearScale } from 'chart.js';
import { Line } from "react-chartjs-2";
ChartJS.register(Tooltip, Legend, PointElement, LineElement, Title, CategoryScale, LinearScale, Filler);
var AmacLineChartJs = function (_a) {
    var data = _a.data;
    return (React.createElement(Line, { data: data, options: {
            responsive: true,
            plugins: {
                legend: {
                    position: "top",
                },
            },
            scales: {
                x: {
                    stacked: true,
                    title: {
                        display: true,
                        text: "Time",
                    },
                },
                y: {
                    stacked: true,
                    title: {
                        display: true,
                        text: "# Members",
                    },
                },
            },
        } }));
};
export default AmacLineChartJs;
