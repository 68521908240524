import { Box, Grid, Paper, Snackbar, Typography, Link, } from "@mui/material";
import orderBy from "lodash/orderBy";
import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import AmacAccountAction from "./components/AmacAccount/AmacAccountAction";
import AmacAccountInfoCard from "./components/AmacAccount/AmacAccountInfoCard";
import AmacAccountActivityCard from "./components/ActivityLog/AmacAccountActivityCard";
import AmacAccountContactCard from "./components/AmacAccount/AmacAccountContactCard";
// import AmacAccountShipAddressCard from "./components/AmacAccount/AmacAccountShipAddressCard";
// import AmacAccountResAddressCard from "./components/AmacAccount/AmacAccountResAddressCard";
import AmacAccountNoteCard from "./components/AmacAccount/AmacAccountNoteCard";
import { Alert } from "@mui/material";
import { agentHasRole } from "../../utils/utils";
import { usePage } from "@inertiajs/react";
import { ErrorOutline } from "@mui/icons-material";
import { AmacAccountNavWrapper } from "./components/Navigation/AmacAccountNavWrapper";
import AmacAccountHeader from "./components/AmacAccountHeader";
var AmacAccountMain = function (_a) {
    var response_data = _a.response_data, flash = _a.flash, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState(false), openMessage = _c[0], setOpenMessage = _c[1];
    var auth = usePage().props.auth;
    useEffect(function () {
        if (flash.message) {
            setOpenMessage(true);
        }
    }, [flash]);
    var amac_account = response_data.amac_account, amac_account_statuses = response_data.amac_account_statuses, leave_reasons = response_data.leave_reasons, states = response_data.states, all_documents = response_data.all_documents, activity_log = response_data.activity_log;
    amac_account.orders = orderBy(amac_account === null || amac_account === void 0 ? void 0 : amac_account.orders, ["created_at"], "desc");
    return (React.createElement(Layout, { title: "AmacAccount Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(AmacAccountNavWrapper, { amac_account: amac_account, all_documents: all_documents, activity_log: activity_log }),
                openMessage && (React.createElement(Box, { mb: 1 },
                    React.createElement(Snackbar, { open: openMessage, anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        }, autoHideDuration: 3000, onClose: function () { return setOpenMessage(false); } },
                        React.createElement(Alert, { onClose: function () { return setOpenMessage(false); }, severity: "success" }, flash === null || flash === void 0 ? void 0 : flash.message)))))),
        !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { my: 2 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))))),
        React.createElement(Paper, { style: { minHeight: "80vh" } },
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { container: true, item: true, xs: 12 },
                    React.createElement(Grid, { item: true, xs: 12, md: 3 },
                        React.createElement(AmacAccountHeader, { amac_account: amac_account })),
                    !agentHasRole(auth, "broker") && (React.createElement(Grid, { item: true, xs: 12, md: 9 },
                        React.createElement(Box, { mx: 2, my: 2 },
                            React.createElement(AmacAccountAction, { amac_account: amac_account, leaveReasons: leave_reasons, amac_accountStatuses: amac_account_statuses }))))),
                amac_account.merged_to_id && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(Alert, { variant: "filled", severity: "error", icon: React.createElement(ErrorOutline, { fontSize: "large" }) },
                            React.createElement(Typography, { variant: "h6" },
                                "This amac_account was transferred to\u00A0",
                                React.createElement(Link, { href: "/amac_account/show/" +
                                        amac_account.merged_to_id, color: "inherit" },
                                    "#",
                                    amac_account.merged_to_id)))))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(AmacAccountInfoCard, { amac_account: amac_account }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(AmacAccountActivityCard, { amac_account: amac_account }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(AmacAccountNoteCard, { amac_account: amac_account }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(AmacAccountContactCard, { amac_account: amac_account })))))));
};
export default AmacAccountMain;
