import React from "react";
import { filterDeletedAppables, filterDeletedDonations, filterDeletedLeads, filterDeletedPolicies, getShowDeleted, } from "../../../../utils/utils";
import AmacAccountNav from "./AmacAccountNav";
export var AmacAccountNavWrapper = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var amac_account = _a.amac_account, all_documents = _a.all_documents, donation_count = _a.donation_count, activity_log = _a.activity_log, _l = _a.showDeleted, showDeleted = _l === void 0 ? getShowDeleted() : _l;
    return (React.createElement(AmacAccountNav, { amac_account: amac_account, order_count: (_b = amac_account === null || amac_account === void 0 ? void 0 : amac_account.orders) === null || _b === void 0 ? void 0 : _b.length, lead_count: (_d = (_c = amac_account === null || amac_account === void 0 ? void 0 : amac_account.leads) === null || _c === void 0 ? void 0 : _c.filter(function (el) {
            return filterDeletedLeads(el, showDeleted);
        })) === null || _d === void 0 ? void 0 : _d.length, app_count: (_f = (_e = amac_account === null || amac_account === void 0 ? void 0 : amac_account.apps) === null || _e === void 0 ? void 0 : _e.filter(function (el) {
            return filterDeletedAppables(el, showDeleted);
        })) === null || _f === void 0 ? void 0 : _f.length, policy_count: (_h = (_g = amac_account === null || amac_account === void 0 ? void 0 : amac_account.policies) === null || _g === void 0 ? void 0 : _g.filter(function (el) {
            return filterDeletedPolicies(el, showDeleted);
        })) === null || _h === void 0 ? void 0 : _h.length, document_count: all_documents.length, 
        // donation_count={amac_account?.donations?.length}
        donation_count: (_k = (_j = amac_account === null || amac_account === void 0 ? void 0 : amac_account.donations) === null || _j === void 0 ? void 0 : _j.filter(function (el) {
            return filterDeletedDonations(el, showDeleted);
        })) === null || _k === void 0 ? void 0 : _k.length, activity_log_count: activity_log === null || activity_log === void 0 ? void 0 : activity_log.length }));
};
