import React from "react";
import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, Title, BarElement, } from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(Tooltip, Legend, CategoryScale, LinearScale, Title, BarElement);
var AmacBarChartJs = function (_a) {
    var data = _a.data, title = _a.title;
    return (React.createElement(Bar, { data: data, options: {
            plugins: {
                title: {
                    display: false,
                    text: title,
                },
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                },
            },
        } }));
};
export default AmacBarChartJs;
