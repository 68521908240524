import React, { useState } from "react";
import Layout from "../Layout";
import { usePage } from "@inertiajs/react";
import FormContactInfo from "../../components/FormContactInfo";
import { Box } from "@mui/material";
import PageTitle from "../../components/PageTitle";
var IndexContact = function (_a) {
    var data = _a.data;
    var auth = usePage().props.auth;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    return (React.createElement(Layout, { title: "Product Index", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: "Latest Contacts" })),
        data.contacts.map(function (contact) {
            return (React.createElement(Box, { key: contact.id, my: 2 },
                React.createElement(FormContactInfo, { contact: contact, spouseInfo: false, states: data.states, useContactNav: true })));
        })));
};
export default IndexContact;
