var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import Layout from "../Layout";
import { Link, usePage } from "@inertiajs/react";
import route from "ziggy-js";
import styled from "styled-components";
import { EditIcon, PlusIcon } from "../../components/IconLibrary";
import DefaultBtn from "../../components/DefaultBtn";
export default function Links(_a) {
    var response_data = _a.response_data;
    var linkCategories = response_data.link_categories;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var auth = usePage().props.auth;
    return (React.createElement(Layout, { title: "Links", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        auth.admin && (React.createElement(AddCategoryBtn, { href: route("add_category") },
            React.createElement(DefaultBtn, { type: "button", color: "primary", variant: "contained" },
                "Add Category ",
                React.createElement(PlusIcon, { color: "#fff" })))),
        React.createElement("div", null, linkCategories.map(function (linkCategory) {
            if (!isEmpty(linkCategory.links)) {
                return (React.createElement(CategoryWrapper, { key: "linkCategory" + linkCategory.id },
                    React.createElement(CategoryLabel, null,
                        React.createElement("h4", null, linkCategory.name),
                        auth.admin && (React.createElement(Link, { href: route("edit_category_form", linkCategory.id) },
                            React.createElement(DefaultBtn, { type: "button", color: "primary", variant: "contained" },
                                "Edit ",
                                React.createElement(EditIcon, { color: "#fff" }),
                                " ")))),
                    React.createElement(LinksWrapper, null, linkCategory.links.map(function (link) {
                        return (React.createElement("li", { key: "link" + link.id },
                            React.createElement("a", { href: link.url, target: "_blank" }, link.name)));
                    }))));
            }
        }))));
}
var AddCategoryBtn = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-bottom: 20px;\n    display: inline-block;\n\n    svg {\n        height: 16px;\n        display: inline-block;\n        margin: -5px 0 0 5px;\n    }\n"], ["\n    margin-bottom: 20px;\n    display: inline-block;\n\n    svg {\n        height: 16px;\n        display: inline-block;\n        margin: -5px 0 0 5px;\n    }\n"])));
var CategoryWrapper = styled.main(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: block;\n    margin-bottom: 20px;\n\n    h4 {\n        margin-bottom: 0;\n    }\n"], ["\n    display: block;\n    margin-bottom: 20px;\n\n    h4 {\n        margin-bottom: 0;\n    }\n"])));
var CategoryLabel = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: inline-block;\n    width: 250px;\n    vertical-align: top;\n\n    a:hover path {\n        fill: #1d68a7;\n    }\n\n    svg {\n        height: 16px;\n        display: inline-block;\n        margin-left: 5px;\n    }\n"], ["\n    display: inline-block;\n    width: 250px;\n    vertical-align: top;\n\n    a:hover path {\n        fill: #1d68a7;\n    }\n\n    svg {\n        height: 16px;\n        display: inline-block;\n        margin-left: 5px;\n    }\n"])));
var LinksWrapper = styled.ul(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: inline-block;\n    list-style: none;\n    border: solid 1px #707070;\n    max-width: calc(100% - 250px);\n    width: 100%;\n    padding: 10px;\n    margin-top: 27px;\n\n    li {\n        width: 25%;\n        display: inline-block;\n\n        a {\n            color: #1d68a7;\n        }\n    }\n"], ["\n    display: inline-block;\n    list-style: none;\n    border: solid 1px #707070;\n    max-width: calc(100% - 250px);\n    width: 100%;\n    padding: 10px;\n    margin-top: 27px;\n\n    li {\n        width: 25%;\n        display: inline-block;\n\n        a {\n            color: #1d68a7;\n        }\n    }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
