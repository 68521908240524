export function getTasksFilter(filter) {
    var currentDate = formatDate(new Date());
    switch (filter) {
        case "today":
            return function (tasksToFilter) {
                return tasksToFilter.filter(function (tasktoFilter) {
                    var followUp = tasktoFilter.follow_up_date
                        ? new Date(tasktoFilter.follow_up_date).toUTCString()
                        : false;
                    return followUp == currentDate;
                });
            };
        default:
            return function (tasksToFilter) {
                return tasksToFilter;
            };
    }
}
var formatDate = function (date) {
    var day = date.getDate();
    day = day.toString().length == 1 ? "0" + day : day;
    var month = date.getMonth() + 1;
    month = month.toString().length == 1 ? "0" + month : month;
    var year = date.getFullYear();
    return new Date("".concat(year, "-").concat(month, "-").concat(day)).toUTCString();
};
export function getTaskableLink(task) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    if ((task === null || task === void 0 ? void 0 : task.policy) && (task === null || task === void 0 ? void 0 : task.policy.length) > 0) {
        var link = "/member/policy/" +
            ((_a = task === null || task === void 0 ? void 0 : task.policy[0]) === null || _a === void 0 ? void 0 : _a.member_id) +
            "/" +
            task.taskable_id;
        var text = "Policy #".concat(task.taskable_id);
        return { link: link, text: text };
    }
    else if ((task === null || task === void 0 ? void 0 : task.abs_broker) && (task === null || task === void 0 ? void 0 : task.abs_broker.length) > 0) {
        var link = "/user/abs/show/" + ((_b = task === null || task === void 0 ? void 0 : task.abs_broker[0]) === null || _b === void 0 ? void 0 : _b.user_id);
        var text = (_e = ((_c = task === null || task === void 0 ? void 0 : task.abs_broker[0]) === null || _c === void 0 ? void 0 : _c.user.first_name) +
            " " +
            ((_d = task === null || task === void 0 ? void 0 : task.abs_broker[0]) === null || _d === void 0 ? void 0 : _d.user.last_name)) !== null && _e !== void 0 ? _e : "";
        return { link: link, text: text };
    }
    else if ((task === null || task === void 0 ? void 0 : task.donation) && (task === null || task === void 0 ? void 0 : task.donation.length) > 0) {
        var link = "/member/show-donations/" + ((_f = task === null || task === void 0 ? void 0 : task.donation[0]) === null || _f === void 0 ? void 0 : _f.member_id);
        var text = "Donation " + ((_g = task === null || task === void 0 ? void 0 : task.donation[0]) === null || _g === void 0 ? void 0 : _g.id);
        return { link: link, text: text };
    }
    else if ((task === null || task === void 0 ? void 0 : task.life_insurance_application) &&
        (task === null || task === void 0 ? void 0 : task.life_insurance_application.length) > 0) {
        var link = "/member/app/" +
            ((_h = task === null || task === void 0 ? void 0 : task.life_insurance_application[0]) === null || _h === void 0 ? void 0 : _h.member_id) +
            "/" +
            (task === null || task === void 0 ? void 0 : task.taskable_id) +
            "/life_insurance_applications";
        var text = "Life Insurance App: " + ((_j = task === null || task === void 0 ? void 0 : task.life_insurance_application[0]) === null || _j === void 0 ? void 0 : _j.id);
        return { link: link, text: text };
    }
    else if ((task === null || task === void 0 ? void 0 : task.health_insurance_application) &&
        (task === null || task === void 0 ? void 0 : task.health_insurance_application.length) > 0) {
        var link = "/member/app/" +
            ((_k = task === null || task === void 0 ? void 0 : task.health_insurance_application[0]) === null || _k === void 0 ? void 0 : _k.member_id) +
            "/" +
            (task === null || task === void 0 ? void 0 : task.taskable_id) +
            "/health_insurance_applications";
        var text = "Health Insurance App: " +
            ((_l = task === null || task === void 0 ? void 0 : task.health_insurance_application[0]) === null || _l === void 0 ? void 0 : _l.id);
        return { link: link, text: text };
    }
    else if ((task === null || task === void 0 ? void 0 : task.financial_application) &&
        (task === null || task === void 0 ? void 0 : task.financial_application.length) > 0) {
        var link = "/member/app/" +
            ((_m = task === null || task === void 0 ? void 0 : task.financial_application[0]) === null || _m === void 0 ? void 0 : _m.member_id) +
            "/" +
            (task === null || task === void 0 ? void 0 : task.taskable_id) +
            "/financial_applications";
        var text = "Financial App: " + ((_o = task === null || task === void 0 ? void 0 : task.financial_application[0]) === null || _o === void 0 ? void 0 : _o.id);
        return { link: link, text: text };
    }
    else {
        return { link: "", text: "Deleted" };
    }
}
export function getNoteRouteFromModel(model) {
    if (model == "App\\Models\\Policy") {
        return "/policy/note/store/";
    }
    if (model == "App\\Models\\FinancialApplication") {
        return "/app/financial_note/create/";
    }
    if (model == "App\\Models\\HealthInsuranceApplication") {
        return "/app/health_note/create/";
    }
    if (model == "App\\Models\\LifeInsuranceApplication") {
        return "/app/life_note/create/";
    }
    if (model == "App\\Models\\AbsBroker") {
        return "/user/abs/note/create/";
    }
    if (model == "App\\Models\\Donation") {
        return "/donation/note/create/";
    }
}
export function getNoteParentID(task) {
    if (task.taskable_type == "App\\Models\\Donation" && task.donation) {
        return task.donation[0].member_id.toString();
    }
    return task.taskable_id.toString();
}
