var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { TextField, } from "@mui/material";
import { Controller } from "react-hook-form";
var GenericControllerHTMLDropdownInput = function (_a) {
    var name = _a.name, label = _a.label, id = _a.id, defaultValue = _a.defaultValue, handleChange = _a.handleChange, control = _a.control, rules = _a.rules, _b = _a.InputLabelProps, InputLabelProps = _b === void 0 ? {} : _b, _c = _a.variant, variant = _c === void 0 ? "outlined" : _c, _d = _a.error, error = _d === void 0 ? false : _d, _e = _a.SelectProps, SelectProps = _e === void 0 ? {
        native: true,
    } : _e, shrinkLabel = _a.shrinkLabel, options = _a.options, placeHolder = _a.placeHolder, placeHolderValue = _a.placeHolderValue, children = _a.children, native = _a.native, fullWidth = _a.fullWidth, props = __rest(_a, ["name", "label", "id", "defaultValue", "handleChange", "control", "rules", "InputLabelProps", "variant", "error", "SelectProps", "shrinkLabel", "options", "placeHolder", "placeHolderValue", "children", "native", "fullWidth"]);
    return (React.createElement(Controller, { control: control, name: name, defaultValue: defaultValue, rules: rules, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value, error = _a.fieldState.error;
            return (React.createElement(TextField, { id: id, select: true, name: name, label: label, value: value, variant: variant, onChange: handleChange
                    ? function (e) {
                        handleChange(e);
                        onChange(e);
                    }
                    : onChange, SelectProps: SelectProps, error: !!error, InputLabelProps: InputLabelProps, helperText: error && "This field is required.", fullWidth: true },
                React.createElement("option", { value: placeHolderValue !== null && placeHolderValue !== void 0 ? placeHolderValue : "" }, placeHolder !== null && placeHolder !== void 0 ? placeHolder : "None"),
                options));
        } }));
};
export default GenericControllerHTMLDropdownInput;
