import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import { Typography, Paper, Modal, Dialog, DialogContent, Container, Button, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import DisplayErrors from "../../components/DisplayErrors";
import axios from "axios";
import ContactFormFields from "./ContactFormFields";
import Layout from "../Layout";
import route from "ziggy-js";
import { Link, router } from "@inertiajs/react";
var EditContact = function (_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState(serverErrors), axiosErrors = _c[0], setAxiosErrors = _c[1];
    var contact = response_data.contact, states = response_data.states, utm_sources = response_data.utm_sources, utm_campaigns = response_data.utm_campaigns, utm_mediums = response_data.utm_mediums, referrals = response_data.referrals;
    contact.formType = "contact";
    var _d = useForm({
        shouldUnregister: true,
    }), handleSubmit = _d.handleSubmit, errors = _d.formState.errors, control = _d.control, watch = _d.watch, getValues = _d.getValues;
    var watchAddress = watch("ship_address_1", "");
    var watchCity = watch("ship_city", "");
    var _e = useState(false), isSubmitting = _e[0], setIsSubmitting = _e[1];
    useEffect(function () {
        for (var key in axiosErrors) {
            // if form is stuck because of server error, check if the key needs to be bypassed
            // look to old contact form to find out how.
            key = key.replace("contact.", "");
            errors[key] = axiosErrors[key];
        }
    }, [axiosErrors]);
    var handleCloseModal = function () {
        setIsSubmitting(false);
    };
    useEffect(function () {
        if (!isEmpty(serverErrors)) {
            setIsSubmitting(false);
        }
    }, [serverErrors]);
    var onSubmit = function (data, e) {
        if (!isSubmitting) {
            setIsSubmitting(true);
            axios
                .post("/contact/update/" + contact.id, {
                contact: data,
            })
                .then(function (res) {
                var _a;
                ((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.success) == true
                    ? router.get(route("show_contact", [contact === null || contact === void 0 ? void 0 : contact.id]))
                    : setIsSubmitting(false);
            })
                .catch(function (error) {
                setAxiosErrors(error.response.data.errors);
                console.error("Error updating contact.");
                setIsSubmitting(false);
            });
        }
    };
    return (React.createElement(Layout, { title: "Edit Contact", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { container: true, xs: 12, direction: "row", spacing: 2 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h4", component: "h3" }, "Edit Contact")),
                React.createElement(Grid, { container: true, xs: 12, component: Paper, direction: "row" },
                    !isEmpty(axiosErrors) && (React.createElement(Grid, { xs: 12 },
                        React.createElement(DisplayErrors, { errors: axiosErrors }))),
                    React.createElement(ContactFormFields, { contact: contact, states: states, utm_sources: utm_sources, utm_campaigns: utm_campaigns, utm_mediums: utm_mediums, referrals: referrals, errors: errors, control: control, watch: watch, watchAddress: watchAddress, watchCity: watchCity, formType: "edit", produceContacts: undefined }),
                    React.createElement(Grid, { xs: 12, container: true, alignItems: "center", justifyContent: "center", spacing: 2 },
                        React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                            React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: (contact === null || contact === void 0 ? void 0 : contact.id) == null
                                    ? "/"
                                    : route("show_contact", [
                                        contact.id,
                                    ]).toString() }, "Cancel")),
                        React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                            React.createElement(Button, { type: "submit", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true }, "Save"))))),
            React.createElement(Modal, { open: isSubmitting, onClose: handleCloseModal },
                React.createElement(Dialog, { open: isSubmitting, onClose: handleCloseModal },
                    React.createElement(DialogContent, { style: { minWidth: "500px", textAlign: "center" } }, "This form is being submitted"))))));
};
export default EditContact;
