import React from "react";
import { Button, ButtonGroup } from "@mui/material";
import { router } from "@inertiajs/react";
var handleClick = function (e, user, path) {
    e.preventDefault();
    router.post(path, { id: user.id });
};
export default function SubordinateHomeMenu(_a) {
    var user = _a.user;
    return (React.createElement(ButtonGroup, null,
        React.createElement(Button, { onClick: function (e) { return handleClick(e, user, "/home"); } }, "Today's"),
        React.createElement(Button, { onClick: function (e) { return handleClick(e, user, "/urgent"); } }, "Urgent"),
        React.createElement(Button, { onClick: function (e) { return handleClick(e, user, "/upcoming"); } }, "Upcoming"),
        React.createElement(Button, { onClick: function (e) { return handleClick(e, user, "/recent"); } }, "Recent"),
        React.createElement(Button, { onClick: function (e) { return handleClick(e, user, "/recently-transferred"); } }, "Recently Transferred")));
}
