import { Box, Paper, TableContainer, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import PageTitle from "../../../components/PageTitle";
var PolicyReferralsTable = function (_a) {
    var data = _a.data, title = _a.title;
    var rows = [];
    data.map(function (referral, index) {
        var _a, _b, _c;
        var values = {
            id: index,
            source: referral.source,
            medium: referral.medium,
            campaign: referral.campaign,
            content: referral.content,
            policies: (_a = Number(referral.policies_count)) !== null && _a !== void 0 ? _a : 0,
            leads: (_b = Number(referral.leads_count)) !== null && _b !== void 0 ? _b : 0,
            // total: Number(referral.total),
            amount: (_c = Number(referral.amount)) !== null && _c !== void 0 ? _c : 0,
        };
        rows.push(values);
    });
    var columns = [
        {
            field: "source",
            headerName: "Source",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.source },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.source))); },
        },
        {
            field: "campaign",
            headerName: "Campaign",
            editable: false,
            flex: 2,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.campaign },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.campaign))); },
        },
        {
            field: "medium",
            headerName: "Medium",
            editable: false,
            flex: 2,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.medium },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.medium))); },
        },
        {
            field: "content",
            headerName: "Content",
            editable: false,
            flex: 2,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.content },
                React.createElement("span", { className: "table-cell-trucate" }, rowValues.row.content))); },
        },
        {
            field: "policies",
            headerName: "Policies",
            headerAlign: "right",
            align: "right",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.policies },
                React.createElement("span", { className: "table-cell-trucate" }, parseInt(rowValues.row.policies)))); },
        },
        {
            field: "leads",
            headerName: "Leads",
            headerAlign: "right",
            align: "right",
            editable: false,
            flex: 1,
            width: 50,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.leads },
                React.createElement("span", { className: "table-cell-trucate" }, parseInt(rowValues.row.leads).toLocaleString("en-US")))); },
        },
        // {
        //     field: "total",
        //     headerName: "T",
        //     headerAlign: "right",
        //     align: "right",
        //     editable: false,
        //     flex: 1,
        //     renderCell: (rowValues) => (
        //         <Tooltip title={rowValues.row.total}>
        //             <span className="table-cell-trucate">
        //                 {parseInt(rowValues.row.total).toLocaleString("en-US")}
        //             </span>
        //         </Tooltip>
        //     ),
        // },
        {
            field: "amount",
            headerName: "Amount",
            headerAlign: "right",
            align: "right",
            editable: false,
            flex: 1,
            renderCell: function (rowValues) { return (React.createElement(Tooltip, { title: rowValues.row.amount },
                React.createElement("span", { className: "table-cell-trucate" }, "$".concat(parseInt(rowValues.row.amount).toLocaleString("en-US"))))); },
        },
    ];
    return (React.createElement(Box, { mt: 2 },
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: title })),
        React.createElement(TableContainer, { component: Paper, elevation: 3 },
            React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: 100, autoHeight: true, rowsPerPageOptions: [100] }))));
};
export default PolicyReferralsTable;
