import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import NumberFormat from "react-number-format";
import { getStateById } from "../utils/utils";
import { helperFunctions } from "../Helpers";
import ContactLink from "./ContactLink";
import ContactStatusIcon from "./ContactStatusIcon";
import ContactNav from "../Pages/Contact/components/Navigation/ContactNav";
var FormContactInfo = function (_a) {
    var _b, _c;
    var contact = _a.contact, spouseInfo = _a.spouseInfo, _d = _a.useContactNav, useContactNav = _d === void 0 ? false : _d, states = _a.states;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2, component: Paper, sx: {
            borderLeft: "10px #035399 solid",
            borderRight: "2px #035399 solid",
            borderBottom: "2px #035399 solid",
            borderTop: "2px #035399 solid",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 12, md: 0.5, container: true, alignItems: "center", justifyContent: "right" },
            React.createElement(ContactStatusIcon, { status: contact.status })),
        React.createElement(Grid, { container: true, xs: 12, md: 11.5 },
            React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(ContactLink, { contact_id: contact === null || contact === void 0 ? void 0 : contact.id, contact_name: spouseInfo ? undefined : contact.full_name, contact_spouse_name: spouseInfo ? contact.spouse_full_name : undefined, typography_variant: "h6" }),
                React.createElement(Typography, { variant: "body2" }, "# ".concat(contact.id)),
                React.createElement(Typography, { variant: "body2" },
                    "DOB:",
                    " ",
                    React.createElement("strong", null, spouseInfo
                        ? helperFunctions.formatDate(contact === null || contact === void 0 ? void 0 : contact.spouse_birth_date)
                        : helperFunctions.formatDate(contact === null || contact === void 0 ? void 0 : contact.birth_date))),
                React.createElement(Typography, { variant: "body2" },
                    "Age:",
                    " ",
                    React.createElement("strong", null, spouseInfo
                        ? helperFunctions.getAge(contact === null || contact === void 0 ? void 0 : contact.spouse_birth_date)
                        : helperFunctions.getAge(contact === null || contact === void 0 ? void 0 : contact.birth_date))),
                React.createElement(Typography, { variant: "body2" },
                    "Email:",
                    " ",
                    React.createElement("strong", null, spouseInfo ? contact.spouse_email : contact.email)),
                React.createElement(Typography, { variant: "body2" },
                    "Phone:",
                    " ",
                    React.createElement(NumberFormat, { value: spouseInfo ? contact.spouse_phone : contact.phone, displayType: "text", format: "(###) ###-####", renderText: function (value) { return React.createElement("strong", null, value); } }))),
            React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(Box, { mb: 2 },
                    React.createElement(Typography, { variant: "h6" }, "Mailing Address")),
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("span", null, contact.ship_address_1),
                    React.createElement("br", null),
                    React.createElement("span", null, contact.ship_address_2 ? contact.ship_address_2 : ""),
                    contact.ship_address_2 ? React.createElement("br", null) : "",
                    React.createElement("span", null,
                        (contact === null || contact === void 0 ? void 0 : contact.ship_city) ? contact.res_city + "," : "",
                        React.createElement("strong", null, " ".concat((_b = getStateById(contact.ship_state_id, states)) === null || _b === void 0 ? void 0 : _b.name, " ")),
                        contact.ship_zip))),
            React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(Box, { mb: 2 },
                    React.createElement(Typography, { variant: "h6" }, "Residential Address")),
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("span", null, contact.res_address_1),
                    React.createElement("br", null),
                    React.createElement("span", null, contact.res_address_2 ? contact.res_address_2 : ""),
                    contact.res_address_2 ? React.createElement("br", null) : "",
                    React.createElement("span", null,
                        (contact === null || contact === void 0 ? void 0 : contact.res_city) ? contact.res_city + "," : "",
                        React.createElement("strong", null, " ".concat((_c = getStateById(contact.res_state_id, states)) === null || _c === void 0 ? void 0 : _c.name, " ")),
                        contact.res_zip)))),
        useContactNav && (React.createElement(ContactNav, { contact: contact, order_count: contact === null || contact === void 0 ? void 0 : contact.orders_count, lead_count: contact === null || contact === void 0 ? void 0 : contact.leads_count, app_count: contact === null || contact === void 0 ? void 0 : contact.apps_count, policy_count: contact === null || contact === void 0 ? void 0 : contact.policies_count, document_count: contact === null || contact === void 0 ? void 0 : contact.documents_count, donation_count: contact === null || contact === void 0 ? void 0 : contact.donations_count, activity_log_count: contact === null || contact === void 0 ? void 0 : contact.activities_count }))));
};
export default FormContactInfo;
