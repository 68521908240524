import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { helperFunctions } from "../../../Helpers";
import NumberFormat from "react-number-format";
export default function AmbassadorRow(_a) {
    var ambassador = _a.ambassador;
    var member = ambassador.member, id = ambassador.id, created_at = ambassador.created_at;
    var first_name = member.first_name, last_name = member.last_name, email = member.email, phone = member.phone;
    return (React.createElement(TableRow, { classes: {} },
        React.createElement(TableCell, null,
            first_name,
            " ",
            last_name),
        React.createElement(TableCell, null, id),
        React.createElement(TableCell, null, email),
        React.createElement(TableCell, null,
            React.createElement(NumberFormat, { value: phone, format: "(###) ###-####" })),
        React.createElement(TableCell, null, "last login"),
        React.createElement(TableCell, null, helperFunctions.getDateTime(created_at))));
}
