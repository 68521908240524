import { Link, usePage } from "@inertiajs/react";
import { Button, Grid } from "@mui/material";
import React from "react";
import { agentHasRole, hasPermission, } from "../../../../utils/utils";
var AccountNav = function (_a) {
    var account = _a.account, 
    // lead_count,
    // app_count,
    // document_count,
    // policy_count,
    order_count = _a.order_count, 
    // donation_count,
    activity_log_count = _a.activity_log_count;
    var url = usePage().url;
    var auth = usePage().props.auth;
    return (React.createElement(Grid, { container: true, spacing: 1, justifyContent: "center", alignContent: "center" },
        React.createElement(Grid, { item: true },
            React.createElement(Link, { href: "/account/show/".concat(account.id), style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { variant: "contained", style: {
                        backgroundColor: url.includes("/account/show/")
                            ? "#E21D37"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                        borderRadius: "0 0px 3px 3px",
                        top: "-5px",
                    } }, "Account"))),
        !agentHasRole(auth, "broker") && (React.createElement(Grid, { item: true },
            React.createElement(Link, { href: "/account/orders/".concat(account.id), style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { type: "button", style: {
                        backgroundColor: url.includes("/account/orders/")
                            ? "#E21D37"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                        borderRadius: "0 0px 3px 3px",
                        top: "-5px",
                    }, variant: "contained" },
                    "Orders",
                    React.createElement("span", { style: {
                            marginLeft: "3px",
                            backgroundColor: "#FFF",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                        } },
                        React.createElement("span", { style: {
                                color: "#000",
                            } }, order_count)))))),
        !agentHasRole(auth, "broker") && (React.createElement(Grid, { item: true },
            React.createElement(Link, { href: "/account/documents/".concat(account.id), style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { type: "button", style: {
                        backgroundColor: url.includes("/account/documents/")
                            ? "#E21D37"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                        borderRadius: "0 0px 3px 3px",
                        top: "-5px",
                    }, variant: "contained" },
                    "Documents",
                    React.createElement("span", { style: {
                            marginLeft: "3px",
                            backgroundColor: "#FFF",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                        } },
                        React.createElement("span", { style: {
                                color: "#000",
                            } }, 0)))))),
        !agentHasRole(auth, "broker") && (React.createElement(Grid, { item: true },
            React.createElement(Link, { href: "/account/recordings/".concat(account.id), style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { type: "button", style: {
                        backgroundColor: url.includes("/account/recordings/")
                            ? "#E21D37"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                        borderRadius: "0 0px 3px 3px",
                        top: "-5px",
                    }, variant: "contained" },
                    "Recordings",
                    React.createElement("span", { style: {
                            marginLeft: "3px",
                            backgroundColor: "#FFF",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                        } },
                        React.createElement("span", { style: {
                                color: "#000",
                            } }, 0)))))),
        hasPermission(auth, "access activity") && (React.createElement(Grid, { item: true },
            React.createElement(Link, { href: "/account/show-activity-log/".concat(account.id), style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { type: "button", style: {
                        backgroundColor: url.includes("/account/show-activity/")
                            ? "#E21D37"
                            : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                        borderRadius: "0 0px 3px 3px",
                        top: "-5px",
                    }, variant: "contained" },
                    "Activity",
                    React.createElement("span", { style: {
                            marginLeft: "3px",
                            backgroundColor: "#FFF",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                        } },
                        React.createElement("span", { style: {
                                color: "#000",
                            } }, activity_log_count))))))));
};
export default AccountNav;
