import { Box, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import Layout from "../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import MemberOrder from "./components/MemberOrder";
import { MemberNavWrapper } from "./components/MemberNavWrapper";
import MemberHeader from "./components/MemberHeader";
var MemberOrders = function (_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var member = response_data.member, all_documents = response_data.all_documents;
    return (React.createElement(Layout, { title: "Member Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(MemberNavWrapper, { member: member, all_documents: all_documents }))),
        !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { my: 2 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))))),
        React.createElement(Paper, { style: { minHeight: "80vh" } },
            React.createElement(Grid, { container: true },
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement(MemberHeader, { member: member })),
                React.createElement(Grid, { item: true, xs: 9 }),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { m: 2 },
                        React.createElement(MemberOrder, { member: member })))))));
};
export default MemberOrders;
