var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createInertiaApp } from "@inertiajs/react";
import { createRoot } from "react-dom/client";
import { ThemeProvider, createTheme } from "@mui/material/styles";
var theme = createTheme({
    palette: {
        primary: {
            main: "#1E5496",
        },
        secondary: {
            main: "#E3596A",
        },
        // mode: "dark",
    },
    components: {
        // todo: remove once the issue is addressed: https://github.com/mui/material-ui/issues/31185
        MuiDialogContent: {
            styleOverrides: { root: { paddingTop: "8px !important" } },
        },
    },
});
createInertiaApp({
    id: "app",
    resolve: function (name) { return require("./Pages/".concat(name)); },
    setup: function (_a) {
        var el = _a.el, App = _a.App, props = _a.props;
        createRoot(el).render(React.createElement(ThemeProvider, { theme: theme },
            React.createElement(App, __assign({}, props))));
    },
});
