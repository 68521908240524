import React, { useState } from "react";
import Layout from "../Layout";
import LeadTable from "../../components/LeadTable";
import AppTable from "../../components/AppTable";
import HomeMenu from "./HomeMenu";
import { maxBy } from "lodash";
export default function Upcoming(_a) {
    var response_data = _a.response_data, auth = _a.auth;
    var upcoming_health_apps = response_data.upcoming_health_apps, upcoming_financial_apps = response_data.upcoming_financial_apps, upcoming_life_apps = response_data.upcoming_life_apps, upcoming_leads = response_data.upcoming_leads, lead_types = response_data.lead_types, insurance_carriers = response_data.insurance_carriers, agents = response_data.agents, user = response_data.user, states = response_data.states, dispositions = response_data.dispositions, app_dispositions = response_data.app_dispositions, totals = response_data.totals;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var dataWithMaxTotal = maxBy([
        upcoming_financial_apps,
        upcoming_leads,
        upcoming_health_apps,
        upcoming_life_apps,
    ], "total");
    return (React.createElement(Layout, { title: "Home", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(HomeMenu, { auth: auth, user: user, title: "Upcoming", data: dataWithMaxTotal, totals: totals }),
        React.createElement(LeadTable, { data: upcoming_leads.data, title: "Upcoming Leads", lead_types: lead_types, agents: agents, states: states, dispositions: dispositions }),
        upcoming_health_apps.data.length > 0 && (React.createElement(AppTable, { noteType: "health", title: "Upcoming Health Apps", data: upcoming_health_apps.data, leadTypes: lead_types, insuranceCarriers: insurance_carriers, numberOfTableColumns: 7, fileUploadDirectory: "health_insurance_applications", agents: agents, states: states, appType: "App\\Models\\HealthInsuranceApplication", dispositions: app_dispositions })),
        upcoming_financial_apps.data.length > 0 && (React.createElement(AppTable, { noteType: "financial", title: "Upcoming Financial Apps", data: upcoming_financial_apps.data, leadTypes: lead_types, insuranceCarriers: insurance_carriers, numberOfTableColumns: 7, fileUploadDirectory: "financial_applications", agents: agents, states: states, appType: "App\\Models\\FinancialApplication", dispositions: app_dispositions })),
        upcoming_life_apps.data.length > 0 && (React.createElement(AppTable, { noteType: "life", title: "Upcoming Life Apps", data: upcoming_life_apps.data, leadTypes: lead_types, insuranceCarriers: insurance_carriers, numberOfTableColumns: 7, fileUploadDirectory: "life_insurance_applications", agents: agents, states: states, appType: "App\\Models\\LifeInsuranceApplication", dispositions: app_dispositions }))));
}
