import React from "react";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import ContactProfileLink from "./ContactProfileLink";
var ContactSubmitButtons = function (_a) {
    var onSubmit = _a.onSubmit, contact = _a.contact, success = _a.success, handleSubmit = _a.handleSubmit, buttonLabel = _a.buttonLabel, isSubmitting = _a.isSubmitting;
    return (React.createElement(Grid, { container: true, alignItems: "center", justifyContent: "center", spacing: 2, xs: 12 }, success ? (React.createElement(ContactProfileLink, { contact: contact })) : (React.createElement(Grid, null,
        React.createElement(Button, { type: "submit", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true, disabled: isSubmitting }, buttonLabel)))));
};
export default ContactSubmitButtons;
