import React, { useState } from "react";
import Layout from "../Layout";
import AmbassadorRow from "./components/AmbassadorRow";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
export default function AllAmbassador(_a) {
    var response_data = _a.response_data;
    var ambassadors = response_data.ambassadors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    return (React.createElement(Layout, { title: "All Ambassador", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement("h3", null, "All Ambassador"),
        React.createElement(TableContainer, { component: Paper },
            React.createElement(Table, { classes: {} },
                React.createElement(TableHead, { classes: {} },
                    React.createElement(TableRow, { classes: {} },
                        React.createElement(TableCell, null, "Name"),
                        React.createElement(TableCell, null, "Ambassador ID"),
                        React.createElement(TableCell, null, "Email"),
                        React.createElement(TableCell, null, "Phone"),
                        React.createElement(TableCell, null, "Last Login"),
                        React.createElement(TableCell, null, "Created At"))),
                React.createElement(TableBody, { classes: {} }, ambassadors.map(function (ambassador) {
                    return (React.createElement(AmbassadorRow, { key: ambassador.id, ambassador: ambassador }));
                }))))));
}
