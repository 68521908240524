import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { helperFunctions } from "../../../../Helpers";
import React from "react";
var AmacAccountActivityCard = function (_a) {
    var _b, _c, _d, _e;
    var amac_account = _a.amac_account;
    var verified = (amac_account === null || amac_account === void 0 ? void 0 : amac_account.email_verified_at) ? true : false;
    return (React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
        React.createElement(Grid, { xs: 12, style: { backgroundColor: "#F1F1F1", marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "Activity"))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Created By: "))),
            React.createElement(Grid, { xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_c = (_b = amac_account === null || amac_account === void 0 ? void 0 : amac_account.user_created) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : ""))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Date Created:"))),
            React.createElement(Grid, { xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.getDateTime(amac_account === null || amac_account === void 0 ? void 0 : amac_account.created_at)))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Modified By: "))),
            React.createElement(Grid, { xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_e = (_d = amac_account === null || amac_account === void 0 ? void 0 : amac_account.user_updated) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : ""))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Last Modified:",
                        " "))),
            React.createElement(Grid, { xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.getDateTime(amac_account === null || amac_account === void 0 ? void 0 : amac_account.updated_at)))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Mailing List:",
                        " "))),
            React.createElement(Grid, { xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (amac_account === null || amac_account === void 0 ? void 0 : amac_account.post_mail) ? "YES" : "NO"))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Magazine: "))),
            React.createElement(Grid, { xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (amac_account === null || amac_account === void 0 ? void 0 : amac_account.magazine) ? "YES" : "NO"))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Email List:"))),
            React.createElement(Grid, { xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (amac_account === null || amac_account === void 0 ? void 0 : amac_account.emailing_list) ? "YES" : "NO"))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "SBS Newsletter:"))),
            React.createElement(Grid, { xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (amac_account === null || amac_account === void 0 ? void 0 : amac_account.sbs_newsletter) ? "YES" : "NO"))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Email Verified Date:"))),
            React.createElement(Grid, { xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (amac_account === null || amac_account === void 0 ? void 0 : amac_account.email_verified_at)
                    ? helperFunctions.getDateTime(amac_account === null || amac_account === void 0 ? void 0 : amac_account.email_verified_at)
                    : "Not Verified"))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 4 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Email Verification Sent:"))),
            React.createElement(Grid, { xs: 8 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (amac_account === null || amac_account === void 0 ? void 0 : amac_account.email_verification_sent)
                    ? helperFunctions.getDateTime(amac_account === null || amac_account === void 0 ? void 0 : amac_account.email_verification_sent)
                    : "Never sent")))));
};
export default AmacAccountActivityCard;
