import React, { useEffect, useState } from "react";
import OfficeLocations from "../components/OfficeLocations";
import { router } from "@inertiajs/react";
import DatePicker from "../components/DatePicker";
import ProductionBoardsTable from "./ProductionBoardsTable";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import ExportButton from "../components/ExportButton";
var ProductionBoards = function (_a) {
    var formData = _a.formData, department = _a.department, location = _a.location, start_date = _a.start_date, end_date = _a.end_date, policies_by_agent = _a.policies_by_agent, use_inactive = _a.use_inactive, csrf = _a.csrf;
    var today = new Date();
    var _b = useState("all"), currentLocation = _b[0], setCurrentLocation = _b[1];
    var _c = useState(new Date(today.getFullYear(), today.getMonth(), 1)), startDate = _c[0], setStartDate = _c[1];
    var _d = useState(today), endDate = _d[0], setEndDate = _d[1];
    var _e = useState(false), useInactive = _e[0], setUseInactive = _e[1];
    var _f = useState(false), ready = _f[0], setReady = _f[1];
    var handleSubmit = function () {
        router.post("/production-boards/d/".concat(department, "/b/production-boards"), {
            location: currentLocation,
            start_date: startDate,
            end_date: endDate,
            use_inactive: useInactive,
        });
    };
    var handleInactive = function () {
        setUseInactive(!useInactive);
    };
    useEffect(function () {
        handleSubmit();
        setReady(true);
    }, [currentLocation, startDate, endDate, useInactive, ready]);
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 3 },
        React.createElement(Grid, { xs: 8 },
            React.createElement(OfficeLocations, { setCurrentLocation: setCurrentLocation, currentLocation: currentLocation, department: department })),
        React.createElement(Grid, { xs: 1 }, formData && csrf && (React.createElement(React.Fragment, null,
            React.createElement(ExportButton, { formData: formData, csrf: csrf })))),
        React.createElement(Grid, { xs: 3 },
            React.createElement(FormControl, null,
                React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { onChange: handleInactive, id: "check-inactive" }), label: "Show Inactive Agents" }))),
        React.createElement(Grid, { xs: 9 }, location && (React.createElement(ProductionBoardsTable, { department: department, policies_by_agent: policies_by_agent }))),
        React.createElement(Grid, { xs: 3 }, startDate && endDate && (React.createElement(DatePicker, { startDate: startDate, endDate: endDate, setStartDate: setStartDate, setEndDate: setEndDate, handleSubmit: handleSubmit })))));
};
export default ProductionBoards;
