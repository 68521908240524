import React from "react";
import { getShowDeleted, } from "../../../../utils/utils";
import AccountNav from "./AccountNav";
export var AccountNavWrapper = function (_a) {
    var _b;
    var account = _a.account, contact_count = _a.contact_count, address_count = _a.address_count, all_documents = _a.all_documents, donation_count = _a.donation_count, activity_log = _a.activity_log, _c = _a.showDeleted, showDeleted = _c === void 0 ? getShowDeleted() : _c;
    return (React.createElement(AccountNav, { account: account, order_count: (_b = account === null || account === void 0 ? void 0 : account.orders) === null || _b === void 0 ? void 0 : _b.length, 
        /*lead_count={
            account?.leads?.filter((el: any) =>
                filterDeletedLeads(el, showDeleted)
            )?.length
        }
        app_count={
            account?.apps?.filter((el: any) =>
                filterDeletedAppables(el, showDeleted)
            )?.length
        }
        policy_count={
            account?.policies?.filter((el: any) =>
                filterDeletedPolicies(el, showDeleted)
            )?.length
        }
            */
        // document_count={all_documents.length}
        // donation_count={account?.donations?.length}
        /*donation_count={
            account?.donations?.filter((el: any) =>
                filterDeletedDonations(el, showDeleted)
            )?.length
        }*/
        activity_log_count: activity_log === null || activity_log === void 0 ? void 0 : activity_log.length }));
};
