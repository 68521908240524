import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import Address from "../../../components/ContactAddress";
import { employerAddressOptions } from "../../../utils/utils";
import GenericControllerTextInput from "../../../components/GenericFormFields/TextInput";
var Employment = function (_a) {
    var _b, _c;
    var app = _a.app, control = _a.control, errors = _a.errors, states = _a.states;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Employment")),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerTextInput, { control: control, label: "Occupation", rules: { required: false }, name: "occupation", defaultValue: (_b = app === null || app === void 0 ? void 0 : app.occupation) !== null && _b !== void 0 ? _b : "", error: "occupation" in errors })),
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerTextInput, { label: "Employer Name", rules: { required: false }, control: control, name: "employer_name", defaultValue: (_c = app === null || app === void 0 ? void 0 : app.employer_name) !== null && _c !== void 0 ? _c : "", error: "occupation" in errors })),
                React.createElement(Address, { data: app, control: control, errors: errors, states: states, options: employerAddressOptions })))));
};
export default Employment;
