import React, { useState } from "react";
import { Button, Box, Modal, Dialog, DialogActions, DialogContent, DialogTitle, TextField, } from "@mui/material";
import { router } from "@inertiajs/react";
import { Controller, useForm } from "react-hook-form";
import { now } from "lodash";
import GenericControllerTextInput from "./GenericControllerTextInput";
var GenericSetDate = function (_a) {
    var _b;
    var data = _a.data, url = _a.url, title = _a.title, label = _a.label, name = _a.name, id = _a.id, buttonName = _a.buttonName, _c = _a.backgroundColor, backgroundColor = _c === void 0 ? "#f2a22f" : _c;
    var _d = useForm(), handleSubmit = _d.handleSubmit, control = _d.control, formErrors = _d.formState.errors;
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    var handleChange = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var submitUpdate = function (data) {
        setOpen(false);
        router.post(url, data);
    };
    return (React.createElement(Box, null,
        React.createElement(Button, { variant: "contained", style: {
                backgroundColor: backgroundColor,
                color: "#FFF",
            }, onClick: handleChange, fullWidth: true, size: "small" }, buttonName),
        React.createElement(Modal, { open: open, onClose: handleClose },
            React.createElement(Dialog, { open: open, onClose: handleClose },
                React.createElement(DialogTitle, { id: "form-dialog-title" }, title),
                React.createElement(DialogContent, null,
                    React.createElement(GenericControllerTextInput, { label: label, type: "date", control: control, name: name, defaultValue: (_b = data === null || data === void 0 ? void 0 : data[name]) !== null && _b !== void 0 ? _b : now(), error: name in formErrors, shrinkLabel: true, fullWidth: true }),
                    React.createElement(Controller, { control: control, name: "route", defaultValue: window.location.href, rules: {
                            required: true,
                        }, render: function (_a) {
                            var field = _a.field;
                            return React.createElement(TextField, { hidden: true });
                        } }),
                    React.createElement(Controller, { control: control, name: "id", defaultValue: id, rules: {
                            required: true,
                        }, render: function (_a) {
                            var field = _a.field;
                            return React.createElement(TextField, { hidden: true });
                        } })),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitUpdate), color: "primary" }, "Change"))))));
};
export default GenericSetDate;
