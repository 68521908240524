import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { router } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import { Container, Button, Typography, Paper, Modal, Dialog, DialogContent, Link, } from "@mui/material";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Unstable_Grid2";
import Layout from "../Layout";
import GenericControllerTextInput from "../../components/GenericFormFields/TextInput";
import DisplayErrors from "../../components/DisplayErrors";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        callout_danger: {
            border: 1,
            borderRadius: 3,
            color: "red",
            height: 90,
            padding: "10px 10px",
            margin: "10px 0px 0px 0px",
        },
        inactive: {
            color: "#aaa",
            weight: "normal",
        },
        active: {
            color: "#000",
            weight: "bold",
        },
    });
});
export var accountIdOptions = {
    id_from: {
        name: "From ID",
        required: true,
        valueAsNumber: true,
        pattern: {
            value: /^(0|[1-9]\d*)(\.\d+)?$/,
        },
    },
    id_to: {
        name: "To ID",
        required: true,
        valueAsNumber: true,
        pattern: {
            value: /^(0|[1-9]\d*)(\.\d+)?$/,
        },
    },
};
var AccountMerge = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _l = useState(true), menuOpen = _l[0], toggleMenuOpen = _l[1];
    var _m = useForm(), handleSubmit = _m.handleSubmit, errors = _m.formState.errors, control = _m.control, watch = _m.watch;
    var _o = useState((_b = response_data === null || response_data === void 0 ? void 0 : response_data.show_form) !== null && _b !== void 0 ? _b : false), showForm = _o[0], setShowForm = _o[1];
    var _p = useState(false), isSubmitting = _p[0], setIsSubmitting = _p[1];
    var _q = useState((_c = response_data === null || response_data === void 0 ? void 0 : response_data.confirmation) !== null && _c !== void 0 ? _c : false), isSureModal = _q[0], setIsSureModal = _q[1];
    var classes = useStyles();
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var handleCloseModal = function () {
        setIsSubmitting(false);
    };
    var handleCloseSureModal = function () {
        setIsSureModal(false);
        setShowForm(true);
    };
    useEffect(function () {
        if (response_data === null || response_data === void 0 ? void 0 : response_data.show_form) {
            setShowForm(true);
        }
        if (!isEmpty(serverErrors)) {
            setIsSubmitting(false);
        }
        if (response_data === null || response_data === void 0 ? void 0 : response_data.confirmation) {
            setIsSureModal(true);
        }
    }, [serverErrors, response_data === null || response_data === void 0 ? void 0 : response_data.confirmation, response_data === null || response_data === void 0 ? void 0 : response_data.show_form]);
    var onSubmit = function (data) {
        setIsSureModal(false);
        setShowForm(false);
        if (!isSubmitting) {
            setIsSubmitting(true);
            var post_data = {
                id_from: data.id_from,
                id_to: data.id_to,
            };
            router.post("/account/merge", post_data, {
                onSuccess: function () {
                    setIsSubmitting(false);
                },
            });
        }
    };
    var onIsSureModal = function (data) {
        var post_data = {
            id_from: data.id_from,
            id_to: data.id_to,
        };
        router.post("/account/merge-confirm", post_data);
    };
    return (React.createElement(Layout, { title: "Merge Account", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { container: true, direction: "column", spacing: 8 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h4", component: "h3", align: "center" }, "Merge Account")),
                !isEmpty(serverErrors) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))),
                (response_data === null || response_data === void 0 ? void 0 : response_data.account_not_found) && (React.createElement(Typography, { component: "h5", variant: "h5", style: { color: "#ff0000" } },
                    React.createElement("strong", null, "Error Occured: "),
                    " ",
                    response_data.message,
                    React.createElement("br", null),
                    React.createElement("br", null))),
                showForm && (React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 4 },
                        React.createElement(Grid, { xs: 4 },
                            React.createElement(GenericControllerTextInput, { control: control, name: "id_from", label: "From ID", variant: "outlined", error: "id_from" in errors, defaultValue: "" })),
                        React.createElement(Grid, { xs: 4 },
                            React.createElement(GenericControllerTextInput, { control: control, name: "id_to", label: "To ID", variant: "outlined", error: "id_to" in errors, defaultValue: "" })),
                        React.createElement(Grid, { xs: 4, container: true, justifyContent: "center", alignContent: "center" },
                            React.createElement(Button, { size: "large", type: "submit", variant: "contained", color: "primary", onClick: handleSubmit(onIsSureModal), fullWidth: true }, "Submit"))))),
                (response_data === null || response_data === void 0 ? void 0 : response_data.processed) && (React.createElement(Grid, { xs: 12 },
                    !response_data.success && (React.createElement(React.Fragment, null,
                        React.createElement("div", null,
                            React.createElement(Alert, { severity: "error" },
                                React.createElement("strong", null,
                                    "There was an issue -",
                                    " ",
                                    response_data.success))),
                        React.createElement("div", null, response_data.message),
                        React.createElement("div", null,
                            React.createElement("br", null),
                            React.createElement(Link, { href: "/account/merge" }, "Try Again")))),
                    response_data.success && (React.createElement(React.Fragment, null,
                        React.createElement(Alert, { severity: "success" },
                            React.createElement("strong", null, "Account Reassign Successful")),
                        React.createElement("div", null,
                            React.createElement("br", null),
                            "From: #",
                            response_data.account_from.id,
                            " (",
                            response_data.account_from.full_name,
                            ")",
                            React.createElement("br", null)),
                        React.createElement("div", null,
                            "To: #",
                            response_data.account_to.id,
                            " (",
                            response_data.account_to.full_name,
                            ")",
                            React.createElement("br", null)),
                        React.createElement("div", null,
                            response_data.message_expiration ==
                                "both" && (React.createElement("div", { className: classes.callout_danger },
                                React.createElement("strong", null,
                                    "Could Not Calculate Expiration Date for",
                                    " ",
                                    response_data.account_to
                                        .full_name),
                                React.createElement("br", null),
                                "Both 'From' Account and 'To' Account are Current (active + not-expired)",
                                React.createElement("br", null),
                                React.createElement("strong", null, "Please Go to 'To' Account and Assign Their Expiration Date"),
                                React.createElement("br", null))),
                            response_data.message_expiration ==
                                "from_only" && (React.createElement("div", null,
                                React.createElement("strong", null,
                                    "Updated 'To' Account with 'From' Account Expiration Date (",
                                    response_data
                                        .account_from
                                        .account_expiration_date,
                                    ") and Status (",
                                    response_data
                                        .account_from.status,
                                    ")"),
                                React.createElement("br", null),
                                "'From' account was current (active and not expired). 'To' account was not current",
                                React.createElement("br", null))),
                            response_data.message_expiration ==
                                "unchanged" && (React.createElement("div", null,
                                React.createElement("strong", null, "Expiration Date Unaffected"),
                                React.createElement("br", null),
                                "'From' account was not current, so 'To' account status and expiration is unchanged.",
                                React.createElement("br", null)))),
                        React.createElement("div", null,
                            React.createElement("ul", null, (_d = response_data === null || response_data === void 0 ? void 0 : response_data.order_notes) === null || _d === void 0 ? void 0 : _d.map(function (el) { return (React.createElement("li", { key: el.id },
                                "Order Note added for Order# ",
                                el.id,
                                "(",
                                React.createElement("em", null, el.message),
                                ")")); }))),
                        React.createElement("div", null,
                            React.createElement("ul", null, (_e = response_data === null || response_data === void 0 ? void 0 : response_data.tables_updated) === null || _e === void 0 ? void 0 : _e.map(function (el) { return (React.createElement("li", { key: el.table, className: el.num
                                    ? classes.active
                                    : classes.inactive },
                                el.num,
                                " ",
                                el.table,
                                " ",
                                "updated")); }))),
                        !(response_data === null || response_data === void 0 ? void 0 : response_data.total_reassigned) && (React.createElement("div", null, "Nothing found to reassign")),
                        (response_data === null || response_data === void 0 ? void 0 : response_data.total_reassigned) && (React.createElement("div", null,
                            "Something found to reassign",
                            React.createElement("br", null),
                            React.createElement("div", null,
                                React.createElement("ul", null, (_f = response_data === null || response_data === void 0 ? void 0 : response_data.account_notes) === null || _f === void 0 ? void 0 : _f.map(function (el) { return (React.createElement("li", { key: el.account_id },
                                    "Account Note added for Account#",
                                    " ",
                                    el.account_id,
                                    "(",
                                    React.createElement("em", null, el.message),
                                    ")")); }))))),
                        (response_data === null || response_data === void 0 ? void 0 : response_data.is_account_from_updated) && (React.createElement("div", null, "'From' Account Updated")),
                        !(response_data === null || response_data === void 0 ? void 0 : response_data.is_account_from_updated) && (React.createElement("div", null, "'From' Account Not Updated")),
                        (response_data === null || response_data === void 0 ? void 0 : response_data.is_account_to_updated) && (React.createElement("div", null, "'To' Account Updated")),
                        !(response_data === null || response_data === void 0 ? void 0 : response_data.is_account_to_updated) && (React.createElement("div", null, "'To' Account Not Updated")),
                        React.createElement("div", null,
                            "'From' Account (",
                            response_data.account_from.full_name,
                            ") status is 'Deleted'",
                            React.createElement("br", null),
                            React.createElement("br", null)),
                        React.createElement("div", null,
                            React.createElement("br", null),
                            React.createElement(Link, { href: "/account/merge" }, "Merge Another"))))))),
            React.createElement(Modal, { open: isSubmitting, onClose: handleCloseModal },
                React.createElement(Dialog, { open: isSubmitting, onClose: handleCloseModal },
                    React.createElement(DialogContent, { style: { minWidth: "500px", textAlign: "center" } }, "This form is being submitted"))),
            React.createElement(Modal, { open: isSureModal, onClose: handleCloseSureModal },
                React.createElement(Dialog, { open: isSureModal, onClose: handleCloseSureModal },
                    React.createElement(DialogContent, { style: { minWidth: "500px" } },
                        React.createElement(Grid, { container: true, spacing: 2 },
                            React.createElement(Grid, { container: true, xs: 12, justifyContent: "center" },
                                React.createElement(Typography, { variant: "h4" },
                                    React.createElement("strong", null, "Are you sure?"))),
                            React.createElement(Grid, { container: true, xs: 12, justifyContent: "center" },
                                React.createElement(Typography, { variant: "h5" }, "You are about to transfer")),
                            React.createElement(Grid, { xs: 12 },
                                React.createElement(Typography, { variant: "h5" },
                                    React.createElement("strong", null, "From: "),
                                    "#", (_g = response_data === null || response_data === void 0 ? void 0 : response_data.account_from) === null || _g === void 0 ? void 0 :
                                    _g.id,
                                    React.createElement("br", null), (_h = response_data === null || response_data === void 0 ? void 0 : response_data.account_from) === null || _h === void 0 ? void 0 :
                                    _h.full_name,
                                    React.createElement("br", null),
                                    React.createElement("strong", null, "To: "),
                                    "#", (_j = response_data === null || response_data === void 0 ? void 0 : response_data.account_to) === null || _j === void 0 ? void 0 :
                                    _j.id,
                                    React.createElement("br", null), (_k = response_data === null || response_data === void 0 ? void 0 : response_data.account_to) === null || _k === void 0 ? void 0 :
                                    _k.full_name)),
                            React.createElement(Grid, { container: true, xs: 12, justifyContent: "center" },
                                React.createElement(Typography, { variant: "h5", style: { color: "#ff0000" } },
                                    React.createElement("strong", null, "This action cannot be undone.")))),
                        React.createElement(Grid, { container: true, spacing: 2 },
                            React.createElement(Grid, { xs: 6 },
                                React.createElement(Button, { type: "submit", variant: "outlined", color: "secondary", onClick: handleCloseSureModal, fullWidth: true, style: {
                                        backgroundColor: "#9e0702",
                                        color: "#FFF",
                                        fontSize: "14px",
                                        fontWeight: "700",
                                    } }, "Cancel")),
                            React.createElement(Grid, { xs: 6 },
                                React.createElement(Button, { type: "submit", variant: "outlined", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true, style: {
                                        backgroundColor: "#0c5f36",
                                        color: "#FFF",
                                        fontSize: "14px",
                                        fontWeight: "700",
                                    } }, "Submit")))))))));
};
export default AccountMerge;
