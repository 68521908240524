import { router } from "@inertiajs/react";
import { Button, Container, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import { throttle } from "lodash";
import React, { useState } from "react";
import { useForm, } from "react-hook-form";
import route from "ziggy-js";
import Address from "../../components/Address";
import DisplayErrors from "../../components/DisplayErrors";
import GenericPhoneInput from "../../components/GenericPhoneInput";
import GenericControllerTextInput from "../../components/GenericControllerTextInput";
import LeadSection from "../../components/LeadSection";
import StatusContact from "../../components/StatusContact";
import { mailingAddressOptions, residentialAddressOptions, setLead, } from "../../utils/utils";
import Layout from "../Layout";
import LeadMemberInfo from "../Lead/components/LeadMemberInfo";
import AgentInformation from "./components/AgentInformation";
import DriversLicense from "./components/DriversLicense";
import Employment from "./components/Employment";
import HealthInsuranceInfo from "./components/HealthInsuranceInfo";
import LifeFinancialQuestions from "./components/LifeFinancialQuestions";
import LifeInsuranceInfo from "./components/LifeInsuranceInfo";
import PersonalInfo from "./components/PersonalInfo";
import { planTypes } from "../../constants/Rosemark";
import FinancialInfoSection from "./components/FinancialInfoSection";
export default function AppForm(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var response_data = _a.response_data, serverErrors = _a.errors;
    var member = response_data.member, app = response_data.app, app_type = response_data.app_type, lead = response_data.lead, lead_types = response_data.lead_types, disposition_group = response_data.disposition_group, medicare_plans = response_data.medicare_plans, medicare_change_reasons = response_data.medicare_change_reasons, agents = response_data.agents, insurance_carriers = response_data.insurance_carriers, insurance_types = response_data.insurance_types, states = response_data.states, referrals = response_data.referrals;
    //initialize form type
    app.formType = "application";
    app.lead_type = lead.lead_type_id;
    var _l = useForm(), handleSubmit = _l.handleSubmit, errors = _l.formState.errors, control = _l.control, resetField = _l.resetField, watch = _l.watch;
    var leadType = setLead(lead);
    var _m = useState(true), menuOpen = _m[0], toggleMenuOpen = _m[1];
    var _o = useState(false), submitting = _o[0], setSubmitting = _o[1];
    var originalDispositionId = (_b = app === null || app === void 0 ? void 0 : app.disposition_id) !== null && _b !== void 0 ? _b : "";
    var _p = useState((_c = app === null || app === void 0 ? void 0 : app.disposition_id) !== null && _c !== void 0 ? _c : ""), dispositionId = _p[0], setDispositionId = _p[1];
    var _q = useState(false), anotherCall = _q[0], setAnotherCall = _q[1];
    var watchInsuranceCarrier = watch("insurance_carrier", (_d = insurance_carriers.find(function (el) { return el.id == (app === null || app === void 0 ? void 0 : app.insurance_carrier_id); })) !== null && _d !== void 0 ? _d : null);
    var watchPlanTypes = watch("plan_type_select", (_e = planTypes.find(function (el) { return el.id == (app === null || app === void 0 ? void 0 : app.plan_type); })) !== null && _e !== void 0 ? _e : {
        name: (_f = app.plan_type) !== null && _f !== void 0 ? _f : "",
        id: (_g = app.plan_type) !== null && _g !== void 0 ? _g : null,
    });
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var setDefaultIfNoLead = function () {
        switch (app_type) {
            case "life":
                return 11;
            case "financial":
                return 5;
            default:
                return 13;
        }
    };
    if (leadType == 0) {
        leadType = setDefaultIfNoLead();
    }
    var onSubmit = function (data) {
        var _a, _b, _c, _d, _e;
        if (originalDispositionId !== dispositionId || anotherCall) {
            data.new_disposition_id = dispositionId;
        }
        data.insurance_type_id = leadType;
        // The insurance_type_id column on the health_ap table is misleading - there is logic in place that is not used, but it will
        // be kept for now. Ultimately it does not get used properly anywhere as far as I have investigated, but it should be reworked.
        // What is ultimately used to determine insurance type is the field on the extra_data_json column on the lead.
        data.insurance_carrier_id = (_a = watchInsuranceCarrier === null || watchInsuranceCarrier === void 0 ? void 0 : watchInsuranceCarrier.id) !== null && _a !== void 0 ? _a : null;
        data.lead_id = (_b = lead === null || lead === void 0 ? void 0 : lead.id) !== null && _b !== void 0 ? _b : "0";
        data.member_id = member === null || member === void 0 ? void 0 : member.id;
        data.member_spouse = lead === null || lead === void 0 ? void 0 : lead.member_spouse;
        data.birth_date = (lead === null || lead === void 0 ? void 0 : lead.member_spouse)
            ? member === null || member === void 0 ? void 0 : member.spouse_birth_date
            : member === null || member === void 0 ? void 0 : member.birth_date;
        data.email = (lead === null || lead === void 0 ? void 0 : lead.member_spouse) ? member === null || member === void 0 ? void 0 : member.spouse_email : member === null || member === void 0 ? void 0 : member.email;
        data.transfer_user_id = (_c = data.transfer_user_id) === null || _c === void 0 ? void 0 : _c.id;
        data.assisted_by = (_d = data.assisted_by) === null || _d === void 0 ? void 0 : _d.id;
        data.plan_type = (_e = data.plan_type_select) === null || _e === void 0 ? void 0 : _e.id;
        if (!data.birth_date) {
            delete data["birth_date"];
        }
        if (!data.email) {
            delete data["email"];
        }
        var submitOptions = {
            onStart: function () {
                setSubmitting(true);
            },
            onFinish: function () {
                setSubmitting(false);
            },
        };
        if (!(app === null || app === void 0 ? void 0 : app.id)) {
            router.post(route("create_app"), data, submitOptions);
        }
        else {
            router.post(route("edit_app", app === null || app === void 0 ? void 0 : app.id), data, submitOptions);
        }
    };
    var handleCancel = function () { return router.get(route("show_member", [member.id])); };
    return (React.createElement(Layout, { title: "App", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { xs: 12, container: true, spacing: 2, direction: "column" },
                React.createElement(Grid, { spacing: 2, container: true, xs: 12, md: 6, justifyContent: "flex-start" },
                    React.createElement(Typography, { variant: "h4", component: "h2" }, (app === null || app === void 0 ? void 0 : app.id) ? "Edit App" : "Convert to App")),
                React.createElement(Grid, { spacing: 2, container: true, xs: 12, md: 6, justifyContent: "flex-end" },
                    React.createElement(Typography, { variant: "h4", component: "h2" }, (_h = lead_types.filter(function (el) { return (el === null || el === void 0 ? void 0 : el.id) == leadType; })[0]) === null || _h === void 0 ? void 0 : _h.name)),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(DisplayErrors, { errors: serverErrors })),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { spacing: 2, xs: 12, container: true, component: Paper },
                            React.createElement(LeadMemberInfo, { member: member, spouseLead: (app === null || app === void 0 ? void 0 : app.member_spouse)
                                    ? true
                                    : (lead === null || lead === void 0 ? void 0 : lead.member_spouse)
                                        ? true
                                        : false, states: states })))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { container: true, xs: 12, spacing: 2, component: Paper },
                            React.createElement(Grid, { xs: 12 },
                                React.createElement(Typography, { variant: "h6", component: "h3" }, "Legal Name")),
                            React.createElement(Grid, { xs: 12 },
                                React.createElement(GenericControllerTextInput, { name: "name", label: "Legal Name", control: control, defaultValue: (app === null || app === void 0 ? void 0 : app.name)
                                        ? app === null || app === void 0 ? void 0 : app.name
                                        : (lead === null || lead === void 0 ? void 0 : lead.member_spouse)
                                            ? member.spouse_full_name
                                            : member.full_name, rules: { required: true }, error: "name" in errors }))))),
                parseInt(leadType) === 11 && ( //done
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                            React.createElement(Address, { data: (app === null || app === void 0 ? void 0 : app.id) ? app : member, control: control, errors: errors, states: states, options: residentialAddressOptions }),
                            React.createElement(Address, { data: (app === null || app === void 0 ? void 0 : app.id) ? app : member, errors: errors, states: states, options: mailingAddressOptions, control: control, showResidentialSame: true }))))),
                ![11, 5].includes(parseInt(leadType)) && ( //done
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                            React.createElement(HealthInsuranceInfo, { insuranceCarriers: insurance_carriers, errors: errors, control: control, watchInsuranceCarrier: watchInsuranceCarrier, app: app }))))),
                parseInt(leadType) === 5 && (React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(FinancialInfoSection, { app: app, insurance_types: insurance_types, control: control, errors: errors, insurance_carriers: insurance_carriers, watchInsuranceCarrier: undefined, watchPlanTypes: undefined })))),
                parseInt(leadType) === 11 && ( //done
                React.createElement(React.Fragment, null,
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12 },
                            React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                                React.createElement(DriversLicense, { app: app, errors: errors, control: control, states: states })))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12 },
                            React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                                React.createElement(PersonalInfo, { app: app, errors: errors, control: control, states: states })))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12 },
                            React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                                React.createElement(Employment, { app: app, errors: errors, control: control, states: states })))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12 },
                            React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                                React.createElement(LifeInsuranceInfo, { insuranceCarriers: insurance_carriers, control: control, appState: app, watchInsuranceCarrier: watchInsuranceCarrier, watchPlanTypes: watchPlanTypes, errors: errors })))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12 },
                            React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                                React.createElement(LifeFinancialQuestions, { errors: errors, control: control, app: app })))))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                            React.createElement(LeadSection, { errors: errors, control: control, lead: lead, leadType: leadType, insuranceTypes: insurance_types, medicarePlans: medicare_plans, medicareChangeReasons: medicare_change_reasons, responseData: response_data })))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                            React.createElement(AgentInformation, { agents: agents, errors: errors, control: control, app: app, deptType: lead.lead_type_id })))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                            React.createElement(StatusContact, { resetField: resetField, errors: errors, control: control, watch: watch, data: app, referrals: referrals, dispositionId: dispositionId, setDispositionId: setDispositionId, dispositionGroup: disposition_group, anotherCall: anotherCall, setAnotherCall: setAnotherCall }),
                            Number(leadType) === 11 && (React.createElement(Grid, { xs: 12 },
                                React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                                    React.createElement(Grid, { xs: 6 },
                                        React.createElement(GenericPhoneInput, { label: "Day Phone", defaultValue: (_j = app === null || app === void 0 ? void 0 : app.day_phone) !== null && _j !== void 0 ? _j : member === null || member === void 0 ? void 0 : member.phone, name: "day_phone", control: control, required: false, helperText: "Please provide full phone (###) ###-####." })),
                                    React.createElement(Grid, { xs: 6 },
                                        React.createElement(GenericPhoneInput, { label: "Evening Phone", defaultValue: (_k = app === null || app === void 0 ? void 0 : app.evening_phone) !== null && _k !== void 0 ? _k : member === null || member === void 0 ? void 0 : member.phone, name: "evening_phone", control: control, required: false, helperText: "Please provide full phone (###) ###-####." }))))),
                            React.createElement(Grid, { xs: 12 },
                                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                                    React.createElement(Grid, { xs: 12 },
                                        React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Note")))),
                            React.createElement(Grid, { xs: 12 },
                                React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                                    React.createElement(Grid, { xs: 12 },
                                        React.createElement(GenericControllerTextInput, { label: "Application Note", multiline: true, control: control, defaultValue: "", name: "app_note_text", minRows: 10, error: "app_note_text" in errors }))))))),
                React.createElement(Grid, { container: true, xs: 12, justifyContent: "center", spacing: 2 },
                    React.createElement(Grid, { xs: 3 },
                        React.createElement(Button, { type: "button", variant: "outlined", color: "secondary", onClick: handleCancel, fullWidth: true }, "Cancel")),
                    React.createElement(Grid, { xs: 3 },
                        React.createElement(Button, { type: "submit", variant: "contained", color: "primary", onClick: throttle(handleSubmit(onSubmit), 2000), disabled: submitting, fullWidth: true }, "Save Application")))))));
}
