import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import FileUploadForm from "../../components/FileUploadForm";
import { hasPermission } from "../../utils/utils";
import Layout from "../Layout";
import BrokerDocumentTable from "./components/BrokerDocumentTable";
import BrokerNav from "./components/BrokerNav";
var AbsDocuments = function (_a) {
    var response_data = _a.response_data, auth = _a.auth, flash = _a.flash;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var user = response_data.user, documents = response_data.documents;
    return (React.createElement(Layout, { title: "Broker Documents", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { container: true, xs: 12, alignItems: "center", justifyContent: "center" },
                React.createElement(BrokerNav, { user: user })),
            React.createElement(Grid, { xs: 12 },
                hasPermission(auth, "add broker-documents") && (React.createElement(FileUploadForm, { memberId: user.id, userId: user.id, name: "abs_brokers", id: user.id })),
                React.createElement(BrokerDocumentTable, { data: documents })))));
};
export default AbsDocuments;
