import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { LifetimeBadge } from "../../../components/IconLibrary";
import { FoundationDonorBadge, } from "../../../components/IconLibrary";
import { helperFunctions } from "../../../Helpers";
var AccountHeader = function (_a) {
    var account = _a.account;
    console.log(account);
    return (React.createElement(Box, { my: 2, px: 1, sx: { backgroundColor: "#F1F1F1" } },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 12, md: 5 }, React.createElement(Typography, { variant: "h6" },
                "Account #",
                account.id,
                (account === null || account === void 0 ? void 0 : account.orders.filter(function (order) { return order.product_id === 7; }).length) > 0 && React.createElement(FoundationDonorBadge, null),
                (account === null || account === void 0 ? void 0 : account.amac_account.lifetime) && React.createElement(LifetimeBadge, null))),
            React.createElement(Grid, { item: true, xs: 12, md: 7 },
                React.createElement(Typography, { sx: { fontSize: '9pt', color: '#888888' }, pt: 1, textAlign: 'right' },
                    "Created: ", "".concat(helperFunctions.getDateTime(account.created_at)),
                    " (",
                    account.created_by_name,
                    " ",
                    account.created_by,
                    ") | Modified: ", "".concat(helperFunctions.getDateTime(account.updated_at)),
                    " (",
                    account.updated_by_name,
                    " ",
                    account.updated_by,
                    ")")))));
};
export default AccountHeader;
