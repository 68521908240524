import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import NotesForm from "../../../../components/NotesForm";
import DonationNotes from "./DonationNotes";
var DonationNoteCard = function (_a) {
    var contact = _a.contact;
    var _b = useState(false), addNotesFlag = _b[0], setAddNotesFlag = _b[1];
    var handleAddNotesFlag = function () {
        setAddNotesFlag(!addNotesFlag);
    };
    return (React.createElement(Grid, { xs: 12, container: true },
        React.createElement(Grid, { xs: 12, style: { backgroundColor: "#F1F1F1", marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Grid, { container: true, xs: 12 },
                    React.createElement(Grid, { xs: 6 },
                        React.createElement(Typography, { variant: "h6" }, "Notes")),
                    React.createElement(Grid, { container: true, xs: 6, justifyContent: "flex-end", alignContent: "center" }, !addNotesFlag && (React.createElement(Button, { type: "button", onClick: handleAddNotesFlag, variant: "outlined", color: "primary", size: "small", style: {
                            fontSize: "10px",
                            fontWeight: "bold",
                            backgroundColor: "#1871BF",
                            color: "#FFF",
                        } }, "+ Note")))))),
        addNotesFlag ? (React.createElement(NotesForm, { noteOwner: contact, noteType: "donation", handleAddNotesFlag: handleAddNotesFlag })) : (React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(DonationNotes, { notes: contact === null || contact === void 0 ? void 0 : contact.donation_notes })))));
};
export default DonationNoteCard;
