import React, { useState } from "react";
import { router, usePage } from "@inertiajs/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import GenericAutoComplete from "./GenericAutoComplete";
import { agentFilter } from "../utils/agentUtils";
import axios from "axios";
//
// This is used to connect with the
// Transfer User controller - starting with the tasks.
// See SetTransferUserId.tsx for the original functionality
//
var ModalTransferUser = function (_a) {
    var agents = _a.agents, current_transferred = _a.current_transferred, created_by = _a.created_by, lead_type_id = _a.lead_type_id, model_id = _a.model_id, model = _a.model, setAgentsArray = _a.setAgentsArray, _b = _a.size, size = _b === void 0 ? "small" : _b;
    var auth = usePage().props.auth;
    var _c = useState(false), openTransferUserId = _c[0], setOpenTransferUserId = _c[1];
    var _d = useForm(), control = _d.control, formErrors = _d.formState.errors, handleSubmit = _d.handleSubmit;
    var getAgentsArray = function () {
        agents.length == 0 && setAgentsArray
            ? axios
                .get("/transfer_users/get")
                .then(function (res) {
                setAgentsArray(res.data.agents);
            })
                .then(function () { return setOpenTransferUserId(true); })
                .catch(function (err) {
                console.log("err", err);
            })
            : setOpenTransferUserId(true);
    };
    var agentsInDepartment = auth.user.userable_type == "App\\Models\\AbsBroker"
        ? agents
        : agentFilter(agents, lead_type_id, current_transferred === null || current_transferred === void 0 ? void 0 : current_transferred.id, created_by === null || created_by === void 0 ? void 0 : created_by.id);
    var submitTransferUserId = function (formData) {
        var _a;
        setOpenTransferUserId(false);
        router.post("/transfer_users/update", {
            model: model,
            model_id: model_id,
            user_id: (_a = formData === null || formData === void 0 ? void 0 : formData.transfer_user_id) === null || _a === void 0 ? void 0 : _a.id,
        });
    };
    var handleCloseTransferUserId = function () {
        setOpenTransferUserId(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: getAgentsArray, variant: "contained", size: size, fullWidth: true, style: {
                backgroundColor: "#1871BF",
                color: "#FFF",
            } }, "Transfer To"),
        React.createElement(Modal, { open: openTransferUserId, onClose: handleCloseTransferUserId },
            React.createElement(Dialog, { open: openTransferUserId, onClose: handleCloseTransferUserId },
                React.createElement(DialogTitle, null, "Transfer To"),
                React.createElement(DialogContent, { style: { minWidth: "500px" } },
                    React.createElement(Grid, { xs: 12, container: true, spacing: 2, margin: "dense" },
                        React.createElement(GenericAutoComplete, { limitTags: 1, name: "transfer_user_id", control: control, label: "Agents", options: agentsInDepartment, rules: {
                                required: true,
                            }, error: "transfer_user_id" in formErrors, value: current_transferred, multiple: false, marginDense: true }))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleCloseTransferUserId, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitTransferUserId), color: "primary" }, "Transfer"))))));
};
export default ModalTransferUser;
