import { Checkbox, FormControlLabel, MenuItem, Typography, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import countryList from "../constants/countries";
import GenericControllerDropdownInput from "./GenericFormFields/DropdownInput";
import GenericControllerHTMLDropdownInput from "./GenericFormFields/HTMLDropdownInput";
import GenericControllerTextInput from "./GenericFormFields/TextInput";
import { Controller } from "react-hook-form";
var Address = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var data = _a.data, errors = _a.errors, states = _a.states, control = _a.control, _l = _a.options, options = _l === void 0 ? {} : _l, _m = _a.showResidentialSame, showResidentialSame = _m === void 0 ? false : _m, produceContacts = _a.produceContacts;
    var _o = useState(false), addressCheckbox = _o[0], setAddressCheckbox = _o[1];
    var _p = useState((_b = data === null || data === void 0 ? void 0 : data[options.state.default]) !== null && _b !== void 0 ? _b : ""), addressState = _p[0], setAddressState = _p[1];
    var _q = useState((_c = data === null || data === void 0 ? void 0 : data[options.country.default]) !== null && _c !== void 0 ? _c : "United States"), addressCountry = _q[0], setAddressCountry = _q[1];
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        ((_d = options === null || options === void 0 ? void 0 : options.title) === null || _d === void 0 ? void 0 : _d.name) && (React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, options.title.name))),
        showResidentialSame && (React.createElement(Grid, { xs: 12 },
            React.createElement(FormControlLabel, { label: "Residential Address is the same as Mailing Address", control: React.createElement(Controller, { name: "ship_res_address_same", control: control, defaultValue: !!addressCheckbox, render: function (_a) {
                        var _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, value = _b.value;
                        return (React.createElement(Checkbox, { onChange: function (e) {
                                setAddressCheckbox(e.target.checked);
                                onChange(e);
                            }, defaultChecked: !!addressCheckbox, value: value }));
                    } }) }))),
        (!addressCheckbox || !showResidentialSame) && (React.createElement(React.Fragment, null,
            React.createElement(Grid, { xs: 12 },
                React.createElement(GenericControllerTextInput, { control: control, label: "Street Address 1", error: options.street_1.name in errors, rules: { required: options.street_1.required }, name: options.street_1.name, defaultValue: !isEmpty(data)
                        ? data[options.street_1.default]
                        : "" })),
            React.createElement(Grid, { xs: 12 },
                React.createElement(GenericControllerTextInput, { control: control, label: "Street Address 2", error: options.street_2.name in errors, rules: { required: options.street_2.required }, name: options.street_2.name, defaultValue: !isEmpty(data)
                        ? data[options.street_2.default]
                        : "" })),
            React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
                React.createElement(Grid, { xs: 12, md: 4 },
                    React.createElement(GenericControllerTextInput, { control: control, label: "City", error: options.city.name in errors, rules: { required: options.city.required }, name: options.city.name, defaultValue: (_e = data === null || data === void 0 ? void 0 : data[options.city.default]) !== null && _e !== void 0 ? _e : "" })),
                React.createElement(Grid, { xs: 12, md: 4 },
                    React.createElement(GenericControllerHTMLDropdownInput, { label: "State", name: options.state.name, id: "".concat(options.state.name, "_select"), defaultValue: addressState, handleChange: function (e) {
                            return setAddressState(e.target.value);
                        }, control: control, rules: {
                            required: options.state.required,
                        }, error: options.state.name in errors, placeHolder: "", options: !!(states === null || states === void 0 ? void 0 : states.length) &&
                            states.map(function (state) { return (React.createElement("option", { key: state.id, value: state.id }, state.name)); }) })),
                React.createElement(Grid, { xs: 12, md: 4 },
                    React.createElement(GenericControllerTextInput, { control: control, name: (_f = options === null || options === void 0 ? void 0 : options.zip) === null || _f === void 0 ? void 0 : _f.name, label: "Zip code", error: options.zip.name in errors, rules: {
                            required: options.zip.required,
                        }, defaultValue: !isEmpty(data)
                            ? data[(_g = options === null || options === void 0 ? void 0 : options.zip) === null || _g === void 0 ? void 0 : _g.default]
                            : "", onblur: produceContacts }))))),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            ((_h = options === null || options === void 0 ? void 0 : options.county) === null || _h === void 0 ? void 0 : _h.name) && (React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(GenericControllerTextInput, { control: control, label: "County", error: options.county.name in errors, name: options.county.name, defaultValue: (_j = data === null || data === void 0 ? void 0 : data[options.county.default]) !== null && _j !== void 0 ? _j : "" }))),
            (!addressCheckbox || !showResidentialSame) &&
                ((_k = options === null || options === void 0 ? void 0 : options.country) === null || _k === void 0 ? void 0 : _k.name) && (React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(GenericControllerDropdownInput, { label: "Country", name: options.country.name, id: "".concat(options.country.name, "_select"), control: control, rules: {
                        required: options.country.required,
                        validate: function (value) { return value !== "0"; },
                    }, error: options.country.name in errors, defaultValue: addressCountry, handleChange: function (e) {
                        return setAddressCountry(e.target.value);
                    }, options: countryList.map(function (country, index) { return (React.createElement(MenuItem, { key: index, value: country }, country)); }) }))))));
};
export default Address;
