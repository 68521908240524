import React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import { FormControl, InputLabel } from "@mui/material";
var GenericControllerSelect = function (_a) {
    var name = _a.name, label = _a.label, control = _a.control, _b = _a.options, options = _b === void 0 ? [] : _b, _c = _a.customOptions, customOptions = _c === void 0 ? [] : _c, rules = _a.rules, handleChange = _a.handleChange, defaultValue = _a.defaultValue, isDisabled = _a.isDisabled, error = _a.error, customSelectSx = _a.customSelectSx;
    return (React.createElement(Controller, { name: name, control: control, defaultValue: defaultValue, rules: rules, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value;
            return (React.createElement(FormControl, { fullWidth: true, variant: "outlined" },
                React.createElement(InputLabel, { id: "select-".concat(name, "-id") }, label),
                React.createElement(Select, { sx: customSelectSx
                        ? customSelectSx
                        : {
                            height: "56px",
                            maxHeight: "56px",
                            minHeight: "56px",
                        }, labelId: "select-".concat(name, "-id"), label: label, value: value, disabled: isDisabled, onChange: handleChange
                        ? function (e) {
                            handleChange(e);
                            onChange(e);
                        }
                        : function (e) {
                            onChange(e);
                        }, 
                    // renderValue={() =>
                    //     options.filter((el) => el.id === value)?.pop()?.name
                    // }
                    error: error }, customOptions
                    ? customOptions
                    : options.map(function (option, idx) { return (React.createElement(MenuItem, { key: "".concat(name, "_").concat(idx), value: option.id },
                        React.createElement(ListItemText, { primary: option.name }))); }))));
        } }));
};
export default GenericControllerSelect;
