var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// This is the 'new' member form, which is responsible strictly for adding a member
// Probably this will change. But the first pass is almost a clone.
// The product/donation functionality is removed.
import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { router } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import { Container, Typography, Paper, Modal, Dialog, DialogContent, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Layout from "../Layout";
import MemberSpouseInfo from "../../components/MemberSpouseInfo";
import MemberInfoForm from "../../components/MemberInfoForm";
import MemberButton from "../../components/MemberButton";
import Address from "../../components/Address";
import { mailingAddressOptions, residentialAddressOptions, } from "../../utils/utils";
import DisplayErrors from "../../components/DisplayErrors";
import { throttle } from "lodash";
var MemberForm = function (_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var member = response_data.member, states = response_data.states;
    member.formType = "member";
    // const { auth } = usePage().props;
    var _c = useForm({
        shouldUnregister: true,
    }), handleSubmit = _c.handleSubmit, errors = _c.formState.errors, control = _c.control, watch = _c.watch;
    var watchAddress = watch("ship_address_1", "");
    var watchCity = watch("ship_city", "");
    var watchProspect = watch("product_id", "");
    var _d = useState(false), isSubmitting = _d[0], setIsSubmitting = _d[1];
    for (var key in serverErrors) {
        // if form is stuck because of server error, check if the key needs to be bypassed
        // look to old member form to find out how.
        key = key.replace("member.", "");
        errors[key] = serverErrors[key];
    }
    var handleCloseModal = function () {
        setIsSubmitting(false);
    };
    useEffect(function () {
        if (!isEmpty(serverErrors)) {
            setIsSubmitting(false);
        }
    }, [serverErrors]);
    var onSubmit = function (data, e, sendToLead) {
        if (!isSubmitting) {
            setIsSubmitting(true);
            var post_data = {
                member: __assign(__assign({}, data), { status: "unmarketable" }),
            };
            router.post("/member/store", post_data);
        }
    };
    return (React.createElement(Layout, { title: "Add New Unmarketable Member", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { container: true, direction: "column", spacing: 4 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h4", component: "h3", align: "center" }, "Add New Unmarketable Member")),
                !isEmpty(serverErrors) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                        React.createElement(Grid, { container: true, component: Paper, xs: 12, spacing: 2 },
                            React.createElement(MemberInfoForm, { member: member, control: control, errors: errors, data: member })))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                        React.createElement(Grid, { container: true, component: Paper, xs: 12, spacing: 2 },
                            React.createElement(Address, { data: member, control: control, errors: errors, states: states, options: mailingAddressOptions }),
                            React.createElement(Address, { data: member, control: control, errors: errors, states: states, options: residentialAddressOptions, showResidentialSame: watchAddress != "" && watchCity != "" })))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                        React.createElement(Grid, { container: true, xs: 12, component: Paper, spacing: 2 },
                            React.createElement(MemberSpouseInfo, { member: member, control: control, errors: errors, data: member })))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(MemberButton, { member: member, watchProspect: watchProspect, handleSubmit: throttle(handleSubmit, 2000), onSubmit: onSubmit }))),
            React.createElement(Modal, { open: isSubmitting, onClose: handleCloseModal },
                React.createElement(Dialog, { open: isSubmitting, onClose: handleCloseModal },
                    React.createElement(DialogContent, { style: { minWidth: "500px", textAlign: "center" } }, "This form is being submitted"))))));
};
export default MemberForm;
