import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
var BrokerDocument = function (_a) {
    var data = _a.data, control = _a.control, errors = _a.errors;
    var options = [
        { name: "agent_contract", label: "Agent Contract PDF" },
        { name: "w9", label: "W9 Form" },
        { name: "direct_deposit", label: "Direct Deposit Form" },
        { name: "voided_check", label: "Voided Check" },
        { name: "bg_check_oig", label: "Background Check: GSA" },
        { name: "bg_check_oig_exp", label: "Background Check: GSA Exp. Data" },
        { name: "bg_check_gsa", label: "Background Check: OIG" },
        { name: "bg_check_gsa_exp", label: "Background Check: OIG Exp. Data" },
        { name: "drivers_license", label: "Driver's License" },
        { name: "ss_card", label: "Social Security Card" },
        { name: "licenses", label: "License(s)" },
        { name: "eo_rec", label: "E&O: Received" },
        { name: "eo_eff", label: "E&O: Effective" },
        { name: "eo_exp", label: "E&O: Expired" },
        { name: "ahip", label: "AHIP Certificate" },
        { name: "fwa", label: "Fraud, Waste, Abuse Certificate" },
        { name: "ffm", label: "Federally Facilitated Marketplace Cert." },
        { name: "all_carrier", label: "All Carrier Certificates" },
    ];
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Documents")),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 }, options.map(function (el) {
            var _a;
            return (React.createElement(Grid, { xs: 12, md: 6, key: el.name },
                React.createElement(GenericControllerTextInput, { control: control, type: "date", label: el.label, name: "broker[".concat(el.name, "]"), rules: {
                        required: false,
                    }, defaultValue: (_a = data[el.name]) !== null && _a !== void 0 ? _a : "", error: errors.error.errors.broker &&
                        el.name in errors.error.errors.broker, shrinkLabel: true })));
        }))));
};
export default BrokerDocument;
