import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { helperFunctions } from "../../../../Helpers";
import { formatContactStatus, fullNameWithPrefixSuffix, } from "../../../../utils/utils";
var ContactInfoCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var contact = _a.contact;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12, style: { backgroundColor: "#F1F1F1", marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "Contact Info"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Name: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_b = fullNameWithPrefixSuffix(contact.full_name, contact.prefix, contact.suffix)) !== null && _b !== void 0 ? _b : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Contact ID: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_c = contact.id) !== null && _c !== void 0 ? _c : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Contact Status:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: {
                        fontWeight: "bold",
                        color: contact.status === "active"
                            ? "#3EBF3E"
                            : contact.status === "pending"
                                ? "#1871BF"
                                : contact.status === "deleted" ||
                                    contact.status === "cancelled"
                                    ? "#F1221C"
                                    : "#000",
                    } }, formatContactStatus(contact.status, contact.merged_to_id, !!contact.contact_leave_reason_id)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Contact DOB:"))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: {
                        fontWeight: "bold",
                        display: "inline",
                        backgroundColor: "rgb(246, 255, 0)",
                    } }, helperFunctions.formatDate(contact.birth_date)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Contact Age:"))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.getAge(contact.birth_date)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Contact Since:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.formatDate(contact.contact_since)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Exp. Date: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.formatDate(contact.contact_expiration_date)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Referral: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_d = contact.utm_campaign) !== null && _d !== void 0 ? _d : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Recurring Billing:"),
                    " ")),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, contact.recurring ? "YES" : "NO"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Welcome Call:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.formatDate((_e = contact.call_data_json) === null || _e === void 0 ? void 0 : _e.welcome_call_date)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Renewal Call:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.formatDate(contact.renewal_call_date)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Congressional District:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_f = contact.congressional_district) !== null && _f !== void 0 ? _f : ""))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { mt: 2, mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "Contact Info"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_g = contact.full_name) !== null && _g !== void 0 ? _g : "")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, contact.email && (React.createElement("a", { href: "mailto:" + contact.email }, contact.email)))))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_h = contact.phone) !== null && _h !== void 0 ? _h : "None")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_j = contact.phone_alt_1) !== null && _j !== void 0 ? _j : "None"))))));
};
export default ContactInfoCard;
