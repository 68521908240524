import React, { useState } from "react";
import { Button, Box, Modal, Dialog, DialogActions, DialogContent, DialogTitle, TextField, DialogContentText, } from "@mui/material";
import { router } from "@inertiajs/react";
import { Controller, useForm } from "react-hook-form";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
var LeadMerge = function (_a) {
    var id = _a.id, _b = _a.backgroundColor, backgroundColor = _b === void 0 ? "#6232a8" : _b;
    var _c = useForm(), control = _c.control, handleSubmit = _c.handleSubmit;
    var _d = useState(false), open = _d[0], setOpen = _d[1];
    var handleChange = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var submitUpdate = function (data) {
        setOpen(false);
        router.post('/merge/lead/store', data);
    };
    return (React.createElement(Box, null,
        React.createElement(Button, { variant: "contained", style: {
                backgroundColor: backgroundColor,
                color: "#fff",
            }, onClick: handleChange, fullWidth: true, size: "small" }, "Merge To Lead"),
        React.createElement(Modal, { open: open, onClose: handleClose },
            React.createElement(Dialog, { open: open, onClose: handleClose },
                React.createElement(DialogTitle, { id: "form-dialog-title" }, "Merge To Another Lead"),
                React.createElement(DialogContent, null,
                    React.createElement(DialogContentText, { id: "alert-dialog-description" }, "This action will give this lead a status of deleted, and copy all notes and documents to the lead entered in the field below."),
                    React.createElement(DialogContentText, { id: "alert-dialog-description" }, "Both leads must be of the same lead type and belong to the same member.")),
                React.createElement(DialogContent, null,
                    React.createElement(GenericControllerTextInput, { label: "Lead ID", type: "number", rules: { required: true, min: 0 }, control: control, name: "to_id", defaultValue: "" }),
                    React.createElement(Controller, { control: control, name: "from_id", defaultValue: id, render: function (_a) {
                            var field = _a.field;
                            return React.createElement(TextField, { hidden: true });
                        } }),
                    React.createElement(Controller, { control: control, name: "route", defaultValue: window.location.href, render: function (_a) {
                            var field = _a.field;
                            return React.createElement(TextField, { hidden: true });
                        } })),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitUpdate), color: "primary" }, "Merge"))))));
};
export default LeadMerge;
