import { router } from "@inertiajs/react";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/";
// import ExportButton from "../components/ExportButton";
import MonthlyTotalsTable from "./MonthlyTotalsTable";
function MonthlyTotals(_a) {
    var selected_year = _a.selected_year, policies_by_month = _a.policies_by_month, formData = _a.formData, department = _a.department, csrf = _a.csrf;
    var today = new Date();
    var _b = useState(today.getFullYear()), year = _b[0], setYear = _b[1];
    var _c = useState(false), ready = _c[0], setReady = _c[1];
    var handleSubmit = function () {
        router.post("/production-boards/d/".concat(department, "/b/monthly-totals"), {
            selected_year: year,
        });
    };
    useEffect(function () {
        handleSubmit();
        setReady(true);
    }, [year, ready]);
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 3 },
        React.createElement(Grid, { xs: 8 }),
        React.createElement(Grid, { xs: 1 }),
        React.createElement(Grid, { xs: 9 }, policies_by_month && (React.createElement(MonthlyTotalsTable, { policies_by_month: policies_by_month, year: year, setYear: setYear, department: department })))));
}
export default MonthlyTotals;
