var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericAutoComplete from "../../../components/GenericAutoComplete";
import { usePage } from "@inertiajs/react";
import styled from "styled-components";
import { agentFilter } from "../../../utils/agentUtils";
import ConditionalWrapper from "../../../components/ConditionalWrapper";
var AgentInformation = function (props) {
    var _a, _b, _c, _d;
    var control = props.control, errors = props.errors, agents = props.agents, app = props.app, deptType = props.deptType;
    var auth = usePage().props.auth;
    var msr_lead = (auth === null || auth === void 0 ? void 0 : auth.roles.some(function (role) { return role.name == "msr"; })) &&
        (app === null || app === void 0 ? void 0 : app.formType) == "lead" &&
        !app.transfer_user_id;
    var agentsInDepartment = auth.user.userable_type == "App\\Models\\AbsBroker"
        ? agents
        : msr_lead
            ? __spreadArray([
                { id: "", name: "Lead Admin" }
            ], agentFilter(agents, deptType, (_a = app.transfer_user_id) !== null && _a !== void 0 ? _a : 0, auth.user.id), true) : agentFilter(agents, deptType, (_b = app.transfer_user_id) !== null && _b !== void 0 ? _b : 0, auth.user.id);
    var transferTo = msr_lead
        ? { id: "", name: "Lead Admin" }
        : (_c = agents.filter(function (el) { return el.id == app.transfer_user_id; }).shift()) !== null && _c !== void 0 ? _c : agents.filter(function (el) { var _a; return el.id == ((_a = auth === null || auth === void 0 ? void 0 : auth.user) === null || _a === void 0 ? void 0 : _a.id); }).shift();
    var assistedBy = agents.filter(function (el) { return el.id == app.assisted_by; }).shift() || null;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(ConditionalWrapper, { condition: app != "task" ? true : false, wrapper: function (children) { return (React.createElement(Grid, { component: Paper, xs: 12 }, children)); } },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Agent Information")),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(TransferAgentMessage, null,
                        "*Transfer agent defaults to ",
                        msr_lead
                            ? "this department's Lead admin."
                            : (_d = auth === null || auth === void 0 ? void 0 : auth.user) === null || _d === void 0 ? void 0 : _d.name)),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericAutoComplete, { error: "transfer_user_id" in errors, label: "Transfer Agent", name: "transfer_user_id", limitTags: 1, control: control, options: agentsInDepartment, rules: {
                            required: false,
                        }, value: transferTo, multiple: false })),
                app.formType !== "policy" && app != "task" && (React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericAutoComplete, { limitTags: 1, control: control, options: agentsInDepartment, rules: {
                            required: false,
                        }, multiple: false, error: "assisted_by" in errors, label: "Assisted By", value: assistedBy, name: "assisted_by" })))))));
};
var TransferAgentMessage = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: -10px 0 -5px 0;\n"], ["\n    margin: -10px 0 -5px 0;\n"])));
export default AgentInformation;
var templateObject_1;
