import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import React from "react";
import { UtmAutoComplete } from "../../../components/UtmAutoComplete";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
export var LeadReferral = function (_a) {
    var _b, _c;
    var data = _a.data, control = _a.control, referrals = _a.referrals, watch = _a.watch, errors = _a.errors;
    var selectedRefferal = (_b = referrals.find(function (el) { return el.referral_id == data.referral_id; })) !== null && _b !== void 0 ? _b : null;
    var watchReferral = watch("referral", selectedRefferal);
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
        React.createElement(Grid, { container: true, xs: 12, component: Paper, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "What prompted you to call AMAC today?")),
            React.createElement(Grid, { xs: 12, md: 6, xl: 4 },
                React.createElement(UtmAutoComplete, { limitTags: 1, name: "referral", control: control, label: "What prompted you to call AMAC today?", options: referrals, rules: {
                        required: data.id ? false : true,
                    }, error: "referral" in errors, value: watchReferral, multiple: false })),
            watchReferral && (React.createElement(GenericControllerTextInput, { hidden: true, label: "referral_id", control: control, name: "referral_id", defaultValue: (_c = watchReferral === null || watchReferral === void 0 ? void 0 : watchReferral.referral_id) !== null && _c !== void 0 ? _c : "" })))));
};
