import React, { useState } from "react";
import { Button, Modal, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, ListItemText, Typography, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { router } from "@inertiajs/react";
import { useFieldArray, useForm } from "react-hook-form";
import GenericControllerTextInput from "./GenericControllerTextInput";
import { format_date } from "../utils/dateUtils";
import { planTypes, modalities } from "../constants/Rosemark";
import GenericControllerSelect from "./GenericControllerSelect";
var ModalQuote = function (_a) {
    var lead_id = _a.lead_id;
    var _b = useForm({
        defaultValues: {
            quoteType: "",
            quotes: [
                {
                    insurance_type: "",
                    death_benefit: "",
                    modality: "",
                    premium: "",
                },
            ],
        },
        mode: "onBlur",
    }), handleSubmit = _b.handleSubmit, control = _b.control, errors = _b.formState.errors;
    var _c = useFieldArray({
        name: "quotes",
        control: control,
    }), fields = _c.fields, append = _c.append, remove = _c.remove;
    var quoteTypes = [
        { name: "Quick Quote", id: "quick_quote" },
        { name: "Quote/Info", id: "quote_info" },
    ];
    var _d = useState(false), open = _d[0], setOpen = _d[1];
    var handleChange = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var submitUpdate = function (data) {
        setOpen(false);
        var postData = data;
        postData.lead_id = lead_id;
        postData.quick_quote_sent = format_date(new Date());
        router.post("/lead/quote", postData);
    };
    var find_errors = function (errors, index, fieldName) {
        var _a;
        var errors_location = (_a = errors === null || errors === void 0 ? void 0 : errors.quotes) === null || _a === void 0 ? void 0 : _a[index];
        if (!errors_location) {
            return false;
        }
        var result = Object.keys(errors).includes("quotes")
            ? fieldName in errors_location
            : false;
        return result;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "contained", style: {
                backgroundColor: "#33B496",
                color: "#FFF",
            }, onClick: handleChange, fullWidth: true, size: "small" }, "Send Quote"),
        React.createElement(Modal, { open: open, onClose: handleClose },
            React.createElement(Dialog, { open: open, maxWidth: "lg", onClose: handleClose },
                React.createElement(DialogTitle, { id: "form-dialog-title" },
                    React.createElement(Grid, { my: 1, xs: 12, container: true, spacing: 2 },
                        React.createElement(Grid, { xs: true },
                            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Send Quote")),
                        React.createElement(Grid, { xs: 4, container: true, alignItems: "right" },
                            React.createElement(GenericControllerSelect, { control: control, label: "Quote Type", name: "quoteType", rules: { required: true }, defaultValue: "", customOptions: quoteTypes.map(function (option, idx) { return (React.createElement(MenuItem, { key: "".concat(name, "_").concat(idx), value: option.id },
                                    React.createElement(ListItemText, { primary: option.name }))); }), customSelectSx: {
                                    height: "56px",
                                    maxHeight: "56px",
                                    minHeight: "56px",
                                    width: 220,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                }, error: "quoteType" in errors })))),
                React.createElement(DialogContent, null,
                    fields.map(function (field, index) {
                        var _a, _b, _c, _d;
                        return (React.createElement(Grid, { xs: 12, container: true, spacing: 2, className: "section", key: field.id },
                            React.createElement(Grid, { xs: 3 },
                                React.createElement(GenericControllerSelect, { control: control, label: "Insurance Type", name: "quotes[".concat(index, "].insurance_type"), rules: { required: true }, defaultValue: field.insurance_type, customOptions: planTypes.map(function (option, idx) { return (React.createElement(MenuItem, { key: "".concat(name, "_").concat(idx), value: option.id },
                                        React.createElement(ListItemText, { primary: option.name }))); }), customSelectSx: {
                                        height: "56px",
                                        maxHeight: "56px",
                                        minHeight: "56px",
                                        width: 220,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    }, error: find_errors(errors, index, "insurance_type") })),
                            React.createElement(Grid, { xs: 3 },
                                React.createElement(GenericControllerTextInput, { control: control, type: "number", label: "Death Benefit", name: "quotes[".concat(index, "].death_benefit"), rules: { required: true, min: 0 }, defaultValue: field.death_benefit, error: find_errors(errors, index, "death_benefit"), className: ((_b = (_a = errors === null || errors === void 0 ? void 0 : errors.quotes) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.death_benefit)
                                        ? "error"
                                        : "" })),
                            React.createElement(Grid, { xs: 3 },
                                React.createElement(GenericControllerSelect, { control: control, label: "Modality", name: "quotes[".concat(index, "].modality"), rules: { required: true }, defaultValue: field.modality, customOptions: modalities.map(function (option, idx) { return (React.createElement(MenuItem, { key: "".concat(name, "_").concat(idx), value: option.id },
                                        React.createElement(ListItemText, { primary: option.name }))); }), customSelectSx: {
                                        height: "56px",
                                        maxHeight: "56px",
                                        minHeight: "56px",
                                        width: 220,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    }, error: find_errors(errors, index, "modality") })),
                            React.createElement(Grid, { xs: 3 },
                                React.createElement(GenericControllerTextInput, { control: control, type: "number", label: "Premium", name: "quotes[".concat(index, "].premium"), rules: { required: true, min: 0 }, defaultValue: field.premium, error: find_errors(errors, index, "premium"), className: ((_d = (_c = errors === null || errors === void 0 ? void 0 : errors.quotes) === null || _c === void 0 ? void 0 : _c[index]) === null || _d === void 0 ? void 0 : _d.premium)
                                        ? "error"
                                        : "" })),
                            React.createElement(Button, { onClick: function () { return remove(index); }, color: "error" }, "Remove Row")));
                    }),
                    React.createElement(Grid, { xs: true, mt: 1 },
                        React.createElement(Button, { onClick: function () {
                                return append({
                                    insurance_type: "",
                                    death_benefit: "",
                                    modality: "",
                                    premium: "",
                                });
                            }, color: "primary" }, "Add row"))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitUpdate), color: "primary" }, "Send"))))));
};
export default ModalQuote;
