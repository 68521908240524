import { TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { validatePhone } from "../utils/utils";
var GenericPhoneInput = function (_a) {
    var name = _a.name, label = _a.label, defaultValue = _a.defaultValue, required = _a.required, control = _a.control, helperText = _a.helperText;
    return (React.createElement(Controller, { control: control, name: name, rules: {
            required: required,
            validate: function (value) { return validatePhone(value); },
        }, defaultValue: defaultValue, render: function (_a) {
            var _b = _a.field, value = _b.value, name = _b.name, ref = _b.ref, onChange = _b.onChange, invalid = _a.fieldState.invalid;
            return (React.createElement(NumberFormat, { name: name, label: label, error: invalid, inputRef: ref, fullWidth: true, helperText: invalid ? helperText : "", defaultValue: value, mask: "#", format: "(###) ###-####", onValueChange: function (values) { return onChange(values.value); }, customInput: TextField, variant: "outlined" }));
        } }));
};
export default GenericPhoneInput;
