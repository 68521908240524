import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { helperFunctions } from "../../../../Helpers";
import { formatAmacAccountStatus, fullNameWithPrefixSuffix, } from "../../../../utils/utils";
var AmacAccountInfoCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var amac_account = _a.amac_account;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12, style: { backgroundColor: "#F1F1F1", marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "AmacAccount Info"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Name: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_b = fullNameWithPrefixSuffix(amac_account.full_name, amac_account.prefix, amac_account.suffix)) !== null && _b !== void 0 ? _b : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "AmacAccount ID: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_c = amac_account.id) !== null && _c !== void 0 ? _c : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "AmacAccount Status:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: {
                        fontWeight: "bold",
                        color: amac_account.status === "active"
                            ? "#3EBF3E"
                            : amac_account.status === "pending"
                                ? "#1871BF"
                                : amac_account.status === "deleted" ||
                                    amac_account.status === "cancelled"
                                    ? "#F1221C"
                                    : "#000",
                    } }, formatAmacAccountStatus(amac_account.status, amac_account.merged_to_id, !!amac_account.amac_account_leave_reason_id)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "AmacAccount DOB:"))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: {
                        fontWeight: "bold",
                        display: "inline",
                        backgroundColor: "rgb(246, 255, 0)",
                    } }, helperFunctions.formatDate(amac_account.birth_date)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "AmacAccount Age:"))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.getAge(amac_account.birth_date)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "AmacAccount Since:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.formatDate(amac_account.amac_account_since)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Exp. Date: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.formatDate(amac_account.amac_account_expiration_date)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Referral: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_d = amac_account.utm_campaign) !== null && _d !== void 0 ? _d : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Recurring Billing:"),
                    " ")),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, amac_account.recurring ? "YES" : "NO"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Welcome Call:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.formatDate((_e = amac_account.call_data_json) === null || _e === void 0 ? void 0 : _e.welcome_call_date)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Renewal Call:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.formatDate(amac_account.renewal_call_date)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Congressional District:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_f = amac_account.congressional_district) !== null && _f !== void 0 ? _f : ""))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { mt: 2, mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "Contact Info"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_g = amac_account.full_name) !== null && _g !== void 0 ? _g : "")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, amac_account.email && (React.createElement("a", { href: "mailto:" + amac_account.email }, amac_account.email)))))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_h = amac_account.phone) !== null && _h !== void 0 ? _h : "None")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_j = amac_account.phone_alt_1) !== null && _j !== void 0 ? _j : "None"))))));
};
export default AmacAccountInfoCard;
