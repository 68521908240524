import React, { useState } from "react";
import { Button, Modal, Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";
import { router } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import { now } from "lodash";
import GenericControllerTextInput from "./GenericControllerTextInput";
var ModalFollowUpDate = function (_a) {
    var current_follow_up_date = _a.current_follow_up_date, model = _a.model, model_id = _a.model_id;
    var _b = useForm(), handleSubmit = _b.handleSubmit, control = _b.control, formErrors = _b.formState.errors;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var handleChange = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var submitUpdate = function (data) {
        setOpen(false);
        data.date = data.follow_up_date;
        data.model = model;
        data.model_id = model_id;
        router.post("/follow_up_date/update", data);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "contained", style: {
                backgroundColor: "#33B496",
                color: "#FFF",
            }, onClick: handleChange, fullWidth: true, size: "small" }, "Change Follow Up Date"),
        React.createElement(Modal, { open: open, onClose: handleClose },
            React.createElement(Dialog, { open: open, onClose: handleClose },
                React.createElement(DialogTitle, { id: "form-dialog-title" }, "Follow Up Date"),
                React.createElement(DialogContent, null,
                    React.createElement(GenericControllerTextInput, { label: "Change Follow Up Date", type: "date", control: control, name: "follow_up_date", defaultValue: current_follow_up_date !== null && current_follow_up_date !== void 0 ? current_follow_up_date : now(), error: "follow_up_date" in formErrors, shrinkLabel: true, rules: {
                            max: "9999-12-31",
                        }, fullWidth: true })),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitUpdate), color: "primary" }, "Change"))))));
};
export default ModalFollowUpDate;
