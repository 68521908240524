import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, usePage } from "@inertiajs/react";
import route from "ziggy-js";
import { hasPermission } from "../../../utils/utils";
import AddTaskButton from "../../Task/AddTaskButton";
var MemberActivityLog = function (_a) {
    var _b, _c;
    var member = _a.member, activity = _a.activity;
    var auth = usePage().props.auth;
    var donation_date = new Date(activity === null || activity === void 0 ? void 0 : activity.created_at)
        .toLocaleDateString("en-US", {
        timeZone: "UTC",
    })
        .replace(/\//g, "-");
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "10px #E21D37 solid",
            borderRight: "2px #E21D37 solid",
            borderBottom: "2px #E21D37 solid",
            borderTop: "2px #E21D37 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 3 },
            React.createElement(Box, { my: 2, mx: 1 },
                React.createElement(Link, { href: (activity === null || activity === void 0 ? void 0 : activity.subject_id)
                        ? route("show_member", {
                            id: activity === null || activity === void 0 ? void 0 : activity.subject_id,
                        })
                        : "#", style: {
                        color: "#000",
                    } },
                    React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, member.member_spouse
                        ? "".concat((_b = member === null || member === void 0 ? void 0 : member.spouse_full_name) !== null && _b !== void 0 ? _b : "N/A", " (Spouse)")
                        : "".concat((_c = member === null || member === void 0 ? void 0 : member.full_name) !== null && _c !== void 0 ? _c : "N/A", " (Member)"))),
                React.createElement(Typography, { variant: "subtitle1" },
                    "Activity id: ", activity === null || activity === void 0 ? void 0 :
                    activity.id),
                React.createElement(Typography, { variant: "subtitle1", style: { color: "#9e9e9e" } },
                    "#", activity === null || activity === void 0 ? void 0 :
                    activity.subject_id))),
        React.createElement(Grid, { xs: 3 },
            React.createElement(Box, { my: 2, mx: 1 },
                React.createElement(Typography, { variant: "body2" },
                    "Amount:",
                    " ",
                    React.createElement("strong", null, "Test")),
                React.createElement(Typography, { variant: "body2" },
                    "Origin:",
                    React.createElement("strong", null,
                        " ", activity === null || activity === void 0 ? void 0 :
                        activity.event)),
                React.createElement(Typography, { variant: "body2" },
                    "Status:",
                    React.createElement("strong", null,
                        " ", activity === null || activity === void 0 ? void 0 :
                        activity.properties)),
                React.createElement(Typography, { variant: "body2" },
                    "Created at:",
                    React.createElement("strong", null,
                        " ", activity === null || activity === void 0 ? void 0 :
                        activity.created_at)))),
        React.createElement(Grid, { xs: 3 },
            hasPermission(auth, "edit donations") && (React.createElement(Box, { m: 1 },
                React.createElement(Link, { href: route("edit_donation", [activity.id]), as: "span" },
                    React.createElement(Button, { variant: "contained", fullWidth: true, size: "small", style: {
                            backgroundColor: "#df880e",
                            color: "#FFF",
                        } }, "Edit Activity (???)")))),
            React.createElement(Box, { m: 1 },
                React.createElement(AddTaskButton, { taskable_type: "Donation", taskable_id: activity.id, variant: "contained", color: "primary", fullWidth: true, size: "small" })))));
};
export default MemberActivityLog;
