import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import React, { useState } from "react";
import { useForm } from "@inertiajs/react";
import { useForm as useFormReact } from "react-hook-form";
import GenericControllerTextInput from "./GenericFormFields/TextInput";
var AccountFileUploadForm = function (_a) {
    var accountId = _a.accountId, userId = _a.userId, name = _a.name, id = _a.id;
    var _b = useState(false), addFile = _b[0], setAddFile = _b[1];
    var control = useFormReact().control;
    var _c = useForm({
        file: "",
        id: id,
        name: name,
        account_id: accountId,
        route: window.location.href,
        user_id: userId,
    }), data = _c.data, setData = _c.setData, post = _c.post, reset = _c.reset;
    var handleFormSubmit = function () {
        //cannot use route for file upload or currently not working
        post("/file/upload", {
            preserveScroll: true,
            onSuccess: function () {
                reset();
                setAddFile(false);
            },
        });
    };
    return (React.createElement(React.Fragment, null,
        !addFile && (React.createElement(Grid, { xs: 12 },
            React.createElement(Box, { my: 1 },
                React.createElement(Button, { type: "button", onClick: function () {
                        setAddFile(!addFile);
                    }, variant: "outlined", color: "primary", fullWidth: true, size: "small" }, "Add Document")))),
        addFile && (React.createElement("form", { encType: "multipart/form-data" },
            React.createElement(Grid, { container: true, spacing: 2, justifyContent: "center", my: 1 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(GenericControllerTextInput, { control: control, type: "file", name: "file", label: "", isFileUpload: true, defaultValue: "", rules: { required: true }, customOnChange: function (e) {
                            setData("file", e.target.files[0]);
                        } })),
                React.createElement(Grid, { xs: 4 },
                    React.createElement(Button, { type: "button", onClick: function () { return setAddFile(!addFile); }, variant: "contained", color: "secondary", fullWidth: true, size: "small" }, "Cancel")),
                React.createElement(Grid, { xs: 4 },
                    React.createElement(Button, { type: "button", onClick: handleFormSubmit, variant: "contained", color: "primary", fullWidth: true, size: "small" }, "Upload")))))));
};
export default AccountFileUploadForm;
