var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Button from "@mui/material/Button";
import React from "react";
import styled from "styled-components";
var DefaultBtn = function (_a) {
    var textColor = _a.textColor, backgroundColor = _a.backgroundColor, children = _a.children, onClick = _a.onClick, attrs = __rest(_a, ["textColor", "backgroundColor", "children", "onClick"]);
    return (React.createElement(StyledButton, __assign({ textcolor: textColor, backgroundcolor: backgroundColor, onClick: onClick }, attrs), children));
};
var StyledButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-size: ", ";\n    font-family: \"Source Sans Pro\", sans-serif;\n    font-weight: bold;\n    letter-spacing: 1px;\n    width: ", ";\n    height: ", ";\n    text-transform: unset;\n    border-radius: ", ";\n    color: ", ";\n    background: ", ";\n    display: flex;\n    align-items: center;\n    text-align: center;\n    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n        opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n\n    &:hover {\n        background: ", ";\n        opacity: ", ";\n    }\n\n    svg {\n        font-size: 26px !important;\n        margin-bottom: ", ";\n    }\n"], ["\n    font-size: ", ";\n    font-family: \"Source Sans Pro\", sans-serif;\n    font-weight: bold;\n    letter-spacing: 1px;\n    width: ", ";\n    height: ", ";\n    text-transform: unset;\n    border-radius: ", ";\n    color: ", ";\n    background: ", ";\n    display: flex;\n    align-items: center;\n    text-align: center;\n    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n        opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n\n    &:hover {\n        background: ", ";\n        opacity: ", ";\n    }\n\n    svg {\n        font-size: 26px !important;\n        margin-bottom: ", ";\n    }\n"])), function (_a) {
    var size = _a.size;
    return (size === "small" ? "16px" : "23px");
}, function (props) { return (props.width ? props.width : null); }, function (props) { return (props.height ? props.height : null); }, function (props) {
    return props.squared ? 0 : props.circle ? "50%" : null;
}, function (props) { return (props.textcolor ? props.textcolor : null); }, function (props) {
    return props.backgroundcolor ? props.backgroundcolor : null;
}, function (_a) {
    var backgroundcolor = _a.backgroundcolor;
    return backgroundcolor ? backgroundcolor : null;
}, function (_a) {
    var backgroundcolor = _a.backgroundcolor;
    return backgroundcolor ? 0.8 : null;
}, function (_a) {
    var size = _a.size;
    return (size === "small" ? null : "-3px");
});
export default DefaultBtn;
var templateObject_1;
