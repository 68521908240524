import { Box, Grid, Paper, Snackbar, Typography, Link, } from "@mui/material";
import orderBy from "lodash/orderBy";
import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import AccountContactsCard from "./components/Account/AccountContactsCard";
import AccountAddressesCard from "./components/Account/AccountAddressesCard";
import AccountAmacCard from "./components/Account/AccountAmacCard";
import AccountNoteCard from "./components/Account/AccountNoteCard";
import { Alert } from "@mui/material";
import { usePage } from "@inertiajs/react";
import { ErrorOutline } from "@mui/icons-material";
import { AccountNavWrapper } from "./components/Navigation/AccountNavWrapper";
import AccountHeader from "./components/AccountHeader";
var AccountMain = function (_a) {
    var response_data = _a.response_data, flash = _a.flash, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState(false), openMessage = _c[0], setOpenMessage = _c[1];
    var auth = usePage().props.auth;
    useEffect(function () {
        if (flash.message) {
            setOpenMessage(true);
        }
    }, [flash]);
    var account = response_data.account, 
    // account_statuses,
    leave_reasons = response_data.leave_reasons, states = response_data.states, 
    // all_documents,
    activity_log = response_data.activity_log;
    account.orders = orderBy(account === null || account === void 0 ? void 0 : account.orders, ["created_at"], "desc");
    return (React.createElement(Layout, { title: "Account Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(AccountNavWrapper, { account: account, 
                    // all_documents={all_documents}
                    activity_log: activity_log }),
                openMessage && (React.createElement(Box, { mb: 1 },
                    React.createElement(Snackbar, { open: openMessage, anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        }, autoHideDuration: 3000, onClose: function () { return setOpenMessage(false); } },
                        React.createElement(Alert, { onClose: function () { return setOpenMessage(false); }, severity: "success" }, flash === null || flash === void 0 ? void 0 : flash.message)))))),
        !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { my: 2 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))))),
        React.createElement(Paper, { style: { minHeight: "80vh" } },
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { container: true, item: true, xs: 12 },
                    React.createElement(Grid, { item: true, xs: 12, md: 12 },
                        React.createElement(Box, { mx: 1, my: 1 },
                            React.createElement(AccountHeader, { account: account })))),
                account.merged_to_id && (React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(Alert, { variant: "filled", severity: "error", icon: React.createElement(ErrorOutline, { fontSize: "large" }) },
                            React.createElement(Typography, { variant: "h6" },
                                "This account was transferred to\u00A0",
                                React.createElement(Link, { href: "/account/show/" +
                                        account.merged_to_id, color: "inherit" },
                                    "#",
                                    account.merged_to_id)))))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(AccountContactsCard, { account: account }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(AccountAddressesCard, { account: account }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(AccountAmacCard, { account: account, leaveReasons: leave_reasons }))),
                React.createElement(Grid, { item: true, xs: 12, md: 12 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(AccountNoteCard, { account: account })))))));
};
export default AccountMain;
