import React, { useState } from "react";
import Layout from "../Layout";
import LeadTable from "../../components/LeadTable";
import AppTable from "../../components/AppTable";
import HomeMenu from "./HomeMenu";
import { maxBy } from "lodash";
export default function Urgent(_a) {
    var response_data = _a.response_data, auth = _a.auth;
    var urgent_health_apps = response_data.urgent_health_apps, urgent_financial_apps = response_data.urgent_financial_apps, urgent_life_apps = response_data.urgent_life_apps, urgent_leads = response_data.urgent_leads, lead_types = response_data.lead_types, insurance_carriers = response_data.insurance_carriers, agents = response_data.agents, user = response_data.user, states = response_data.states, dispositions = response_data.dispositions, app_dispositions = response_data.app_dispositions, totals = response_data.totals;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var dataWithMaxTotal = maxBy([
        urgent_financial_apps,
        urgent_leads,
        urgent_health_apps,
        urgent_life_apps,
    ], "total");
    return (React.createElement(Layout, { title: "Home", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(HomeMenu, { auth: auth, user: user, title: "Urgent", data: dataWithMaxTotal, totals: totals }),
        React.createElement(LeadTable, { data: urgent_leads.data, title: "Urgent Leads", lead_types: lead_types, agents: agents, states: states, dispositions: dispositions }),
        urgent_health_apps.data.length > 0 && (React.createElement(AppTable, { noteType: "health", title: "Urgent Health Apps", data: urgent_health_apps.data, leadTypes: lead_types, insuranceCarriers: insurance_carriers, numberOfTableColumns: 7, fileUploadDirectory: "health_insurance_applications", agents: agents, states: states, appType: "App\\Models\\HealthInsuranceApplication", dispositions: app_dispositions })),
        urgent_financial_apps.data.length > 0 && (React.createElement(AppTable, { noteType: "financial", title: "Urgent Financial Apps", data: urgent_financial_apps.data, leadTypes: lead_types, insuranceCarriers: insurance_carriers, numberOfTableColumns: 7, fileUploadDirectory: "financial_applications", agents: agents, states: states, appType: "App\\Models\\FinancialApplication", dispositions: app_dispositions })),
        urgent_life_apps.data.length > 0 && (React.createElement(AppTable, { noteType: "life", title: "Urgent Life Apps", data: urgent_life_apps.data, leadTypes: lead_types, insuranceCarriers: insurance_carriers, numberOfTableColumns: 7, fileUploadDirectory: "life_insurance_applications", agents: agents, states: states, appType: "App\\Models\\LifeInsuranceApplication", dispositions: app_dispositions }))));
}
