import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import React from "react";
import GenericNumberInput from "../../../components/GenericNumberInput";
import { contingentBeneficiaryOption, primaryBeneficiaryOption, } from "../../../utils/utils";
import Beneficiary from "./Beneficiary";
import CurrentLifeInsurance from "./CurrentLifeInsurance";
var LifeFinancialQuestions = function (props) {
    var _a, _b, _c, _d, _e;
    var errors = props.errors, control = props.control, app = props.app;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Financial Questions")),
            React.createElement(Beneficiary, { errors: errors, control: control, app: app, options: primaryBeneficiaryOption }),
            React.createElement(Beneficiary, { errors: errors, control: control, app: app, options: contingentBeneficiaryOption }),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericNumberInput, { label: "Binder Amount (1 month)", error: "binder_amount" in errors, rules: { required: false }, name: "binder_amount", defaultValue: (_a = app === null || app === void 0 ? void 0 : app.binder_amount) !== null && _a !== void 0 ? _a : null, prefix: "$", thousandSeparator: true, control: control })),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericNumberInput, { label: "Earned Income", error: "income" in errors, rules: { required: false }, name: "income", defaultValue: (_b = app === null || app === void 0 ? void 0 : app.income) !== null && _b !== void 0 ? _b : null, prefix: "$", thousandSeparator: true, control: control })),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericNumberInput, { label: "Total Assets", error: "assets" in errors, rules: { required: false }, name: "assets", defaultValue: (_c = app === null || app === void 0 ? void 0 : app.assets) !== null && _c !== void 0 ? _c : null, prefix: "$", thousandSeparator: true, control: control })),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericNumberInput, { label: "Total Liabilities", rules: { required: false }, error: "liability" in errors, name: "liability", defaultValue: (_d = app === null || app === void 0 ? void 0 : app.liability) !== null && _d !== void 0 ? _d : null, prefix: "$", thousandSeparator: true, control: control })),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericNumberInput, { label: "Net Worth", error: "net_worth" in errors, name: "net_worth", rules: { required: false }, defaultValue: (_e = app === null || app === void 0 ? void 0 : app.net_worth) !== null && _e !== void 0 ? _e : null, prefix: "$", thousandSeparator: true, control: control })),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(CurrentLifeInsurance, { errors: errors, control: control, app: app }))))));
};
export default LifeFinancialQuestions;
