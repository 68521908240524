import { Box, Button, Grid, Paper, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../../../components/DisplayErrors";
import ContactLead from "./ContactLead";
import { Alert } from "@mui/material";
import { BackToContact } from "../BackToContact";
import { Link } from "@inertiajs/react";
var ContactSingleLead = function (_a) {
    var _b, _c;
    var response_data = _a.response_data, flash = _a.flash, serverErrors = _a.errors;
    var _d = useState(true), menuOpen = _d[0], toggleMenuOpen = _d[1];
    var _e = useState(false), openMessage = _e[0], setOpenMessage = _e[1];
    var contact = response_data.contact, lead_types = response_data.lead_types, agents = response_data.agents, states = response_data.states, lead_id = response_data.lead_id, dispositions = response_data.dispositions;
    useEffect(function () {
        if (flash.message) {
            setOpenMessage(true);
        }
    }, [flash]);
    return (React.createElement(Layout, { title: "Contact Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { my: 2 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))))),
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 12 }, openMessage && (React.createElement(Box, { mb: 1 },
                React.createElement(Snackbar, { open: openMessage, anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    }, autoHideDuration: 3000, onClose: function () { return setOpenMessage(false); } },
                    React.createElement(Alert, { onClose: function () { return setOpenMessage(false); }, severity: "success" }, flash === null || flash === void 0 ? void 0 : flash.message))))),
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 2 },
                React.createElement(Grid, { item: true },
                    React.createElement(BackToContact, { contact: contact, hasCustomStyles: true })),
                React.createElement(Grid, { item: true },
                    React.createElement(Link, { href: "/lead/form/".concat(contact.id) },
                        React.createElement(Button, { variant: "contained", style: {
                                backgroundColor: "#286941",
                                color: "#FFF",
                            }, size: "small" }, "Add Lead")))),
            React.createElement(Grid, { item: true, xs: 12 }, (_c = (_b = contact === null || contact === void 0 ? void 0 : contact.leads) === null || _b === void 0 ? void 0 : _b.filter(function (el) { return el.id == lead_id; })) === null || _c === void 0 ? void 0 : _c.map(function (lead) { return (React.createElement(Box, { mb: 1, key: lead.id },
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "subtitle1" },
                        "Lead # ",
                        lead_id,
                        " ",
                        "   "),
                    React.createElement(Typography, { variant: "subtitle2", component: "span" }, "*Disclaimer: In order to promote a lead to an app, the disposition \"Application Submitted to Carrier\" must be used.")),
                React.createElement(Paper, null,
                    React.createElement(ContactLead, { contact: contact, lead: lead, leadTypes: lead_types, agents: agents, states: states, showDefault: true, dispositions: dispositions })))); })))));
};
export default ContactSingleLead;
