var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FormGroup, FormLabel, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import styled from "styled-components";
import GenericControllerCheckboxInput from "../../components/GenericFormFields/CheckboxInput";
var AmacAccountOptions = function (props) {
    var _a;
    var amac_account = props.amac_account, control = props.control;
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "AmacAccount Options")),
        React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
            React.createElement(Grid, { xs: 12, sm: 6 },
                React.createElement(FormLabel, { component: "legend" }, "Opt-in Lists"),
                React.createElement(CheckboxList, null,
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: amac_account.id
                            ? amac_account.emailing_list
                                ? true
                                : false
                            : true, name: "emailing_list", control: control, label: "Email List" }),
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: amac_account.id
                            ? amac_account.post_mail
                                ? true
                                : false
                            : true, name: "post_mail", control: control, label: "Residential Mailing List" }),
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: amac_account.id
                            ? amac_account.magazine
                                ? true
                                : false
                            : true, name: "magazine", control: control, label: "Magazine List" }),
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: amac_account.recurring ? true : false, name: "recurring", control: control, label: "Recurring Billing" }),
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: amac_account.daily_news ? true : false, name: "daily_news", control: control, label: "Daily News" }),
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: amac_account.sbs_newsletter ? true : false, name: "sbs_newsletter", control: control, label: "SBS Newsletter" }),
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: amac_account.text_notification ? true : false, name: "text_notification", control: control, label: "Text Notification" }))),
            (amac_account === null || amac_account === void 0 ? void 0 : amac_account.id) != null && (React.createElement(Grid, { xs: 12, sm: 6 },
                React.createElement(FormGroup, null,
                    React.createElement(FormLabel, { component: "legend" }, "Renewal Calls"),
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: amac_account.renewal_call_date ? true : false, name: "renewal_call_date", control: control, label: "Renewal call was made" }),
                    amac_account.renewal_call_date && (React.createElement(GenericControllerCheckboxInput, { defaultChecked: amac_account.update_renewal_call_date
                            ? true
                            : false, name: "update_renewal_call_date", control: control, label: "Update Renewal call was made" })),
                    React.createElement(GenericControllerCheckboxInput, { defaultChecked: ((_a = amac_account === null || amac_account === void 0 ? void 0 : amac_account.call_data_json) === null || _a === void 0 ? void 0 : _a.welcome_call_date)
                            ? true
                            : false, name: "welcome_call_date", control: control, label: "Welcome call was made" })))))));
};
var CheckboxList = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    list-style: none;\n    padding: 0;\n    margin-bottom: 0;\n    width: 100%;\n"], ["\n    list-style: none;\n    padding: 0;\n    margin-bottom: 0;\n    width: 100%;\n"])));
export default AmacAccountOptions;
var templateObject_1;
