import React from "react";
import { Typography } from "@mui/material";
import { Link } from "@inertiajs/react";
import route from "ziggy-js";
var AmacAccountLink = function (_a) {
    var amac_account_id = _a.amac_account_id, amac_account_name = _a.amac_account_name, amac_account_spouse_name = _a.amac_account_spouse_name, typography_variant = _a.typography_variant;
    return (React.createElement(Link, { href: route("show_amac_account", {
            id: amac_account_id,
        }), style: {
            color: "#000",
        } },
        React.createElement(Typography, { variant: typography_variant !== null && typography_variant !== void 0 ? typography_variant : "body2", style: { fontWeight: "bold" } }, amac_account_name
            ? amac_account_name + " (AmacAccount)"
            : amac_account_spouse_name
                ? amac_account_spouse_name + " (Spouse)"
                : amac_account_id)));
};
export default AmacAccountLink;
