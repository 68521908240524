import React, { useState } from "react";
import { MenuItem, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import ImmLead from "./ImmLead";
import GenericControllerDropdownInput from "../../../components/GenericControllerDropdownInput";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
import GenericRadioInput from "../../../components/GenericRadioInput";
import { snakeCase } from "lodash";
var MedicareLead = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var errors = _a.errors, control = _a.control, lead = _a.lead, medicareChangeReasons = _a.medicareChangeReasons, medicarePlans = _a.medicarePlans, insuranceTypes = _a.insuranceTypes, leadType = _a.leadType;
    var medicarePartA = ((_b = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _b === void 0 ? void 0 : _b.medicare_part_a) ? 1 : 0;
    var medicarePartB = ((_c = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _c === void 0 ? void 0 : _c.medicare_part_b) ? 1 : 0;
    var _h = useState((_e = (_d = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _d === void 0 ? void 0 : _d.insurance_product) !== null && _e !== void 0 ? _e : ""), insuranceProduct = _h[0], setInsuranceProduct = _h[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
            React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h5", component: "h3" }, "Medicare Insurance Info")),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericControllerDropdownInput, { label: "Insurance Product", name: "extra_data_json[insurance_product]", id: "insurance_product_select", rules: {
                            required: true,
                        }, control: control, defaultValue: insuranceProduct, handleChange: function (e) {
                            return setInsuranceProduct(e.target.value);
                        }, error: errors.extra_data_json
                            ? "insurance_product" in
                                errors.extra_data_json
                            : false, options: insuranceTypes
                            .sort(function (a, b) {
                            if (a.name < b.name) {
                                return -1;
                            }
                            if (a.name > b.name) {
                                return 1;
                            }
                            return 0;
                        })
                            .map(function (el) {
                            if (![1, 2, 3, 4, 7, 10].includes(el.id))
                                return null;
                            return (React.createElement(MenuItem, { value: el.name, key: "type".concat(snakeCase(el.name)) }, el.name));
                        }) })),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericControllerTextInput, { label: "Product Notes", multiline: true, type: "text", control: control, defaultValue: (_g = (_f = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _f === void 0 ? void 0 : _f.insurance_product_notes) !== null && _g !== void 0 ? _g : "", name: "extra_data_json[insurance_product_notes]", error: false })),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericRadioInput, { error: false, control: control, defaultValue: medicarePartA, legend: "Do you have Medicare Part A?", name: "extra_data_json[medicare_part_a]" })),
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericRadioInput, { error: false, control: control, defaultValue: medicarePartB, legend: "Do you have Medicare Part B?", name: "extra_data_json[medicare_part_b]" })))),
        React.createElement(ImmLead, { control: control, errors: errors, lead: lead, insuranceTypes: insuranceTypes, medicarePlans: medicarePlans, medicareChangeReasons: medicareChangeReasons, leadType: leadType })));
};
export default MedicareLead;
