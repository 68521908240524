import React, { useState } from "react";
import Layout from "../Layout";
export default function Details(_a) {
    var user = _a.user;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    return (React.createElement(Layout, { title: "User Details Page", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement("h2", null, "User Details"),
        React.createElement("p", null,
            "Name: ",
            user.first_name,
            " ",
            user.last_name,
            React.createElement("br", null),
            "Email: ",
            user.email)));
}
