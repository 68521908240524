import React, { useState } from "react";
import { Button, Modal, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Alert, Snackbar, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import GenericControllerDropdownInput from "../GenericFormFields/DropdownInput";
import axios from "axios";
import { GridCheckIcon } from "@mui/x-data-grid";
var ModalAccountRecurring = function (_a) {
    var current_setting = _a.current_setting, account_id = _a.account_id, _b = _a.size, size = _b === void 0 ? "small" : _b;
    var _c = useForm(), handleSubmit = _c.handleSubmit, control = _c.control, formErrors = _c.formState.errors;
    var _d = useState(false), open = _d[0], setOpen = _d[1];
    var _e = useState(false), showSnackBar = _e[0], setShowSnackBar = _e[1];
    var handleChange = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var submitUpdate = function (data) {
        setOpen(false);
        var newValue = data.account.recurring ? "yes" : "no";
        var oldValue = current_setting ? "yes" : "no";
        axios
            .post("/account/update/" + account_id, data)
            .then(function () {
            setShowSnackBar(true);
            axios.post("/account/log-action/" + account_id, {
                action: "update-recurring",
                text: "Updated Recurring from " +
                    oldValue +
                    " to " +
                    newValue,
            });
        })
            .catch(function () {
            console.error("Error updating auto-renewal setting.");
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "text", onClick: handleChange, fullWidth: true, style: {
                fontSize: '11px',
                textTransform: 'none',
                fontWeight: "400",
                textDecoration: 'underline'
            }, size: "small" }, "Update Auto Renewal"),
        React.createElement(Modal, { open: open, onClose: handleClose },
            React.createElement(Dialog, { open: open, onClose: handleClose },
                React.createElement(DialogTitle, null, "Change Auto-Renewal Setting"),
                React.createElement(DialogContent, { style: { minWidth: "500px" } },
                    React.createElement(Grid, { container: true, xs: 12, spacing: 1 },
                        React.createElement(GenericControllerDropdownInput, { formControlSX: { my: 1 }, name: "account[recurring]", control: control, label: "Automatic Renewal", id: "recurring_billing_setting", defaultValue: current_setting ? 1 : 0, handleChange: handleChange, rules: { required: true }, options: [
                                React.createElement(MenuItem, { key: "recur-true", value: 1 }, "Yes"),
                                React.createElement(MenuItem, { key: "recur-false", value: 0 }, "No"),
                            ] }))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary", size: "small" }, "Cancel"),
                    React.createElement(Button, { onClick: handleSubmit(submitUpdate), color: "primary" }, "Change")))),
        React.createElement(Snackbar, { open: showSnackBar, anchorOrigin: { vertical: "top", horizontal: "center" }, autoHideDuration: 5000, message: "Auto Renewal Updated", onClose: function () {
                setShowSnackBar(false);
            } },
            React.createElement(Alert, { icon: React.createElement(GridCheckIcon, { fontSize: "inherit" }), severity: "success" }, "The Amac Account's Auto Renewal Setting was updated"))));
};
export default ModalAccountRecurring;
