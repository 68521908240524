import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import NumberFormat from "react-number-format";
import { getStateById } from "../../../utils/utils";
import { helperFunctions } from "../../../Helpers";
var LeadMemberInfo = function (_a) {
    var _b, _c;
    var member = _a.member, spouseLead = _a.spouseLead, states = _a.states;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2, component: Paper },
        React.createElement(Grid, { xs: 12, md: 4 },
            React.createElement(Box, { mb: 2 },
                React.createElement(Typography, { variant: "h6" }, "".concat(spouseLead ? "Spouse/Family Member" : "Member", " Information"))),
            "ID / Name:",
            " ",
            React.createElement("strong", null,
                member.id,
                " ",
                " - ",
                spouseLead ? member.spouse_full_name : member.full_name),
            React.createElement("br", null),
            "DOB:",
            " ",
            React.createElement("strong", null, spouseLead
                ? member === null || member === void 0 ? void 0 : member.spouse_birth_date
                : member === null || member === void 0 ? void 0 : member.birth_date),
            React.createElement("br", null),
            "Age:",
            " ",
            React.createElement("strong", null, spouseLead
                ? helperFunctions.getAge(member === null || member === void 0 ? void 0 : member.spouse_birth_date)
                : helperFunctions.getAge(member === null || member === void 0 ? void 0 : member.birth_date)),
            React.createElement("br", null),
            "Email:",
            " ",
            React.createElement("strong", null, spouseLead ? member.spouse_email : member.email),
            React.createElement("br", null),
            "Phone:",
            " ",
            React.createElement(NumberFormat, { value: spouseLead ? member.spouse_phone : member.phone, displayType: "text", format: "(###) ###-####", renderText: function (value) { return React.createElement("strong", null, value); } })),
        React.createElement(Grid, { xs: 12, md: 4 },
            React.createElement(Box, { mb: 2 },
                React.createElement(Typography, { variant: "h6" }, "Mailing Address")),
            React.createElement(Typography, { variant: "body2" },
                React.createElement("span", null, member.ship_address_1),
                React.createElement("br", null),
                React.createElement("span", null, member.ship_address_2 ? member.ship_address_2 : ""),
                member.ship_address_2 ? React.createElement("br", null) : "",
                React.createElement("span", null,
                    (member === null || member === void 0 ? void 0 : member.ship_city) ? member.res_city + "," : "",
                    React.createElement("strong", null, " ".concat((_b = getStateById(member.ship_state_id, states)) === null || _b === void 0 ? void 0 : _b.name, " ")),
                    member.ship_zip))),
        React.createElement(Grid, { xs: 12, md: 4 },
            React.createElement(Box, { mb: 2 },
                React.createElement(Typography, { variant: "h6" }, "Residential Address")),
            React.createElement(Typography, { variant: "body2" },
                React.createElement("span", null, member.res_address_1),
                React.createElement("br", null),
                React.createElement("span", null, member.res_address_2 ? member.res_address_2 : ""),
                member.res_address_2 ? React.createElement("br", null) : "",
                React.createElement("span", null,
                    (member === null || member === void 0 ? void 0 : member.res_city) ? member.res_city + "," : "",
                    React.createElement("strong", null, " ".concat((_c = getStateById(member.res_state_id, states)) === null || _c === void 0 ? void 0 : _c.name, " ")),
                    member.res_zip)))));
};
export default LeadMemberInfo;
