import { Link, usePage } from "@inertiajs/react";
import { Button, Grid } from "@mui/material";
import React from "react";
var UtmAdminNav = function (_a) {
    var objective_count = _a.objective_count, source_count = _a.source_count;
    var url = usePage().url;
    var auth = usePage().props.auth;
    var member = {
        id: 1
    };
    return (React.createElement(Grid, { container: true, spacing: 1, justifyContent: "center", alignContent: "center" },
        React.createElement(Grid, { item: true },
            React.createElement(Link, { href: "/utm-builder-admin/objectives", style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { type: "button", style: {
                        backgroundColor: url.includes("utm-builder-admin/objectives")
                            ? "#E21D37"
                            : "#2C3137",
                        color: "#FFF",
                        textDecoration: "none",
                        borderRadius: "0 0px 3px 3px",
                        top: "-5px",
                    }, variant: "contained" },
                    "UTM Objectives",
                    React.createElement("span", { style: {
                            marginLeft: "3px",
                            backgroundColor: "#FFF",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                        } },
                        React.createElement("span", { style: {
                                color: "#000",
                            } }, objective_count))))),
        React.createElement(Grid, { item: true },
            React.createElement(Link, { href: "/utm-builder-admin/sources", style: {
                    textDecoration: "none",
                } },
                React.createElement(Button, { variant: "contained", style: {
                        backgroundColor: (url.includes("utm-builder-admin/sources") ||
                            url.includes("utm-builder-admin/campaigns") ||
                            url.includes("utm-builder-admin/mediums"))
                            ? "#E21D37"
                            : "#2C3137",
                        color: "#FFF",
                        textDecoration: "none",
                        borderRadius: "0 0px 3px 3px",
                        top: "-5px",
                    } },
                    "UTM Sources",
                    React.createElement("span", { style: {
                            marginLeft: "3px",
                            backgroundColor: "#FFF",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                        } },
                        React.createElement("span", { style: {
                                color: "#000",
                            } }, source_count)))))));
};
export default UtmAdminNav;
