import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import NumberFormat from "react-number-format";
import { getStateById } from "../utils/utils";
import { helperFunctions } from "../Helpers";
import AccountLink from "./AccountLink";
import AccountStatusIcon from "./AccountStatusIcon";
import AccountNav from "../Pages/Account/components/Navigation/AccountNav";
var FormAccountInfo = function (_a) {
    var _b, _c;
    var account = _a.account, spouseInfo = _a.spouseInfo, _d = _a.useAccountNav, useAccountNav = _d === void 0 ? false : _d, states = _a.states;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2, component: Paper, sx: {
            borderLeft: "10px #035399 solid",
            borderRight: "2px #035399 solid",
            borderBottom: "2px #035399 solid",
            borderTop: "2px #035399 solid",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 12, md: 0.5, container: true, alignItems: "center", justifyContent: "right" },
            React.createElement(AccountStatusIcon, { status: account.status })),
        React.createElement(Grid, { container: true, xs: 12, md: 11.5 },
            React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(AccountLink, { account_id: account === null || account === void 0 ? void 0 : account.id, account_name: spouseInfo ? undefined : account.full_name, account_spouse_name: spouseInfo ? account.spouse_full_name : undefined, typography_variant: "h6" }),
                React.createElement(Typography, { variant: "body2" }, "# ".concat(account.id)),
                React.createElement(Typography, { variant: "body2" },
                    "DOB:",
                    " ",
                    React.createElement("strong", null, spouseInfo
                        ? helperFunctions.formatDate(account === null || account === void 0 ? void 0 : account.spouse_birth_date)
                        : helperFunctions.formatDate(account === null || account === void 0 ? void 0 : account.birth_date))),
                React.createElement(Typography, { variant: "body2" },
                    "Age:",
                    " ",
                    React.createElement("strong", null, spouseInfo
                        ? helperFunctions.getAge(account === null || account === void 0 ? void 0 : account.spouse_birth_date)
                        : helperFunctions.getAge(account === null || account === void 0 ? void 0 : account.birth_date))),
                React.createElement(Typography, { variant: "body2" },
                    "Email:",
                    " ",
                    React.createElement("strong", null, spouseInfo ? account.spouse_email : account.email)),
                React.createElement(Typography, { variant: "body2" },
                    "Phone:",
                    " ",
                    React.createElement(NumberFormat, { value: spouseInfo ? account.spouse_phone : account.phone, displayType: "text", format: "(###) ###-####", renderText: function (value) { return React.createElement("strong", null, value); } }))),
            React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(Box, { mb: 2 },
                    React.createElement(Typography, { variant: "h6" }, "Mailing Address")),
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("span", null, account.ship_address_1),
                    React.createElement("br", null),
                    React.createElement("span", null, account.ship_address_2 ? account.ship_address_2 : ""),
                    account.ship_address_2 ? React.createElement("br", null) : "",
                    React.createElement("span", null,
                        (account === null || account === void 0 ? void 0 : account.ship_city) ? account.res_city + "," : "",
                        React.createElement("strong", null, " ".concat((_b = getStateById(account.ship_state_id, states)) === null || _b === void 0 ? void 0 : _b.name, " ")),
                        account.ship_zip))),
            React.createElement(Grid, { xs: 12, md: 4 },
                React.createElement(Box, { mb: 2 },
                    React.createElement(Typography, { variant: "h6" }, "Residential Address")),
                React.createElement(Typography, { variant: "body2" },
                    React.createElement("span", null, account.res_address_1),
                    React.createElement("br", null),
                    React.createElement("span", null, account.res_address_2 ? account.res_address_2 : ""),
                    account.res_address_2 ? React.createElement("br", null) : "",
                    React.createElement("span", null,
                        (account === null || account === void 0 ? void 0 : account.res_city) ? account.res_city + "," : "",
                        React.createElement("strong", null, " ".concat((_c = getStateById(account.res_state_id, states)) === null || _c === void 0 ? void 0 : _c.name, " ")),
                        account.res_zip)))),
        useAccountNav && (React.createElement(AccountNav, { account: account, order_count: account === null || account === void 0 ? void 0 : account.orders_count, 
            // lead_count={account?.leads_count}
            // app_count={account?.apps_count}
            // policy_count={account?.policies_count}
            // document_count={account?.documents_count}
            // donation_count={account?.donations_count}
            activity_log_count: account === null || account === void 0 ? void 0 : account.activities_count }))));
};
export default FormAccountInfo;
