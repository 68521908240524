import React, { useState } from "react";
import { router, Link } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import { Container, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import Layout from "../Layout";
import { isEmpty } from "lodash";
import DisplayErrors from "../../components/DisplayErrors";
import route from "ziggy-js";
import GenericControllerCheckboxInput from "../../components/GenericControllerCheckboxInput";
var AssignCarriersForm = function (_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useForm(), control = _c.control, handleSubmit = _c.handleSubmit, errors = _c.formState.errors;
    var insurance_carriers = response_data.insurance_carriers, user = response_data.user;
    var onSubmit = function (data) {
        data.user_id = user === null || user === void 0 ? void 0 : user.id;
        var selected_carriers = Object.entries(data.insurance_carriers).filter(function (carrier) { return carrier[1]; });
        data.carriers = [];
        selected_carriers.forEach(function (carrier) {
            data.carriers.push(parseInt(carrier[0]));
        });
        router.post("/user/abs/carriers/assign", data);
    };
    return (React.createElement(Layout, { title: "Assign Broker Carriers", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement("form", { onSubmit: handleSubmit(onSubmit), style: { backgroundColor: "#f8fafc" } },
                React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                    !isEmpty(serverErrors) && (React.createElement(Grid, { xs: 12 },
                        React.createElement(DisplayErrors, { errors: serverErrors }))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Typography, { variant: "h4", component: "h3", align: "center" }, "Assign Carrier")),
                    insurance_carriers ? (insurance_carriers.map(function (carrier) { return (React.createElement(Grid, { key: carrier.id, xs: 3 },
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: user.insurance_carriers.find(function (element) {
                                return element.name == carrier.name;
                            })
                                ? true
                                : false, control: control, name: "insurance_carriers[".concat(carrier.id, "]"), color: "primary", label: carrier.name }))); })) : (React.createElement(React.Fragment, null)),
                    React.createElement(Grid, { xs: 12, container: true, alignItems: "center", justifyContent: "center", spacing: 2 },
                        React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                            React.createElement(Button, { component: Link, type: "button", variant: "outlined", fullWidth: true, color: "secondary", href: route("broker_carriers_states", [
                                    user === null || user === void 0 ? void 0 : user.id,
                                ]).toString() }, "Cancel")),
                        React.createElement(Grid, { xs: 12, md: 6, lg: 3 },
                            React.createElement(Button, { type: "button", variant: "contained", color: "primary", onClick: handleSubmit(onSubmit), fullWidth: true }, "Update Carriers"))))))));
};
export default AssignCarriersForm;
