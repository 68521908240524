import React from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerDropdownInput from "../../components/GenericControllerDropdownInput";
import { MenuItem, Typography } from "@mui/material";
import GenericControllerTextInput from "../../components/GenericControllerTextInput";
function TaskFormTaskType(_a) {
    var control = _a.control, taskTypes = _a.taskTypes, taskTypeId = _a.taskTypeId, setTaskTypeId = _a.setTaskTypeId, followUpDate = _a.followUpDate, setFollowUpDate = _a.setFollowUpDate, errors = _a.errors;
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Task Type and Follow Up")),
        React.createElement(Grid, { xs: 12, sm: 6, xl: 6 },
            React.createElement(GenericControllerDropdownInput, { name: "task_type", id: "task_type", label: "Task Type", control: control, rules: { required: true }, defaultValue: taskTypeId, error: "task_type" in errors, handleChange: function (e) {
                    setTaskTypeId(e.target.value);
                }, options: taskTypes.map(function (taskType) {
                    return (React.createElement(MenuItem, { key: taskType.id, value: taskType.id }, taskType.name));
                }) })),
        React.createElement(Grid, { xs: 12, sm: 6, xl: 6 },
            React.createElement(GenericControllerTextInput, { label: "Follow-up Date", type: "date", control: control, rules: { required: false }, name: "follow_up_date", defaultValue: followUpDate, customOnChange: setFollowUpDate, error: "follow_up_date" in errors, shrinkLabel: true }))));
}
export default TaskFormTaskType;
