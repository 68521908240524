import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import monthsList from "../../../constants/months";
function MonthPicker(_a) {
    var month = _a.month, setMonth = _a.setMonth;
    return (React.createElement(React.Fragment, null, month >= 0 && (React.createElement(FormControl, null,
        React.createElement(Select, { size: "small", id: "month-select", value: month, onChange: function (e) { return setMonth(e.target.value); } }, monthsList.map(function (m) {
            return (React.createElement(MenuItem, { key: m, value: monthsList.indexOf(m) }, m));
        }))))));
}
export default MonthPicker;
