import { Box, Grid, Typography, Divider } from "@mui/material";
import React from "react";
import { Link } from "@inertiajs/react";
import AccountAddressCard from "./AccountAddressCard";
var AccountAddressesCard = function (_a) {
    var account = _a.account;
    console.log(account);
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 6, sx: { borderBottom: 1, marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "Addresses"))),
        React.createElement(Grid, { item: true, xs: 6, sx: { borderBottom: 1, marginBottom: "14px" } },
            React.createElement(Box, { mx: 1, mt: 1, style: { textAlign: "right" } },
                React.createElement(Link, { href: "#", style: {
                        textDecoration: "underline",
                        fontSize: '9pt',
                        color: '#3f51b5'
                    } }, "Edit Addresses"))),
        account.addresses.map(function (address) { return (React.createElement(Grid, { container: true, item: true, xs: 12, key: address.id },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(AccountAddressCard, { address: address, is_residential: account.residential_address_id == (address === null || address === void 0 ? void 0 : address.id), is_shipping: account.shipping_address_id == (address === null || address === void 0 ? void 0 : address.id) })),
                React.createElement(Box, { mx: 1 },
                    React.createElement(Divider, { sx: { marginTop: 2, marginBottom: 2 } }))))); })));
};
export default AccountAddressesCard;
