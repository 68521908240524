import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import ContactSpouseInfo from "../../components/ContactSpouseInfo";
import ContactInfoForm from "../../components/ContactInfoForm";
import Address from "../../components/ContactAddress";
import { mailingAddressOptions, residentialAddressOptions, } from "../../utils/utils";
import ContactOptions from "./ContactOptions";
import UtmSection from "../AmacAccount/UtmSection";
var ContactFormFields = function (_a) {
    var contact = _a.contact, states = _a.states, utm_sources = _a.utm_sources, utm_campaigns = _a.utm_campaigns, utm_mediums = _a.utm_mediums, referrals = _a.referrals, produceContacts = _a.produceContacts, errors = _a.errors, watch = _a.watch, watchAddress = _a.watchAddress, watchCity = _a.watchCity, control = _a.control, formType = _a.formType;
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(ContactInfoForm, { contact: contact, control: control, errors: errors, data: contact, produceContacts: produceContacts }))),
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(Address, { data: contact, control: control, errors: errors, states: states, options: mailingAddressOptions, produceContacts: produceContacts }),
                React.createElement(Address, { data: contact, control: control, errors: errors, states: states, options: residentialAddressOptions, showResidentialSame: watchAddress != "" && watchCity != "", produceContacts: produceContacts }))),
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(ContactSpouseInfo, { contact: contact, control: control, errors: errors, data: contact, produceContacts: produceContacts }))),
        formType != "edit" && (React.createElement(Grid, { xs: 12 },
            React.createElement(UtmSection, { utm_sources: utm_sources, utm_campaigns: utm_campaigns, utm_mediums: utm_mediums, referrals: referrals, errors: errors, watch: watch, control: control, formType: formType }))),
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(ContactOptions, { contact: contact, control: control })))));
};
export default ContactFormFields;
