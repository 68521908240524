import React from "react";
import route from "ziggy-js";
import { Button } from "@mui/material";
import { Link } from "@inertiajs/react";
function AddTaskButton(_a) {
    var taskable_id = _a.taskable_id, taskable_type = _a.taskable_type, _b = _a.variant, variant = _b === void 0 ? undefined : _b, _c = _a.color, color = _c === void 0 ? undefined : _c, _d = _a.fullWidth, fullWidth = _d === void 0 ? false : _d, _e = _a.size, size = _e === void 0 ? "medium" : _e;
    return (React.createElement(Link, { href: route("create-task", {
            taskable_type: taskable_type,
            taskable_id: taskable_id,
        }), as: "span", style: {
            textDecoration: "none",
        } },
        React.createElement(Button, { type: "submit", key: "submit", variant: variant, color: color, fullWidth: fullWidth, size: size }, "Add Task")));
}
export default AddTaskButton;
