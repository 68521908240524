import { Box, Grid, Typography } from "@mui/material";
import { helperFunctions } from "../../../Helpers";
import React from "react";
var PolicyInfoCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var data = _a.data, insuranceCarriers = _a.insuranceCarriers;
    return (React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
        React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Policy Info:"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Policy ID: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_b = data === null || data === void 0 ? void 0 : data.id) !== null && _b !== void 0 ? _b : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Policy Number:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_c = data === null || data === void 0 ? void 0 : data.policy_number) !== null && _c !== void 0 ? _c : ""))),
        data.enrollment_period && (React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Enrollment Period:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_d = data === null || data === void 0 ? void 0 : data.enrollment_period) !== null && _d !== void 0 ? _d : "")))),
        data.effective_date && (React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Effective Date:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_e = data === null || data === void 0 ? void 0 : data.effective_date) !== null && _e !== void 0 ? _e : "")))),
        data.payment_method && (React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Payment Method:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_f = data === null || data === void 0 ? void 0 : data.payment_method) !== null && _f !== void 0 ? _f : "")))),
        data.insurance_carrier_id && (React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Insurance Carrier: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_g = insuranceCarriers === null || insuranceCarriers === void 0 ? void 0 : insuranceCarriers.filter(function (el) {
                    return (el === null || el === void 0 ? void 0 : el.id) == (data === null || data === void 0 ? void 0 : data.insurance_carrier_id);
                })[0]) === null || _g === void 0 ? void 0 : _g.name)))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Best Time to Call:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_h = data === null || data === void 0 ? void 0 : data.best_time_call) !== null && _h !== void 0 ? _h : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" },
                        "Transferred To:",
                        " "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_k = (_j = data === null || data === void 0 ? void 0 : data.user_transferred) === null || _j === void 0 ? void 0 : _j.name) !== null && _k !== void 0 ? _k : ""))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Upated At: "))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_l = helperFunctions.getDateTime(data === null || data === void 0 ? void 0 : data.updated_at)) !== null && _l !== void 0 ? _l : "")))));
};
export default PolicyInfoCard;
