import React from "react";
import { Link, usePage } from "@inertiajs/react";
import { Button } from "@mui/material";
var BrokerNav = function (_a) {
    var user = _a.user;
    var url = usePage().url;
    return (React.createElement(React.Fragment, null,
        user.userable_type == 'App\\Models\\AbsBroker' && React.createElement(Link, { href: "/user/abs/show/".concat(user.id) },
            React.createElement(Button, { type: "button", color: "primary", variant: "outlined", style: {
                    backgroundColor: url.includes("/user/abs/show/")
                        ? "#E21D37"
                        : "#1E5496",
                    color: "#FFF",
                    textDecoration: "none",
                    borderRadius: "0 0px 3px 3px",
                } }, "Broker Details")),
        React.createElement(Link, { href: "/user/abs/leads/".concat(user.id) },
            React.createElement(Button, { type: "button", color: "primary", variant: "outlined", style: {
                    backgroundColor: url.includes("/user/abs/leads/")
                        ? "#E21D37"
                        : "#1E5496",
                    color: "#FFF",
                    textDecoration: "none",
                    borderRadius: "0 0px 3px 3px",
                } },
                "Leads (",
                user.transferred_leads_count,
                ")")),
        React.createElement(Link, { href: "/user/abs/carriers_states/".concat(user.id) },
            React.createElement(Button, { type: "button", color: "primary", variant: "outlined", style: {
                    backgroundColor: url.includes("/user/abs/carriers_states/")
                        ? "#E21D37"
                        : "#1E5496",
                    color: "#FFF",
                    textDecoration: "none",
                    borderRadius: "0 0px 3px 3px",
                } },
                "Carriers (",
                user.insurance_carriers_count,
                ") States (",
                user.states_count,
                ")")),
        React.createElement(Link, { href: "/user/abs/documents/".concat(user.id) },
            React.createElement(Button, { type: "button", color: "primary", variant: "outlined", style: {
                    backgroundColor: url.includes("/user/abs/documents/")
                        ? "#E21D37"
                        : "#1E5496",
                    color: "#FFF",
                    textDecoration: "none",
                    borderRadius: "0 0px 3px 3px",
                } },
                "Documents (",
                user.abs_documents_count,
                ")"))));
};
export default BrokerNav;
