import React from "react";
import { Box, Paper, TableContainer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageTitle from "../../../components/PageTitle";
var BrokerDocumentTable = function (_a) {
    var data = _a.data;
    if (data.length < 1) {
        return null;
    }
    var rows = [];
    var count = 1;
    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
        var item = data_1[_i];
        var values = {
            count: count,
            id: item.id,
            name: item.file_name,
        };
        rows.push(values);
        count += 1;
    }
    var columns = [
        {
            field: "count",
            headerName: "#",
            editable: false,
            flex: 0.5,
        },
        {
            field: "id",
            headerName: "ID",
            editable: false,
            flex: 0.5,
        },
        {
            field: "name",
            headerName: "Document",
            editable: false,
            flex: 2,
            renderCell: function (rowValues) {
                var _a, _b, _c;
                try {
                    return (React.createElement("a", { href: "/file/show?path=".concat(btoa((_a = rowValues === null || rowValues === void 0 ? void 0 : rowValues.row) === null || _a === void 0 ? void 0 : _a.name)), target: "_blank" }, (_b = rowValues === null || rowValues === void 0 ? void 0 : rowValues.row) === null || _b === void 0 ? void 0 : _b.name));
                }
                catch (e) {
                    return (_c = rowValues === null || rowValues === void 0 ? void 0 : rowValues.row) === null || _c === void 0 ? void 0 : _c.name;
                }
            },
        },
    ];
    return (React.createElement(Box, { mt: 2 },
        React.createElement(Box, { mb: 2 },
            React.createElement(PageTitle, { title: "Documents" })),
        React.createElement(TableContainer, { component: Paper, elevation: 3 },
            React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: 20, autoHeight: true, rowsPerPageOptions: [20] }))));
};
export default BrokerDocumentTable;
