import { Box, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import Layout from "../../../Layout";
import { AccountNavWrapper } from "../Navigation/AccountNavWrapper";
import AccountDocument from "./AccountDocument";
import AccountFileUploadForm from "../../../../components/AccountFileUploadForm";
import AccountHeader from "../AccountHeader";
var AccountDocuments = function (_a) {
    var response_data = _a.response_data, auth = _a.auth;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var account = response_data.account, all_documents = response_data.all_documents;
    return (React.createElement(Layout, { title: "Account Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, item: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(AccountNavWrapper, { account: account, all_documents: all_documents, activity_log: response_data === null || response_data === void 0 ? void 0 : response_data.activity_log }))),
        React.createElement(Paper, { style: { minHeight: "80vh" } },
            React.createElement(Grid, { container: true },
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement(AccountHeader, { account: account })),
                React.createElement(Grid, { item: true, xs: 9 }),
                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                    React.createElement(Box, { mx: 1, mt: 3 },
                        React.createElement(AccountFileUploadForm, { accountId: account.id, userId: auth.user.id, name: "accounts", id: account.id }))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { m: 2 },
                        React.createElement(AccountDocument, { data: all_documents })))))));
};
export default AccountDocuments;
