import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { helperFunctions } from "../../../Helpers";
var BrokerRow = function (_a) {
    var broker = _a.broker;
    var user = broker.user, id = broker.id, status = broker.status, phone = broker.phone, extension = broker.extension, created_at = broker.created_at;
    if (!user) {
        return null;
    }
    var first_name = user.first_name, last_name = user.last_name, email = user.email;
    return (React.createElement(TableRow, { classes: {} },
        React.createElement(TableCell, null,
            first_name,
            " ",
            last_name),
        React.createElement(TableCell, null, id),
        React.createElement(TableCell, null, status),
        React.createElement(TableCell, null, email),
        React.createElement(TableCell, null, phone),
        React.createElement(TableCell, null, extension),
        React.createElement(TableCell, null, "last login"),
        React.createElement(TableCell, null, helperFunctions.getDateTime(created_at))));
};
export default BrokerRow;
