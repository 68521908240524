import { Box, Grid, Typography, Link, Chip } from "@mui/material";
import React from "react";
import { helperFunctions } from "../../../../Helpers";
var AccountContactCard = function (_a) {
    var _b, _c, _d, _e;
    var contact = _a.contact, is_primary = _a.is_primary;
    console.log(contact);
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_c = (_b = contact.fancy_name) !== null && _b !== void 0 ? _b : contact.full_name) !== null && _c !== void 0 ? _c : "-"))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { mx: 1 },
                React.createElement(Link
                // as="span"
                // href={route("show_leads", { id: account.contact.id })}
                , { 
                    // as="span"
                    // href={route("show_leads", { id: account.contact.id })}
                    href: '#', 
                    /* method="post"
                    data={{ id: contact.id }}
                     */
                    style: {
                        textDecoration: "underline",
                        fontSize: '9pt',
                        color: '#3f51b5'
                    } }, "Leads(0)"),
                "\u00A0\u00B7\u00A0",
                React.createElement(Link
                // as="span"
                // href={route("show_apps", { id: account.contact.id })}
                , { 
                    // as="span"
                    // href={route("show_apps", { id: account.contact.id })}
                    href: '#', 
                    /*
                    method="post"
                    data={{ id: contact.id }}
                    */
                    style: {
                        textDecoration: "underline",
                        fontSize: '9pt',
                        color: '#3f51b5'
                    } }, "Apps(0)"),
                "\u00A0\u00B7\u00A0",
                React.createElement(Link
                // as="span"
                // href={route("show_policies", { id: account.contact.id })}
                , { 
                    // as="span"
                    // href={route("show_policies", { id: account.contact.id })}
                    href: '#', 
                    /*
                    method="post"
                    data={{ id: contact.id }}
                    */
                    style: {
                        textDecoration: "underline",
                        fontSize: '9pt',
                        color: '#3f51b5'
                    } }, "Policies(0)"),
                "\u00A0\u00B7\u00A0",
                React.createElement(Link
                // as="span"
                // href={route("show_donations", { id: account.contact.id })}
                , { 
                    // as="span"
                    // href={route("show_donations", { id: account.contact.id })}
                    href: '#', 
                    /*
                    method="post"
                    data={{ id: contact.id }}
                    */
                    style: {
                        textDecoration: "underline",
                        fontSize: '9pt',
                        color: '#3f51b5'
                    } }, "Donations(0)"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2", style: { color: '#aaaaaa', fontSize: '9pt' } }, "Contact\u00A0ID:"))),
            React.createElement(Grid, { item: true, xs: 9 },
                React.createElement(Typography, { variant: "body2", style: { color: '#aaaaaa', fontSize: '9pt' } }, contact.id))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "DOB: "))),
            React.createElement(Grid, { item: true, xs: 9 }, React.createElement(Typography, { variant: "body2", style: contact.birth_date ? {
                    fontWeight: "bold",
                } : {
                    color: '#aaa'
                } }, contact.birth_date ?
                React.createElement("span", { style: { backgroundColor: "rgb(246, 255, 0)", display: "inline" } }, helperFunctions.formatDate(contact.birth_date))
                :
                    '-'))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Age: "))),
            React.createElement(Grid, { item: true, xs: 9 },
                React.createElement(Typography, { variant: "body2", style: contact.phone ? { fontWeight: "bold" } : { color: "#aaa" } }, contact.birth_date ?
                    helperFunctions.getAge(contact.birth_date) :
                    '-'))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Email: "))),
            React.createElement(Grid, { item: true, xs: 9 },
                React.createElement(Typography, { variant: "body2", style: contact.phone ? { fontWeight: "bold" } : { color: "#aaa" } }, contact.email ?
                    React.createElement(Link
                    // as="span"
                    // href={route("show_policies", { id: account.contact.id })}
                    , { 
                        // as="span"
                        // href={route("show_policies", { id: account.contact.id })}
                        href: "mailto:" + contact.email, 
                        /*
                        method="post"
                        data={{ id: contact.id }}
                        */
                        style: {
                            textDecoration: "underline",
                            color: '#3f51b5'
                        } }, contact.email)
                    :
                        '-'))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, "Phone: "))),
            React.createElement(Grid, { item: true, xs: 9 }, React.createElement(Typography, { variant: "body2", style: contact.phone ? { fontWeight: "bold" } : { color: "#aaa" } }, (_d = contact.phone) !== null && _d !== void 0 ? _d : '-')),
            React.createElement(Grid, { container: true, item: true, xs: 12 },
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement(Box, { mx: 1 },
                        React.createElement(Typography, { variant: "body2" }, "Alt. Phone: "))),
                React.createElement(Grid, { item: true, xs: 9 },
                    React.createElement(Typography, { variant: "body2", style: contact.alt_phone ? { fontWeight: "bold" } : { color: "#aaa" } }, (_e = contact.alt_phone) !== null && _e !== void 0 ? _e : '-'))),
            React.createElement(Grid, { item: true, xs: 12 }, is_primary &&
                React.createElement(Box, { mx: 1, mb: 1 },
                    React.createElement(Chip, { label: "Primary Member", size: "small" }))))));
};
export default AccountContactCard;
