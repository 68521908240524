import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
var ContactShipAddressCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var contact = _a.contact, states = _a.states;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { xs: 12, style: { backgroundColor: "#F1F1F1", marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "Mailing Address"))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { display: "inline", variant: "body2" }, (_b = contact === null || contact === void 0 ? void 0 : contact.fancy_name) !== null && _b !== void 0 ? _b : "")))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { display: "inline", variant: "body2" }, (_c = contact.ship_address_1) !== null && _c !== void 0 ? _c : "")))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { display: "inline", variant: "body2" }, (_d = contact.ship_address_2) !== null && _d !== void 0 ? _d : "")))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { display: "inline", variant: "body2" }, "".concat((contact === null || contact === void 0 ? void 0 : contact.ship_city) ? contact.ship_city + "," : "", "\n                        ").concat((_f = (_e = states.find(function (el) {
                        return el.id == Number(contact.ship_state_id);
                    })) === null || _e === void 0 ? void 0 : _e.abbreviation) !== null && _f !== void 0 ? _f : "", "\n                        ").concat((_g = contact.ship_zip) !== null && _g !== void 0 ? _g : ""))))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { display: "inline", variant: "body2" }, (_h = contact.ship_country) !== null && _h !== void 0 ? _h : "None"))))));
};
export default ContactShipAddressCard;
