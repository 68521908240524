import { Box, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { upperFirst } from "lodash";
import React, { useState } from "react";
import MemberUtmChild from "./MemberUtmChild";
import { MagnifyingGlassIcon } from "../../components/IconLibrary";
import MemberUtmCRUDDialog from "./MemberUtmCRUDDialog";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
        },
        withoutLabel: {
            marginTop: theme.spacing(1),
        },
        textField: {
            height: "40px",
            borderColor: "#000",
        },
        label: {
            transform: "translate(17px, 12px) scale(1)",
            color: "#000",
        },
        button: {
            height: "40px",
        },
    });
});
export default function MemberUtmCard(_a) {
    var resources = _a.resources, resource = _a.resource, resourceType = _a.resourceType, childType = _a.childType, resourceColor = _a.resourceColor, childColor = _a.childColor;
    var classes = useStyles();
    var _b = useState(""), filter = _b[0], setFilter = _b[1];
    return (React.createElement(Grid, { container: true, alignContent: "center", xs: 12 },
        React.createElement(Grid, { container: true, xs: 8 },
            React.createElement(Box, null,
                React.createElement(Typography, { variant: "subtitle1", sx: {
                        fontWeight: "bold",
                        ml: resourceType !== "sources" ? "50px" : "0px",
                    } }, upperFirst(resourceType)))),
        React.createElement(Grid, { container: true, xs: 2, justifyContent: "flex-end", alignContent: "center" },
            React.createElement(Box, { my: 1 },
                React.createElement(MemberUtmCRUDDialog, { resource: resource, CRUDOperation: "add", resourceType: resourceType, showButtonStyles: {
                        backgroundColor: "#1b517e",
                        color: "#fff",
                        fontWeight: 600,
                        marginRight: 1,
                        borderRadius: 0,
                        borderColor: "#1b517e",
                        border: 1,
                        "&:hover": {
                            backgroundColor: "#fff",
                            borderColor: "#1b517e",
                            color: "#1b517e",
                        },
                    }, CRUDButtonStyles: {
                        backgroundColor: "#1b517e",
                        color: "#fff",
                        fontWeight: 600,
                        marginRight: 1,
                        borderRadius: 0,
                        borderColor: "#1b517e",
                        border: 1,
                        width: "100%",
                        marginTop: 1,
                        "&:hover": {
                            backgroundColor: "#fff",
                            borderColor: "#1b517e",
                            color: "#1b517e",
                        },
                    } }))),
        React.createElement(Grid, { container: true, xs: 2, justifyContent: "flex-end", alignContent: "center" },
            React.createElement(Box, { my: 1, width: "100%" },
                React.createElement(TextField, { fullWidth: true, id: "".concat(resourceType, "-filter"), type: "text", variant: "outlined", label: "Filter ".concat(resourceType, " by"), onChange: function (e) { return setFilter(e.target.value); }, defaultValue: "", size: "small", InputProps: {
                        endAdornment: (React.createElement("div", { style: {
                                display: "inline-flex",
                                height: "30px",
                                width: "25px",
                                position: "absolute",
                                right: "4px",
                                border: "1px",
                                cursor: "pointer",
                            } },
                            React.createElement(MagnifyingGlassIcon, { color: "#3F3F3F" }))),
                    } }))),
        resources
            .filter(function (resource) {
            return resource.name.toLowerCase().includes(filter.toLowerCase());
        })
            .map(function (resource) { return (React.createElement(MemberUtmChild, { key: resource.id, resource: resource, resourceType: resourceType, childType: childType, resourceColor: resourceColor, childColor: childColor })); })));
}
