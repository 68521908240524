import React from "react";
import Grid from "@mui/material/Unstable_Grid2/";
import { Box, Typography } from "@mui/material";
import { helperFunctions } from "../../../Helpers";
export var BrokerInfoCard = function (_a) {
    var _b, _c, _d, _e;
    var broker = _a.broker, user = _a.user;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { xs: 12, style: {
                backgroundColor: "#F1F1F1",
                padding: "6px",
                marginBottom: "14px",
            } },
            React.createElement(Typography, { variant: "h6" }, "Broker Info")),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2" }, "Name: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_b = user === null || user === void 0 ? void 0 : user.name) !== null && _b !== void 0 ? _b : ""))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2" }, "Broker ID: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_c = broker.id) !== null && _c !== void 0 ? _c : ""))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2" }, "Status: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: {
                        fontWeight: "bold",
                        color: broker.status === "active"
                            ? "#3EBF3E"
                            : broker.status === "pending"
                                ? "#1871BF"
                                : broker.status === "inactive"
                                    ? "#F1221C"
                                    : "#222",
                    } }, broker.status === "deleted"
                    ? "inactive"
                    : (broker === null || broker === void 0 ? void 0 : broker.status) != undefined
                        ? broker.status
                        : ""))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2" },
                        "Business Name:",
                        " "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, (_d = broker.business_name) !== null && _d !== void 0 ? _d : ""))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2" }, "Birthdate: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, helperFunctions.formatDate(broker.birth_date)))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2" },
                        "Years in Business:",
                        " "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2" }, broker === null || broker === void 0 ? void 0 : broker.years_in_business))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2" },
                        "Medicare Experience:",
                        " "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2" }, (broker === null || broker === void 0 ? void 0 : broker.experience) ? "Yes" : "No"))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2" }, "Referral: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2" }, (_e = broker === null || broker === void 0 ? void 0 : broker.referral) === null || _e === void 0 ? void 0 : _e.name))),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { xs: 6 },
                React.createElement(Box, null,
                    React.createElement(Typography, { variant: "body2" }, "Onboarding Status: "))),
            React.createElement(Grid, { xs: 6 },
                React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, broker === null || broker === void 0 ? void 0 : broker.onboarding_status)))));
};
