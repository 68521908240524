import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { DataGrid } from "@mui/x-data-grid";
import { Button, TextField, Box, Snackbar, Alert, MenuItem, Link, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import PageTitle from "../../components/PageTitle";
import { router, usePage } from "@inertiajs/react";
import { helperFunctions } from "../../Helpers";
import { isEmpty } from "lodash";
import { useForm as useFormReact } from "react-hook-form";
import GenericControllerTextInput from "../../components/GenericControllerTextInput";
import GenericControllerDropdownInput from "../../components/GenericControllerDropdownInput";
import DisplayErrors from "../../components/DisplayErrors";
import { hasPermission } from "../../utils/utils";
var ImportBatchMain = function (_a) {
    var response_data = _a.response_data, serverErrors = _a.errors;
    /* START originally in FileUploadForm.tsx */
    var _b = useState(''), file = _b[0], setFile = _b[1];
    var _c = useFormReact(), handleSubmit = _c.handleSubmit, control = _c.control, formErrors = _c.formState.errors;
    var submitForm = function (data, e) {
        router.post("/import/file/store", {
            file: file,
            import_source: data.import_source,
            route: "import-batch.index"
        });
    };
    /* END originally in FileUploadForm.tsx */
    var auth = usePage().props.auth;
    var _d = useState(true), menuOpen = _d[0], toggleMenuOpen = _d[1];
    var _e = useState(response_data.logs), logs = _e[0], setLogs = _e[1];
    var rows = [];
    var _f = useState(""), filter = _f[0], setFilter = _f[1];
    var _g = usePage().props, flash = _g.flash, errors = _g.errors;
    var _h = useState(false), openMessage = _h[0], setOpenMessage = _h[1];
    useEffect(function () {
        if (flash.message) {
            setOpenMessage(true);
        }
    }, [flash]);
    useEffect(function () {
        setLogs(response_data.logs.filter(function (log) { var _a; return (_a = log === null || log === void 0 ? void 0 : log.file_name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(filter.toLowerCase()); }));
    }, [filter]);
    logs.map(function (log) {
        rows.push(log);
    });
    var columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            editable: false,
            valueGetter: function (rowValues) { var _a; return (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.id; },
        },
        {
            field: "created_at",
            headerName: "Created At",
            flex: 2,
            editable: false,
            valueGetter: function (rowValues) {
                return helperFunctions.getDateTime(rowValues.row.created_at);
            },
        },
        {
            field: "file_name",
            headerName: "File Name",
            flex: 4,
            editable: false,
            valueGetter: function (rowValues) { var _a; return (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.file_name; },
        },
        {
            field: "source",
            headerName: "Source",
            flex: 1,
            editable: false,
            valueGetter: function (rowValues) { var _a; return (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.source; },
        },
        {
            field: "success",
            headerName: "Success",
            flex: 1,
            editable: false,
            valueGetter: function (rowValues) { var _a; return (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.success; },
        },
        {
            field: "members",
            headerName: "Members",
            flex: 1,
            editable: false,
            valueGetter: function (rowValues) { var _a; return (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.members; },
        },
        {
            field: "fail_total",
            headerName: "Fail Total",
            flex: 1,
            editable: false,
            valueGetter: function (rowValues) { var _a; return (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.fail_total; },
        },
        {
            field: "fail_under_10",
            headerName: "Fail Under 10",
            flex: 1,
            editable: false,
            valueGetter: function (rowValues) { var _a; return (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.fail_under_10; },
        },
        {
            field: "fail_errors",
            headerName: "Fail Errors",
            flex: 1,
            editable: false,
            valueGetter: function (rowValues) { var _a; return (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.fail_errors; },
        },
        {
            field: "fail_dupes",
            headerName: "Fail Dupes",
            flex: 2,
            editable: false,
            valueGetter: function (rowValues) { var _a; return (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.fail_dupes; },
        },
        {
            field: "member_cnt_beg",
            headerName: "Member Beg",
            flex: 1,
            editable: false,
            valueGetter: function (rowValues) { var _a; return (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.member_cnt_beg; },
        },
        {
            field: "member_cnt_end",
            headerName: "Member End",
            flex: 1,
            editable: false,
            valueGetter: function (rowValues) { var _a; return (_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.member_cnt_end; },
        },
        {
            field: "view",
            headerName: "Option",
            flex: 1,
            editable: false,
            renderCell: function (rowValues) {
                var _a;
                return hasPermission(auth, "view members") && ((_a = rowValues.row) === null || _a === void 0 ? void 0 : _a.member_cnt_beg) && (React.createElement(Link, { href: "batch/download/".concat(rowValues.row.id) },
                    React.createElement(Button, { type: "button", color: "primary", size: "small", variant: "contained" }, ".CSV")));
            },
        },
    ];
    var import_sources = ['hsp', 'free', 'free_epoch'];
    return (React.createElement(Layout, { title: "Import Logs", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(PageTitle, { title: "Import Logs" }),
        React.createElement(Grid, { container: true, spacing: 2, justifyContent: "flex-end" },
            React.createElement(Grid, { xs: 12 },
                !isEmpty(serverErrors) && (React.createElement(Box, { mx: 1, mt: 2 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))),
                openMessage && (React.createElement(Box, { mb: 1 },
                    React.createElement(Snackbar, { open: openMessage, anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        }, autoHideDuration: 3000, onClose: function () { return setOpenMessage(false); } },
                        React.createElement(Alert, { onClose: function () { return setOpenMessage(false); }, severity: "success" }, flash === null || flash === void 0 ? void 0 : flash.message)))),
                React.createElement(Box, { mx: 1, mt: 3 },
                    React.createElement(React.Fragment, null,
                        React.createElement(Grid, { container: true, spacing: 2, justifyContent: "center", my: 1 },
                            React.createElement(Grid, { xs: 6 },
                                React.createElement(GenericControllerTextInput, { control: control, type: "file", name: "file", label: "", 
                                    // isFileUpload
                                    defaultValue: "", rules: { required: true }, error: "file" in formErrors, customOnChange: function (e) {
                                        setFile(e.target.files[0]);
                                    } })),
                            React.createElement(Grid, { xs: 6 },
                                React.createElement(GenericControllerDropdownInput, { name: "import_source", label: "Import Source", defaultValue: '', control: control, rules: { required: true }, error: "import_source" in formErrors, options: import_sources.map(function (source) {
                                        return (React.createElement(MenuItem, { value: source !== null && source !== void 0 ? source : '', key: "source_" +
                                                import_sources.indexOf(source) }, source));
                                    }) })),
                            React.createElement(Grid, { xs: 4 },
                                React.createElement(Button, { type: "button", onClick: function () {
                                        setFile('');
                                    }, variant: "contained", color: "secondary", fullWidth: true, size: "small" }, "Cancel")),
                            React.createElement(Grid, { xs: 4 },
                                React.createElement(Button, { type: "button", onClick: handleSubmit(function (data, e) { return submitForm(data, e); }), variant: "contained", color: "primary", fullWidth: true, size: "small" }, "Upload")))))),
            React.createElement(Grid, { xs: 3 },
                React.createElement(TextField, { variant: "outlined", label: "Filter By Name", type: "text", name: "filterByName", defaultValue: filter, fullWidth: true, onChange: function (e) { return setFilter(e.target.value); } })),
            React.createElement(Grid, { xs: 12 },
                React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: 20, rowsPerPageOptions: [20], autoHeight: true })))));
};
export default ImportBatchMain;
