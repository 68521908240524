import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { helperFunctions } from "../../../../Helpers";
var AmacAccountContactCard = function (_a) {
    var _b, _c, _d;
    var amac_account = _a.amac_account;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12, style: { backgroundColor: "#F1F1F1", marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "h6" }, "Spouse/Family AmacAccount"))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_b = amac_account.spouse_full_name) !== null && _b !== void 0 ? _b : "")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, amac_account.spouse_email && (React.createElement("a", { href: "mailto:" + amac_account.spouse_email }, amac_account.spouse_email)))))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_c = amac_account.spouse_phone) !== null && _c !== void 0 ? _c : "")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 8 },
                React.createElement(Box, { mx: 1 },
                    React.createElement(Typography, { variant: "body2" }, (_d = amac_account.spouse_phone_alt) !== null && _d !== void 0 ? _d : "")))),
        React.createElement(Grid, { container: true, item: true, xs: 12 },
            React.createElement(Grid, { item: true, xs: 6 }, amac_account.spouse_birth_date && (React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "body2" }, helperFunctions.formatDate(amac_account.spouse_birth_date))))),
            React.createElement(Grid, { item: true, xs: 6 }, amac_account.spouse_birth_date && (React.createElement(Box, { mx: 1 },
                React.createElement(Typography, { variant: "body2" },
                    "Age:",
                    " ",
                    helperFunctions.getAge(amac_account.spouse_birth_date))))))));
};
export default AmacAccountContactCard;
