var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Grid from "@mui/material/Unstable_Grid2/";
import React, { useState } from "react";
import GenericNumberInput from "../../../components/GenericNumberInput";
import GenericRadioInput from "../../../components/GenericRadioInput";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
var CurrentLifeInsurance = function (props) {
    var _a, _b, _c, _d;
    var errors = props.errors, app = props.app, control = props.control;
    var _e = useState(app), appState = _e[0], setAppState = _e[1];
    var handleStateChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        setAppState(function (prevState) {
            var _a;
            return __assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a));
        });
    };
    return (React.createElement(Grid, { xs: 12, container: true },
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(GenericRadioInput, { defaultValue: (_a = app === null || app === void 0 ? void 0 : app.current_life_insurance) !== null && _a !== void 0 ? _a : "", control: control, rules: { required: false }, handleChange: handleStateChange, name: "current_life_insurance", legend: "Current Life Insurance", error: "current_life_insurance" in errors })),
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 }, String(appState === null || appState === void 0 ? void 0 : appState.current_life_insurance) === "1" && (React.createElement(React.Fragment, null,
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(GenericNumberInput, { label: "Face Amount", rules: { required: true }, name: "insurance_face_amount", defaultValue: (_b = app === null || app === void 0 ? void 0 : app.insurance_face_amount) !== null && _b !== void 0 ? _b : null, error: "insurance_face_amount" in errors, prefix: "$", thousandSeparator: true, control: control })),
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericControllerTextInput, { type: "date", label: "Date policy was issued", rules: { required: true }, name: "insurance_policy_date", defaultValue: (_c = app === null || app === void 0 ? void 0 : app.insurance_policy_date) !== null && _c !== void 0 ? _c : "", error: "insurance_policy_date" in errors, shrinkLabel: true, control: control })),
                React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(GenericRadioInput, { legend: "Insurance Policy Replace", name: "insurance_policy_replace", control: control, rules: { required: true }, defaultValue: (_d = app === null || app === void 0 ? void 0 : app.insurance_policy_replace) !== null && _d !== void 0 ? _d : "", handleChange: handleStateChange, error: "insurance_policy_replace" in errors }))))))));
};
export default CurrentLifeInsurance;
