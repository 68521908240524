import { usePage } from "@inertiajs/react";
import { Tab, Tabs } from "@mui/material";
import React from "react";
import { agentHasRole } from "../../../utils/utils";
var MemberTabs = function (_a) {
    var tab = _a.tab, handleTabChange = _a.handleTabChange, ordersCount = _a.ordersCount, leadsCount = _a.leadsCount, appsCount = _a.appsCount, policiesCount = _a.policiesCount;
    var auth = usePage().props.auth;
    return (React.createElement(Tabs, { value: tab, onChange: handleTabChange },
        React.createElement(Tab, { label: "Member Details" }),
        React.createElement(Tab, { label: "Orders (" + ordersCount + ")" }),
        React.createElement(Tab, { label: "Leads (" + leadsCount + ")" }),
        React.createElement(Tab, { label: "Apps (" + appsCount + ")" }),
        React.createElement(Tab, { label: "Policies (" + policiesCount + ")" }),
        !agentHasRole(auth, "broker") && (React.createElement(Tab, { label: "Documents" }))));
};
export default MemberTabs;
