import React, { useEffect, useState } from "react";
import { router } from "@inertiajs/react";
import DatePicker from "../components/DatePicker";
import CarriersTable from "./CarriersTable";
import Grid from "@mui/material/Unstable_Grid2/";
import ExportButton from "../components/ExportButton";
function Carriers(_a) {
    var start_date = _a.start_date, end_date = _a.end_date, carriers = _a.carriers, selected_carriers = _a.selected_carriers, policies_by_carrier = _a.policies_by_carrier, formData = _a.formData, department = _a.department, csrf = _a.csrf;
    var setDefaultCarriers = function () {
        if (department === "Medicare") {
            return [
                { id: 1, name: "Accendo" },
                { id: 2, name: "Aetna" },
                { id: 6, name: "Cigna" },
                { id: 14, name: "Humana" },
                { id: 16, name: "Mutual of Omaha" },
                { id: 40, name: "Wellcare" },
                { id: 67, name: "Allstate" },
            ];
        }
        else if (department === "IMM") {
            return [
                { id: 6, name: "Cigna" },
                { id: 26, name: "Anthem BC CA" },
                { id: 51, name: "Anthem/BCBS" },
                { id: 69, name: "BCBS OK" },
                { id: 68, name: "BCBS TX" },
                { id: 70, name: "BCBS IL" },
                { id: 71, name: "BCBS MT" },
                { id: 72, name: "BCBS NM" },
                { id: 67, name: "Allstate" },
                { id: 62, name: "Oscar" },
                { id: 45, name: "Ameritas" },
                { id: 2, name: "Aetna" },
                { id: 14, name: "Humana" },
                { id: 21, name: "United Healthcare" },
                { id: 58, name: "Pivot Health" },
                { id: 63, name: "Bright Health" },
                { id: 64, name: "Molina" },
                { id: 94, name: "Medica" },
                { id: 66, name: "Ambetter" },
                { id: 65, name: "Highmark" },
                { id: 60, name: "National Care Dental" },
            ];
        }
        else if (department === "Rosemark") {
            return [
                { id: 17, name: "Pruddential" },
                { id: 75, name: "Pacific Life" },
                { id: 73, name: "AIG" },
                { id: 41, name: "Banner" },
                { id: 25, name: "Transamerica" },
                { id: 29, name: "Protective Life" },
                { id: 76, name: "Assurity" },
                { id: 82, name: "Nationwide" },
                { id: 81, name: "Mass Mutual" },
                { id: 39, name: "Principal" },
                { id: 74, name: "North American" },
                { id: 80, name: "John Hancock" },
                { id: 85, name: "Symetra" },
                { id: 84, name: "Security Mutual" },
                { id: 16, name: "Mutual of Omaha" },
                // { id: 14, name: "United of Omaha" }, //not found?
                { id: 45, name: "Ameritas" },
                { id: 78, name: "Lincoln" },
                { id: 89, name: "William Penn" },
            ];
        }
        else {
            return [];
        }
    };
    var today = new Date();
    var _b = useState(new Date(today.getFullYear(), today.getMonth(), 1)), startDate = _b[0], setStartDate = _b[1];
    var _c = useState(today), endDate = _c[0], setEndDate = _c[1];
    var _d = useState(function () {
        return setDefaultCarriers();
    }), selectedCarriers = _d[0], setSelectedCarriers = _d[1];
    var _e = useState(false), ready = _e[0], setReady = _e[1];
    var handleSubmit = function () {
        var carriersIdArray = selectedCarriers.map(function (carrier) {
            return carrier.id;
        });
        router.post("/production-boards/d/".concat(department, "/b/carriers"), {
            start_date: startDate,
            end_date: endDate,
            selected_carriers: carriersIdArray,
        });
    };
    useEffect(function () {
        handleSubmit();
        setReady(true);
    }, [startDate, endDate, selectedCarriers, ready]);
    return (React.createElement(React.Fragment, null, startDate && (React.createElement(Grid, { container: true, xs: 12, spacing: 3 },
        React.createElement(Grid, { xs: 8 }),
        React.createElement(Grid, { xs: 1 }, ready && formData && csrf && (React.createElement(React.Fragment, null,
            React.createElement(ExportButton, { formData: formData, csrf: csrf })))),
        React.createElement(Grid, { xs: 9 }, ready && (React.createElement(CarriersTable, { department: department, carriers: carriers, policies_by_carrier: policies_by_carrier, selectedCarriers: selectedCarriers, setSelectedCarriers: setSelectedCarriers }))),
        React.createElement(Grid, { xs: 3 }, start_date && end_date && (React.createElement(DatePicker, { startDate: startDate, endDate: endDate, setStartDate: setStartDate, setEndDate: setEndDate, handleSubmit: handleSubmit })))))));
}
export default Carriers;
