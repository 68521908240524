import { router } from "@inertiajs/react";
import { Box, Button, Divider, InputBase } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import makeStyles from "@mui/styles/makeStyles";
import { upperFirst } from "lodash";
import React, { useState } from "react";
import MemberUtmCard from "./MemberUtmCard";
import { useForm } from "react-hook-form";
import MemberUtmCRUDDialog from "./MemberUtmCRUDDialog";
var useStyles = makeStyles({
    groupedButtons: {
        borderColor: "#e31d38",
        color: "#000",
        fontWeight: 600,
        margin: 1,
        borderRadius: 0,
    },
    resourceName: {
        "& input.Mui-disabled": {
            color: "#d1363e",
            fontWeight: "bold",
        },
        "& .MuiInput-underline.Mui-disabled:before": {
            borderBottom: 0,
        },
    },
});
export default function MemberUtmChild(_a) {
    var resource = _a.resource, resourceType = _a.resourceType, childType = _a.childType, resourceColor = _a.resourceColor, childColor = _a.childColor;
    var classes = useStyles();
    var _b = useState(false), show = _b[0], setShow = _b[1];
    var _c = useState(false), isSubmitting = _c[0], setIsSubmitting = _c[1];
    var _d = useForm(), errors = _d.formState.errors, handleSubmit = _d.handleSubmit;
    var submitOptions = {
        preserveScroll: true,
        onStart: function () {
            setIsSubmitting(true);
        },
        onFinish: function () {
            setIsSubmitting(false);
        },
    };
    var toggleActive = function (resource) {
        if (!isSubmitting) {
            var data = {
                id: resource.id,
                type: resourceType,
                active: !resource.active,
            };
            router.patch("/member/utm/".concat(resource.id), data, submitOptions);
        }
    };
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 1, sx: {
            // marginBottom: "3px",
            justifyContent: "end",
            ml: resourceType !== "sources" ? "50px" : "0px",
        } },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Divider, null)),
        React.createElement(Grid, { container: true, xs: 12 },
            React.createElement(Grid, { container: true, xs: 6, alignContent: "center" },
                React.createElement(Grid, { container: true, xs: 9, alignContent: "center" },
                    React.createElement(Box, { sx: {
                            display: "flex",
                            alignSelf: "center",
                            color: resource.active ? "#e31d38" : "#3F3F3F",
                            fontWeight: 600,
                            textDecoration: resource.active
                                ? "none"
                                : "line-through",
                        } }, resource.name),
                    React.createElement(Box, { mr: 2 },
                        React.createElement(InputBase, { type: "hidden", name: "id", defaultValue: resource.id })),
                    React.createElement(MemberUtmCRUDDialog, { resource: resource, CRUDOperation: "edit", resourceType: resourceType, showButtonStyles: {
                            border: 0,
                            color: "#000",
                            fontSize: "11px",
                            fontWeight: "400",
                            height: "40px",
                            marginRight: 0.5,
                        }, CRUDButtonStyles: {
                            backgroundColor: "#1b517e",
                            color: "#fff",
                            fontWeight: 600,
                            marginRight: 10,
                            borderRadius: 0,
                            borderColor: "#1b517e",
                            border: 1,
                            width: "100%",
                            marginTop: 1,
                            "&:hover": {
                                backgroundColor: "#fff",
                                borderColor: "#1b517e",
                                color: "#1b517e",
                            },
                        } }))),
            React.createElement(Grid, { container: true, xs: 6, justifyContent: "flex-end", alignItems: "center" },
                React.createElement(Grid, { mt: 1, mb: 2, direction: "row" },
                    (resource === null || resource === void 0 ? void 0 : resource[childType]) && (React.createElement(Button, { variant: "outlined", disabled: isSubmitting, onClick: function () { return setShow(!show); }, className: classes.groupedButtons, sx: { mr: 1 } }, !show
                        ? "Show ".concat(upperFirst(childType))
                        : "Hide ".concat(upperFirst(childType)))),
                    React.createElement(Button, { variant: "outlined", disabled: isSubmitting, onClick: function () { return toggleActive(resource); }, className: classes.groupedButtons, sx: { mr: 2 } }, resource.active ? "deactivate" : "activate "),
                    React.createElement(MemberUtmCRUDDialog, { resource: resource, CRUDOperation: "delete", resourceType: resourceType, showButtonStyles: {
                            borderColor: "#e31d38",
                            backgroundColor: "#e31d38",
                            color: "#FFF",
                            fontWeight: 600,
                            // margin: 2,
                            borderRadius: 0,
                            "&:hover": {
                                backgroundColor: "#fff",
                                borderColor: "#e31d38",
                                color: "#e31d38",
                            },
                        }, CRUDButtonStyles: {
                            borderColor: "#e31d38",
                            backgroundColor: "#e31d38",
                            color: "#FFF",
                            fontWeight: 600,
                            borderRadius: 0,
                            width: "100%",
                            border: 1,
                            // marginTop: 1,
                            "&:hover": {
                                backgroundColor: "#fff",
                                borderColor: "#e31d38",
                                color: "#e31d38",
                            },
                        } })))),
        show && (React.createElement(Grid, { xs: 12, container: true },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Divider, null)),
            React.createElement(MemberUtmCard, { resources: resource[childType], resource: resource, resourceType: childType, childType: "mediums", resourceColor: childColor, childColor: "#318953" })))));
}
