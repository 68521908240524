import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import Layout from "../../../Layout";
import { AmacAccountNavWrapper } from "../Navigation/AmacAccountNavWrapper";
import AmacAccountHeader from "../AmacAccountHeader";
import { getShowDeleted } from "../../../../utils/utils";
var AmacAccountActivityLog = function (_a) {
    var response_data = _a.response_data, auth = _a.auth;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState(function () { return getShowDeleted(); }), showDeleted = _c[0], setShowDeleted = _c[1];
    var amac_account = response_data.amac_account, all_documents = response_data.all_documents, activity_log = response_data.activity_log;
    var rows = [];
    var previous_header = "--";
    useEffect(function () {
        localStorage.setItem("showDeleted", JSON.stringify(showDeleted));
    }, [showDeleted]);
    var previous_date = "";
    activity_log.map(function (activity) {
        var created_at = new Date(activity.created_at);
        var display_month = created_at.getMonth() + 1;
        var activity_date = display_month +
            "/" +
            created_at.getDate() +
            "/" +
            created_at.getFullYear();
        var activity_time = created_at.getHours() +
            ":" +
            created_at.getMinutes() +
            ":" +
            created_at.getSeconds();
        activity.display_date = activity_date;
        activity.display_time = activity_time;
        activity.display_description =
            activity.description.charAt(0).toUpperCase() +
                activity.description.slice(1);
        activity.header = "";
        if (activity_date != previous_date) {
            activity.header = activity_date;
        }
        previous_date =
            display_month +
                "/" +
                created_at.getDate() +
                "/" +
                created_at.getFullYear();
        rows.push(activity);
    });
    return (React.createElement(Layout, { title: "AmacAccount Info", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 },
                React.createElement(AmacAccountNavWrapper, { amac_account: amac_account, all_documents: all_documents, activity_log: activity_log }))),
        React.createElement(Grid, { container: true, m: 1, p: 1, xs: 12 },
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 3 },
                    React.createElement(AmacAccountHeader, { amac_account: amac_account }))),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { mb: 2, mt: 2 },
                        React.createElement(Typography, { variant: "h5" }, "Activity")), activity_log === null || activity_log === void 0 ? void 0 :
                    activity_log.map(function (activity) { return (React.createElement(Grid, { xs: 12 },
                        React.createElement(Box, { key: activity.id, p: 1, mb: 1, sx: {
                                fontWeight: "bold",
                                backgroundColor: "#d8d8d8",
                                display: activity.header.length > 0
                                    ? "block"
                                    : "none",
                            } }, activity.header),
                        React.createElement(Box, { key: activity.id, pl: 1, sx: { fontWeight: "bold" } },
                            React.createElement("li", null, activity.display_time +
                                " | " +
                                activity.display_description +
                                " | " +
                                activity.first_name +
                                " " +
                                activity.last_name +
                                " (" +
                                activity.id +
                                ")")),
                        React.createElement(Box, { key: activity.id, pl: 4 },
                            "NEW:\u00A0",
                            JSON.stringify(activity.properties.attributes)),
                        React.createElement(Box, { key: activity.id, pl: 4, sx: {
                                borderColor: "#ddd",
                                color: "#aaaaaa",
                            }, borderBottom: 1, mb: 1, pb: 1 },
                            "OLD:\u00A0",
                            //JSON.stringify(activity.properties.old)
                            JSON.stringify(activity.properties.old, undefined, 4)))
                    /*
                     <Box
                         key={activity.id}
                     >
                         {activity.id}
                     </Box>
                     */
                    ); })
                /*activity_log?.map((activity: any) => (
                    <Box
                        mb={1}
                        key={activity.id}
                        id={`activity_${activity.id}`}
                    >
                        <Paper>
                            <AmacAccountActivity
                                amac_account={amac_account}
                                activity={activity}
                            />
                        </Paper>
                    </Box>
                ))*/
                )))));
};
export default AmacAccountActivityLog;
